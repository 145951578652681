import React from 'react'
import { Close } from '@material-ui/icons'
import ReactModal from 'react-modal'
import styled from 'styled-components'

const ModalBaseSmall = ({ open, onClose, children }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={open}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
      overlayClassName='Overlay'
    >
      <ModalContent>
        <CloseIconContainer onClick={onClose}>
          <CloseIcon />
        </CloseIconContainer>
        {children}
      </ModalContent>
    </Modal>
  )
}

const CloseIconContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  position: absolute;
  right: 10;
  top: 10;
`

const Modal = styled(ReactModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  border-radius: 4px;
  height: 200px;
  background: #fff;
  &:focus {
    outline: 0;
  }
`

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export { ModalBaseSmall }
