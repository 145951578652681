import { FETCH_CAMPAIGN, UPDATE_CAMPAIGN, CREATE_CAMPAIGN, LOADING_CAMPAIGN } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN:
      return {
        ...action.payload.campaign,
        brandImg: action.payload.photo ? action.payload.photo.photoURL : null
      }

    case LOADING_CAMPAIGN:
      return action.payload

    case UPDATE_CAMPAIGN:
    case CREATE_CAMPAIGN:
      return { ...action.payload }

    default:
      return state
  }
}
