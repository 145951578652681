import styled from 'styled-components'
import { Dialog as MaterialModal } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import { Row, Column } from '../'
import { IconClear } from '../iconsV2'

export const Modal = styled(MaterialModal)`
  div {
    &.MuiPaper-elevation24 {
      box-shadow: none;
      border-radius: 8px;
    }
  }
`

export const ModalContainer = styled(Column)`
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
  padding: 24px;
`
export const Close = styled(IconClear)`
  cursor: pointer;
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${props => props.flexDirection};
  width: 100%;
`
export const StyledH4 = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  margin: 0px;
  padding: 7px;
`
