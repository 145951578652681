import React from 'react'
import styled from 'styled-components'

import { installmentStatuses } from '../constants'
import { formatMoney, sanitizeDate } from '../helpers'

const AnticipationRow = ({ portion, handleInstallmentClick }) => {
  return (
    <Row className='accordion-table-row' onClick={handleInstallmentClick(portion.installment_number - 1)}>
      <Col className='accordion-table-cell col-mw50'>
        <p className='acordion-table-cell__content'>
          {portion.installment_number}/{portion.total_installments}
        </p>
      </Col>
      <Col className='accordion-table-cell col-mw100'>R$ {formatMoney(portion.liquidation_transfer_amount)}</Col>
      <Col className='accordion-table-cell col-mw100'>
        {portion.abbreviation === installmentStatuses.PAID
          ? `R$ ${formatMoney(portion.transfer_amount_delayed || portion.transfer_amount)}`
          : '-'}
      </Col>
      <Col className='accordion-table-cell col-mw80'>{sanitizeDate(portion.transfer_date)}</Col>
      <Col className='accordion-table-cell col-mw80'>{sanitizeDate(portion.pay_date)}</Col>
    </Row>
  )
}

const Col = styled.th`
  font-weight: normal;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Row = styled.tr`
  cursor: pointer;
  background-color: white;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
    transition: all 0.3s;
  }
`

export default AnticipationRow
