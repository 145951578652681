import React, { Component } from 'react'
import { i18n } from '../../constants'

class Datepicker extends Component {
  componentDidMount() {
    let element = document.getElementById(this.props.name)
    window.M.Datepicker.init(element, {
      onSelect: this.onDateChange,
      autoClose: true,
      i18n,
      format: 'mmm dd, yyyy'
    })
  }

  onDateChange = event => {
    this.props.onDatepickerChange(event, this.props.name)
  }

  render() {
    const { className, name, placeholder, value, label } = this.props
    return (
      <div className={`input-field ${className}`}>
        <input
          type='text'
          className='datepicker'
          id={name}
          name={name}
          onChange={this.onDateChange}
          placeholder={placeholder}
          value={value}
          readOnly
        />
        <label htmlFor={name}>{label}</label>
      </div>
    )
  }
}

export { Datepicker }
