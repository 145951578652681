import React, { Component, Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { LoadingScreen } from './LoadingScreen'

class CPFRedux extends Component {
  state = {
    loading: false,
    error: false
  }

  checkCPF = async values => {
    const cpf = values.value
    if (cpf.length === 11 && !this.state.loading && this.props.field.handleCPF) {
      this.props.field.handleCPF(cpf)
    }
  }

  render() {
    const { field } = this.props
    const { loading } = this.state
    return (
      <Fragment>
        {loading && <LoadingScreen />}

        <div className={`input-field ${field.className ? field.className : ''}`}>
          <NumberFormat
            id={field.id}
            autoComplete='off'
            placeholder={field.placeholder}
            className='validate'
            format='###.###.###-##'
            onValueChange={this.checkCPF}
            {...field.input}
          />
          <label className='active' htmlFor={field.id}>
            {field.label}
          </label>
          <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
            {field.meta.touched ? field.meta.error : ''}
          </span>
        </div>
      </Fragment>
    )
  }
}

export { CPFRedux }
