import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { Field, change } from 'redux-form'
import { fetchCep } from '../../actions'
import handleField from '../../forms/handleField'
import { addressForm } from '../../forms'
import { LoadingScreen } from './LoadingScreen'
import { normalizeCity } from '../../helpers'
import { getAddressByCep } from '../../services/v3'
import { toast } from 'react-toastify'

const StyledCepWrapper = styled.div`
  margin-top: 45px;
`

class CepInputRedux extends Component {
  state = { showFields: false, data: null, loading: false }

  fetchCep = async ({ target }) => {
    const { dispatch, field } = this.props

    if (target.value && target.value.length > 8) {
      try {
        this.setState({ loading: true })
        const cep = target.value.replace(/\D/g, '')
        const { logradouro, bairro, localidade, uf } = await getAddressByCep(cep)
        const form = 'userAddressForm'
        dispatch(change(form, 'street', logradouro))
        dispatch(change(form, 'district', bairro))
        dispatch(change(form, 'birth_something2', `${normalizeCity(localidade)} - ${uf}`))
        this.setState({ showFields: true })
      } catch (error) {
        console.error(error)
        toast.error('Erro ao buscar o CEP')
      } finally {
        this.setState({ loading: false })
      }
    }
    field.input.onBlur(target.value)
  }

  renderField = field => handleField(field)

  renderFields = () => {
    const { showFields } = this.state
    const { field } = this.props
    if (!showFields && !field.showHiddenFields) return null

    return addressForm.map(field => (
      <Field
        key={field.id}
        label={field.label}
        placeholder={field.placeholder}
        name={field.name}
        id={field.id}
        type={field.type}
        error={field.error}
        readOnly={field.readOnly}
        options={field.options}
        className={field.className}
        component={this.renderField}
      />
    ))
  }

  render() {
    const { field } = this.props
    const { loading } = this.state
    return (
      <div className='row'>
        {loading ? (
          <LoadingScreen small />
        ) : (
          <Fragment>
            <StyledCepWrapper className='input-field col m6 styled-cep'>
              <NumberFormat
                id={field.id}
                autoComplete='off'
                placeholder={field.placeholder}
                className='validate'
                format='#####-###'
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault()
                    this.fetchCep(e)
                  }
                }}
                {...field.input}
                onBlur={this.fetchCep}
              />
              <label className='active' htmlFor={field.id}>
                {field.label}
              </label>
              <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
                {field.meta.touched ? field.meta.error : ''}
              </span>
            </StyledCepWrapper>
            <div className='input-field col m6'>
              <Field
                label='Tipo'
                type='select'
                name='type'
                id='type'
                error='Campo obrigatório'
                options={[
                  { id: 'O', text: '' },
                  { id: 'Residencial', text: 'Residencial' },
                  { id: 'Comercial', text: 'Comercial' }
                ]}
                component={this.renderField}
              />
            </div>
            {this.renderFields()}
          </Fragment>
        )}
      </div>
    )
  }
}

export default connect(null, { fetchCep })(CepInputRedux)
