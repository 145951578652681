import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment-timezone'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const userPersonalDataSchema = yupResolver(
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone: yup.string().required(),
    birth_date: yup
      .string()
      .nullable()
      .required()
      .test(
        'valid_date',
        'Data inválida',
        date => date !== 'Invalid date' && moment().diff(moment(date), 'years', true) <= 100
      )
      .test(
        'under_age',
        'Você precisa ser maior de 18 para poder investir',
        date => moment().diff(moment(date), 'years', true) >= 18
      ),
    nationality: yup.string().required(),
    birth_something: yup
      .object()
      .typeError('Campo Obrigatório')
      .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value)),
    gender_id: yup.string().required(),
    marital_type_id: yup.string().required(),
    rg: yup.string().required(),
    agent: yup.string().required(),
    uf: yup.string().required(),
    mother_name: yup.string().required(),
    father_name: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required()
  })
)
