import React from 'react'
import { withRouter } from 'react-router'

const UserMessageRaw = ({ signup, ...props }) => {
  return (
    <div className='signup-success'>
      <div className='signup-successful'>
        <p>{signup ? 'Parabéns' : 'SEJA BEM-VINDO NOVAMENTE!'}</p>
      </div>
      <div className='signup-success-info'>
        <p>
          {signup
            ? 'Agora você já pode visualizar as campanhas da Kavod Lending.'
            : 'Lembramos que você já pode visualizar as campanhas da Kavod Lending.'}
        </p>
        <p>
          Mas, <span>para poder investir</span>, precisamos de mais alguns dados importantes.
        </p>
        <a href={'/cadastro-completo'}>CLIQUE AQUI e aproveite para completar o seu cadastro agora.</a>
      </div>
      <div className='social-media-icons'>
        <a href='https://www.facebook.com/kavodlending'>
          <img src='/assets/img/social/facebook.png' alt='facebook' />
        </a>
        <a href='https://www.youtube.com/channel/UCGwiK2YOwBPmx1vVz4PuqMQ'>
          <img src='/assets/img/social/youtube.png' alt='youtube' />
        </a>
        <a href='https://www.instagram.com/kavodlending/'>
          <img src='/assets/img/social/instagram.png' alt='instagram' />
        </a>
        <a href='https://twitter.com/kavodlending'>
          <img src='/assets/img/social/twitter.png' alt='twitter' />
        </a>
        <a href='https://www.linkedin.com/company/kavod-lending/'>
          <img src='/assets/img/social/linkedin.png' alt='linkedin' />
        </a>
      </div>
    </div>
  )
}

const UserMessage = withRouter(UserMessageRaw)

export { UserMessage }
