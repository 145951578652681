import React, { Component } from 'react'

class SelectRedux extends Component {
  componentDidMount() {
    const elems = document.querySelectorAll('select')
    window.M.FormSelect.init(elems)
  }

  renderOptions = () => {
    const { options } = this.props.field
    if (!options) return null

    return options.map(option => (
      <option key={option.id} value={option.id}>
        {option.text}
      </option>
    ))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.someValue !== this.props.field.input.value) {
      const elems = document.querySelectorAll('select')
      window.M.FormSelect.init(elems)
    }
  }

  render() {
    const { field } = this.props
    return (
      <div className={`select-group ${field.className ? field.className : ''}`}>
        <div className='input-field'>
          <select name={field.id} autoComplete='off' required id={field.id} {...field.input}>
            {this.renderOptions()}
          </select>
          <label>{field.label}</label>
        </div>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }
}

export { SelectRedux }
