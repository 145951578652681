import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  spinner: {
    marginTop: '4px',
    color: '#fff'
  }
})

const Button = ({ type, disabled, label, onClick, align, loading, ...props }) => {
  const { spinner } = useStyles()
  return (
    <ButtonStyled type={type} disabled={disabled || loading} onClick={onClick} {...props}>
      <Label>{loading ? <CircularProgress className={spinner} size={24} /> : label}</Label>
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button(
  ({ margin = 0, padding = 0, width = '180px' }) => `
  margin: ${margin};
  padding: ${padding};
  background-color: #076f9f;
  border-radius: 24px;
  width: ${width};
  height: 40px;
  border: none;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  
  &:hover { 
    background-color: #07567B;
  }
  &:disabled { 
    background-color: #9E9E9E;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
)

const Label = styled.span`
  margin-bottom: 2px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
`

export { Button }
