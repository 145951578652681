import React from 'react'
import { withRouter } from 'react-router'

import { BorderedButton } from './commom'

import { minInvestmentAmount } from '../constants'

const InvestorContainer = props => (
  <div className='cards-wrapper'>
    <section className='card'>
      <div className='image-wrapper'>
        <img src='/assets/img/home/Card1.jpg' alt='primeiro cartão' />
      </div>
      <div className='content'>
        <div className='text'>
          <p>Investimentos rentáveis para o seu patrimônio.</p>
          <ul className='investor-list'>
            <li className='investor-list__item'>
              <span>Retornos que variam de 14% a 27% ao ano.</span>
            </li>
            <li className='investor-list__item'>
              <span>Investimentos a partir de {minInvestmentAmount.text}.</span>
            </li>
            <li className='investor-list__item'>
              <span>Sem cobrança de taxas no investimento ou recebimento das parcelas.</span>
            </li>
          </ul>
        </div>
        <div className='button-wrapper'>
          <BorderedButton
            label='Saiba Mais'
            onClick={() => (props.authenticated ? props.history.push('/campanhas') : props.history.push('/investidor'))}
          />
        </div>
      </div>
    </section>
    <section className='card'>
      <div className='image-wrapper'>
        <img src='/assets/img/home/Card2.png' alt='primeiro cartão' />
      </div>
      <div className='content'>
        <div className='text'>
          <p>Empréstimo para empresas.</p>
          <ul className='investor-list'>
            <li className='investor-list__item'>
              <span>Uma linha de financiamento que cabe no seu bolso.</span>
            </li>
            <li className='investor-list__item'>
              <span>Taxas de juros a partir de 0,99% a.m</span>
            </li>
            <li className='investor-list__item'>
              <span>Rápida aprovação. Sem burocracia.</span>
            </li>
          </ul>
        </div>
        <div className='button-wrapper'>
          <BorderedButton label='Saiba Mais' onClick={() => props.history.push('/empresa')} />
        </div>
      </div>
    </section>
  </div>
)

const Investor = withRouter(InvestorContainer)

export { Investor }
