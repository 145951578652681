import client from '../providers/fetchClient'

export const getToken = () => window.localStorage.getItem('kavodJWT')

export const setToken = token => window.localStorage.setItem('kavodJWT', `Bearer ${token}`)

export const clearToken = () => window.localStorage.removeItem('kavodJWT')

export const checkCPF = cpf => client.get(`/check-document-number/${cpf}`)

export const getLegacyId = () => client.get('/legacy-id')

export const checkUserFiles = legacyId => client.get(`/check-user-files/${legacyId}/informe_rendimentos.pdf`)

export const getUserFiles = legacyId => client.get(`get-user-files/${legacyId}/informe_rendimentos.pdf`)

export const getUserInfo = () => client.get('current-user')

export const getUserInvestments = kvd_id => client.get(`/investments/${kvd_id}`)

export const getUserPledges = kvd_id => client.get(`/intentions/${kvd_id}`)

export const getUserPledgesAwaitingProcessing = kvd_id => client.get(`/v2/pledges/kvd_id/${kvd_id}/`)

export const getCurrentUser = () => client.get('/current-user')
