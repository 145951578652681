import React from 'react'

const ThankYouCompanies = () => (
  <div className='container thank-you-company'>
    <h2 className='thank-you-company h2'>
      Agradecemos o seu interesse em saber mais sobre as nossas linhas de crédito.
    </h2>
    <p>Analisaremos a sua solicitação e entraremos em contato caso necessário.</p>
    <p>Desde já agradecemos a compreensão.</p>
    <div className='social-media-icons social-centered'>
      <a href='https://www.facebook.com/kavodlending'>
        <img src='/assets/img/social/facebook.png' alt='facebook logo' />
      </a>
      <a href='https://www.youtube.com/channel/UCGwiK2YOwBPmx1vVz4PuqMQ'>
        <img src='/assets/img/social/youtube.png' alt='youtube logo' />
      </a>
      <a href='https://www.instagram.com/kavodlending/'>
        <img src='/assets/img/social/instagram.png' alt='instagram logo' />
      </a>
      <a href='https://twitter.com/kavodlending'>
        <img src='/assets/img/social/twitter.png' alt='twitter logo' />
      </a>
      <a href='https://www.linkedin.com/company/kavod-lending/'>
        <img src='/assets/img/social/linkedin.png' alt='linkedin logo' />
      </a>
    </div>
  </div>
)

export default ThankYouCompanies
