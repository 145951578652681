import React, { useState } from 'react'
import styled from 'styled-components'
import { CheckCircle, Cancel, List as ListIcon } from '@material-ui/icons'

import ModalAggregatePledges from './ModalAggregatePledges'

import { formatMoney, getOldStatus, verifyPledgeSteps } from '../../helpers'
import { contentOptions, campaignStatuses } from '../../constants'

const closedOptions = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION]

const PledgeContent = ({ color, title, caption, handleCardDelete }) => {
  return (
    <CardContainer>
      <CardTitle color={color}>{title}</CardTitle>
      {caption.map((cpt, index) => (
        <CardCaption key={index}>{cpt}</CardCaption>
      ))}
      {handleCardDelete && <CardDelete onClick={handleCardDelete} />}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CardTitle = styled.p`
  color: ${({ color }) => color};
  font-weight: bold;
`

const CardCaption = styled.p`
  font-size: 0.8em;
`

const CardDelete = styled(Cancel)`
  color: #a80303;
  position: absolute;
  font-size: 2.3em !important;
  bottom: 0;
  margin: 0.3em;
  cursor: pointer;
  right: 0;
`

const PledgeSteps = ({ pledge, campaignStatus }) => {
  const { payments_received, disbursement_done } = verifyPledgeSteps(
    pledge.campaign.campaign_status.abbreviation,
    campaignStatus
  )

  return (
    <PledgeStepsContainer>
      <EachStepContainer>
        <I color={payments_received ? 'green' : 'grey'}>
          <CheckCircle />
        </I>
        <p>{payments_received ? 'TED dos investidores recebidas' : 'Aguardando TED dos investidores'}</p>
      </EachStepContainer>
      <EachStepContainer>
        <I color={disbursement_done ? 'green' : 'grey'}>
          <CheckCircle />
        </I>
        <p>{disbursement_done ? 'Desembolso realizado' : 'Processando desembolso'}</p>
      </EachStepContainer>
      <EachStepContainer>
        <I color='grey'>
          <CheckCircle />
        </I>
        <p>Aguardando emissão das CCBs</p>
      </EachStepContainer>
    </PledgeStepsContainer>
  )
}

const PledgeStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

const EachStepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const I = styled.i`
  color: ${({ color }) => color};
  font-size: 16px;
  margin-right: 5px;
`

const AggregatePledgesCard = ({ pledges, handleDeleteCard, handleDeleteAggregateCard }) => {
  const [deleting, setDeleting] = useState(false)
  const [isModalPledgesOpen, setIsModalPledgesOpen] = useState(false)

  const handleDeleteClick = e => {
    e.stopPropagation()
    setDeleting(true)
  }

  const handleConfirmDeleteClick = e => {
    e.stopPropagation()
    handleDeleteAggregateCard(pledges)
  }

  const handleCancelDeleteClick = e => {
    e.stopPropagation()
    setDeleting(false)
  }

  const campaignStatus = getOldStatus(pledges[0].campaign.campaign_status.abbreviation)
  return (
    <>
      <PledgesContainer onClick={() => setIsModalPledgesOpen(true)}>
        <PledgesContentImg>
          <Img src={pledges[0].image_url} alt={pledges[0].campaign.name} />
        </PledgesContentImg>
        <PledgesContent>
          <PledgesTitle>Investimento Total:</PledgesTitle>
          <p>R$ {formatMoney(pledges.reduce((total, pledge) => total + pledge.amount, 0))}</p>
        </PledgesContent>
        <PledgesContentBigger>
          {(() => {
            switch (pledges[0].pledge_status.abbreviation) {
              case 'A':
                return <PledgeContent {...contentOptions.mainList} />
              case 'C':
                return <PledgeContent {...contentOptions.canceledPledge} handleCardDelete={handleDeleteClick} />
              case 'OH':
                return closedOptions.includes(campaignStatus) ? (
                  <PledgeContent {...contentOptions.finishedCampaign} handleCardDelete={handleDeleteClick} />
                ) : (
                  <PledgeContent {...contentOptions.holdList} />
                )
              default:
                return <PledgeSteps pledge={pledges[0]} campaignStatus={campaignStatus} />
            }
          })()}
        </PledgesContentBigger>
        <OptionsDiv>
          <ListIcon fontSize='large' />
        </OptionsDiv>
        {deleting && (
          <DeleteContainer>
            <ButtonRed onClick={handleConfirmDeleteClick}>Confirmar exclusão</ButtonRed>
            <ButtonBordered onClick={handleCancelDeleteClick}>Cancelar</ButtonBordered>
          </DeleteContainer>
        )}
      </PledgesContainer>
      <ModalAggregatePledges
        pledges={pledges}
        handleDeleteCard={handleDeleteCard}
        open={isModalPledgesOpen}
        onClose={_ => setIsModalPledgesOpen(false)}
      />
    </>
  )
}

const PledgesContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`

const Button = styled.button`
  padding: 0.7em 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 0 0.8em;
  transition: all 0.3s;

  @media (max-width: 769px) {
    width: 80%;
    margin: 1em 0;
  }
`

const ButtonRed = styled(Button)`
  color: #fff;
  background: #a80303;

  :hover {
    background-color: #880202 !important;
  }

  :focus {
    background-color: #880202 !important;
  }
`

const ButtonBordered = styled(Button)`
  border: 1px solid #555;
  color: #555;

  :hover {
    background: #555 !important;
    color: white;
    border: 1px solid white;
  }

  :focus {
    background: #555 !important;
    color: white;
    border: 1px solid white;
  }
`

const PledgesContent = styled.div`
  padding: 14px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 150px;

  @media (max-width: 1025px) {
    min-height: initial;
  }
`

const PledgesContentImg = styled(PledgesContent)`
  background-color: white;
`

const Img = styled.img`
  align-self: center;
  height: 128px;
  width: 213px;
`

const PledgesTitle = styled.p`
  font-weight: 600;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 1025px) {
    width: initial;
  }
`

const PledgesContentBigger = styled.div`
  padding: 14px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 150px;

  @media (max-width: 950px) {
    width: 90%;
    justify-content: center;
  }
`

const OptionsDiv = styled.div`
  margin: 30px;
  margin-left: 10px;
  cursor: pointer;
`

const DeleteContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

export default AggregatePledgesCard
