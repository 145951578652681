import React, { Component } from 'react'
import newsInfo from '../constants/homeNewsInfo'
class HomeNews extends Component {
  state = { front: 0, rear: 3, maxSize: 7, info: newsInfo }

  previousNews = () => {
    this.setState(
      this.state.front !== 0
        ? {
            front: this.state.front - 1,
            rear: this.state.rear - 1
          }
        : {}
    )
  }

  nextNews = () => {
    this.setState(
      this.state.maxSize !== this.state.rear ? { rear: this.state.rear + 1, front: this.state.front + 1 } : {}
    )
  }

  render() {
    const { front, rear, info, maxSize } = this.state
    return (
      <div className='section-description'>
        <div className='text-wrapper'>
          <h3>Na mídia</h3>
          <p>Investidor, dê mais rentabilidade ao seu patrimônio.</p>
          <p>Empreendedor, consiga empréstimos a juros justos.</p>
        </div>
        <div className='news-container' id='news-container'>
          <img
            className={front !== 0 ? 'previous-news' : 'previous-news hidden'}
            src='/assets/img/home/next.svg'
            onClick={this.previousNews}
            alt='notícia anterior'
          />
          {info.map((news, index) => (
            <div key={index} className={index >= front && index <= rear ? 'news-content' : 'news-content hidden'}>
              <a href={news.href} target='_blank' rel='noopener noreferrer'>
                <div className='news-img'>
                  <img src={news.src} alt={news.alt} />
                </div>
              </a>
            </div>
          ))}
          <img
            className={rear !== maxSize ? 'next-news' : 'next-news hidden'}
            src='/assets/img/home/next.svg'
            onClick={this.nextNews}
            alt='próxima notícia'
          />
        </div>
      </div>
    )
  }
}

export { HomeNews }
