import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { navbarLinks } from '../../constants'

class ToolbarContentWithRouter extends Component {
  componentDidMount() {
    let elems = document.querySelectorAll('.dropdown-trigger')
    let options = {
      hover: true,
      coverTrigger: false,
      constrainWidth: false
    }
    window.M.Dropdown.init(elems, options)
  }

  logout = () => {
    window.localStorage.removeItem('kavodJWT')
    this.props.fetchCurrentUser()
  }

  renderContent = () => {
    const { currentUser } = this.props
    return navbarLinks.map(
      ({ anchor, text, className, href, dataTarget, blank, rel, to, onlyLogged, onlyNonLogged, logout }) => {
        if (onlyLogged && !currentUser.authenticated) return null

        if (onlyNonLogged && currentUser.authenticated) return null

        if (anchor) {
          return (
            <li key={text}>
              <a
                className={className}
                href={href}
                data-target={dataTarget || null}
                target={blank ? '_blank' : null}
                rel={rel || null}
              >
                {text}
              </a>
            </li>
          )
        }

        return (
          <li key={text}>
            {logout ? (
              <p className={className} onClick={this.logout}>
                {text}
              </p>
            ) : (
              <Link className={className} to={to}>
                {text}
              </Link>
            )}
          </li>
        )
      }
    )
  }

  render() {
    return <div>{this.renderContent()}</div>
  }
}

const ToolbarContent = withRouter(ToolbarContentWithRouter)

export { ToolbarContent }
