import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { LoadingScreen } from '../components/commom'

import { generateIncomeReportData, downloadIncomeReportPDF } from '../utils'

const UserAccountOptionsPage = () => {
  const [loading, setLoading] = useState(true)
  const [userIRData, setUserIRData] = useState(null)

  useEffect(() => {
    const fetchIncomeReportData = async () => {
      try {
        const userIncomeReportData = await generateIncomeReportData()
        setUserIRData(userIncomeReportData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchIncomeReportData()
  }, [])

  const handleIncomeReport = async _ => {
    try {
      setLoading(true)
      await downloadIncomeReportPDF(userIRData)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <LoadingScreen />

  return (
    <div className='container user-account-options-wrapper'>
      <div className='row'>
        <div className='col s12 m4 center-align'>
          <Link to={'/cadastro-completo'}>
            <img
              src='/assets/img/platform/user-account.png'
              alt='Minha conta | KavodLending'
              className='responsive-img'
            />
          </Link>
        </div>
        <div className='col s12 m4 center-align'>
          <Link to='/meus-investimentos'>
            <img
              src='/assets/img/platform/my-investments.png'
              alt='Meus Investimentos | KavodLending'
              className='responsive-img'
            />
          </Link>
        </div>
        <div className='col s12 m4 center-align'>
          <Link to='/minhas-indicacoes'>
            <img
              src='/assets/img/platform/my-indications.png'
              alt='Minhas Indicações | KavodLending'
              className='responsive-img'
            />
          </Link>
        </div>
        <div className='col s12 m4 center-align'>
          <a
            rel='noopener noreferrer'
            href='https://yubb.com.br/melhores-empresas-de-investimento/kavod-lending'
            target='_blank'
          >
            <img
              src='/assets/img/platform/SeloYubbBtn.png'
              alt='Meus Investimentos | KavodLending'
              className='responsive-img'
            />
          </a>
        </div>
        {userIRData && (
          <div className='col s12 m4 center-align'>
            <img
              src='/assets/img/platform/IrpfBtn.png'
              alt='Meus Investimentos | KavodLending'
              className='responsive-img'
              onClick={handleIncomeReport}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default connect()(UserAccountOptionsPage)
