import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import CampaignCard from '../components/CampaignCard'
import { LoadingScreen } from '../components/commom'

import { fetchCurrentUser } from '../actions'
import { campaignTypes } from '../constants'
import { getCampaigns, getPhotos } from '../services/v3'

const CampaignsPage = ({ currentUser, fetchCurrentUser }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setIsLoading(true)

        const { data: campaigns } = await getCampaigns({ campaign_type_id: campaignTypes.AVAL })

        const avalAndGuaranteeCampaigns = await Promise.all(
          campaigns.map(async campaign => {
            let campaignPhotos = []
            try {
              const respCampaignPhotos = await getPhotos({ campaign_id: campaign.id })
              campaignPhotos = respCampaignPhotos ? respCampaignPhotos.data : []
            } catch (error) {
              return campaign
            }
            return {
              ...campaign,
              brand_image:
                campaignPhotos.length !== 0
                  ? campaignPhotos.find(
                      ({ campaign_photo_type_id, campaign_photo_type, photo_url }) =>
                        campaign_photo_type_id && photo_url && campaign_photo_type.abbreviation === 'B'
                    )
                  : null
            }
          })
        )
        const validCampaigns = avalAndGuaranteeCampaigns
          .filter(campaign => {
            const serverTime = moment.utc(campaign.publication_date)
            return (
              campaign.published === 1 &&
              campaign.campaign_status_id &&
              (campaign.publication_date === null ||
                moment
                  .tz('America/Araguaina')
                  .diff(
                    moment.tz(`${serverTime.format('YYYY-MM-DD')} ${serverTime.format('HH:mm')}`, 'America/Araguaina')
                  ) >= 0)
            )
          })
          .sort((a, b) => b.id - a.id)

        setCampaigns(validCampaigns)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar as campanhas')
      } finally {
        setIsLoading(false)
      }
    }
    fetchCurrentUser()
    fetchInfos()
  }, [fetchCurrentUser])

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <section id='campaigns-page'>
      <div className='container'>
        <div className='row'>
          {campaigns.length === 0 ? (
            <Title>Nenhuma campanha foi encontrada nessa modalidade.</Title>
          ) : (
            campaigns.map(campaign => <CampaignCard key={campaign.id} campaign={campaign} currentUser={currentUser} />)
          )}
        </div>
      </div>
    </section>
  )
}

const Title = styled.h4`
  text-align: center;
`

const mapStateToProps = ({ currentUser }) => ({
  currentUser
})

export default connect(mapStateToProps, { fetchCurrentUser })(CampaignsPage)
