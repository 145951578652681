import * as yup from 'yup'

export const modalRefuseProposalResolver = yup.object().shape({
  observation: yup
    .string()
    .max(255, 'Deve ter no máximo 255 caracteres')
    .required('Campo obrigatório.')
})

export const modalAcceptProposalResolver = yup.object().shape({
  observation: yup
    .string()
    .max(255, 'Deve ter no máximo 255 caracteres')
    .nullable()
})
