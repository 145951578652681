import client from '../providers/fetchClient'

export const fetchCep = zipCode => dispatch => {
  dispatch({ type: 'FETCH_CEP_LOADING', payload: null })

  return new Promise((resolve, reject) => {
    let token = window.localStorage.getItem('kavodJWT')

    if (!token) {
      return reject({ err: 'not authenticated!' })
    }

    client
      .get(`zip_code/${zipCode}`)
      .then(res => resolve(res.data))
      .catch(() => reject({ error: 'Not able to fetch zip_code' }))
  })
}
