import React, { useState } from 'react'
import styled from 'styled-components'
import Label from './Label'
import Error from './Error'
import SelectComponent, { createFilter, components } from 'react-select'
import { Controller } from 'react-hook-form'
import { colors } from '../../Theme'
import { Grid } from '@material-ui/core'

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps
  const newProps = Object.assign(props, { innerProps: rest })
  return <StyledOption {...newProps}>{children}</StyledOption>
}

const StyledOption = styled(components.Option)`
  :hover {
    background-color: rgb(232, 240, 254);
  }
`

const Select = ({
  control,
  rules,
  defaultValue = '',
  name,
  error,
  label,
  options,
  isLoading = false,
  isDisabled = false,
  isClearable = false,
  isSearchable = true,
  hideArrow = false,
  filterIgnoreCase = true,
  filterIgnoreAccents = true,
  filterTrim = true,
  filterMatchFrom = 'any',
  ...props
}) => {
  const [focused, setFocused] = useState(false)

  const style = {
    container: base => ({
      ...base,
      width: '100%',
      height: '46px'
    }),
    control: base => ({
      ...base,
      height: '46px',
      border: 0,
      borderRadius: 0,
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.boldGray}`
    }),
    valueContainer: base => ({
      ...base,
      height: '46px',
      padding: '0px'
    }),
    input: base => ({
      ...base,
      height: '43px',
      padding: '0px'
    }),
    option: base => ({
      ...base,
      backgroundColor: '#fff',
      color: '#000'
    })
  }

  const filterConfig = {
    ignoreCase: filterIgnoreCase,
    ignoreAccents: filterIgnoreAccents,
    trim: filterTrim,
    matchFrom: filterMatchFrom
  }

  return (
    <Container onFocus={() => setFocused(true)} item xs={12} {...props}>
      <Label focused={focused} label={label} />
      <Controller
        as={
          <SelectComponent
            options={options}
            styles={style}
            isLoading={isLoading}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isSearchable={isSearchable}
            components={
              hideArrow
                ? { IndicatorSeparator: () => null, DropdownIndicator: () => null, Option }
                : { IndicatorSeparator: () => null, Option }
            }
            filterOption={createFilter(filterConfig)}
          />
        }
        control={control}
        rules={rules}
        onChange={([selected]) => {
          return selected
        }}
        name={name}
        defaultValue={{ value: defaultValue }}
      />
      <Error error={error} />
    </Container>
  )
}

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export { Select }
