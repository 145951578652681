import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

const Dropdown = ({ title, children, subDropdown, onClick, href }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [subDropdownIsOpen, setSubDropdownIsOpen] = useState(false)

  const handleDropdownIsOpen = () => setDropdownIsOpen(!dropdownIsOpen)

  const handleSubDropdownIsOpen = () => {
    setSubDropdownIsOpen(!subDropdownIsOpen)
  }
  const handleDropdownMouseOver = () => setDropdownIsOpen(true)

  const handleSubDropdownMouseOver = () => setSubDropdownIsOpen(true)

  return (
    <Fragment>
      <div className='nav-item-bordered' onClick={handleDropdownIsOpen}>
        {href ? (
          window.innerWidth < 768 ? (
            dropdownIsOpen ? (
              <a href='#!' onClick={handleDropdownIsOpen}>
                {title}
                <i className='icon fas fa-sort-down nav-icon' />
              </a>
            ) : (
              <a href={href} onClick={handleDropdownIsOpen}>
                {title}
                <i className='icon fas fa-sort-down nav-icon' />
              </a>
            )
          ) : (
            <>
              <a href={href} onMouseOver={handleDropdownMouseOver}>
                {title}
              </a>
              <i className='icon fas fa-sort-down nav-icon' />
            </>
          )
        ) : (
          <Fragment>
            <span onMouseOver={handleDropdownMouseOver}>{title}</span> <i className='icon fas fa-sort-down nav-icon' />
          </Fragment>
        )}
      </div>

      {dropdownIsOpen && (
        <div
          className='dropdown'
          onMouseLeave={() => {
            subDropdownIsOpen && setSubDropdownIsOpen(false)
            !dropdownIsOpen && setSubDropdownIsOpen(false)
          }}
        >
          {children.map((child, index) => {
            if (subDropdown) {
              return (
                <div
                  onClick={handleSubDropdownIsOpen}
                  className='sub-item nav-item-bordered'
                  key={index}
                  onMouseOver={handleSubDropdownMouseOver}
                >
                  <span>
                    {child.title}
                    <i className='icon fas fa-sort-down nav-icon' />
                  </span>
                </div>
              )
            }

            if (child.callback) {
              return (
                <a
                  href='#!'
                  onMouseOver={handleSubDropdownMouseOver}
                  className='sub-item nav-item-bordered'
                  key={index}
                  onClick={_ => {
                    child.callback()
                    onClick()
                  }}
                >
                  {child.title}
                </a>
              )
            }

            return child.blank ? (
              <a
                href={child.href}
                target='blank'
                onMouseOver={handleSubDropdownMouseOver}
                className='sub-item nav-item-bordered'
                key={index}
                onClick={onClick}
              >
                {child.title}
              </a>
            ) : (
              <Link
                to={child.href}
                onClick={() => {
                  setDropdownIsOpen(false)
                  onClick()
                }}
                className='sub-item nav-item-bordered'
                onMouseOver={handleSubDropdownMouseOver}
                key={index}
              >
                {child.title}
              </Link>
            )
          })}
          {subDropdownIsOpen && subDropdown && (
            <div className='dropdown-content2' onMouseLeave={handleSubDropdownIsOpen}>
              {subDropdown.map((item, index) =>
                item.blank ? (
                  <a
                    href={item.href}
                    target='blank'
                    key={index}
                    className='sub-item nav-item-bordered'
                    onClick={() => {
                      setSubDropdownIsOpen(false)
                      setDropdownIsOpen(false)
                      onClick()
                    }}
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={item.href}
                    key={index}
                    className='sub-item nav-item-bordered'
                    onMouseOver={handleSubDropdownMouseOver}
                    onClick={() => {
                      setSubDropdownIsOpen(false)
                      setDropdownIsOpen(false)
                      onClick()
                    }}
                  >
                    {item.title}
                  </Link>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default Dropdown
