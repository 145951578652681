import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const userFinancialInfoSchema = yupResolver(
  yup.object().shape({
    type: yup.string().required(),
    code: yup
      .object()
      .typeError('Campo Obrigatório')
      .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value)),
    account: yup.string().required(),
    account_digit: yup.string().required(),
    agency: yup.string().required(),
    monthly_earn: yup.string().required(),
    property_value: yup.string().required()
  })
)
