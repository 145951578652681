export const campaignInformationFormValidator = campaignData => {
  let response = {}
  response.errors = []
  if (!campaignData.name) {
    response.isValid = false
    response.errors.push('Nome é um campo obrigatório')
    return response
  }

  if (!campaignData.url) {
    response.isValid = false
    response.errors.push('URL é um campo obrigatório')
    return response
  }

  if (!campaignData.company_name) {
    response.isValid = false
    response.errors.push('Empresa é um campo obrigatório')
    return response
  }

  if (!campaignData.cnpj) {
    response.isValid = false
    response.errors.push('CNPJ é um campo obrigatório')
    return response
  }

  if (isNaN(parseInt(campaignData.type))) {
    response.isValid = false
    response.errors.push('Tipo de companhia é um campo obrigatório')
    return response
  }
  if (campaignData.amount === 0) {
    response.isValid = false
    response.errors.push('Meta da captação é um campo obrigatório')
    return response
  }

  if (campaignData.amount < 0) {
    response.isValid = false
    response.errors.push('Meta da captação não pode ser negativo')
    return response
  }

  if (!campaignData.rate_month || campaignData.rate_month === 0) {
    response.isValid = false
    response.errors.push('Taxa mensal é um campo obrigatório')
    return response
  }

  if (campaignData.rate_month < 0) {
    response.isValid = false
    response.errors.push('Taxa mensal não pode ser negativo')
    return response
  }

  if (!campaignData.TAC) {
    response.isValid = false
    response.errors.push('TAC é um campo obrigatório')
    return response
  }

  if (campaignData.TAC <= 0) {
    response.isValid = false
    response.errors.push('TAC não pode ser 0')
    return response
  }

  if (campaignData.TAC < 0) {
    response.isValid = false
    response.errors.push('TAC não pode ser negativo')
    return response
  }

  if (campaignData.max_amount === 0) {
    response.isValid = false
    response.errors.push('Valor máximo é um campo obrigatório')
    return response
  }

  if (campaignData.max_amount < 0) {
    response.isValid = false
    response.errors.push('Valor máximo não pode ser negativo')
    return response
  }

  if (campaignData.max_amount < campaignData.min_amount) {
    response.isValid = false
    response.errors.push('Valor máximo não pode ser menor que o valor mínimo.')
    return response
  }

  if (campaignData.min_amount === 0) {
    response.isValid = false
    response.errors.push('Valor mínimo é um campo obrigatório')
    return response
  }

  if (campaignData.min_amount < 0) {
    response.isValid = false
    response.errors.push('Valor mínimo não pode ser negativo')
    return response
  }

  if (!campaignData.installments || campaignData.installments === 0) {
    response.isValid = false
    response.errors.push('Número de parcelas é um campo obrigatório')
    return response
  }

  if (campaignData.installments < 0) {
    response.isValid = false
    response.errors.push('Número de parcelas não pode ser negativo')
    return response
  }

  if (!campaignData.grace_period && campaignData.grace_period !== 0) {
    response.isValid = false
    response.errors.push('Período de carência é um campo obrigatório')
    return response
  }

  if (campaignData.grace_period < 0) {
    response.isValid = false
    response.errors.push('Período de carência não pode ser negativo')
    return response
  }

  if (!campaignData.publication_date) {
    response.isValid = false
    response.errors.push('Data de publicação é um campo obrigatório')
    return response
  }

  if (!campaignData.publication_time) {
    response.isValid = false
    response.errors.push('Horário de publicação é um campo obrigatório')
    return response
  }

  if (!campaignData.start_date) {
    response.isValid = false
    response.errors.push('Data de abertura é um campo obrigatório')
    return response
  }

  if (!campaignData.start_time) {
    response.isValid = false
    response.errors.push('Horário de abertura é um campo obrigatório')
    return response
  }

  if (!campaignData.end_date) {
    response.isValid = false
    response.errors.push('Data de término é um campo obrigatório')
    return response
  }

  if (!campaignData.end_time) {
    response.isValid = false
    response.errors.push('Horário de término é um campo obrigatório')
    return response
  }

  const campaignPublicationAt = new Date(`${campaignData.publication_date} ${campaignData.publication_time}`)
  const campaignStartsAt = new Date(`${campaignData.start_date} ${campaignData.start_time}`)
  const campaignEndsAt = new Date(`${campaignData.end_date} ${campaignData.end_time}`)

  if (campaignPublicationAt >= campaignStartsAt) {
    response.isValid = false
    response.errors.push('Data de abertura deve ser maior do que a data de publicação')
    return response
  }

  if (campaignStartsAt >= campaignEndsAt) {
    response.isValid = false
    response.errors.push('Data de término deve ser maior do que a data de abertura')
    return response
  }

  response.isValid = true
  return response
}
