import axios from 'axios'
import { getToken } from '../services'

// export const __API__ = 'https://api-hmg.kavodlending.com'

// export const __API__ = 'ec2-18-231-172-73.sa-east-1.compute.amazonaws.com/api'
// export const __API__ = 'http://192.168.0.8:8080'

const getEnviroment = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://api-prod.kavodlending.com'
    case 'homolog':
      return 'https://api-hmg.kavodlending.com'
    case 'dev':
    default:
      return 'https://api-dev.kavodlending.com'
  }
}

const __API__ = getEnviroment()

const defaultOptions = {
  baseURL: __API__
}

let instance = axios.create(defaultOptions)

instance.interceptors.request.use(config => {
  config.headers.Authorization = getToken()
  return config
})

instance.interceptors.response.use(
  function(response) {
    return response
  },
  error => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/empresa' &&
      window.location.pathname !== '/parcerias' &&
      window.location.pathname !== '/parcerias/'
    ) {
      window.location.href = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
