import React, { Fragment } from 'react'

const HomeQuestions = () => (
  <Fragment>
    <div className='questionCardContainer'>
      <div className='questionCard'>
        <p className='questionTitle'>Quanto pago de IR, IOF e outros custos?</p>
        <p className='questionBody'>
          Não há incidência de IOF para o investidor, pois o primeiro recebimento é sempre pelo menos 30 dias depois do
          investimento. O único custo é o Imposto de Renda baseado na tabela regressiva de renda fixa, que começa em
          22,5% e pode chegar a 15% de acordo com o prazo da operação. Para ver uma tabela com os valores e os prazos e
          entender como calcular o valor de IR,{' '}
          <a
            href='https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/crowdfunding/tabelaIR.jpeg'
            target='_blank'
            className='bold questionBody__link'
            rel='noopener noreferrer'
          >
            {' '}
            clique aqui
          </a>
        </p>
      </div>
      <div className='questionCard'>
        <p className='questionTitle'>O que acontece se a Kavod quebrar?</p>
        <p className='questionBody'>
          Não há nenhum risco para os investidores. Como a Kavod conecta os investidores e as empresas, mesmo que a
          Kavod deixe de existir, o compromisso entre empresa e investidores permanece e os pagamentos continuarão sendo
          feitos normalmente através do nosso parceiro financeiro. Trabalhamos duro para que isso não aconteça!
        </p>
      </div>
    </div>
    <div className='questionCardContainer'>
      <div className='questionCard'>
        <p className='questionTitle'>Vocês cobram TED, quando fazem o pagamento mensal?</p>
        <p className='questionBody'>
          Não, a Kavod não cobra nada do investidor. Isso mesmo, sem taxas ocultas ou tarifas abusivas. A nossa receita
          vem 100% das empresas que solicitam o empréstimo. A taxa de juros paga pela empresa é exatamente a mesma taxa
          de juros que o investidor recebe. Além disso, a empresa paga uma comissão à Kavod, apenas caso a captação seja
          concluída com sucesso
        </p>
      </div>
      <div className='questionCard'>
        <p className='questionTitle'>Qual é o risco de investir?</p>
        <p className='questionBody'>
          Risco é uma medida relativa, cada pessoa enxerga de uma maneira diferente. Nas nossas operações, o risco varia
          de acordo com cada empresa e as garantias, assim como o retorno (quanto maior o risco, maior o retorno). O
          nosso forte é a transparência, então, com o relatório de Rating do Serasa, o investidor tem em mãos um
          material bastante completo, de fonte isenta e confiável, com todas as informações relevantes sobre a empresa
          (balanços financeiros, restrições, nome dos sócios, etc) para que ele possa avaliar e decidir exatamente em
          qual empresa quer investir.
        </p>
      </div>
    </div>
  </Fragment>
)

export { HomeQuestions }
