export const formatPersonData = (values, kvdId) => {
  const prefix = values.phone
    .substr(0, 4)
    .replace(/\(/g, '')
    .replace(/\)/g, '')
  const phone = values.phone
    .substr(5)
    .replace(/-/g, '')
    .replace(/ /g, '')

  const date = values.birth_date.split('/')
  const birthDate = `${date[2]}-${date[1]}-${date[0]}T00:00:00.000Z`

  return Object.assign(
    {},
    {
      person: {
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender,
        marital_status_id: values.marital_status_id,
        naturality: values.birth_something,
        nationality: values.nationality,
        // birth_date: formatDateToBackEnd(values.birth_date),
        birth_date: birthDate,
        mother_name: values.mother_name,
        father_name: values.father_name
      }
    },
    { kvd_id: kvdId },
    { phone: { prefix, phone } },
    {
      document: {
        kvd_id: kvdId,
        number: values.rg,
        agent: values.agent,
        uf: values.agent_uf,
        personal_document_type_id: 'R'
      }
    }
  )
}

export const formatAddressData = (values, id) => {
  return Object.assign(
    {},
    {
      address: {
        zip_code: values.zip_code.replace(/-/g, '').replace(/" "/g, ''),
        street: values.street,
        district: values.district,
        uf: values.birth_something2.split(' - ')[1],
        city: values.birth_something2.split(' - ')[0],
        type: values.type,
        number: values.number,
        complement: values.complement,
        reference: values.reference
      }
    },
    { id: id }
  )
}

export const formatInvestorData = (values, kvd_id) =>
  Object.assign(
    {},
    {
      investor: {
        monthly_earn: values.monthly_earn / 100,
        property_value: values.property_value / 100
      }
    },
    { kvd_id }
  )

export const formatBankData = (values, id) => {
  return Object.assign(
    {},
    {
      bank: {
        code: values.code.split(' - ')[0],
        agency: values.agency.trim(),
        agency_digit: values.agency_digit,
        account_digit: values.account_digit,
        account: values.account.trim(),
        type: values.type
      }
    },
    { id: id }
  )
}

export const formatDocumentName = string => {
  return string.replace(/[^A-Z0-9.]+/gi, '_')
}

export const formatCurriculumName = string => string.normalize('NFD').replace(/[^A-Z0-9.]+/gi, '')

export const formatCPF = string => {
  if (string) return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

export const formatCNPJ = string => {
  if (string) return string.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const formatZipCode = string => {
  if (string) return string.replace(/(\d{5})(\d{3})/g, '$1-$2')
}

export const formatName = name => {
  if (typeof name !== 'string') return ''
  return name
    .toLowerCase()
    .split(/\s/)
    .reduce((acc, currentName) => {
      if (!currentName) return acc
      const captilizedName = currentName.charAt(0).toUpperCase() + currentName.slice(1)
      return `${acc} ${captilizedName}`.trim()
    }, '')
}

export const formatValueToBrl = value => {
  return isNaN(Number(value)) ? formatToBrl.format(0) : formatToBrl.format(Number(value))
}

export const formatToBrl = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})
