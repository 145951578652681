export const accountTypeOptions = [
  { value: '', label: '' },
  { value: 'CC', label: 'Conta Corrente' },
  { value: 'CP', label: 'Conta Poupança' }
]

export const banks = {
  '': null,
  '001 - BANCO DO BRASIL S/A': null,
  '002 - BANCO CENTRAL DO BRASIL': null,
  '003 - BANCO DA AMAZONIA S.A': null,
  '004 - BANCO DO NORDESTE DO BRASIL S.A': null,
  '007 - BANCO NAC DESENV. ECO. SOCIAL S.A': null,
  '008 - BANCO MERIDIONAL DO BRASIL': null,
  '020 - BANCO DO ESTADO DE ALAGOAS S.A': null,
  '021 - BANCO DO ESTADO DO ESPIRITO SANTO S.A': null,
  '022 - BANCO DE CREDITO REAL DE MINAS GERAIS SA': null,
  '024 - BANCO DO ESTADO DE PERNAMBUCO': null,
  '025 - BANCO ALFA S/A': null,
  '026 - BANCO DO ESTADO DO ACRE S.A': null,
  '027 - BANCO DO ESTADO DE SANTA CATARINA S.A': null,
  '028 - BANCO DO ESTADO DA BAHIA S.A': null,
  '029 - BANCO DO ESTADO DO RIO DE JANEIRO S.A': null,
  '030 - BANCO DO ESTADO DA PARAIBA S.A': null,
  '031 - BANCO DO ESTADO DE GOIAS S.A': null,
  '032 - BANCO DO ESTADO DO MATO GROSSO S.A.': null,
  '033 - BANCO SANTANDER S.A': null,
  '034 - BANCO DO ESADO DO AMAZONAS S.A': null,
  '035 - BANCO DO ESTADO DO CEARA S.A': null,
  '036 - BANCO DO ESTADO DO MARANHAO S.A': null,
  '037 - BANCO DO ESTADO DO PARA S.A': null,
  '038 - BANCO DO ESTADO DO PARANA S.A': null,
  '039 - BANCO DO ESTADO DO PIAUI S.A': null,
  '041 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A': null,
  '047 - BANCO DO ESTADO DE SERGIPE S.A': null,
  '048 - BANCO DO ESTADO DE MINAS GERAIS S.A': null,
  '059 - BANCO DO ESTADO DE RONDONIA S.A': null,
  '070 - BANCO DE BRASILIA S.A': null,
  '077 - BANCO INTER S.A': null,
  '084 - CC UNIPRIME NORTE DO PARANA': null,
  '104 - CAIXA ECONOMICA FEDERAL': null,
  '106 - BANCO ITABANCO S.A.': null,
  '107 - BANCO BBM S.A': null,
  '109 - BANCO CREDIBANCO S.A': null,
  '116 - BANCO B.N.L DO BRASIL S.A': null,
  '136 - CC UNICRED DO BRASI': null,
  '148 - MULTI BANCO S.A': null,
  '151 - CAIXA ECONOMICA DO ESTADO DE SAO PAULO': null,
  '153 - CAIXA ECONOMICA DO ESTADO DO R.G.SUL': null,
  '165 - BANCO NORCHEM S.A': null,
  '166 - BANCO INTER-ATLANTICO S.A': null,
  '168 - BANCO C.C.F. BRASIL S.A': null,
  '175 - CONTINENTAL BANCO S.A': null,
  '184 - BBA - CREDITANSTALT S.A': null,
  '199 - BANCO FINANCIAL PORTUGUES': null,
  '200 - BANCO FRICRISA AXELRUD S.A': null,
  '201 - BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A': null,
  '204 - BANCO S.R.L S.A': null,
  '205 - BANCO SUL AMERICA S.A': null,
  '206 - BANCO MARTINELLI S.A': null,
  '208 - BANCO PACTUAL S.A': null,
  '210 - DEUTSCH SUDAMERIKANICHE BANK AG': null,
  '211 - BANCO SISTEMA S.A': null,
  '212 - BANCO MATONE S.A': null,
  '213 - BANCO ARBI S.A': null,
  '214 - BANCO DIBENS S.A': null,
  '215 - BANCO AMERICA DO SUL S.A': null,
  '216 - BANCO REGIONAL MALCON S.A': null,
  '217 - BANCO AGROINVEST S.A': null,
  '218 - BBS - BANCO BONSUCESSO S.A.': null,
  '219 - BANCO DE CREDITO DE SAO PAULO S.A': null,
  '220 - BANCO CREFISUL': null,
  '221 - BANCO GRAPHUS S.A': null,
  '222 - BANCO AGF BRASIL S. A.': null,
  '223 - BANCO INTERUNION S.A': null,
  '224 - BANCO FIBRA S.A': null,
  '225 - BANCO BRASCAN S.A': null,
  '228 - BANCO ICATU S.A': null,
  '229 - BANCO CRUZEIRO S.A': null,
  '230 - BANCO BANDEIRANTES S.A': null,
  '231 - BANCO BOAVISTA S.A': null,
  '232 - BANCO INTERPART S.A': null,
  '233 - BANCO MAPPIN S.A': null,
  '234 - BANCO LAVRA S.A.': null,
  '235 - BANCO LIBERAL S.A': null,
  '236 - BANCO CAMBIAL S.A': null,
  '237 - BANCO BRADESCO S.A': null,
  '239 - BANCO BANCRED S.A': null,
  '240 - BANCO DE CREDITO REAL DE MINAS GERAIS S.': null,
  '241 - BANCO CLASSICO S.A': null,
  '242 - BANCO EUROINVEST S.A': null,
  '243 - BANCO STOCK S.A': null,
  '244 - BANCO CIDADE S.A': null,
  '245 - BANCO EMPRESARIAL S.A': null,
  '246 - BANCO ABC ROMA S.A': null,
  '247 - BANCO OMEGA S.A': null,
  '249 - BANCO INVESTCRED S.A': null,
  '250 - BANCO SCHAHIN CURY S.A': null,
  '251 - BANCO SAO JORGE S.A.': null,
  '252 - BANCO FININVEST S.A': null,
  '254 - BANCO PARANA BANCO S.A': null,
  '255 - MILBANCO S.A.': null,
  '256 - BANCO GULVINVEST S.A': null,
  '258 - BANCO INDUSCRED S.A': null,
  '260 - NUBANK': null,
  '261 - BANCO VARIG S.A': null,
  '262 - BANCO BOREAL S.A': null,
  '263 - BANCO CACIQUE': null,
  '264 - BANCO PERFORMANCE S.A': null,
  '265 - BANCO FATOR S.A': null,
  '266 - BANCO CEDULA S.A': null,
  '267 - BANCO BBM-COM.C.IMOB.CFI S.A.': null,
  '275 - BANCO REAL S.A': null,
  '277 - BANCO PLANIBANC S.A': null,
  '282 - BANCO BRASILEIRO COMERCIAL': null,
  '291 - BANCO DE CREDITO NACIONAL S.A': null,
  '294 - BCR - BANCO DE CREDITO REAL S.A': null,
  '295 - BANCO CREDIPLAN S.A': null,
  '300 - BANCO DE LA NACION ARGENTINA S.A': null,
  '302 - BANCO DO PROGRESSO S.A': null,
  '303 - BANCO HNF S.A.': null,
  '304 - BANCO PONTUAL S.A': null,
  '308 - BANCO COMERCIAL BANCESA S.A.': null,
  '318 - BANCO B.M.G. S.A': null,
  '320 - BANCO INDUSTRIAL E COMERCIAL': null,
  '336 - Banco C6 S.A – C6 Bank': null,
  '341 - BANCO ITAU S.A': null,
  '346 - BANCO FRANCES E BRASILEIRO S.A': null,
  '347 - BANCO SUDAMERIS BRASIL S.A': null,
  '351 - BANCO BOZANO SIMONSEN S.A': null,
  '353 - BANCO GERAL DO COMERCIO S.A': null,
  '356 - ABN AMRO S.A': null,
  '366 - BANCO SOGERAL S.A': null,
  '369 - PONTUAL': null,
  '370 - BEAL - BANCO EUROPEU PARA AMERICA LATINA': null,
  '372 - BANCO ITAMARATI S.A': null,
  '375 - BANCO FENICIA S.A': null,
  '376 - CHASE MANHATTAN BANK S.A': null,
  '380 - PICPAY SERVICOS S.A': null, 
  '388 - BANCO MERCANTIL DE DESCONTOS S/A': null,
  '389 - BANCO MERCANTIL DO BRASIL S.A': null,
  '392 - BANCO MERCANTIL DE SAO PAULO S.A': null,
  '394 - BANCO B.M.C. S.A': null,
  '399 - BANCO BAMERINDUS DO BRASIL S.A': null,
  '409 - UNIBANCO - UNIAO DOS BANCOS BRASILEIROS': null,
  '412 - BANCO NACIONAL DA BAHIA S.A': null,
  '415 - BANCO NACIONAL S.A': null,
  '420 - BANCO NACIONAL DO NORTE S.A': null,
  '422 - BANCO SAFRA S.A': null,
  '424 - BANCO NOROESTE S.A': null,
  '434 - BANCO FORTALEZA S.A': null,
  '453 - BANCO RURAL S.A': null,
  '456 - BANCO TOKIO S.A': null,
  '464 - BANCO SUMITOMO BRASILEIRO S.A': null,
  '466 - BANCO MITSUBISHI BRASILEIRO S.A': null,
  '472 - LLOYDS BANK PLC': null,
  '473 - BANCO FINANCIAL PORTUGUES S.A': null,
  '477 - CITIBANK N.A': null,
  '479 - BANCO DE BOSTON S.A': null,
  '480 - BANCO PORTUGUES DO ATLANTICO-BRASIL S.A': null,
  '483 - BANCO AGRIMISA S.A.': null,
  '487 - DEUTSCHE BANK S.A - BANCO ALEMAO': null,
  '488 - BANCO J. P. MORGAN S.A': null,
  '489 - BANESTO BANCO URUGAUAY S.A': null,
  '492 - INTERNATIONALE NEDERLANDEN BANK N.V.': null,
  '493 - BANCO UNION S.A.C.A': null,
  '494 - BANCO LA REP. ORIENTAL DEL URUGUAY': null,
  '495 - BANCO LA PROVINCIA DE BUENOS AIRES': null,
  '496 - BANCO EXTERIOR DE ESPANA S.A': null,
  '498 - CENTRO HISPANO BANCO': null,
  '499 - BANCO IOCHPE S.A': null,
  '501 - BANCO BRASILEIRO IRAQUIANO S.A.': null,
  '504 - BANCO MULTIPLIC S.A': null,
  '505 - BANCO GARANTIA S.A': null,
  '600 - BANCO LUSO BRASILEIRO S.A': null,
  '601 - BFC BANCO S.A.': null,
  '602 - BANCO PATENTE S.A': null,
  '604 - BANCO INDUSTRIAL DO BRASIL S.A': null,
  '607 - BANCO SANTOS NEVES S.A': null,
  '608 - BANCO OPEN S.A': null,
  '610 - BANCO V.R. S.A': null,
  '611 - BANCO PAULISTA S.A': null,
  '612 - BANCO GUANABARA S.A': null,
  '613 - BANCO PECUNIA S.A': null,
  '616 - BANCO INTERPACIFICO S.A': null,
  '617 - BANCO INVESTOR S.A.': null,
  '618 - BANCO TENDENCIA S.A': null,
  '621 - BANCO APLICAP S.A.': null,
  '622 - BANCO DRACMA S.A': null,
  '623 - BANCO PANAMERICANO S.A': null,
  '624 - BANCO GENERAL MOTORS S.A': null,
  '625 - BANCO ARAUCARIA S.A': null,
  '626 - BANCO FICSA S.A': null,
  '627 - BANCO DESTAK S.A': null,
  '628 - BANCO CRITERIUM S.A': null,
  '629 - BANCORP BANCO COML. E. DE INVESTMENTO': null,
  '630 - BANCO INTERCAP S.A': null,
  '633 - BANCO RENDIMENTO S.A': null,
  '634 - BANCO TRIANGULO S.A': null,
  '635 - BANCO DO ESTADO DO AMAPA S.A': null,
  '637 - BANCO SOFISA S.A': null,
  '638 - BANCO PROSPER S.A': null,
  '639 - BIG S.A. - BANCO IRMAOS GUIMARAES': null,
  '640 - BANCO DE CREDITO METROPOLITANO S.A': null,
  '641 - BANCO EXCEL ECONOMICO S/A': null,
  '643 - BANCO SEGMENTO S.A': null,
  '645 - BANCO DO ESTADO DE RORAIMA S.A': null,
  '647 - BANCO MARKA S.A': null,
  '648 - BANCO ATLANTIS S.A': null,
  '649 - BANCO DIMENSAO S.A': null,
  '650 - BANCO PEBB S.A': null,
  '652 - BANCO FRANCES E BRASILEIRO SA': null,
  '653 - BANCO INDUSVAL S.A': null,
  '654 - BANCO A. J. RENNER S.A': null,
  '655 - BANCO VOTORANTIM S.A.': null,
  '656 - BANCO MATRIX S.A': null,
  '657 - BANCO TECNICORP S.A': null,
  '658 - BANCO PORTO REAL S.A': null,
  '702 - BANCO SANTOS S.A': null,
  '705 - BANCO INVESTCORP S.A.': null,
  '707 - BANCO DAYCOVAL S.A': null,
  '711 - BANCO VETOR S.A.': null,
  '713 - BANCO CINDAM S.A': null,
  '715 - BANCO VEGA S.A': null,
  '718 - BANCO OPERADOR S.A': null,
  '719 - BANCO PRIMUS S.A': null,
  '720 - BANCO MAXINVEST S.A': null,
  '721 - BANCO CREDIBEL S.A': null,
  '722 - BANCO INTERIOR DE SAO PAULO S.A': null,
  '724 - BANCO PORTO SEGURO S.A': null,
  '725 - BANCO FINABANCO S.A': null,
  '726 - BANCO UNIVERSAL S.A': null,
  '728 - BANCO FITAL S.A': null,
  '729 - BANCO FONTE S.A': null,
  '730 - BANCO COMERCIAL PARAGUAYO S.A': null,
  '731 - BANCO GNPP S.A.': null,
  '732 - BANCO PREMIER S.A.': null,
  '733 - BANCO NACOES S.A.': null,
  '734 - BANCO GERDAU S.A': null,
  '735 - NEON/BANCO POTTENCIAL S.A': null,
  '736 - BANCO UNITED S.A': null,
  '737 - THECA': null,
  '738 - MARADA': null,
  '739 - BGN': null,
  '740 - BCN BARCLAYS': null,
  '741 - BRP': null,
  '742 - EQUATORIAL': null,
  '743 - BANCO EMBLEMA S.A': null,
  '744 - THE FIRST NATIONAL BANK OF BOSTON': null,
  '745 - BANCO CITIBANK S.A': null,
  '746 - MODAL SA': null,
  '747 - RAIBOBANK DO BRASIL': null,
  '748 - SICREDI': null,
  '749 - BRMSANTIL SA': null,
  '750 - BANCO REPUBLIC NATIONAL OF NEW YORK (BRA': null,
  '751 - DRESDNER BANK LATEINAMERIKA-BRASIL S/A': null,
  '752 - BANCO BANQUE NATIONALE DE PARIS BRASIL S': null,
  '753 - BANCO COMERCIAL URUGUAI S.A.': null,
  '755 - BANCO MERRILL LYNCH S.A': null,
  '756 - BANCO COOPERATIVO DO BRASIL S.A.': null,
  '757 - BANCO KEB DO BRASIL S.A.': null
}

export const bankOptions = Object.keys(banks).map(city => ({ value: city, label: city }))
