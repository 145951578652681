import React from 'react'
import styled from 'styled-components'

import { Input, NumberInput, Select } from '../index'
import Text from '../Text'

const CompanyDataCompleted = ({ isEditing, states, errors, isMobile, isTablet, control }) => {
  return (
    <div>
      <Row>
        <Text mt={5} variant='h4'>
          {' '}
          Dados da empresa{' '}
        </Text>
      </Row>
      <Row>
        <NumberInput
          mt={5}
          inputType='cnpj'
          control={control}
          name='cnpj'
          width={isMobile || isTablet ? '90%' : '25%'}
          error={errors.cnpj}
        />
        <Input
          mt={5}
          label='Empresa (Razão social) *'
          error={errors.company_name}
          control={control}
          name='company_name'
          width={isMobile || isTablet ? '90%' : '65%'}
          ml={isMobile || isTablet ? 0 : 3}
        />
      </Row>
      <Row>
        <Input
          label='Site da empresa (opcional)'
          control={control}
          name='company_site'
          error={errors.company_site}
          width={isMobile || isTablet ? '90%' : '45%'}
          mt={5}
        />
      </Row>
      <Row>
        <NumberInput
          mt={5}
          inputType='cep'
          control={control}
          name='zip_code'
          width={isMobile || isTablet ? '90%' : '25%'}
          error={errors.zip_code}
        />
        <Input
          mt={5}
          label='Rua *'
          control={control}
          name='street'
          width={isMobile || isTablet ? '90%' : '37%'}
          ml={isMobile || isTablet ? 0 : 3}
          error={errors.street}
        />
        <Input
          mt={5}
          label='Número *'
          control={control}
          name='number'
          width={isMobile || isTablet ? '90%' : '14%'}
          ml={isMobile || isTablet ? 0 : 3}
          error={errors.number}
        />
        <Input
          mt={5}
          label='Complemento'
          control={control}
          name='complement'
          width={isMobile || isTablet ? '90%' : '10%'}
          ml={isMobile || isTablet ? 0 : 3}
        />
      </Row>
      <Row>
        <Input
          mt={5}
          label='Cidade *'
          control={control}
          name='city'
          width={isMobile || isTablet ? '90%' : '25%'}
          error={errors.city}
        />
        <Input
          mt={5}
          label='Bairro *'
          control={control}
          name='district'
          width={isMobile || isTablet ? '90%' : '37%'}
          ml={isMobile || isTablet ? 0 : 3}
          error={errors.district}
        />
        <Select
          mt={5}
          label='Estado *'
          control={control}
          name='uf'
          width={isMobile || isTablet ? '90%' : '26%'}
          ml={isMobile || isTablet ? 0 : 3}
          error={errors.uf}
          options={states.map(({ id, text }) => ({ value: id, label: text }))}
        />
      </Row>
      <Row>
        <DivNumber>
          <NumberInput
            mt={5}
            error={errors.average_monthly_revenue}
            inputType='currency'
            label='FATURAMENTO MÉDIO MENSAL PREVISTO *'
            name='average_monthly_revenue'
            control={control}
            width={isMobile || isTablet ? '90%' : '45%'}
          />
        </DivNumber>
        {!isEditing && (
          <NumberInput
            mt={5}
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width={isMobile || isTablet ? '90%' : '45%'}
            ml={isMobile || isTablet ? 0 : 3}
          />
        )}
      </Row>
      <Row>
        <Input
          mt={5}
          error={errors.company_history}
          label='Histórico da empresa *'
          control={control}
          name='company_history'
          width={isMobile || isTablet ? '90%' : '92%'}
          multiline
          rows={5}
        />
      </Row>
      <Row>
        <Input
          mt={5}
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width={isMobile || isTablet ? '90%' : '92%'}
          multiline
          rows={5}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const DivNumber = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: contents;
  @media (max-width: 768px) {
    label {
      width: 108%;
    }
  }
`

export default CompanyDataCompleted
