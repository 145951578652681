import { validateCPF } from './validateCPF'

export const signUpValidator = values => {
  let errors = {}

  if (!values.first_name) errors.first_name = 'Nome é obrigatório'
  if (!values.last_name) errors.last_name = 'Sobrenome é obrigatório'
  if (!values.email) errors.email = 'Email é obrigatório'
  if (!values.cpf) errors.cpf = 'CPF é obrigatório'
  if (values.cpf && !validateCPF(values.cpf)) errors.cpf = 'CPF inválido'
  if (values.existingCPF && values.existingCPF === 1) errors.cpf = 'CPF em uso'
  if (!values.password) errors.password = 'Senha é um campo obrigatório'
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirmar senha é obrigatório'
  }

  if (values.password !== values.confirmPassword) {
    errors.password = 'As senhas devem ser iguais'
    errors.confirmPassword = 'As senhas devem ser iguais'
  }

  if (values.password && values.password.length < 8) {
    errors.password = 'Senha deve ter pelo menos 8 caracteres'
  }

  if (values.password) {
    const regexUpperCase = /[A-Z]/
    const hasUpperCase = regexUpperCase.test(values.password)
    if (!hasUpperCase) errors.password = 'Senha deve conter letras maíusculas'
  }

  if (values.password) {
    const regexLowerCase = /[a-z]/
    const hasLowerCase = regexLowerCase.test(values.password)
    if (!hasLowerCase) errors.password = 'Senha deve conter letras minúsculas'
  }

  if (values.password) {
    const regexHasNumber = /\d/
    const hasNumber = regexHasNumber.test(values.password)
    if (!hasNumber) errors.password = 'Senha deve conter números'
  }

  return errors
}
