import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalContainer, Close, StyledRow, StyledH4 } from './styles'

const ModalBase = ({ open, onClose, width, height, children, title, iconClose, ...props }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer width={width} height={height} {...props}>
        <StyledRow flexDirection={iconClose ? 'row-reverse' : 'row'}>
          {iconClose && <Close onClick={onClose} fontSize='small' />}
          {title && <StyledH4>{title}</StyledH4>}
        </StyledRow>
        {children}
      </ModalContainer>
    </Modal>
  )
}

ModalBase.defaultProps = {
  width: 200,
  height: 200
}

ModalBase.propType = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  iconClose: PropTypes.bool
}

export default ModalBase
