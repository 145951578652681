import React from 'react'

const Title = ({ text }) => {
  if (!text) return null

  return (
    <div>
      <h1>{text}</h1>
    </div>
  )
}

export { Title }
