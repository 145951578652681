import _ from 'lodash'
import {
  CAMPAIGN_GALLERY_FETCH,
  CAMPAIGN_GALLERY_CREATE,
  CAMPAIGN_GALLERY_DELETE,
  CAMPAING_GALLERY_UPDATE
} from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_GALLERY_FETCH:
      let id = 0
      let campaignGallery = _.map(action.payload.data, photo => {
        photo.galleryId = id
        id++
        return photo
      })

      return {
        ...campaignGallery
      }

    case CAMPAIGN_GALLERY_CREATE:
      return state

    case CAMPAING_GALLERY_UPDATE:
      return state

    case CAMPAIGN_GALLERY_DELETE:
      return _.omit(state, action.payload)

    default:
      return state
  }
}
