import React from 'react'
import styled from 'styled-components'

import { Input } from '../index'

const FranchiseData = ({ errors, isMobile, isTablet, control }) => {
  return (
    <div>
      <Row>
        <Input
          label='Franqueador *'
          control={control}
          error={errors.franchisor_name}
          name='franchisor_name'
          width={isMobile || isTablet ? '90%' : '45%'}
          mt={5}
        />
      </Row>
      <Row>
        <Input
          error={errors.franchise_description}
          label='Descrição da franquia *'
          control={control}
          name='franchise_description'
          width={isMobile || isTablet ? '92%' : '45%'}
          multiline
          rows={5}
          mt={5}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

export default FranchiseData
