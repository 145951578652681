import React, { useState, useEffect, useCallback } from 'react'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Text, Row, Button } from '../../../../../components/v2Components'
import {
  StyledTimeline,
  StyledTimelineItem,
  StyledTimelineItemDate,
  StyledTimelineDot,
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimelineContentDate,
  StyledAvatar,
  StyledTimelineSeparator,
  StyledDataTimelineSeparator,
  Hr,
  TextDate,
  TextStatus,
  Image,
  StyledIconCheck,
  StyledIconClear,
  DivText
} from './styles'

import { BadgeStatus, ModalAcceptProposal, ModalRefuseProposal } from '../'

import {
  IconSpeakerNotes,
  IconPhone,
  IconTask,
  IconUpdate,
  IconArrowRightAlt,
  IconMonetization2
} from '../../../../../components/v2Components/iconsV2'

const Timeline = ({ timelineData }) => {
  const [historicalData, setHistoricalData] = useState([])

  const [modalAcceptIsOpen, setModalAcceptIsOpen] = useState(undefined)
  const [modalRefuseOpen, setModalRefuseIsOpen] = useState(undefined)

  let varData = ''

  const proposalTimeline = useCallback(
    (data, removeItem) => {
      const timeline = [data, ...historicalData]

      function checkId(value) {
        return value.id !== removeItem.id
      }

      const filtered = timeline.filter(checkId)

      setHistoricalData(filtered)
    },
    [historicalData, setHistoricalData]
  )

  useEffect(() => {
    const orderLeads = (a, b) => {
      const date1 = new Date(a.created_at).valueOf()
      const date2 = new Date(b.created_at).valueOf()

      if (date1 < date2) {
        return 1
      }

      if (date1 > date2) {
        return -1
      }

      return 0
    }

    const orderByDate = timelineData.lead_updates.sort(orderLeads)

    setHistoricalData(orderByDate)
  }, [timelineData.lead_updates])

  return (
    <div>
      <ModalAcceptProposal
        open={modalAcceptIsOpen}
        handleClose={() => setModalAcceptIsOpen(undefined)}
        proposalTimeline={proposalTimeline}
      />
      <ModalRefuseProposal
        open={modalRefuseOpen}
        handleClose={() => setModalRefuseIsOpen(undefined)}
        proposalTimeline={proposalTimeline}
      />

      <DivText>
        <Text variant='h4'>Histórico</Text>
      </DivText>
      <StyledTimeline>
        {Array.isArray([historicalData]) &&
          historicalData.map(item => {
            let avatar = ''
            let nameAvatar = ''
            try {
              if (!!item.user.employee) {
                avatar = item.user.employee.s3_name || ''
                nameAvatar = item.user.employee.first_name || ''
              }
            } catch (error) {}

            let changeData = false

            if (varData === format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })) {
              changeData = false
            } else {
              changeData = true
              varData = format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })
            }

            switch (item.lead_update_status_id) {
              case 1:
                return (
                  <>
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot>
                          <IconSpeakerNotes />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Comentário
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                          <Row>
                            <StyledAvatar title={nameAvatar} src={avatar} />
                          </Row>
                        </Row>
                        <Text variant='subtitle1'>{item.observation}</Text>
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )
              case 2:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot>
                          <IconPhone />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Ligação
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                          <Row>
                            <StyledAvatar title={nameAvatar} src={avatar} />
                          </Row>
                        </Row>
                        <Text variant='subtitle1'>{item.observation}</Text>
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )
              case 3:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot>
                          <IconTask />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Tarefa
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                          <Row>
                            <StyledAvatar title={nameAvatar} src={avatar} />
                          </Row>
                        </Row>
                        <Text variant='subtitle1'>{item.observation}</Text>
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )
              case 4:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot status={item.new_lead_status_id}>
                          <IconUpdate />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Alteração de status
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                          <Row>
                            <StyledAvatar title={nameAvatar} src={avatar} />
                          </Row>
                        </Row>
                        <Row alignItems='center'>
                          <BadgeStatus status={item.old_lead_status_id} />
                          <IconArrowRightAlt />
                          <BadgeStatus status={item.new_lead_status_id} />
                        </Row>
                        {item.new_lead_status_id === 4 && (
                          <>
                            <Row flexDirection='column'>
                              <TextStatus>Motivo</TextStatus>
                              <Text variant='subtitle1'>{item.reason}</Text>
                            </Row>
                            {!!item.observation && (
                              <Row flexDirection='column'>
                                <TextStatus>Comentário</TextStatus>
                                <Text variant='subtitle1'>{item.observation}</Text>
                              </Row>
                            )}
                          </>
                        )}
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )

              case 5:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot bgcolor='#4AB34E'>
                          <IconMonetization2 />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Proposta
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                          <Row>
                            <StyledAvatar title={nameAvatar} src={avatar} />
                          </Row>
                        </Row>
                        <Row>
                          <Image src={item.s3_name} />
                        </Row>
                        <Row justifyContent='center' mt='16px'>
                          <Button
                            variant='outlined'
                            color='#FD4646'
                            borderColor='#FD4646'
                            width='50%'
                            maxwidth={245}
                            mr='24px'
                            onClick={() => setModalRefuseIsOpen(item)}
                          >
                            <StyledIconClear />
                            Recusar
                          </Button>
                          <Button
                            variant='outlined'
                            color='#4AB34E'
                            borderColor='#4AB34E'
                            width='50%'
                            maxwidth={245}
                            onClick={() => setModalAcceptIsOpen(item)}
                          >
                            <StyledIconCheck />
                            Aceitar
                          </Button>
                        </Row>
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )

              case 6:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot bgcolor='#4AB34E'>
                          <IconMonetization2 />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent bgcolor='#edf7ed'>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Proposta aceita
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                        </Row>
                        <Row flexDirection='column' mb='10px'>
                          <TextStatus mt='0px !important'>Comentário</TextStatus>
                          <Text variant='subtitle1'>{item.observation}</Text>
                        </Row>
                        <Row mb='10px'>
                          <Image src={item.s3_name} />
                        </Row>
                      </StyledTimelineContent>
                    </StyledTimelineItem>
                  </>
                )
              case 7:
                return (
                  <>
                    {' '}
                    {changeData ? (
                      <StyledTimelineItemDate>
                        <StyledDataTimelineSeparator>
                          <StyledTimelineConnector />
                        </StyledDataTimelineSeparator>
                        <StyledTimelineContentDate>
                          <div>
                            <TextDate>{format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })}</TextDate>
                          </div>
                          <hr />
                        </StyledTimelineContentDate>
                      </StyledTimelineItemDate>
                    ) : null}
                    <StyledTimelineItem key={item.created_at}>
                      <StyledTimelineSeparator>
                        <StyledTimelineDot bgcolor='#FD4646'>
                          <IconMonetization2 />
                        </StyledTimelineDot>
                        <Hr />
                        <StyledTimelineConnector />
                      </StyledTimelineSeparator>
                      <StyledTimelineContent bgcolor='#fbebec'>
                        <Row justifyContent='space-between' mb={1}>
                          <Row>
                            <Text variant='subtitle2' mt='10px'>
                              Proposta recusada
                            </Text>
                            {
                              <Text mt='10px' ml='8px'>
                                {' '}
                                {format(parseISO(item.created_at), "HH'h'mm")}
                              </Text>
                            }
                          </Row>
                        </Row>
                        <Row flexDirection='column' mb='10px'>
                          <TextStatus mt='0px !important'>Comentário</TextStatus>
                          <Text variant='subtitle1'>{item.observation}</Text>
                        </Row>
                        <Row mb='10px'>
                          <Image src={item.s3_name} />
                        </Row>
                      </StyledTimelineContent>
                    </StyledTimelineItem>{' '}
                  </>
                )

              default:
                return null
            }
          })}
      </StyledTimeline>
    </div>
  )
}

export default Timeline
