import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { DisplayFormErrors, LoadingScreen, BorderedButton } from '../components/commom'
import handleField from '../forms/handleField'

import { signup, clearUserForm, fetchCurrentUser } from '../actions'
import { signUpValidator } from '../validators'
import { signUpForm } from '../forms'

class SignUp extends Component {
  state = { loading: false }

  componentWillUnmount() {
    if (!this.props.currentUser.authenticated) {
      this.props.clearUserForm()
    }
  }

  onSubmit = async values => {
    this.setState({ loading: true })

    const body = {
      ...values,
      document_number: values.cpf.replace(/\./g, '').replace(/-/, ''),
      first_name: values.first_name.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) {
        return capitalize.toUpperCase()
      }),
      last_name: values.last_name.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) {
        return capitalize.toUpperCase()
      })
    }
    delete body.cpf

    try {
      await this.props.signup(body)
      await this.props.fetchCurrentUser()
      this.props.history.push('/cadastro-incompleto')
    } catch (e) {
      this.setState({
        loading: false
      })
      setTimeout(() => window.M.updateTextFields(), 200)
    }
  }

  renderFields = fields =>
    fields.map(field => {
      return (
        <Field
          key={field.id}
          label={field.label}
          placeholder={field.placeholder}
          name={field.name}
          type={field.type}
          error={field.error}
          id={field.id}
          component={this.renderField}
          maxLength={field.maxLength}
          normalize={field.normalize}
          className={field.name === 'password' ? 'has-tip' : ''}
          tooltipText={field.tooltipText}
          autoComplete={field.autoComplete}
        />
      )
    })

  renderField = field => handleField(field)

  render() {
    const { handleSubmit, currentUser } = this.props
    const { loading } = this.state
    return (
      <div className='signup-row'>
        <div className='z-depth-2 login-section'>
          {loading && <LoadingScreen />}
          <form className='form-spaccing' onSubmit={handleSubmit(this.onSubmit)}>
            {this.renderFields(signUpForm)}
            <DisplayFormErrors signUp errors={[currentUser.message]} />
            <BorderedButton center disabled={this.state.loading} label='CRIAR CONTA' type='submit' />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default reduxForm({
  form: 'SignUpForm',
  validate: signUpValidator
})(connect(mapStateToProps, { signup, clearUserForm, fetchCurrentUser })(SignUp))
