import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const BadgeStatus = ({ status }) => {
  const statusName = status => {
    switch (status) {
      case 4:
        return 'Negado'
      case 2:
        return 'Em análise'
      case 1:
        return 'Novo'
      case 3:
        return 'Aprovado'
      default:
        return 'Novo'
    }
  }

  const StatusBadge = status => {
    switch (status) {
      case 4:
        return 'red'
      case 2:
        return 'blue'
      case 1:
        return 'purple'
      case 3:
        return 'green'
      default:
        return 'blue'
    }
  }

  return (
    <Container color={StatusBadge(status)} title={statusName(status)}>
      <label>{statusName(status)}</label>
    </Container>
  )
}

BadgeStatus.propTypes = {
  status: PropTypes.string.isRequired
}

export default BadgeStatus
