import React, { Component } from 'react'

class Input extends Component {
  helperText = () => {
    if (!this.props.helperText) return null

    return <span className='helper-text'>{this.props.helperText}</span>
  }

  render() {
    const { className } = this.props
    return (
      <div className={`input-field ${className || ''}`}>
        <input
          onChange={this.props.onInputChange}
          disabled={this.props.disabled}
          onClick={this.props.onInputClick}
          onBlur={this.handleErrorMsg}
          value={this.props.value}
          step={this.props.step}
          name={this.props.field}
          type={this.props.type}
          className='validate'
          id={this.props.field}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          placeholder={this.props.placeholder}
        />
        <label htmlFor={this.props.field}>{this.props.label}</label>
        {this.helperText()}
      </div>
    )
  }
}

export { Input }
