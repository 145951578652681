import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { format, parseISO } from 'date-fns'

import { getIndications, getIndicationsCSV } from '../../services/v3/indications'

import { getCurrentUser } from '../../services/v3'

import { Title, TableBase, Option, Drawer, Radio, Checkbox, Button } from '../../components/v2Components'
import { LoadingScreen } from '../../components/commom/LoadingScreen'
import { BadgeStatus } from '../MyIndications/Show/components'
import { CardBase } from '../MyIndications/components'

import { Form, Detail, OptionDiv, OptionCardDiv, RowMyIndication, DivExport, Container, StyledMain } from './styles'

const MyIndications = ({ history }) => {
  const [userInfo, setUserInfo] = useState()

  const [filterValue, setFilterValue] = useState({
    all: true,
    approved: false,
    denied: false,
    new: false,
    analysis: false
  })

  const { handleSubmit, control } = useForm({ defaultValues: filterValue })
  const [loading, setLoading] = useState(true)

  const [indications, setIndications] = useState([])
  const [indicationsData, setIndicationsData] = useState([])

  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [rowLength, setRowLength] = useState(8)

  const [pageSelected, setPageSelected] = useState(0)

  const [orderBy, setOrderBy] = useState('current')

  const indicationsColumns = useMemo(
    () => [
      {
        Header: 'Razão Social',
        accessor: 'Razão Social'
      },
      {
        Header: 'Status',
        accessor: 'Status'
      },
      {
        Header: 'CNPJ',
        accessor: 'CNPJ'
      },
      {
        Header: 'Fat. Médio Mensal',
        accessor: 'Médio Mensal'
      },
      {
        Header: 'Valor Solicitado',
        accessor: 'Valor Solicitado'
      },
      {
        Header: 'Solicitado em',
        accessor: 'Solicitado em'
      },
      {
        Header: 'Responsável',
        accessor: 'Responsável'
      },
      {
        Header: '',
        accessor: 'detalhes'
      }
    ],
    []
  )

  const orderByBiggestDate = useCallback((a, b) => {
    const date1 = new Date(a['Solicitado em']).valueOf()
    const date2 = new Date(b['Solicitado em']).valueOf()

    if (date1 > date2) {
      return -1
    }

    if (date1 < date2) {
      return 1
    }

    return 0
  }, [])

  const orderByLowestDate = useCallback((a, b) => {
    const date1 = new Date(a['Solicitado em']).valueOf()
    const date2 = new Date(b['Solicitado em']).valueOf()

    if (date1 > date2) {
      return 1
    }

    if (date1 < date2) {
      return -1
    }

    return 0
  }, [])

  const orderByGrowing = useCallback((a, b) => {
    if (a['Razão Social'].toUpperCase() < b['Razão Social'].toUpperCase()) {
      return -1
    }

    if (a['Razão Social'].toUpperCase() > b['Razão Social'].toUpperCase()) {
      return 1
    }

    return 0
  }, [])

  const orderByDecreasing = useCallback((a, b) => {
    if (a['Razão Social'].toUpperCase() < b['Razão Social'].toUpperCase()) {
      return 1
    }

    if (a['Razão Social'].toUpperCase() > b['Razão Social'].toUpperCase()) {
      return -1
    }

    return 0
  }, [])

  const handleOrderindications = useCallback(
    (orderBy = 'current', filterVal) => {
      try {
        let order = []
        if (filterVal.all) {
          const newOrder = indications
          order = newOrder
        } else {
          if (filterVal.approved) {
            const newOrder = indications.filter(item => {
              return item.Status === 3
            })
            order = order.concat(newOrder)
          }
          if (filterVal.analysis) {
            const newOrder = indications.filter(item => {
              return item.Status === 2
            })
            order = order.concat(newOrder)
          }
          if (filterVal.denied) {
            const newOrder = indications.filter(item => {
              return item.Status === 4
            })
            order = order.concat(newOrder)
          }
          if (filterVal.new) {
            const newOrder = indications.filter(item => {
              return item.Status === 1
            })
            order = order.concat(newOrder)
          }
        }

        switch (orderBy) {
          case 'current':
            order.sort(orderByBiggestDate)

            break
          case 'old':
            order.sort(orderByLowestDate)

            break
          case 'growing':
            order.sort(orderByGrowing)

            break
          case 'decreasing':
            order.sort(orderByDecreasing)

            break
          default:
            break
        }

        const response = order.map(indications => {
          return {
            'Razão Social': indications['Razão Social'],
            Status: <BadgeStatus status={indications.Status} />,
            CNPJ: indications.CNPJ,
            'Médio Mensal': indications['Médio Mensal'],
            'Valor Solicitado': indications['Valor Solicitado'],
            'Solicitado em': format(parseISO(indications['Solicitado em']), 'dd/MM/yy'),
            Responsável: indications['Responsável'],
            detalhes: indications.detalhes
          }
        })
        setIndicationsData(response)
      } catch (err) {
        console.log(err)
      }
    },
    [indications, orderByBiggestDate, orderByLowestDate, orderByGrowing, orderByDecreasing]
  )

  const handleFilter = useCallback(
    data => {
      try {
        setPageSelected(0)
        setRowLength(Number(data.pagination))
        setOrderBy(data.order)

        handleOrderindications(data.order, filterValue)
      } catch (err) {
        toast.error('Não foi possível realizar esse filtro.')
      } finally {
        setIsOpenFilter(false)
      }
    },
    [handleOrderindications, filterValue]
  )

  const handleDetails = useCallback(
    data => {
      history.push({
        pathname: `/minhas-indicacoes/${data}`
      })
    },
    [history]
  )

  const handleExportCSV = useCallback(() => {
    const fetchCSV = async () => {
      try {
        const { data: response } = await getIndicationsCSV(userInfo.id, {
          responseType: 'arraybuffer'
        })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        element.download = 'minhas_indicações.xlsx'
        element.click()
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao exportar a listagem em CSV')
      }
    }

    fetchCSV()
  }, [userInfo])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        const { data: userData } = await getCurrentUser()
        setUserInfo(userData)

        if (!userData.partner) {
          history.push('/indicacao-parceria')
        }
        const { data: indi } = await getIndications(userData.id)

        if (indi.length === 0) {
          toast.info('Você não possui nenhuma indicação cadastrada.')
          history.push('/indicacao-parceria')
        }

        const responseIndication = indi.map(item => {
          return {
            'Razão Social': item.company_name,
            Status: item.lead_status.id,
            CNPJ: !!item.cnpj ? item.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : '',
            'Médio Mensal': new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
              item.average_monthly_revenue
            ),
            'Valor Solicitado': new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
              item.desired_amount
            ),
            'Solicitado em': item.created_at,
            Responsável: item.responsible,
            detalhes: (
              <Detail
                title='Ver detalhes'
                onClick={() => {
                  handleDetails(item.id)
                }}
              >
                Ver detalhes
              </Detail>
            )
          }
        })
        setIndications(responseIndication)
        setLoading(false)
      } catch {}
    }
    fetchInfos()
  }, [handleDetails, history])

  useEffect(() => {
    handleOrderindications('current', { all: true })
  }, [indications, handleOrderindications])

  if (loading) return <LoadingScreen />

  return (
    <Container>
      <StyledMain>
        <RowMyIndication>
          <Title title='Minhas Indicações' mb='10px' />
          <Button
            onClick={() => {
              history.push('/indicacao-parceria')
            }}
          >
            Adicionar indicação
          </Button>
        </RowMyIndication>

        <DivExport>
          <OptionDiv>
            <Option name='Exportar' imgUrl='/img/export.svg' onPress={handleExportCSV} />
            <Option name='Filtrar' imgUrl='/img/filter.svg' onPress={() => setIsOpenFilter(true)} />
          </OptionDiv>
        </DivExport>
        <TableBase
          hasPaginationTop
          hasPaginationBottom
          hasFilter
          widthHidden={1200}
          columns={indicationsColumns}
          data={indicationsData}
          length={rowLength}
        ></TableBase>

        <CardBase
          indicators={indicationsData}
          hasPaginationTop
          hasPaginationBottom
          offset={pageSelected}
          rowCount={rowLength}
          navigationTo={setPageSelected}
        >
          <OptionCardDiv>
            <Option name='Exportar' imgUrl='/img/export.svg' />
            <Option name='Filtrar' imgUrl='/img/filter.svg' onPress={() => setIsOpenFilter(true)} />
          </OptionCardDiv>
        </CardBase>

        <Drawer
          anchor='right'
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          iconClose
          titleContainer='Filtrar'
        >
          <Form onSubmit={handleSubmit(data => handleFilter(data))}>
            <main>
              <section>
                <h2>Status da indicação:</h2>

                <Checkbox
                  control={control}
                  name='aprovado'
                  label='Aprovado'
                  checked={filterValue.approved}
                  onChange={() =>
                    setFilterValue({
                      approved: !filterValue.approved,
                      all: false,
                      new: filterValue.new,
                      denied: filterValue.denied,
                      analysis: filterValue.analysis
                    })
                  }
                />
                <Checkbox
                  control={control}
                  name='emAnalise'
                  label='Em análise'
                  checked={filterValue.analysis}
                  onChange={() =>
                    setFilterValue({
                      analysis: !filterValue.analysis,
                      all: false,
                      new: filterValue.new,
                      denied: filterValue.denied,
                      approved: filterValue.approved
                    })
                  }
                />
                <Checkbox
                  control={control}
                  name='negado'
                  label='Negado'
                  checked={filterValue.denied}
                  onChange={() =>
                    setFilterValue({
                      denied: !filterValue.denied,
                      all: false,
                      new: filterValue.new,
                      approved: filterValue.approved,
                      analysis: filterValue.analysis
                    })
                  }
                />
                <Checkbox
                  control={control}
                  name='recebido'
                  label='Novo'
                  checked={filterValue.new}
                  onChange={() =>
                    setFilterValue({
                      new: !filterValue.new,
                      all: false,
                      denied: filterValue.denied,
                      approved: filterValue.approved,
                      analysis: filterValue.analysis
                    })
                  }
                />
                <Checkbox
                  control={control}
                  name='todos'
                  label='Todos'
                  checked={filterValue.all}
                  onChange={() =>
                    setFilterValue({
                      all: !filterValue.all,
                      new: false,
                      denied: false,
                      approved: false,
                      analysis: false
                    })
                  }
                />
              </section>

              <section>
                <h2>Ordenar por:</h2>

                <Radio
                  control={control}
                  name='order'
                  defaultValue={String(orderBy)}
                  options={[
                    {
                      value: 'current',
                      label: 'Mais recentes'
                    },
                    {
                      value: 'old',
                      label: 'Mais antigas'
                    },
                    {
                      value: 'growing',
                      label: 'De A a Z'
                    },
                    {
                      value: 'decreasing',
                      label: 'De Z a A'
                    }
                  ]}
                />
              </section>

              <section>
                <h2>Itens por página:</h2>

                <Radio
                  control={control}
                  name='pagination'
                  defaultValue={String(rowLength)}
                  options={[
                    {
                      value: '8',
                      label: '8 itens'
                    },
                    {
                      value: '24',
                      label: '24 itens'
                    },
                    {
                      value: '40',
                      label: '40 itens'
                    },
                    {
                      value: '-1',
                      label: 'Todos'
                    }
                  ]}
                />
              </section>
            </main>

            <footer>
              <Button type='submit'>Filtrar</Button>
            </footer>
          </Form>
        </Drawer>
      </StyledMain>
    </Container>
  )
}

export default MyIndications
