import React from 'react'

const CheckBoxRedux = ({ field }) => {
  return (
    <div>
      <p>
        <label>
          <input type='checkbox' {...field.input} />
          <span dangerouslySetInnerHTML={{ __html: field.label }} />
        </label>
      </p>
      <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
        {field.meta.touched ? field.meta.error : ''}
      </span>
    </div>
  )
}

export { CheckBoxRedux }
