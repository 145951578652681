export const loginForm = [
  {
    label: 'CPF',
    placeholder: 'Ex: 076.414.749-85',
    name: 'cpf',
    type: 'cpf',
    error: 'CPF é obrigatório',
    id: 'cpf'
  },
  {
    label: 'Senha',
    placeholder: 'password',
    type: 'password',
    name: 'password',
    error: 'Senha é obrigatório',
    id: 'password',
    maxLength: 24
  }
]

export const resetPassword = [
  {
    label: 'Senha',
    placeholder: '',
    type: 'password',
    name: 'password',
    error: 'Senha é obrigatório',
    id: 'password',
    maxLength: 24
  },
  {
    label: 'Confirmar senha',
    placeholder: '',
    type: 'password',
    name: 'confirmPassword',
    error: 'Senha inválida',
    id: 'confirmPassword',
    maxLength: 24
  }
]

export const signUpForm = [
  {
    label: 'CPF *',
    placeholder: 'Ex: 076.414.749-85',
    name: 'cpf',
    type: 'cpf',
    error: 'CPF é obrigatório',
    id: 'cpf'
  },
  {
    label: 'Nome *',
    placeholder: '',
    type: 'text',
    name: 'first_name',
    error: 'Nome é um campo obrigatório',
    id: 'first_name',
    maxLength: 128,
    normalize: value => value.replace(/[\d]/, '')
  },
  {
    label: 'Sobrenome *',
    placeholder: '',
    type: 'text',
    name: 'last_name',
    error: 'Sobrenome é um campo obrigatório',
    id: 'last_name',
    maxLength: 128,
    normalize: value => value.replace(/[\d]/, '')
  },
  {
    label: 'Email *',
    placeholder: 'user@gmail.com',
    type: 'email',
    name: 'email',
    error: 'Email é um campo obrigatório',
    id: 'email',
    maxLength: 255
  },
  {
    label: 'Senha *',
    placeholder: '',
    type: 'password',
    name: 'password',
    error: 'Senha é obrigatório',
    id: 'password',
    maxLength: 24
  },
  {
    label: 'Confirmar senha *',
    placeholder: '',
    type: 'password',
    name: 'confirmPassword',
    error: 'Senha inválida',
    id: 'confirmPassword',
    maxLength: 24
  }
]

export const workWithUsForm = [
  {
    label: 'Nome Completo *',
    placeholder: '',
    type: 'text',
    name: 'name',
    error: 'Nome é um campo obrigatório',
    id: 'name',
    maxLength: 256
  },
  {
    label: 'Email *',
    placeholder: 'user@gmail.com',
    type: 'email',
    name: 'email',
    error: 'Email é um campo obrigatório',
    id: 'email',
    maxLength: 128
  },
  {
    label: 'Assunto *',
    placeholder: '',
    type: 'text',
    name: 'subject',
    error: 'Assunto é um campo obrigatório',
    id: 'subject',
    maxLength: 1024
  },
  {
    label: 'Áreas de interesse *',
    placeholder: '',
    type: 'select',
    name: 'area_type_id',
    error: 'Campo obrigatório',
    id: 'area_type_id',
    options: [{ id: 'O', text: '' }]
  },
  {
    label: 'Currículo *',
    placeholder: '',
    type: 'file',
    name: 'curriculum',
    error: 'Campo obrigatório',
    id: 'curriculum',
    maxLength: 1024
  },
  {
    label: 'Por que você quer trabalhar na Kavod Lending? *',
    placeholder: '',
    type: 'text-area',
    name: 'body',
    error: 'Campo obrigatório',
    id: 'body',
    maxLength: 1024
  }
]

export const contactForm = [
  {
    label: 'Nome',
    placeholder: '',
    type: 'text',
    name: 'name',
    error: 'Nome é um campo obrigatório',
    id: 'name',
    maxLength: 256
  },
  {
    label: 'Email',
    placeholder: 'user@gmail.com',
    type: 'email',
    name: 'from',
    error: 'Email é um campo obrigatório',
    id: 'from',
    maxLength: 128
  },
  {
    label: 'Selecione o departamento ou tema',
    placeholder: '',
    type: 'select',
    name: 'subject_type_id',
    error: 'Campo obrigatório',
    id: 'subject_type_id',
    options: [{ id: 'O', text: '' }]
  },
  {
    label: 'Assunto',
    placeholder: '',
    type: 'text',
    name: 'subject',
    error: 'Assunto é um campo obrigatório',
    id: 'subject',
    maxLength: 1024
  },
  {
    label: 'Mensagem',
    placeholder: '',
    type: 'text-area',
    name: 'body',
    error: 'Mensagem é um campo obrigatório',
    id: 'body',
    maxLength: 1024
  }
]

export const companyForm = [
  {
    label: 'Nome',
    name: 'Nome',
    type: 'text',
    required: true
  },
  {
    label: 'Sobrenome',
    name: 'Sobrenome',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone Fixo com DDD*',
    name: 'telefone',
    type: 'text',
    required: true
  },
  {
    label: 'Celular*',
    name: 'celular',
    type: 'text',
    required: true
  },
  {
    label: 'E-mail*',
    name: 'email',
    type: 'email',
    required: true
  },
  {
    label: 'Empresa*',
    name: 'Empresa',
    type: 'text',
    required: true
  },
  {
    label: 'CNPJ*',
    name: 'cnpj',
    type: 'text',
    required: true
  }
]
