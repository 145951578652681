import React from 'react'

const HomeLaptop = () => (
  <section className='laptop-container'>
    <div className='laptop-text-wrapper'>
      <div className='laptop-text'>
        <p className='laptop-title'>Descomplicamos Tudo.</p>
        <br />
        <p className='laptop-body'>
          Temos uma plataforma simples e fácil para o investidor acompanhar a sua rentabilidade e pagamentos mensais
          feitos através da Kavod. <br />
        </p>
        <p className='laptop-body'>
          Com um Dashboard moderno, o investidor consegue ver seus investimentos, quantas parcelas já recebeu e diversas
          informações referentes aos seus investimentos
        </p>
      </div>
    </div>
    <div className='laptop-image-wrapper'>
      <img src='/assets/img/home/laptop.png' alt='laptop' />
    </div>
  </section>
)

export { HomeLaptop }
