const newsInfo = [
  {
    href: 'https://globoplay.globo.com/v/6752363/',
    src: '/assets/img/home/JonalDaGlobo.png',
    alt: 'jornal da globo logo'
  },
  {
    href:
      'https://www.gazetadopovo.com.br/economia/fintech-levanta-r-140-mil-em-2h30-para-franqueado-do-china-in-box-sera-que-a-moda-pega-dk3wfr6jcfvxrr1dd3ba1l2k2/',
    src: '/assets/img/home/GazetaDoPovo.png',
    alt: 'gazeta do povo logo'
  },
  {
    href:
      'https://exame.abril.com.br/seu-dinheiro/os-bancos-corretoras-e-fintechs-mais-buscados-para-investir-em-2018/',
    src: '/assets/img/home/Exame.png',
    alt: 'exame logo'
  },
  {
    href: 'https://veja.abril.com.br/economia/o-que-voce-precisa-saber-antes-de-colocar-seu-dinheiro-em-uma-fintech/',
    src: '/assets/img/home/Veja.png',
    alt: 'veja logo'
  },
  {
    href: 'https://istoe.com.br/fintechs-crescimento-pode-aumentar-concorrencia-e-baratear-emprestimo/',
    src: '/assets/img/home/logo-istoe.svg',
    alt: 'isto é logo'
  },

  {
    href:
      'https://noticias.uol.com.br/ultimas-noticias/agencia-brasil/2018/04/28/fintechs-crescimento-pode-aumentar-concorrencia-e-baratear-emprestimo.htm?cpVersion=newsstand',
    src: '/assets/img/home/Uol.png',
    alt: 'Uol logo'
  },

  {
    href: 'https://www.istoedinheiro.com.br/credito-franqueado/',
    src: '/assets/img/home/IstoeDinheiro.png',
    alt: 'Istoé dinheiro logo'
  },
  {
    href:
      'https://www.dci.com.br/economia/demanda-de-franqueados-por-credito-tem-aumento-de-mais-de-50-em-2018-1.770015',
    src: '/assets/img/home/dci.png',
    alt: 'DCi logo'
  }
]

export default newsInfo
