import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import {
  Checkbox,
  Button,
  Radio,
  ContactData,
  FranchiseData,
  CompanyDataCompleted,
  CompanyDataIncompleted,
  Text,
  InternalNavigation
} from '../components/v2Components'

import { getCompanyInfo, createLeadsPartner, getAddressByCep, getCurrentUser } from '../services/v3'
import { partnerLeadsSchema } from '../schemas'
import { sanitizeMoney } from '../helpers'
import { states } from '../constants'
import useDevice from '../hooks/useDevice'

const signupCompanyDefaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  street: '',
  number: '',
  zip_code: '',
  complement: '',
  district: '',
  city: '',
  uf: '',
  celphone: '',
  telephone: '',
  cnpj: '',
  company_name: '',
  company_site: '',
  average_monthly_revenue: '',
  desired_amount: '',
  company_history: '',
  lending_reason: ''
}

const PartnerLeads = ({ history }) => {
  const { handleSubmit, errors, control, watch, setValue, reset } = useForm({
    resolver: partnerLeadsSchema,
    defaultValues: signupCompanyDefaultValues,
    mode: 'all',
    shouldUnregister: false
  })

  const [user, setUser] = useState()

  const { isMobile, isTablet } = useDevice()

  const cnpjValue = watch('cnpj')
  const zipCode = watch('zip_code')
  const values = watch(['company_type', 'franchise_type', 'have_cnpj'])

  const handleCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfo(cnpj)

        if (companyInfo.status === 'ERROR') {
          throw new Error(companyInfo.message)
        }

        setValue('company_name', companyInfo.nome)
      } catch (error) {
        if (error.message === 'Erro ao buscar o CNPJ: undefined.') {
          toast.error('Erro ao buscar CNPJ')

          return
        }

        toast.error(error.message)
      }
    },
    [setValue]
  )

  const handleCompanyAddress = useCallback(
    async cep => {
      try {
        const companyAddress = await getAddressByCep(cep)

        setValue('city', companyAddress.localidade)
        setValue('uf', companyAddress.uf)
        setValue('street', companyAddress.logradouro)
        setValue('district', companyAddress.bairro)
      } catch (error) {
        toast.error(error.message || 'Erro a buscar CEP')
      }
    },
    [setValue]
  )

  const onSubmit = useCallback(
    async values => {
      try {
        if (!user || !user.partner) {
          return toast.error('Ocorreu um erro ao buscar seu dados de parceiro')
        }

        const celphone = values.celphone.replace(/\D/g, '')
        const telephone = values.telephone ? values.telephone.replace(/\D/g, '') : ''
        const body =
          values.franchise_type === 'Nova'
            ? {
                average_monthly_revenue: sanitizeMoney(values.average_monthly_revenue),
                celphone: celphone.slice(2),
                celphone_prefix: celphone.slice(0, 2),
                city: undefined,
                cnpj: values.have_cnpj ? (values.cnpj ? values.cnpj.replace(/[^\d]+/g, '') : '') : '',
                company_history: values.company_history,
                company_name: values.company_name,
                company_site: undefined,
                partner_id: user.partner.id,
                company_type: values.company_type,
                complement: undefined,
                desired_amount: sanitizeMoney(values.desired_amount),
                district: undefined,
                email: values.email,
                first_name: values.first_name,
                franchise_description: values.franchise_description,
                franchise_type: values.franchise_type,
                franchisor: values.franchisor,
                franchisor_name: values.franchisor_name,
                last_name: values.last_name,
                lending_reason: values.lending_reason,
                number: undefined,
                street: undefined,
                telephone: telephone.slice(2),
                telephone_prefix: telephone.slice(0, 2),
                uf: undefined,
                zip_code: undefined
              }
            : {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                street: values.street,
                number: values.number,
                zip_code: values.zip_code,
                complement: values.complement,
                district: values.district,
                city: values.city,
                uf: values.uf,
                celphone: celphone.slice(2),
                celphone_prefix: celphone.slice(0, 2),
                telephone: telephone.slice(2),
                telephone_prefix: telephone.slice(0, 2),
                cnpj: values.cnpj ? values.cnpj.replace(/[^\d]+/g, '') : '',
                company_name: values.company_name,
                company_site: values.company_site,
                company_history: values.company_history,
                average_monthly_revenue: sanitizeMoney(values.average_monthly_revenue),
                desired_amount: sanitizeMoney(values.desired_amount),
                lending_reason: values.lending_reason,
                partner_id: user.partner.id,
                company_type: values.company_type,
                franchisor: values.franchisor,
                franchise_type: values.franchise_type,
                franchisor_name: values.franchisor_name,
                franchise_description: values.franchise_description
              }

        await createLeadsPartner(body)

        toast.success('Indicação realizada com sucesso!')
        reset(signupCompanyDefaultValues)
        history.push('/minhas-indicacoes')
      } catch (err) {
        if (err.response.status === 400) {
          toast.error(err.response.data.message)

          return
        }
        if (err.response.status === 403) {
          toast.error('Você precisa ser parceiro para realizar esta indicação')

          return
        }

        toast.error('Ocorreu um erro ao enviar a indicação')
      }
    },
    [reset, user, history]
  )

  const handleContactData = useCallback(async () => {
    try {
      if (!user || !user.partner) {
        throw Error()
      }

      if (!!user.partner.first_name) {
        setValue('first_name', user.partner.first_name)
      }

      if (!!user.partner.last_name) {
        setValue('last_name', user.partner.last_name)
      }

      if (!!user.partner.celphone && !!user.partner.celphone_prefix) {
        const celphone = user.partner.celphone_prefix + user.partner.celphone
        setValue('celphone', celphone)
      }

      if (!!user.email) {
        setValue('email', user.email)
      }

      if (!!user.partner.telephone && user.partner.telephone_prefix) {
        const telephone = user.partner.telephone_prefix + user.partner.telephone
        setValue('telephone', telephone)
      }
    } catch (error) {
      toast.error('Não foi possível obter as informações do usuário.')
    }
  }, [setValue, user])

  const changeCheckbox = useCallback(
    event => {
      if (event.target.checked) {
        handleContactData()
        return
      }
      setValue('first_name', '')
      setValue('last_name', '')
      setValue('celphone', '')
      setValue('email', '')
      setValue('telephone', '')
    },
    [handleContactData, setValue]
  )

  useEffect(() => {
    if (!cnpjValue) {
      return
    }

    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')

    if (sanitizedCnpj.length === 14) {
      handleCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, handleCompanyName])

  useEffect(() => {
    if (!zipCode) return

    const sanitizedZipCode = zipCode.replace(/\D/g, '')
    if (sanitizedZipCode.length === 8) {
      handleCompanyAddress(sanitizedZipCode)
    }
  }, [zipCode, handleCompanyAddress])

  useEffect(() => {
    getCurrentUser().then(response => setUser(response.data))
  }, [])

  return (
    <Container>
      <InternalNavigation link='/minhas-indicacoes' routeName='Minhas Indicações' name={'Indicações de empresas'} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text variant='h3'>Indicação de empresas</Text>
        <TitleContainer>
          <SubTitle>Dados de contato</SubTitle>
        </TitleContainer>
        <Row>
          <Checkbox
            onChange={e => changeCheckbox(e)}
            name='mock_checkbox'
            control={control}
            label='Eu serei o contato responsável pelo envio dos documentos e informações financeiras'
            error={errors.mock_checkbox}
          />
        </Row>
        <ContactData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
        <Text variant='h4' mt={5} mb={5}>
          Qual empresa você está indicando
        </Text>
        <Row>
          <Radio
            name='company_type'
            control={control}
            error={errors.company_type}
            options={[
              {
                value: 'Franquia',
                label: 'Franquias'
              },
              {
                value: 'Geral',
                label: 'Empresas em geral'
              }
            ]}
          />
        </Row>
        {values.company_type === 'Geral' && (
          <div>
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <ButtonDiv>
              <Button mt={5} type='submit' width='270px'>
                Enviar
              </Button>
            </ButtonDiv>
          </div>
        )}
        {values.company_type === 'Franquia' && (
          <>
            <Text variant='h4' mt={5} mb={5}>
              Franqueador
            </Text>
            <Row>
              <Checkbox name='franchisor' control={control} label='Sou o franqueador' />
            </Row>
            <Text variant='h4' mt={5} mb={5}>
              Tipo de franquia
            </Text>
            <Row>
              <Radio
                name='franchise_type'
                control={control}
                options={[
                  {
                    value: 'Existente',
                    label: 'Unidade em operação'
                  },
                  {
                    value: 'Nova',
                    label: 'Nova loja'
                  }
                ]}
              />
            </Row>
          </>
        )}
        {values.company_type === 'Franquia' && values.franchise_type === 'Existente' && (
          <div>
            <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <ButtonDiv>
              <Button mt={5} type='submit' width='270px'>
                Enviar
              </Button>
            </ButtonDiv>
          </div>
        )}
        {values.company_type === 'Franquia' && values.franchise_type === 'Nova' && (
          <div>
            <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
            <Checkbox mt={5} name='have_cnpj' control={control} label='Já possuo CNPJ' />
          </div>
        )}
        {values.company_type === 'Franquia' && values.franchise_type === 'Nova' && values.have_cnpj && (
          <div>
            <Checkbox name='have_pj_count' control={control} label='Já possuo conta PJ aberta' mt={5} />
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <ButtonDiv>
              <Button mt={5} type='submit' width='270px'>
                Enviar
              </Button>
            </ButtonDiv>
          </div>
        )}
        {values.company_type === 'Franquia' && values.franchise_type === 'Nova' && !values.have_cnpj && (
          <div>
            <CompanyDataIncompleted control={control} errors={errors} isMobile={isMobile} isTablet={isTablet} />
            <ButtonDiv>
              <Button mt={5} type='submit' width='270px'>
                Enviar
              </Button>
            </ButtonDiv>
          </div>
        )}
      </Form>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  @media (min-width: 768px) {
    width: 85%;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 110%;
  margin-bottom: 40px;
`

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const SubTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #424242;
  margin: 14px 0px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const TitleContainer = styled.div`
  margin: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
const ButtonDiv = styled.div`
  width: 92%;
  display: flex;
  justify-content: flex-end;
`

export default PartnerLeads
