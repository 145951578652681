import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import styled from 'styled-components'

import Accordion from './Accordion'
import PledgeCard from './PledgeCard'
import AggregatePledgesCard from './AggregatePledgesCard'
import { InstallmentsTable } from '../../components'
import { DarkBlueButton, LoadingScreen, InvestmentsResumeModal } from '../../components/commom'

import { fetchCurrentUser } from '../../actions'
import { formatMoney } from '../../helpers'
import {
  getCurrentUser,
  getPledges,
  getOrders,
  getInstallments,
  getCampaigns,
  getPhotos,
  deletePledge
} from '../../services/v3'
import { installmentStatuses, pledgeStatuses, photoTypes, campaignTypes } from '../../constants'

import './my-investments.scss'

const MyInvestmentsNew = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [investmentsResumeModalContent, setInvestmentsResumeModalContent] = useState(null)
  const [investmentsResumeModalIsOpen, setInvestmentsResumeModalIsOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [investmentIntentions, setInvestmentIntentions] = useState([])
  const [investments, setInvestments] = useState([])
  const [investmentsOverview, setInvestmentsOverview] = useState(null)

  const fetchInfos = async () => {
    try {
      setIsLoading(true)
      const { data: currentUser } = await getCurrentUser()

      if (!currentUser && !currentUser.investor) {
        setIsLoading(false)
        return
      }

      const [{ data: campaigns }, { data: orders }, { data: pledges }, { data: installments }] = await Promise.all([
        getCampaigns(),
        getOrders({ investor_id: currentUser.investor.id }),
        getPledges({ investor_id: currentUser.investor.id }),
        getInstallments({ investor_id: currentUser.investor.id })
      ])

      const nonInvestmentPledges = pledges.filter(
        ({ order_id, pledge_status }) => !order_id && pledge_status.abbreviation !== pledgeStatuses.DELETED
      )

      const totalInvested = orders.reduce((acc, { amount }) => acc + amount, 0)
      const { totalReceived, remainingToReceive } = installments.reduce(
        (acc, { abbreviation, transfer_amount, transfer_amount_delayed }) => {
          switch (abbreviation) {
            case installmentStatuses.OPEN:
              return {
                ...acc,
                remainingToReceive: acc.remainingToReceive + (transfer_amount_delayed || transfer_amount)
              }
            case installmentStatuses.PAID:
              return {
                ...acc,
                totalReceived: acc.totalReceived + (transfer_amount_delayed || transfer_amount)
              }
            default:
              return acc
          }
        },
        { totalReceived: 0, remainingToReceive: 0 }
      )
      const investmentsOverview = { totalInvested, totalReceived, remainingToReceive }

      const investmentIntentions = await Promise.all(
        nonInvestmentPledges.map(async pledge => {
          const { data: campaignPhotos } = await getPhotos({ campaign_id: pledge.campaign_id })
          const dashImage = campaignPhotos.find(
            photo => photo.campaign_photo_type.abbreviation === photoTypes.DASHBOARD
          )
          return {
            ...pledge,
            campaign: campaigns.find(campaign => campaign.id === pledge.campaign_id),
            image_url: dashImage ? dashImage.photo_url : null
          }
        })
      )

      const investments = await Promise.all(
        orders.map(async order => {
          const { data: campaignPhotos } = await getPhotos({ campaign_id: order.pledges[0].campaign_id })
          const dashImage = campaignPhotos.find(
            photo => photo.campaign_photo_type.abbreviation === photoTypes.DASHBOARD
          )
          const {
            data: [overview]
          } = await getInstallments({
            investor_id: currentUser.investor.id,
            campaign_id: order.pledges[0].campaign_id,
            net_amount: true,
            gross_amount: true,
            percentual_return: true,
            value_return: true
          })

          const campaignInstallments = installments
            .filter(installment => installment.campaign_id === order.pledges[0].campaign_id)
            .sort((a, b) => a.installment_number - b.installment_number)

          const nextInstallment = campaignInstallments.find(
            ({ abbreviation }) => abbreviation === installmentStatuses.OPEN
          )

          const { remaining_to_receive, total_received } = campaignInstallments.reduce(
            (acc, { transfer_amount, transfer_amount_delayed, abbreviation }) => {
              const transferAmount = transfer_amount_delayed || transfer_amount
              switch (abbreviation) {
                case installmentStatuses.OPEN:
                  return {
                    ...acc,
                    remaining_to_receive: acc.remaining_to_receive + transferAmount
                  }
                case installmentStatuses.PAID:
                  return {
                    ...acc,
                    total_received: acc.total_received + transferAmount
                  }
                default:
                  return acc
              }
            },
            { remaining_to_receive: 0, total_received: 0 }
          )

          return {
            ...order,
            campaign: campaigns.find(campaign => campaign.id === order.pledges[0].campaign_id),
            image_url: dashImage ? dashImage.photo_url : null,
            installments: campaignInstallments,
            payment_done:
              campaignInstallments.length !== 0 &&
              campaignInstallments.every(({ abbreviation }) => abbreviation === installmentStatuses.PAID),
            nextInstallmentValue: nextInstallment
              ? nextInstallment.transfer_amount_delayed || nextInstallment.transfer_amount
              : null,
            nextInstallmentDate: nextInstallment
              ? nextInstallment.transfer_date_delayed || nextInstallment.transfer_date
              : null,
            total_received,
            remaining_to_receive,
            overview
          }
        })
      )
      setInvestmentsOverview(investmentsOverview)
      setInvestmentIntentions(investmentIntentions)
      setInvestments(investments)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar seus investimentos')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchInfos()
  }, [])

  const sttOrder = { AC: 1, A: 2, OH: 3, C: 4, D: 5 }

  const sortPledges = (a, b) => {
    return (
      a.campaign_id - b.campaign_id ||
      sttOrder[a.pledge_status.abbreviation] - sttOrder[b.pledge_status.abbreviation] ||
      a.id - b.id
    )
  }

  const sortMatrizationById = (a, b) => a[0].id - b[0].id

  const matrization = pledges => {
    let finalArray = []
    let currentCampaign = null
    let currentStatus = null
    let currentArray = []

    pledges.forEach(pledge => {
      if (!currentCampaign) {
        currentCampaign = pledge.campaign_id
        currentStatus = pledge.pledge_status_id
      } else if (currentCampaign !== pledge.campaign_id || currentStatus !== pledge.pledge_status_id) {
        finalArray.push(currentArray)
        currentArray = []
        currentCampaign = pledge.campaign_id
        currentStatus = pledge.pledge_status_id
      }

      currentArray.push(pledge)
    })
    if (currentArray.length) finalArray.push(currentArray)
    return finalArray
  }

  const toggleOpen = async index => {
    if (isOpen) {
      setIsOpen(!isOpen)
      setCurrentIndex(currentIndex === index ? null : index)
      if (currentIndex !== index) {
        setIsOpen(!isOpen)
        setCurrentIndex(index)
      }
    } else {
      setIsOpen(!isOpen)
      setCurrentIndex(index)
    }
  }

  const openInvestmentsResumeModal = investment => {
    setInvestmentsResumeModalIsOpen(true)
    setInvestmentsResumeModalContent(investment)
  }

  const deleteCard = async pledge => {
    try {
      await deletePledge(pledge.id)
      toast.success('Pedido excluído com sucesso.')
      fetchInfos()
    } catch (error) {
      console.error(error)
      toast.error('Não conseguimos deletar seu pedido.')
    }
  }

  const deleteAggregateCard = async pledges => {
    try {
      await Promise.all(pledges.map(pledge => deletePledge(pledge.id)))
      toast.success('Pedidos excluídos com sucesso.')
      fetchInfos()
    } catch (error) {
      console.error(error)
      toast.error('Não conseguimos deletar seu pedido.')
    }
  }

  const navigateToUpdates = campaign => {
    switch (campaign.campaign_type.id) {
      case campaignTypes.AVAL:
        history.push(`/campanhas/com-aval/${campaign.url}?section=updates`)
        break
      case campaignTypes.AVAL_E_GARANTIA:
        history.push(`/campanhas/com-aval-e-garantia/${campaign.url}?section=updates`)
        break
      default:
        break
    }
  }

  const renderInvestment = (investment, index) => {
    return (
      <Accordion isOpen={currentIndex === index} key={investment.id}>
        <div className='investments-container' onClick={() => toggleOpen(index)}>
          <div className='investments-row campaign-image-container'>
            <div className='investments-content --img'>
              <img src={investment.image_url} alt={investment.campaign.name} />
            </div>
            <div className='investments-content'>
              <p className={investment.payment_done ? 'investments-title green-text' : 'investments-title'}>
                {investment.payment_done ? 'Campanha finalizada' : 'Investimento:'}
              </p>
              {!investment.payment_done && <p>{`R$ ${formatMoney(investment.amount)}`}</p>}
            </div>
          </div>
          <div className='investments-row'>
            <div className='investments-content'>
              <p className='investments-title'>{investment.payment_done ? 'Investimento:' : 'Próxima parcela'}</p>
              {investment.payment_done ? (
                <p>R$ {formatMoney(investment.amount)}</p>
              ) : (
                <p>R$ {formatMoney(investment.nextInstallmentValue)}</p>
              )}
            </div>
            <div className='investments-content'>
              <p className='investments-title'>{investment.payment_done ? 'Retorno líquido' : 'Próximo pagamento:'}</p>
              <p>
                {investment.payment_done
                  ? investment.overview && investment.overview.net_amount
                    ? `R$ ${formatMoney(investment.overview.net_amount)}`
                    : 'R$ -'
                  : investment.nextInstallmentDate
                  ? moment.utc(investment.nextInstallmentDate).format('DD/MM/YYYY')
                  : '-'}
              </p>
            </div>
          </div>
          <button className='toggle-modal-btn'>{currentIndex === index ? '-' : '+'}</button>
        </div>
        <div>
          <div className='investment-summary'>
            <p>Total recebido: R$ {formatMoney(investment.total_received)}</p>
            <p> | </p>
            <p> Total a receber: R$ {formatMoney(investment.remaining_to_receive)}</p>
          </div>
          <ActionContainer>
            {investment.campaign.campaign_updates.length !== 0 && (
              <ActionButton onClick={() => navigateToUpdates(investment.campaign)} label='Atualizações da Campanha' />
            )}
            <ActionButton onClick={() => openInvestmentsResumeModal(investment)} label='Resumo do investimento' />
            <a
              label='Download CCB'
              download
              href={investment.ccb && investment.ccb.ccb_url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ActionButton label='Download CCB' />
            </a>
          </ActionContainer>
          <InstallmentsTable investment={investment} installments={investment.installments} />
        </div>
      </Accordion>
    )
  }

  if (isLoading) return <LoadingScreen />

  if (investments.length === 0 && investmentIntentions.length === 0) {
    return (
      <div className='my-investments'>
        <div className='container'>
          <h2 className='title-investments'>Você ainda não possui investimentos.</h2>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='my-investments'>
        <div className='container'>
          {investments.length !== 0 && (
            <h2 className='title-investments'>
              <p>Total investido: R$ {formatMoney(investmentsOverview.totalInvested)}</p>
              <p>Total recebido: R$ {formatMoney(investmentsOverview.totalReceived)}</p>
              <p>Total a receber: R$ {formatMoney(investmentsOverview.remainingToReceive)}</p>
            </h2>
          )}
          {investments.map((investment, index) => renderInvestment(investment, index))}
          {investmentIntentions.length > 0 && <h2 className='title-investments'>Investimentos em processamento</h2>}
          {matrization(investmentIntentions.slice().sort(sortPledges))
            .sort(sortMatrizationById)
            .map(pledgeArray =>
              pledgeArray.length === 1 ? (
                <PledgeCard key={pledgeArray[0].id} pledge={pledgeArray[0]} handleDeleteCard={deleteCard} />
              ) : (
                <AggregatePledgesCard
                  key={pledgeArray[0].id}
                  pledges={pledgeArray}
                  handleDeleteCard={deleteCard}
                  handleDeleteAggregateCard={deleteAggregateCard}
                />
              )
            )}
        </div>
      </div>
      {investmentsResumeModalContent && (
        <InvestmentsResumeModal
          info={investmentsResumeModalContent}
          className='Modal'
          overlayClassName='Overlay'
          isOpen={investmentsResumeModalIsOpen}
          closeModal={() => setInvestmentsResumeModalIsOpen(false)}
        />
      )}
    </div>
  )
}

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`

const ActionButton = styled(DarkBlueButton)`
  font-size: 12px;
  padding: 12px;
  margin: 10px;
  width: 250px;
`

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default connect(mapStateToProps, { fetchCurrentUser })(MyInvestmentsNew)
