import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { Select, NumberInput, TextInput, Autocomplete } from '../newFormComponents'
import { LoadingScreen, BorderedButton } from '../commom'

import { getAddressById, updateInvestor, getAddressByCep } from '../../services/v3'
import { checkIsCompletedAddress } from '../../helpers'
import { addressesTypeOptions, cityOptions, CEP_LENGTH, userAddressFormDefaultValues } from '../../constants'
import { userAddressSchema } from '../../schemas'

const UserAddressForm = ({ investor, fetchInvestor, changeTab, style }) => {
  const [loading, setLoading] = useState(true)
  const [isCompleted, setIsCompleted] = useState(false)

  const { handleSubmit, errors, control, watch, setValue, reset } = useForm({
    resolver: userAddressSchema,
    defaultValues: userAddressFormDefaultValues
  })

  const zipCode = watch('zip_code')

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setLoading(true)
        if (investor && investor.address_id) {
          const { data: address } = await getAddressById(investor.address_id)
          setIsCompleted(checkIsCompletedAddress({ ...investor, address }))
          reset({
            zip_code: address.zip_code,
            type: address.type,
            street: address.street,
            number: address.number,
            complement: address.complement || '',
            district: address.district,
            address_city: { value: `${address.city} - ${address.uf}`, label: `${address.city} - ${address.uf}` }
          })
        }
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao carregar o endereço, por favor tente novamente')
      } finally {
        setLoading(false)
      }
    }
    fetchInfos()
  }, [investor, reset])

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const cleanZipCode = zipCode.replace(/\D/g, '')
        if (cleanZipCode.length === CEP_LENGTH) {
          const addressInfo = await getAddressByCep(cleanZipCode)
          const address_city = `${addressInfo.localidade} - ${addressInfo.uf}`
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
          setValue('street', addressInfo.logradouro)
          setValue('district', addressInfo.bairro)
          setValue('city', addressInfo.localidade)
          setValue('address_city', { value: address_city, label: address_city })
        }
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar os dados pelo CEP, por favor tente novamente ou preencha manualmente')
      }
    }

    if (zipCode) {
      fetchAddress()
    }
  }, [zipCode, setValue])

  const onSubmit = async values => {
    try {
      const {
        address_city: { value: address_city }
      } = values

      const city = address_city.slice(0, address_city.length - 5)
      const uf = address_city.slice(city.length + 3)

      const body = {
        street: values.street,
        number: values.number,
        zip_code: values.zip_code.replace(/\D/g, ''),
        complement: values.complement,
        district: values.district,
        type: values.type,
        city,
        uf
      }
      if (!values.complement) delete body.complement

      await updateInvestor(investor.id, { address: body })
      setIsCompleted(true)
      toast.success('Endereço salvo com sucesso')
      fetchInvestor()
      changeTab(1, 2)
    } catch (e) {
      console.error(e)
      toast.error('Ocorreu um erro ao salvar o endereço')
    }
  }

  return (
    <li style={style}>
      <div className='collapsible-header'>
        <div className='collapsible-description'>
          <i className='material-icons'>location_on</i>
          <p>Endereço principal</p>
        </div>
        <i className='material-icons' style={{ color: isCompleted ? 'green' : 'grey' }}>
          check_circle
        </i>
      </div>
      <div className='collapsible-body'>
        {loading ? (
          <LoadingScreen small />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <NumberInput
                name='zip_code'
                format='#####-###'
                width='50%'
                control={control}
                label='CEP *'
                error={errors['zip_code']}
              />
              <Select
                name='type'
                control={control}
                label='Tipo *'
                error={errors['type']}
                options={addressesTypeOptions}
                width='50%'
              />
            </InputContainer>
            <InputContainer>
              <TextInput name='street' width='40%' control={control} label='Endereço *' error={errors['street']} />
              <TextInput name='number' width='20%' control={control} label='Número *' error={errors['number']} />
              <TextInput
                name='complement'
                width='40%'
                control={control}
                label='Complemento'
                error={errors['complement']}
              />
            </InputContainer>
            <InputContainer>
              <TextInput name='district' width='50%' control={control} label='Bairro *' error={errors['district']} />
              <Autocomplete
                name='address_city'
                width='50%'
                control={control}
                label='Cidade *'
                error={errors['address_city']}
                options={cityOptions}
              />
            </InputContainer>
            <div className='dfp'>
              <BorderedButton center type='submit' label='SALVAR E CONTINUAR' />
            </div>
          </form>
        )}
      </div>
    </li>
  )
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 620px) {
    flex-direction: column;
  }
`

export default UserAddressForm
