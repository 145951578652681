import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getToken } from '../services'
import { LoadingScreen } from '../components/commom'
import { fetchCurrentUser } from '../actions'
class MyInvestments extends Component {
  state = {
    loading: true,
    changedUrl: false,
    kvdId: null
  }

  async componentDidMount() {
    try {
      await this.props.fetchCurrentUser()
      this.setState({ kvdId: this.props.currentUser.kvd_id })
    } catch (e) {
      console.log(e)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.kvdId && !this.state.changedUrl) {
      this.setState({ loading: true, changedUrl: true })
      const token = getToken()
      document.getElementById(
        'dashboard'
      ).src = `https://hmg.kavodlending.com/dashboard/${this.props.currentUser.kvd_id}/${token}`
    }
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        {this.state.loading && <LoadingScreen />}
        <iframe
          title='my-investmets'
          src=''
          width='100%'
          height='100%'
          allowFullScreen
          id='dashboard'
          onLoad={e => {
            this.setState({ loading: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser
})

export default connect(
  mapStateToProps,
  {
    fetchCurrentUser
  }
)(MyInvestments)
