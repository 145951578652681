import 'react-datepicker/dist/react-datepicker.css'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'

class DatepickerRedux extends Component {
  render() {
    const { field } = this.props
    return (
      <div className={`input-field ${field.className ? field.className : ''}`}>
        <NumberFormat
          id={field.id}
          autoComplete='off'
          placeholder={field.placeholder}
          className='validate'
          format='##/##/####'
          {...field.input}
        />
        <label className='active' htmlFor={field.id}>
          {field.label}
        </label>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }
}

export { DatepickerRedux }
