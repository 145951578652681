import { FORM_SUBMIT } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FORM_SUBMIT:
      return action.payload

    default:
      return state
  }
}
