import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

const TextInput = ({
  register,
  defaultValue,
  name,
  error,
  label,
  type,
  placeholder,
  disabled,
  control,
  required,
  width,
  margin,
  dense = false,
  multiline = false,
  ...props
}) => {
  const customProps = {}

  if (type === 'date') {
    customProps.InputLabelProps = { shrink: true }
  }

  return (
    <Container width={width} margin={margin}>
      <Controller
        as={TextField}
        inputProps={{
          className: 'browser-default'
        }}
        label={label}
        defaultValue={defaultValue}
        variant='outlined'
        error={Boolean(error)}
        helperText={error && error.message}
        name={name}
        control={control}
        ref={register}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        fullWidth
        multiline={multiline}
        {...(dense && { margin: 'dense' })}
        {...(multiline && { rows: 7 })}
        {...customProps}
        {...props}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '200px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export { TextInput }
