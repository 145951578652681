import React from 'react'

import { campaignStatuses } from '../../constants'

const CampaignStatus = ({ status }) => {
  const getRibbonUrl = () => {
    switch (status) {
      case campaignStatuses.CLOSED_WITHOUT_CAPTATION:
        return 'https://s3-sa-east-1.amazonaws.com/app-kavod/common/campanha_encerrada.png'
      case campaignStatuses.CLOSED:
        return 'https://s3-sa-east-1.amazonaws.com/app-kavod/common/captacao_concluida.png'
      case campaignStatuses.WAITING_LIST:
        return 'https://s3-sa-east-1.amazonaws.com/app-kavod/common/lista_espera.png'
      default:
        break
    }
  }

  return (
    <img
      src={getRibbonUrl()}
      className='responsive-img status-campaign-img'
      style={{ position: 'absolute' }}
      alt='Status da campanha'
    />
  )
}

export { CampaignStatus }
