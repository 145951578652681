import React from 'react'

const IconPay = () => {
  return (
    <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 230 230'>
      <path style={{ fill: '#42C7EF' }} d='M142.1,180.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C142.9,179.7,142.6,180.1,142.1,180.1z' />
      <path style={{ fill: '#42C7EF' }} d='M163,164.3h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C163.8,164,163.4,164.3,163,164.3z' />
      <path style={{ fill: '#42C7EF' }} d='M152.7,172.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C153.5,171.8,153.1,172.1,152.7,172.1z' />
      <path style={{ fill: '#42C7EF' }} d='M170.7,154.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C171.5,153.8,171.1,154.1,170.7,154.1z' />
      <path style={{ fill: '#42C7EF' }} d='M178.7,144.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C179.4,143.8,179.1,144.1,178.7,144.1z' />
      <path style={{ fill: '#046F9F' }} d='M84.3,192.5l-32.7-49c-0.8-1.2-0.1-2.9,1.4-3.1l0,0c0.1,0,12.1-1.9,28.3-9.9c1-0.5,2.2-0.1,2.7,0.9l0,0
	c0.5,1,0.1,2.2-0.9,2.7c-10.1,5-18.5,7.6-23.7,9c-1.3,0.3-1.9,1.9-1.2,3l27.5,41.3c0.5,0.8,1.6,1.1,2.5,0.7
	c39.6-18.5,67.6-45.2,75.6-53.3c0.7-0.7,0.8-1.8,0.2-2.6l-3.5-4.7c-0.7-0.9-0.5-2.1,0.4-2.8l0,0c0.9-0.7,2.1-0.5,2.8,0.4l5.5,7.4
	c0.6,0.8,0.5,1.8-0.1,2.5l0,0c-0.3,0.4-32.9,35.9-82.3,58.1l0,0C85.9,193.7,84.9,193.4,84.3,192.5z' />
      <path style={{ fill: '#046F9F' }} d='M105.3,145.2L74,98.2c-0.8-1.1-0.2-2.7,1.2-3l0,0c0.4-0.1,44.1-12.1,77.9-56.3l0,0c0.8-1.1,2.4-1,3.2,0l33.7,46
	c0.5,0.7,0.5,1.7,0,2.4l0,0c-0.3,0.4-27.6,37-82.2,58.7l0,0C106.9,146.3,105.9,146,105.3,145.2z M80.3,100.4l26.6,39.9
	c0.5,0.8,1.6,1.1,2.4,0.7c43.7-18.1,68.8-45.6,75.5-53.7c0.6-0.7,0.6-1.7,0.1-2.5l-28.8-39.3c-0.8-1.1-2.3-1.1-3.2-0.1
	C125.8,78.9,93.5,93,81.2,97.4C80,97.8,79.5,99.3,80.3,100.4z' />
      <path style={{ fill: '#046F9F' }} d='M118.8,128.9c-0.8,0.4-1.7,0.2-2.3-0.4l-0.1-0.1c-0.2-0.2-4.9-5.5-9.3-2.1l-0.1,0.1c-0.9,0.7-2.1,0.4-2.7-0.5
	l-10.2-16.6c-0.6-1-0.2-2.3,0.9-2.7l0,0c0.1,0,0.3-0.1,0.4-0.2c0.7-0.5,2.9-2.6,2.2-9.1l0-0.1c-0.1-1,0.5-1.9,1.5-2l0.1,0
	c0.3-0.1,17.7-3.8,46.7-31.5l0.1-0.1c0.7-0.7,1.9-0.7,2.6,0l0.1,0.1c0.5,0.5,4.8,4.4,9.2,0l0.1-0.1c0.8-0.8,2.1-0.7,2.8,0.2
	L171,77.3c0.6,0.8,0.5,1.8-0.2,2.5l-0.1,0.1c0,0-2.5,2.3-2.5,5.4c0,1.8,0.8,3.7,2.6,5.4l0.1,0.1c0.8,0.8,0.7,2-0.1,2.7l-0.1,0.1
	C170.4,93.8,145.5,115.3,118.8,128.9L118.8,128.9z M110.4,121.2c2.7,0,5.2,1.3,7.1,2.7c0.6,0.5,1.4,0.5,2,0.2
	c20.1-10.7,38.8-25.4,45.7-31.1c0.7-0.6,0.9-1.6,0.4-2.4c-1-1.7-1.5-3.5-1.4-5.4c0.1-2.4,1-4.4,1.9-5.8c0.4-0.6,0.4-1.5-0.1-2.1
	l-5.9-7.9c-0.5-0.7-1.5-1-2.4-0.6c-3.4,1.7-6.7,1-9.2-0.3c-0.7-0.4-1.6-0.2-2.2,0.3c-22.1,20.6-37.2,27.3-43.3,29.4
	c-0.7,0.3-1.2,1-1.2,1.7c0,4-1.2,6.5-2.3,8.1c-0.5,0.6-0.5,1.4-0.1,2.1l6.5,10.6c0.4,0.7,1.3,1.1,2.1,0.8
	C108.7,121.3,109.6,121.2,110.4,121.2z' />
      <path style={{ fill: '#046F9F' }} d='M96,174.8h-0.8l-0.6-0.6c-3.5-3.5-8.5-1.1-8.7-1l0,0c-0.9,0.5-2.1,0.2-2.6-0.7l0,0l-9.3-15
	c-0.5-0.8-0.4-1.8,0.3-2.5l0,0c4.1-4.1,2.2-10.3,2.2-10.3l0,0c-0.3-1,0.2-2,1.1-2.4l11.2-5.2c1-0.5,2.2,0,2.7,1l0,0
	c0.5,1,0,2.2-1,2.7l-8.4,3.9c-0.8,0.4-1.2,1.1-1.2,2c0.2,2.4,0,5.9-2,9c-0.4,0.6-0.4,1.5,0,2.1l6.2,9.9c0.4,0.7,1.3,1.1,2.1,0.9
	c2.6-0.6,5.9-0.5,8.8,1.5c0.5,0.3,1.1,0.4,1.7,0.2c4.4-1.4,17.8-7.3,47.8-32.5c0.8-0.7,2.1-0.6,2.8,0.2l0,0c0.7,0.8,0.6,2.1-0.2,2.8
	C108.9,173.8,97.2,174.8,96,174.8z' />
      <circle style={{ fill: '#046F9F' }} cx='132.7' cy='97.8' r='4.4' />
      <path style={{ fill: '#42C7EF' }} d='M71.4,84.3l-0.6-0.9c-0.4-0.7-0.3-1.6,0.4-2l4.8-3.2c0.7-0.4,1.6-0.3,2,0.4l0.6,0.9c0.4,0.7,0.3,1.6-0.4,2
	l-4.8,3.2C72.7,85.2,71.8,85,71.4,84.3z' />
      <path style={{ fill: '#42C7EF' }} d='M78.4,79' />
      <path style={{ fill: '#42C7EF' }} d='M84.4,75L84.4,75c-0.6-0.9-0.4-2.2,0.6-2.8l17.6-11.7c0.9-0.6,2-0.4,2.7,0.4l5,6.2l4.3-18.9l-18.8,0.9l3.6,4.1
	c0.8,0.9,0.6,2.4-0.5,3L87,63.7c-0.9,0.6-2.2,0.3-2.8-0.6v0c-0.6-0.9-0.3-2.2,0.6-2.8l7.8-4.9c1-0.6,1.3-2.1,0.5-3L90,48.7
	c-1.1-1.3-0.2-3.2,1.4-3.3l25.5-1.3c1.3-0.1,2.3,1.1,2,2.4L113.2,72c-0.4,1.6-2.5,2.1-3.5,0.8l-5.3-6.5c-0.6-0.8-1.8-1-2.7-0.4
	l-14.6,9.7C86.2,76.2,85,76,84.4,75z' />
      <path style={{ fill: '#42C7EF' }} d='M68.9,101.3L68.9,101.3c0.6,0.9,0.3,2.2-0.6,2.8l-12.2,7.9c-0.9,0.6-2,0.4-2.7-0.5l-4.9-6.3L44,124l18.8-0.6
	l-3.5-4.2c-0.8-0.9-0.5-2.4,0.5-3l8.3-5c0.9-0.6,2.2-0.3,2.7,0.7l0,0c0.6,0.9,0.3,2.2-0.7,2.7l-4.3,2.6c-1.1,0.6-1.3,2-0.5,3
	l3.1,3.8c1.1,1.3,0.2,3.2-1.5,3.3l-25.5,0.8c-1.3,0-2.3-1.2-2-2.5l6.2-25.4c0.4-1.6,2.5-2.1,3.5-0.8l5.2,6.6c0.6,0.8,1.8,1,2.7,0.5
	l9.1-5.9C67.1,100.1,68.3,100.3,68.9,101.3z' />
      <path style={{ fill: '#42C7EF' }} d='M69.4,72.9L68.9,72c-0.4-0.7-0.2-1.6,0.5-2l5.4-3.4c0.7-0.4,1.6-0.2,2,0.5l0.6,0.9c0.4,0.7,0.2,1.6-0.5,2
	l-5.4,3.4C70.8,73.8,69.9,73.6,69.4,72.9z' />
      <path style={{ fill: '#42C7EF' }} d='M58,80l-0.6-0.9c-0.4-0.7-0.2-1.6,0.5-2l3.1-1.9c0.7-0.4,1.6-0.2,2,0.5l0.6,0.9c0.4,0.7,0.2,1.6-0.5,2l-3.1,1.9
	C59.4,80.9,58.5,80.7,58,80z' />
    </svg>

  )
}

export { IconPay }
