import styled from 'styled-components'

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  background-color: transparent;
  box-shadow: none;

  a {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #076f9f;
    min-width: 138px;
  }

  div {
    margin: 0 8px;
  }

  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  @media (max-width: 768px) {
    label {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      max-width: 75ch !important;
    }
  }

  @media (max-width: 550px) {
    label {
      display: none;
    }
  }
`
