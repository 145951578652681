import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { ModalEditPhotoOrder } from '../components/Modals'
import UploadCampaignGalleryPhoto from './UploadCampaignGalleryPhoto'
import { LoadingScreen } from '../components/commom'

import { getCampaignById, getCampaignPhotoTypes, deleteCampaignPhoto, getPhotos } from '../services/v3'

class CampaignUploadGalleryForm extends Component {
  state = {
    campaign: null,
    loading: true,
    galleryImages: [],
    galleryPhotoTypeId: null,
    selectedPhoto: null,
    isOpenModalPhotoOrder: false
  }

  componentDidMount() {
    this.fetchInfos()
  }

  fetchInfos = async () => {
    try {
      this.setState({ loading: true })
      const { campaignURL } = this.props.match.params

      const [{ data: campaign }, { data: campaignPhotos }, { data: campaignPhotoTypes }] = await Promise.all([
        getCampaignById(campaignURL),
        getPhotos({ campaign_id: campaignURL }),
        getCampaignPhotoTypes()
      ])

      const galleryPhotoType = campaignPhotoTypes.find(({ abbreviation }) => abbreviation === 'GL')

      const galleryImages = campaignPhotos.filter(
        ({ photo_url, campaign_photo_type_id }) => photo_url && campaign_photo_type_id === galleryPhotoType.id
      )

      this.setState({ campaign, galleryPhotoTypeId: galleryPhotoType.id, galleryImages })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar a campanha')
    } finally {
      this.setState({ loading: false })
    }
  }

  deletePhoto = async photo => {
    try {
      await deleteCampaignPhoto(photo.id)
      toast.success('Foto deletada com sucesso')
      this.fetchInfos()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar deletar a foto')
    }
  }

  renderGalleryPhotos = () => {
    const { campaignURL } = this.props.match
    const { galleryImages } = this.state

    if (galleryImages.length === 0) {
      return (
        <div>
          <p>Sem fotos na galeria</p>
        </div>
      )
    }

    return galleryImages.map(photo => {
      return (
        <div key={photo.id} className='col s12 m4'>
          <div className='dfp'>
            <img className='responsive-img' src={photo.photo_url} alt={campaignURL} />
          </div>
          <div className='flex-center-flex-end gallery-options'>
            <i
              onClick={() => this.setState({ isOpenModalPhotoOrder: true, selectedPhoto: photo })}
              className='material-icons modal-trigger'
              data-target='modal-photo'
            >
              edit
            </i>
            <i onClick={() => this.deletePhoto(photo)} className='material-icons'>
              delete
            </i>
          </div>
        </div>
      )
    })
  }

  render() {
    const { loading, galleryPhotoTypeId, campaign, isOpenModalPhotoOrder, selectedPhoto } = this.state

    if (loading) return <LoadingScreen />

    return (
      <div>
        <div className='container dfp'>
          <UploadCampaignGalleryPhoto photoTypeId={galleryPhotoTypeId} campaign={campaign} refetch={this.fetchInfos} />
        </div>
        <div className='row'>{this.renderGalleryPhotos()}</div>
        {isOpenModalPhotoOrder && (
          <ModalEditPhotoOrder
            photo={selectedPhoto}
            open={isOpenModalPhotoOrder}
            onClose={() => this.setState({ isOpenModalPhotoOrder: false })}
            refresh={this.fetchInfos}
          />
        )}
      </div>
    )
  }
}

export default connect()(CampaignUploadGalleryForm)
