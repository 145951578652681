import React from 'react'

const LoadingScreen = ({ small }) => (
  <div className={small ? 'loading-small' : 'loading-wrapper'}>
    <div className='flex-center-column'>
      <img id='loading-logo' alt='loading logo' src='\assets\img\loading\logo-icon.png' width='100%' />
    </div>
  </div>
)

export { LoadingScreen }
