import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { toast } from 'react-toastify'

import { Switch } from './commom'

import { getCampaignById, updateCampaign, getCampaignStatuses, getPledges } from '../services/v3'
import { navigationSidebarLinks, campaignStatuses } from '../constants'

const openOptions = [campaignStatuses.ACTIVE, campaignStatuses.WAITING_LIST]
const closedOptions = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION]

const ModalCloseCampaign = ({ isOpen, onRequestClose, changeCampaignStatus }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      onRequestClose={onRequestClose}
      overlayClassName='Overlay'
    >
      <ModalContent>
        <CloseIconContainer onClick={onRequestClose}>
          <CloseIcon />
        </CloseIconContainer>
        <Title>A campanha atingiu a meta?</Title>
        <Buttons>
          <Button
            color='primary'
            variant='contained'
            onClick={() => changeCampaignStatus(campaignStatuses.CLOSED)}
            style={{ margin: '0 1em', backgroundColor: '#076f9f' }}
          >
            Sim
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => changeCampaignStatus(campaignStatuses.CLOSED_WITHOUT_CAPTATION)}
          >
            Não
          </Button>
        </Buttons>
      </ModalContent>
    </Modal>
  )
}

const ModalOpenCampaign = ({ isOpen, onRequestClose, changeCampaignStatus, campaignId, fundraisingGoal }) => {
  const handleOpenCampaign = async () => {
    try {
      const { data: pledges } = await getPledges({ campaign_id: campaignId })
      const totalAmount = pledges
        .filter(
          ({ pledge_status_id, pledge_status }) => pledge_status_id && ['AC', 'A'].includes(pledge_status.abbreviation)
        )
        .reduce((totalAmount, { amount }) => totalAmount + amount, 0)
      if (totalAmount >= fundraisingGoal) {
        changeCampaignStatus(campaignStatuses.WAITING_LIST)
      } else {
        changeCampaignStatus(campaignStatuses.ACTIVE)
      }
      onRequestClose()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar reabrir a campanha')
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      onRequestClose={onRequestClose}
      overlayClassName='Overlay'
    >
      <ModalContent>
        <CloseIconContainer onClick={onRequestClose}>
          <CloseIcon />
        </CloseIconContainer>
        <Title>Reabrir a campanha?</Title>
        <Buttons>
          <Button
            color='primary'
            variant='contained'
            onClick={handleOpenCampaign}
            style={{ margin: '0 1em', backgroundColor: '#076f9f' }}
          >
            Sim
          </Button>
          <Button color='secondary' variant='contained' onClick={onRequestClose}>
            Não
          </Button>
        </Buttons>
      </ModalContent>
    </Modal>
  )
}

const CloseIconContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  position: absolute;
  right: 10;
  top: 10;
`

const Title = styled.p`
  font-size: 1.1em;
`

const Buttons = styled.div`
  padding: 1em 0;
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
  }
`

const Modal = styled(ReactModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  border-radius: 4px;
  height: 200px;
  background: #fff;
  &:focus {
    outline: 0;
  }
`

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CampaignNavigationOptions = ({ match, onTabChange }) => {
  const [isOpenModalCloseCampaign, setIsOpenModalCloseCampaign] = useState(false)
  const [isOpenModalOpenCampaign, setIsOpenModalOpenCampaign] = useState(false)
  const [campaign, setCampaign] = useState({})

  const fetchCampaignNew = useCallback(async () => {
    try {
      const { data: campaign } = await getCampaignById(match.params.campaignURL)
      setCampaign(campaign)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar a campanha na base de dados.')
    }
  }, [match.params.campaignURL])

  useEffect(() => {
    fetchCampaignNew()
  }, [fetchCampaignNew])

  const onCheckBoxChange = async event => {
    try {
      const published = event.target.checked
      await updateCampaign(campaign.id, { published })
      toast.success(`${published ? 'Campanha publicada' : 'Campanha retirada do ar'}`)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status da campanha')
    } finally {
      fetchCampaignNew()
    }
  }

  const onSwitchClick = async e => {
    if (e.target.checked) {
      setIsOpenModalCloseCampaign(!isOpenModalCloseCampaign)
    } else {
      setIsOpenModalOpenCampaign(!isOpenModalOpenCampaign)
    }
  }

  const renderContent = _ => {
    const url = window.location.pathname.split('/').pop()
    return navigationSidebarLinks.map(({ to, text }) => {
      const path = to(match.params.campaignURL)
        .split('/')
        .pop()
      return (
        <Link
          onClick={e => {
            onTabChange && onTabChange()
          }}
          key={text}
          to={to(match.params.campaignURL)}
          className={url === path ? 'collection-item active background-blue-kavod' : 'collection-item blue-kavod'}
        >
          {text}
        </Link>
      )
    })
  }

  const changeCampaignStatus = async status => {
    try {
      const { data: campaignStatuses } = await getCampaignStatuses()
      const newStatus = campaignStatuses.find(({ abbreviation }) => abbreviation === status)

      if (!newStatus) throw new Error('Ocorreu um erro ao encontrar o novo status na atualização da campanha')
      await updateCampaign(campaign.id, { campaign_status_id: newStatus.id })
      if (openOptions.includes(status)) setIsOpenModalOpenCampaign(false)
      else if (closedOptions.includes(status)) setIsOpenModalCloseCampaign(false)
      toast.success('Status da campanha atualizado com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status da campanha')
    } finally {
      fetchCampaignNew()
    }
  }

  if (!campaign.id) return null

  return (
    <Wrapper className='collection'>
      <ModalCloseCampaign
        isOpen={isOpenModalCloseCampaign}
        onRequestClose={() => setIsOpenModalCloseCampaign(false)}
        changeCampaignStatus={changeCampaignStatus}
      />
      <ModalOpenCampaign
        isOpen={isOpenModalOpenCampaign}
        onRequestClose={() => setIsOpenModalOpenCampaign(false)}
        changeCampaignStatus={changeCampaignStatus}
        campaignId={match.params.campaignURL}
        fundraisingGoal={campaign.amount}
      />
      {renderContent()}
      <a href='#!' className='collection-item blue-kavod'>
        <Switch
          left='Publicada'
          onClick={onCheckBoxChange}
          checked={campaign.published === 1}
          name='published'
          className='blue-kavod'
        />
      </a>
      <a href='#!' className='collection-item'>
        <Switch
          left='Encerrada'
          onClick={onSwitchClick}
          checked={campaign.campaign_status_id && closedOptions.includes(campaign.campaign_status.abbreviation)}
          name='closed'
        />
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 12px;
`

export default connect()(CampaignNavigationOptions)
