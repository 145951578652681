import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Navigation } from './styles'

const InternalNavigation = ({ link, routeName, name }) => {
  return (
    <Navigation>
      <Link to={link}>{routeName}</Link>
      <div>
        <img src='/img/arrow.svg' alt='arrow' />
      </div>
      <label>{name}</label>
    </Navigation>
  )
}

InternalNavigation.propTypes = {
  link: PropTypes.string.isRequired,
  routeName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default InternalNavigation
