import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { toast } from 'react-toastify'

import { createLeadUpdate, deleteLeadUpdate } from '../../../../../services/v3/timeline'

import { modalAcceptProposalResolver } from '../../../../../helpers'

import { ModalBase, Column, Button, Row, Input } from '../../../../../components/v2Components'

const defaultValue = { observation: '' }

const ModalAcceptProposal = ({ open, handleClose, proposalTimeline }) => {
  const { control, errors, handleSubmit } = useForm({
    resolver: yupResolver(modalAcceptProposalResolver),
    defaultValues: defaultValue
  })

  const onSubmit = async data => {
    try {
      const body = {
        lead_update_status_id: 6,
        lead_id: open.lead_id,
        observation: data.observation.length > 0 ? data.observation : undefined,
        is_visible_to_partner: true
      }

      const { data: response } = await createLeadUpdate(body)
      await deleteLeadUpdate(open.id)

      proposalTimeline(response, open)

      toast.success('Proposta Aceita.')
    } catch {
      toast.error('Ocorreu um erro ao Aceitar a proposta.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase open={open ? true : false} onClose={handleClose} height='100%' width='100%' title={'Aceitar proposta'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column height='100%'>
          <Input
            name='observation'
            placeholder='Mensagem opcional.'
            control={control}
            multiline
            rows={6}
            error={errors.observation}
          />

          <Row mt={3}>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit'>
              Aceitar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalAcceptProposal
