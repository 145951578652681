import React from 'react'
import styled from 'styled-components'

const Button = ({ className, dataTarget, type, disabled, text, align, onClick, margin = 0, padding = 0 }) => {
  return (
    <Wrapper className={align || 'center-align'} margin={margin} padding={padding}>
      <button
        className={`btn waves-effect waves-light ${className}`}
        data-target={dataTarget}
        type={type}
        disabled={disabled}
        name='action'
        style={{ backgroundColor: '#076f9f' }}
        onClick={onClick}
      >
        {text}
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.div(
  ({ margin, padding }) => `
  margin: ${margin};
  padding: ${padding};
`
)

export { Button }
