import React from 'react'
import { withRouter } from 'react-router'
import { BorderedButton, OutlinedButton } from './commom'

const WeAreKavodContainer = props => (
  <section className='video-section-container'>
    <div className='video-section-content'>
      <div className='video-section-text-wrapper'>
        <p className='video-section-title'>Somos a Kavod</p>
        <p className='video-section-body'>
          Somos uma fintech que veio para ajudar ambos os lados do processo. Investidores que querem rentabilidades
          melhores e empresários que querem juros mais justos. Para isso, ambos buscam alternativas aos bancos
          tradicionais e encontram em empresas como a Kavod uma maneira mais justa e transparente disso ser feito.
        </p>
        <div className='video-section-button-container'>
          <BorderedButton
            label='Quero Investir'
            onClick={() => (props.authenticated ? props.history.push('/campanhas') : props.history.push('/investidor'))}
          />
          <OutlinedButton label='Quero empréstimo' onClick={() => props.history.push('/empresa')} />
        </div>
      </div>
    </div>
    {/* <div className='video-section-player'>
      <iframe width='100%' height='100%' src='https://www.youtube.com/embed/998RvXeR-Ng' title='Somos Kavod' />
    </div> */}
  </section>
)

const WeAreKavod = withRouter(WeAreKavodContainer)

export { WeAreKavod }
