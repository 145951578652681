import React from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'

import { UploadIcon } from '../components/icons'

import { fetchCampaignGallery, createCampaignGalleryPhoto, formSubmited } from '../actions'
import { createCampaignPhoto } from '../services/v3'

const dropzoneStyleActive = {
  border: '1px solid green',
  opacity: '0.9'
}

const UploadCampaignGalleryPhoto = ({ formSubmited, campaign, refetch, photoTypeId }) => {
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles.length) return

    try {
      await Promise.all(
        acceptedFiles.map(async file => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('campaign_id', campaign.id)
          formData.append('photo_order', 1)
          formData.append('campaign_photo_type_id', photoTypeId)
          return await createCampaignPhoto(formData)
        })
      )
      formSubmited({ message: 'Foto adicionada' })
      toast.success('Foto(s) adicionada(s) com sucesso')
      refetch()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao salvar as fotos da galeria')
    }
  }

  return (
    <Dropzone
      onDrop={onDrop}
      className='dropzone dropzone-big flex-center-column'
      activeStyle={dropzoneStyleActive}
      multiple
    >
      <UploadIcon />
      <span>Arraste ou clique para fazer upload das imagens</span>
    </Dropzone>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { createCampaignGalleryPhoto, fetchCampaignGallery, formSubmited })(
  UploadCampaignGalleryPhoto
)
