export * from './cities'
export * from './states'
export * from './banks'
export * from './campaigns'
export * from './pledges'
export * from './photos'
export * from './nationality'
export * from './addresses'
export * from './userAddressForm'
export * from './userFinancialInfoForm'
export * from './installments'
export * from './companies'
export * from './investors'

export const termsAndConditionsInvestor =
  'https://www.kavodlending.com/wp-content/uploads/2017/11/Kavod-Lending-Termos-e-Condi%C3%A7%C3%B5es-do-Investidor-e-Tomador.pdf'

export const termsAndCondtionsDigitalPlataform =
  'https://www.kavodlending.com/wp-content/uploads/2017/11/Kavod-Lending-Termos-e-Condi%C3%A7%C3%B5es-de-Uso-de-Plataforma-Digital.pdf'

const whatsPhoneAppNumber = '5511975052863'
const encondedMessage = 'Oi%20KavodLending%21%20Voc%C3%AA%20pode%20me%20ajudar%3F'
export const whatsappApiLink = `https://api.whatsapp.com/send?phone=${whatsPhoneAppNumber}&text=${encondedMessage}`

export const addressInfo =
  'Kavod Lending Tecnologia e Serviços Ltda. – CNPJ 26.610.266/0001-40, Rua João Moura, nº 870, Apartamento 21 - Bloco A, São Paulo, SP. CEP 05412-002.'

export const taxesInfo =
  'kavodlending.com pertence à Kavod Lending Tecnologia e Serviços Ltda (“Kavod Lending”). A Kavod Lending não é uma instituição financeira e não realiza operações de crédito diretamente, mas um correspondente bancário da PARATI CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A ("Parati") nos termos do artigo 2º, da Resolução CMN nº 3.954, de 24 de fevereiro de 2011. Ouvidoria da Instituição Financeira: 0800 723 4787. Informações adicionais - Custo Efetivo Total: CET mínimo de 1,8% ao mês. e máximo de 7% ao mês. Exemplo - Empréstimo de R$ 100.000,00 para pagar em 2 anos (24 meses) – Total de 24 parcelas de R$ 5.349,40. Valor total a pagar igual a R$ 128.385,60 com uma taxa de juros de 1,81% ao mês (24,01% ao ano). CET de 2,1% ao mês (28,27% ao ano).'

export const alertInfo =
  'Kavod Lending Tecnologia e Serviços Ltd não realiza operações de financiamento e/ou investimento diretamente, tampouco realiza quaisquer outras operações privativas de instituições financeiras. Todos os financiamentos e/ou investimentos são realizados através da Parati, devidamente autorizada pelo Banco Central do Brasil a realizar esse tipo de atividade.'

export const FacebookLink = 'https://www.facebook.com/kavodlending/'
export const TwitterLink = 'https://twitter.com/kavodlending'
export const LinkedInLink = 'https://www.linkedin.com/company/kavod-lending/'
export const YoutubeLink = 'https://www.youtube.com/kavodlending'
export const InstagramLink = 'https://www.instagram.com/kavodlending/'

export const userTestimonials = [
  {
    name: 'Camila B. Giuliano',
    text:
      'Invisto com a Kavod porque é uma ótima forma de diversificar o portfólio com baixo risco, além de saber que estou fazendo a diferença na vida dos empreendedores que estão acessando um crédito mais barato e viável para o seu negócio. É uma relação ganha-ganha!',
    image: '/assets/testimonials/Camila_Giulliano.jpg'
  },
  {
    name: 'Leonardo Direzenchi',
    text:
      'Como investidor, fiquei impressionado com as taxas quase irresistíveis. Vejo na Kavod a oportunidade de ganhar mais dinheiro investindo e ao mesmo tempo transformar o futuro em algo melhor, pois sei que estou ajudando empreendedores com uma taxa muito melhor que os bancos tradicionais.',
    image: '/assets/testimonials/Leonardo_Direzenchi.jpg'
  },
  {
    name: 'Julia R. F. Chagas',
    text:
      'A rapidez, facilidade e alta rentabilidade de investir com a Kavod foram o que mais me chamaram a atenção. E somado a isso, saber que é possível contribuir para que empreendedores possam desenvolver seus negócios, é ainda melhor! Tenho certeza que meu investimento está em boas mãos!',
    image: '/assets/testimonials/Julia_Chagas.jpg'
  }
]

export const teamMembers = [
  {
    name: 'FABIO NEUFELD',
    role: 'CEO & Founder',
    borderColor: '#076f9f',
    fb: 'https://www.facebook.com/FabioNeufeld?fref=ts',
    linkedIn: 'https://www.linkedin.com/in/fabioneufeld/',
    photoURL: '/assets/img/team/fabio.png',
    order: 1,
    fullDescription: `Formado em administração de empresas pela FAAP-SP, com MBA em Finanças pelo Insper. 
                      Mais de 16 anos de atuação no mercado financeiro em bancos de primeira linha. 
                      Especialista em estruturação de operações financeiras.`,
    showDescription: false
  },
  {
    name: 'RENATO DOUEK',
    role: 'CMO & Founder',
    borderColor: '#ffc800',
    fb: 'https://www.facebook.com/renatodouek',
    linkedIn: 'https://www.linkedin.com/in/renatodouek',
    photoURL: '/assets/img/team/renato.png',
    order: 2,
    fullDescription: `Formado em Publicidade na FAAP e com MBA em Boston College, 
                      possui quase 15 anos de experiencia nacional e internacional.
                      
                      Atuando há mais de 10 anos no mercado financeiro como profissional de marketing, 
                      está preparado para qualquer desafio.`,
    showDescription: false
  },
  {
    name: 'ADRIANA BAUER',
    role: 'COO',
    borderColor: '#ff8a00',
    fb: 'https://www.facebook.com/adriana.bauer01',
    linkedIn: 'hhttps://www.linkedin.com/in/adrianabauer',
    photoURL: '/assets/img/team/adriana.png',
    order: 3,
    showDescription: false
  },
  {
    name: 'MARCELO LOPES',
    role: 'Crédito',
    borderColor: '#c69c6d',
    linkedIn: 'https://www.linkedin.com/in/marceloaflopes/',
    photoURL: '/assets/img/team/marcelo.png',
    order: 4,
    fullDescription: `Formado em Adm. de Empresas e com MBA em Finanças (IBMEC – SP), 
                      possui experiência de 33 anos no mercado financeiro (bancos, FIDCs e factorings)
                      na área de crédito e comercial. Instituições: 
                      Banco Cidade, Bradesco, Santander, Banco Fator, Zfac e FIDC Federal Invest.`,
    showDescription: false
  },
  {
    name: 'CARLOS PEREIRA',
    role: 'Operations Manager',
    borderColor: '#076f9f',
    fb: 'https://www.facebook.com/carlos.pereira.9469',
    photoURL: '/assets/img/team/carlos.png',
    order: 5,
    fullDescription: '',
    showDescription: false
  },
  {
    name: 'RENAN SOARES',
    role: 'CTO',
    borderColor: '#ffc800',
    fb: 'https://www.facebook.com/renan.soares.108',
    linkedIn: 'https://www.linkedin.com/in/renanss',
    photoURL: '/assets/img/team/renan.png',
    order: 6,
    fullDescription: '',
    showDescription: false
  },
  {
    name: 'VINÍCIUS GOMES',
    role: 'Developer - Estagiário',
    borderColor: '#ff8a00',
    fb: 'https://www.facebook.com/vini.gomes.71',
    linkedIn: 'https://www.linkedin.com/in/vinicius-matos-gomes/',
    photoURL: '/assets/img/team/vini.png',
    order: 7,
    fullDescription: '',
    showDescription: false
  }
]

export const navbarLinks = [
  {
    to: '/',
    className: 'navbar-links text-dark',
    dataTarget: false,
    text: 'Home',
    blank: false,
    external: false,
    anchor: false
  },
  {
    className: 'navbar-links text-dark',
    text: 'Campanhas',
    to: '/campanhas',
    onlyLogged: true
  },
  {
    href: '/minha-conta',
    className: 'navbar-links text-dark dropdown-trigger',
    dataTarget: 'my-account',
    text: 'Minha Conta',
    anchor: true,
    blank: false,
    onlyLogged: true
  },
  {
    href: '/#about-us',
    className: 'navbar-links text-dark dropdown-trigger',
    dataTarget: 'terms-and-conditions',
    text: 'Sobre nós',
    blank: false,
    anchor: true
  },
  {
    href: '/#team',
    className: 'navbar-links text-dark dropdown-trigger',
    dataTarget: 'team',
    text: 'Equipe',
    blank: false,
    anchor: true
  },
  {
    href: 'https://www.kavodlending.com/blog/',
    className: 'navbar-links text-dark',
    dataTarget: 'team',
    text: 'Blog',
    blank: true,
    rel: 'noopener noreferrer',
    anchor: true
  },
  {
    href: '/#signup',
    className: 'navbar-links text-dark dropdown-trigger',
    dataTarget: 'signup-link',
    text: 'Cadastre-se',
    blank: false,
    rel: 'noopener noreferrer',
    anchor: true,
    onlyNonLogged: true
  },
  {
    to: '/contato',
    className: 'navbar-links text-dark',
    dataTarget: false,
    text: 'Contato',
    blank: false,
    external: false,
    anchor: false
  },
  {
    to: '/login',
    className: 'navbar-links text-dark',
    dataTarget: false,
    text: 'Entrar',
    blank: false,
    external: false,
    anchor: false,
    onlyNonLogged: true
  },
  {
    className: 'navbar-links text-dark',
    text: 'Sair',
    to: '/',
    onlyLogged: true,
    logout: true
  }
]
export const sidenavbarLinks = [
  {
    to: '/',
    className: 'waves-effect sidenav-close',
    dataTarget: false,
    text: 'Home',
    blank: false,
    external: false,
    anchor: false
  },
  {
    className: 'waves-effect sidenav-close',
    text: 'Campanhas',
    to: '/campanhas',
    onlyLogged: true
  },
  {
    href: '/minha-conta',
    className: 'waves-effect dropdown-trigger sidenav-close',
    dataTarget: 'my-account',
    text: 'Minha Conta',
    anchor: true,
    blank: false,
    onlyLogged: true
  },
  {
    href: '/#about-us',
    className: 'waves-effect dropdown-trigger sidenav-close',
    dataTarget: 'sidebar-terms-and-conditions',
    text: 'Sobre nós',
    blank: false,
    anchor: true
  },
  {
    href: '/#team',
    className: 'waves-effect dropdown-trigger sidenav-close',
    dataTarget: 'sidebar-team',
    text: 'Equipe',
    blank: false,
    anchor: true
  },
  {
    href: 'https://www.kavodlending.com/blog/',
    className: 'waves-effect sidenav-close',
    dataTarget: 'team',
    text: 'Blog',
    blank: true,
    rel: 'noopener noreferrer',
    anchor: true
  },
  {
    href: '/#signup',
    className: 'waves-effect dropdown-trigger sidenav-close',
    dataTarget: 'sidebar-signup',
    text: 'Cadastre-se',
    blank: false,
    rel: 'noopener noreferrer',
    anchor: true,
    onlyNonLogged: true
  },
  {
    to: '/contato',
    className: 'waves-effect sidenav-close',
    dataTarget: false,
    text: 'Contato',
    blank: false,
    external: false,
    anchor: false
  },
  {
    to: '/login',
    className: 'waves-effect sidenav-close',
    dataTarget: false,
    text: 'Entrar',
    blank: false,
    external: false,
    anchor: false,
    onlyNonLogged: true
  },
  {
    className: 'waves-effect sidenav-close',
    text: 'Sair',
    to: '/',
    onlyLogged: true,
    logout: true
  }
]

export const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    [{ align: [] }, 'direction'],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'super' }, { script: 'sub' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false
  }
}

export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const i18n = {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  weekDays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
}

export const navigationSidebarLinks = [
  {
    to: url => `/admin`,
    text: 'Lista de campanhas'
  },
  {
    to: url => `/admin/campanhas/${url}/informacoes`,
    text: 'Informações'
  },
  {
    to: url => `/admin/campanhas/${url}/resumo`,
    text: 'Resumo '
  },

  {
    to: url => `/admin/campanhas/${url}/foto-capa`,
    text: 'Foto de capa'
  },
  {
    to: url => `/admin/campanhas/${url}/sobre-a-empresa`,
    text: 'Sobre a empresa '
  },
  {
    to: url => `/admin/campanhas/${url}/galeria`,
    text: 'Galeria '
  },
  {
    to: url => `/admin/campanhas/${url}/rating-serasa`,
    text: 'Rating Serasa'
  },
  {
    to: url => `/admin/campanhas/${url}/sobre-a-industria`,
    text: 'Setoriais/Sobre a indústria'
  },
  {
    to: url => `/admin/campanhas/${url}/imagem-dashboard`,
    text: 'Imagem dashboard'
  },
  {
    to: url => `/admin/campanhas/${url}/atualizacoes`,
    text: 'Atualizações '
  },

  {
    to: url => `/admin/campanhas/${url}/draft`,
    text: 'Visualizar draft '
  },

  {
    to: url => `/admin/campanhas/${url}/excluir`,
    text: 'Deletar campanha '
  }
]
