import React from 'react'
import CampaignInformationForm from '../containers/CampaignInformationForm'

const CampaignNew = props => (
  <div className='container'>
    <div className='row'>
      <div className='col s12'>
        <CampaignInformationForm newCampaign history={props.history} />
      </div>
    </div>
  </div>
)

export default CampaignNew
