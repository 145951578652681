import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import styled, { css } from 'styled-components'
import moment from 'moment-timezone'

import { Button } from '../components/commom'
import CampaignReturnSimulator from '../components/CampaignReturnSimulator'

import { calculatePercent, remainingDays, formatCurrency, getOldStatus } from '../helpers'
import { setCurrentInvestment } from '../actions'
import { campaignStatuses } from '../constants'

const closedOptions = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION]

const CampaignPageAmount = ({
  campaign,
  history,
  sumOrders,
  sumInvestorOrders,
  completeProfile,
  match,
  setCurrentInvestment
}) => {
  const [amount, setAmount] = useState(0)
  const [disabledButton, setDisabledButton] = useState(true)
  const [isSimulated, setIsSimulated] = useState(false)

  const { total_amount, max_amount, min_amount } = campaign

  const campaignStatus = useMemo(() => getOldStatus(campaign.campaign_status.abbreviation), [
    campaign.campaign_status.abbreviation
  ])

  const isGoalAchieved = [campaignStatuses.CLOSED, campaignStatuses.WAITING_LIST].includes(campaignStatus)

  useEffect(() => {
    if (
      closedOptions.includes(campaignStatus) ||
      (!campaign.is_before_start_date && !campaign.is_available_for_investments) ||
      (!campaign.is_before_start_date && !Boolean(campaign.published))
    ) {
      setAmount(10000)
      setIsSimulated(true)
    }
  }, [campaign, sumInvestorOrders, campaignStatus])

  const goToCheckoutPage = event => {
    event.preventDefault()
    if (amount <= +max_amount && amount >= +min_amount) {
      setCurrentInvestment({ amount, campaign }, () => {
        const type = match.url.split('/')[2]
        match.path === '/admin/campanhas/:campaignURL/draft'
          ? history.push(`/campanhas/${type}/${campaign.id}/resumo-da-operacao`)
          : history.push(`/campanhas/${type}/${campaign.url}/resumo-da-operacao`)
      })
    }
  }

  useEffect(() => {
    setDisabledButton(
      !completeProfile ||
        amount > max_amount ||
        amount < min_amount ||
        amount + sumInvestorOrders > max_amount ||
        amount > total_amount ||
        !amount
    )
  }, [completeProfile, amount, max_amount, min_amount, total_amount, sumInvestorOrders])

  const handleMessagesWhenUserCanNotInvest = () => {
    if (!completeProfile) return renderCompleteRegistration()
    if (amount && amount < min_amount) return renderAmountMessage('Valor mínimo permitido', min_amount)
    if (amount && amount > max_amount) return renderAmountMessage('Valor máximo permitido', max_amount)
    if (amount + sumInvestorOrders > max_amount) return renderAmountMessage('Valor máximo somado permitido', max_amount)
  }

  const setFocusInputAmount = () => {
    const input = document.querySelector('#campaign-input')
    input.focus()
  }

  const handleChange = ({ target: { value } }) => {
    setAmount(parseInt(value.replace('.', '') || 0) * 1000)
    setIsSimulated(false)
  }

  const renderInputValueButtons = () => {
    return (
      <div className='row'>
        <div className='col s12 m6 input-field inline'>
          <button
            className='btn-block btn waves-effect waves-light btn-simulate'
            type='button'
            onClick={() => setIsSimulated(true)}
          >
            Simular
          </button>
        </div>
        <div className='col s12 m6 input-field inline'>
          {campaignStatus === campaignStatuses.WAITING_LIST ? (
            <Button
              disabled={disabledButton}
              text='Lista de espera'
              className='btn-block'
              type='submit'
              name='action'
            />
          ) : (
            <Button disabled={disabledButton} text='Investir' className='btn-block' type='submit' name='action' />
          )}
        </div>
      </div>
    )
  }

  const renderCompleteRegistration = () => {
    return (
      <div>
        <TextCompleteProfile>
          <i>
            Para finalizar o investimento, é necessário que complete algumas informações em seu cadastro.
            <Link to={'/cadastro-completo'} className='sidenav-close'>
              <span> Clique aqui para completar agora.</span>
            </Link>
          </i>
        </TextCompleteProfile>
      </div>
    )
  }

  const renderAmountMessage = (message, value) => {
    return (
      <div className='row'>
        <AmountMessage id='minToInvest' className='z-depth-2 red darken-1'>
          {message} : {formatCurrency(value)}
        </AmountMessage>
      </div>
    )
  }

  const renderCampaignValuesInfo = () => {
    const capturedValue = sumOrders > campaign.amount ? campaign.amount : sumOrders

    return (
      campaign !== null && (
        <div className='row'>
          <div className='col s12 m4 campaign-page-values-align'>
            <div className='row'>
              <span className='campaign-page-values col s12'>{formatCurrency(campaign.amount)}</span>
              <span className='col s12 campaign-page-values-info'>Meta de Captação</span>
            </div>
          </div>

          <div className='col s12 m4 campaign-page-values-align'>
            <div className='row'>
              <span className='campaign-page-values col s12'>
                {isGoalAchieved ? formatCurrency(campaign.amount) : formatCurrency(capturedValue)}
              </span>
              <span className='col s12 campaign-page-values-info'>Valor Captado</span>
            </div>
          </div>

          <div className='col s12 m4 campaign-page-values-align'>
            <div className='row'>
              <span className='campaign-page-values col s12'>
                {closedOptions.includes(campaignStatus) ? 0 : remainingDays(campaign.end_date)}
              </span>
              <span className='col s12 campaign-page-values-info'>Dias Restantes</span>
            </div>
          </div>
        </div>
      )
    )
  }

  const renderAmountCampaign = () => {
    if (campaign.is_before_start_date) {
      const timestamp = moment.utc(campaign.start_date)
      return (
        <ContainerAmount>
          <TextCampaign className='campaign-page-title'>
            Esta campanha ainda não está disponível para investimento.
          </TextCampaign>
          <TextCampaign className='campaign-page-title'>
            {`Abertura da campanha - ${timestamp.format('DD/MM/YYYY')} às
                  ${timestamp.format('HH:mm')}`}
          </TextCampaign>
        </ContainerAmount>
      )
    }

    if (campaign.is_available_for_investments && campaign.published) {
      return (
        <div>
          <Form onSubmit={event => goToCheckoutPage(event)}>
            <div className='row'>
              <div className='col s12'>{handleMessagesWhenUserCanNotInvest()}</div>
              <div className='col s12 xl5'>
                <ContainerInput>
                  <MaskedInput
                    style={{
                      textAlign: 'right',
                      width: 'calc(100% - 55px)',
                      paddingRight: '55px'
                    }}
                    id='campaign-input'
                    onChange={handleChange}
                    mask={rawValue => {
                      let numberMask = createNumberMask({
                        prefix: '',
                        includeThousandsSeparator: true,
                        allowDecimal: false,
                        allowLeadingZeroes: false,
                        thousandsSeparatorSymbol: '.'
                      })
                      return numberMask(rawValue)
                    }}
                  />
                  <TextInputLeft
                    onClick={() => {
                      setFocusInputAmount()
                    }}
                    className='labels-campaign'
                  >
                    R$
                  </TextInputLeft>
                  <TextInputRight
                    onClick={() => {
                      setFocusInputAmount()
                    }}
                    className='labels-campaign'
                  >
                    .000,00
                  </TextInputRight>
                </ContainerInput>
              </div>
              <div className='col s12 xl7'>{renderInputValueButtons()}</div>
            </div>
          </Form>
        </div>
      )
    }

    return (
      <ContainerAmount>
        <span className='campaign-page-title'>Esta campanha foi finalizada.</span>
      </ContainerAmount>
    )
  }

  return (
    <div className='col s12 l7 center-align'>
      <div className='campaign-page-right-section-margin'>
        <h2 className='campaign-page-title hide-on-med-and-down'>{campaign.name}</h2>
        {renderCampaignValuesInfo()}
        <ContainerValuesCampaign className='campaign-page-values'>
          <span>Captado: {isGoalAchieved ? '100' : calculatePercent(campaign.amount, sumOrders)}%</span>
          <div className='progress campaign-progress'>
            <div
              className='determinate'
              style={{
                width: isGoalAchieved ? '100%' : calculatePercent(campaign.amount, sumOrders) + '%'
              }}
            />
          </div>
        </ContainerValuesCampaign>
        {renderAmountCampaign()}
        {isSimulated ? (
          <CampaignReturnSimulator
            campaign={campaign}
            isSimulated={isSimulated}
            isAvailable={campaign.published && campaign.is_available_for_investments}
            investmentValue={amount}
          />
        ) : (
          Boolean(campaign.published) &&
          campaign.is_available_for_investments && <p>Clique no botão pra simular um investimento na Kavod.</p>
        )}
      </div>
    </div>
  )
}

const AmountMessage = styled.span`
  display: inline-block;
  padding: 6px 8px;
  height: 35px;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  margin: 20px 0px;
`
const TextCompleteProfile = styled.h5`
  text-align: justify;
`
const ContainerAmount = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 30px;
`

const TextCampaign = styled.span`
  font-size: 15pt;
  margin: 0;
`
const Form = styled.form`
  padding: 0px;
  margin-top: 30px;
`
const ContainerInput = styled.div`
  position: relative;
`
const TextInput = css`
  position: absolute;
  bottom: 18px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
`
const TextInputLeft = styled.span`
  ${TextInput}
  left: 0;
`
const TextInputRight = styled.span`
  ${TextInput}
  right: 0;
`
const ContainerValuesCampaign = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

export default withRouter(connect(() => ({}), { setCurrentInvestment })(CampaignPageAmount))
