import {
  FETCH_CURRENT_USER,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
  SIGN_UP_LOADING,
  TOKEN_RESET_LOADING,
  TOKEN_RESET_SUCCESS,
  TOKEN_RESET_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_FORM_SUCCESS,
  LOGOUT
} from '../actions/types'

const initialState = {
  authenticated: false,
  message: '',
  loading: true
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER:
    case RESET_FORM_SUCCESS:
      return { ...action.payload }

    case TOKEN_RESET_LOADING:
    case SIGN_UP_LOADING:
    case RESET_PASSWORD_LOADING:
      return { ...action.payload }

    case LOGIN_SUCCESS:
    case SIGN_UP_SUCCESS:
    case TOKEN_RESET_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return { ...action.payload }

    case LOGIN_FAIL:
    case SIGN_UP_FAIL:
    case RESET_PASSWORD_FAIL:
    case TOKEN_RESET_FAIL:
      return { ...action.payload }

    case LOGIN_LOADING:
      return {
        ...action.payload,
        authenticated: state.authenticated
      }

    case LOGOUT:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
