import React from 'react'
import NumberFormat from 'react-number-format'
import SimpleCurrencyInput from 'react-simple-currency'

const InputRedux = ({ className, field }) => {
  if (field.type === 'agency' || field.type === 'bank_account') {
    return (
      <div className={`input-field ${field.className ? field.className : ''}`}>
        <NumberFormat
          id={field.id}
          placeholder={field.placeholder}
          className='validate'
          autoComplete='off'
          format={field.pattern}
          {...field.input}
          type='text'
        />
        <label className='active' htmlFor={field.id}>
          {field.label}
        </label>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }

  if (field.type === 'currency') {
    return (
      <div className='input-field col s6'>
        <SimpleCurrencyInput
          id={field.id}
          placeholder={field.placeholder}
          autoComplete='off'
          className='validate'
          precision={2}
          separator=','
          delimiter='.'
          unit='R$'
          readOnly={field.readOnly}
          onInputChange={(raw, display) => field.input.onChange(raw)}
          {...field.input}
          value={field.input.value === '' ? 0 : field.input.value}
        />
        <label className='active' htmlFor={field.id}>
          {field.label}
        </label>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }

  if (field.type === 'tel') {
    return (
      <div className={`input-field ${field.className ? field.className : ''}`}>
        <NumberFormat
          id={field.id}
          placeholder={field.placeholder}
          className='validate'
          autoComplete='off'
          format='(##) # ####-####'
          {...field.input}
          type='tel'
        />
        <label className='active' htmlFor={field.id}>
          {field.label}
        </label>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }

  if (field.type === 'text-area') {
    return (
      <div className={`input-field ${className || field.className ? className || field.className : ''}`}>
        <textarea
          id={field.id}
          autoComplete='off'
          placeholder={field.placeholder}
          className='materialize-textarea'
          type={field.type}
          {...field.input}
          readOnly={field.readOnly ? field.readOnly : false}
          maxLength={field.maxLength}
        />
        <label className='active' htmlFor={field.id}>
          {field.label}
        </label>
        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }
  return (
    <div className={`input-field ${className || field.className ? className || field.className : ''}`}>
      <input
        id={field.id}
        placeholder={field.placeholder}
        className={field.readOnly ? 'read-only-text' : 'validate'}
        type={field.type}
        {...field.input}
        readOnly={field.readOnly ? field.readOnly : false}
        maxLength={field.maxLength}
      />
      {field.className === 'has-tip' && (
        <p className='tooltip'>
          Sua senha precisa ter ao menos 8 caracteres, contendo entre eles caracteres maiúsculos, minúsculos e numerais.
        </p>
      )}
      <label className='active' htmlFor={field.id}>
        {field.label}
      </label>
      <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
        {field.meta.touched ? field.meta.error : ''}
      </span>
    </div>
  )
}
export { InputRedux }
