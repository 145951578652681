import React, { Component } from 'react'
import { connect } from 'react-redux'
import CampaignPage from './CampaignPage'

class CampaignDraftShow extends Component {
  render() {
    const { match } = this.props
    return <CampaignPage match={match} />
  }
}
const mapStateToProps = ({ campaign }) => ({ campaign })
export default connect(mapStateToProps)(CampaignDraftShow)
