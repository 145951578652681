export const limitString = (string, limit) => {
  if (!limit || !string || string.length <= limit) {
    return string
  }

  return `${string.slice(0, limit).trim()}...`
}

export const formatCurrency = value => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

const limit = (value, max) => {
  if (value.length === 1 && value[0] > max[0]) value = '0' + value
  if (value.length === 2 && value > max) value = max

  return value
}

export const timeValidation = value => {
  const hour = limit(value.substring(0, 2), '23')
  const minutes = limit(value.substring(2, 4), '59')

  return hour + (minutes.length ? ':' + minutes : '')
}

export const formatCPFToNumber = value => value.replace(/\D/g, '')

export const formatName = name => {
  if (typeof name !== 'string') return ''
  return name
    .toLowerCase()
    .split(/\s/)
    .reduce((acc, currentName) => {
      if (!currentName) return acc
      const captilizedName = currentName.charAt(0).toUpperCase() + currentName.slice(1)
      return `${acc} ${captilizedName}`.trim()
    }, '')
}

export const formatDate = date => {
  if (!date) {
    return undefined
  }

  return new Intl.DateTimeFormat('pt-BR').format(new Date(date))
}

export const formatPhoneWithoutPrefix = number => {
  const phonePattern = /(\d{4}\d?)(\d{4})/
  return number ? number.replace(phonePattern, '$1-$2') : '-'
}
