import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import { LoadingScreen, BorderedButton } from '../components/commom'
import ChangePasswordModal from '../components/ChangePasswordModal/index'
import handleField from '../forms/handleField'

import { login, fetchCurrentUser, clearUserForm } from '../actions'
import { checkIsCompletedInvestor } from '../helpers/investors'
import { loginFormValidator } from '../validators'
import { loginForm } from '../forms'

import '../css/toastify.css'
import 'react-toastify/dist/ReactToastify.css'

class Login extends Component {
  state = { loading: false }

  componentDidMount() {
    setTimeout(() => window.M.updateTextFields(), 300)
  }

  componentWillUnmount() {
    if (!this.props.currentUser.authenticated) {
      this.props.clearUserForm()
    }
  }

  onSubmit = async values => {
    const { login, fetchCurrentUser, history } = this.props

    this.setState({ loading: true })

    login({
      document_number: values.cpf.replace(/\./g, '').replace(/-/, ''),
      password: values.password
    })
      .then(() => {
        fetchCurrentUser().then(({ data: user }) => {
          if (history.location.state && history.location.state.from && history.location.state.from.pathname !== '/') {
            if (history.location.state.from.pathname.split('/').find(item => item === 'cadastro-completo'))
              return (window.location.href = 'cadastro-completo')

            return (window.location.href = '/')
          }

          checkIsCompletedInvestor().then(isCompleted => {
            isCompleted ? (window.location.href = '/campanhas') : (window.location.href = '/cadastro-incompleto')
          })
        })
      })
      .catch(() => {
        this.setState({ loading: false })
        setTimeout(() => window.M.updateTextFields(), 200)
      })
  }

  renderFields = fields =>
    fields.map(field => (
      <Field
        key={field.id}
        label={field.label}
        placeholder={field.placeholder}
        name={field.name}
        type={field.type}
        error={field.error}
        id={field.id}
        maxLength={field.maxLength}
        component={this.renderField}
      />
    ))

  renderField = field => handleField(field)

  render() {
    const { handleSubmit, currentUser } = this.props
    const { loading } = this.state

    if (loading) return <LoadingScreen />

    return (
      <div className='login-row'>
        <ChangePasswordModal />
        <div className='z-depth-2 login-section'>
          <form className='form-spaccing' onSubmit={handleSubmit(this.onSubmit)}>
            {this.renderFields(loginForm)}
            {currentUser.message && (
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '5px' }} className='error-msg'>
                  {currentUser.message}
                </p>
                <p style={{ marginTop: '5px' }} className='error-msg'>
                  Favor revisar.
                </p>
              </div>
            )}
            <BorderedButton center disabled={loading} label='login' type='submit' />
            <div className='reset-password-link'>
              <Link to='/investidor'>Ainda não sou cadastrado</Link>
              <p>|</p>
              <Link to='/password-reset'>Esqueceu sua senha?</Link>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser, completeProfile }) => ({ currentUser, completeProfile })

export default reduxForm({
  form: 'loginForm',
  validate: loginFormValidator,
  destroyOnUnmount: false
})(connect(mapStateToProps, { login, fetchCurrentUser, clearUserForm })(Login))
