import React, { Component } from 'react'

class CampaignPageGallery extends Component {
  state = {
    selectedPhoto: null,
    waitingGallery: null,
    maxLimitGallery: 0
  }

  navOnGallery = event => {
    var key = event.keyCode || event.charCode || 0
    if ([37].indexOf(key) !== -1) {
      this.setState({ selectedPhoto: this.state.selectedPhoto - 1 })
    }
    if ([27].indexOf(key) !== -1) {
      this.setState({ selectedPhoto: null })
    }
    if ([39].indexOf(key) !== -1) {
      this.setState({ selectedPhoto: this.state.selectedPhoto + 1 })
    }
  }

  closeModal = ({ target: div }) => {
    if (div.className === 'campaign-page-photo-modal') {
      this.setState({ selectedPhoto: null })
    }
  }

  componentDidMount() {
    this.setMaxGalleryLength()
  }

  componentWillReceiveProps() {
    this.setMaxGalleryLength()
  }

  setMaxGalleryLength() {
    const { campaignGallery } = this.props
    if (campaignGallery.length !== 0) {
      this.setState({
        maxLimitGallery: campaignGallery.length - 1
      })
    }
  }
  onImgLoad({ target: img }) {
    let content = document.getElementsByClassName('campaign-page-photo-modal-content')[0]
    let imgP = img.width / img.height
    if (window.innerHeight > window.innerWidth) {
      content.style.maxWidth = window.innerWidth * 0.8 + 'px'
    } else {
      content.style.maxWidth = window.innerHeight * 0.8 * imgP + 'px'
    }
  }
  renderModalPhoto = photoId => {
    const { campaignGallery, campaign } = this.props
    const { maxLimitGallery, selectedPhoto } = this.state

    if (photoId > maxLimitGallery) {
      photoId = 0
      this.setState({ selectedPhoto: 0 })
    }
    if (photoId < 0) {
      photoId = maxLimitGallery
      this.setState({ selectedPhoto: maxLimitGallery })
    }

    if (selectedPhoto === null) {
      document.body.removeEventListener('keyup', this.navOnGallery)
    } else {
      document.body.addEventListener('keyup', this.navOnGallery)
    }

    return campaignGallery.map(photo => {
      if (photo.galleryId === photoId) {
        return (
          <div key={photo.galleryId} className='campaign-page-photo-modal' onClick={this.closeModal}>
            <div className='campaign-page-photo-modal-content'>
              <div className='flex'>
                <div
                  className='campaign-page-modal-img'
                  onClick={() => this.setState({ selectedPhoto: photo.galleryId + 1 })}
                >
                  <img
                    className=''
                    onLoad={this.onImgLoad}
                    src={photo.photo_url}
                    alt={'Imagem da galeria para a campanha da empresa: ' + campaign.name}
                  />
                </div>
                <div
                  className='campaign-page-photo-modal-nav-wrapper-previous'
                  onClick={() => this.setState({ selectedPhoto: photo.galleryId - 1 })}
                >
                  <div className='campaign-page-photo-modal-nav round previous'>
                    <i className='material-icons' onClick={() => this.setState({ selectedPhoto: photo.galleryId - 1 })}>
                      navigate_before
                    </i>
                  </div>
                </div>
                <div
                  className='campaign-page-photo-modal-nav-wrapper-next'
                  onClick={() => this.setState({ selectedPhoto: photo.galleryId + 1 })}
                >
                  <div className='campaign-page-photo-modal-nav round next'>
                    <i className='material-icons' onClick={() => this.setState({ selectedPhoto: photo.galleryId + 1 })}>
                      navigate_next
                    </i>
                  </div>
                </div>

                <div
                  className='campaign-page-photo-modal-close round close'
                  onClick={() => this.setState({ selectedPhoto: null })}
                >
                  <i className='material-icons'>close</i>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return null
      }
    })
  }

  render() {
    const { campaignGallery } = this.props

    if (campaignGallery.length === 0) return <div />
    return (
      <div>
        <p>
          <strong className='campaign-gallery__title'> Galeria de imagens </strong>
          <br />
          (Clique nas imagens para ampliar)
        </p>
        <div className='img-list '>
          {campaignGallery.map(photo => (
            <div
              key={photo.id}
              className='campaign-page-img'
              onClick={() => this.setState({ selectedPhoto: photo.galleryId })}
            >
              <img
                className='campaign-page-gallery-img-list-item'
                src={photo.photo_url}
                alt={'Imagem da galeria para a campanha da empresa: ' + this.props.campaign.name}
              />
            </div>
          ))}
        </div>
        <div>{this.renderModalPhoto(this.state.selectedPhoto)}</div>
      </div>
    )
  }
}

export { CampaignPageGallery }
