import { validateCPF } from './validateCPF'

export const resetPasswordValidator = data => {
  let errors = {}

  if (!data.cpf) errors.cpf = 'CPF é obrigatório'
  if (!data.email) errors.email = 'Email é um campo obrigatório'
  if (!data.password) errors.password = 'Senha é um campo obrigatório'
  if (!data.confirmPassword) errors.confirmPassword = 'Confirmar senha é um campo obrigatório'

  if (data.cpf && !validateCPF(data.cpf)) {
    errors.cpf = 'CPF inválido'
  }

  if (data.password !== data.confirmPassword) {
    errors.password = 'As senhas devem ser iguais'
    errors.confirmPassword = 'As senhas devem ser iguais'
  }

  if (data.password && data.password.length < 8) {
    errors.password = 'Senha deve ter pelo menos 8 caracteres'
  }

  if (data.password) {
    const regexUpperCase = /[A-Z]/
    const hasUpperCase = regexUpperCase.test(data.password)
    if (!hasUpperCase) errors.password = 'Senha deve conter letras maíusculas'
  }

  if (data.password) {
    const regexLowerCase = /[a-z]/
    const hasLowerCase = regexLowerCase.test(data.password)
    if (!hasLowerCase) errors.password = 'Senha deve conter letras minúsculas'
  }

  if (data.password) {
    const regexHasNumber = /\d/
    const hasNumber = regexHasNumber.test(data.password)
    if (!hasNumber) errors.password = 'Senha deve conter números'
  }

  return errors
}
