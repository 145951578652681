import React, { Component } from 'react'

class Accordion extends Component {
  render() {
    return (
      <div className='accordion-investments'>
        {this.props.children[0]}
        {this.props.isOpen && this.props.children[1]}
      </div>
    )
  }
}

export default Accordion
