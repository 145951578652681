import { FETCH_CAMPAIGNS, CREATE_CAMPAIGN, LOADING_CAMPAIGNS } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return { ...action.payload }

    case LOADING_CAMPAIGNS:
      return null

    case CREATE_CAMPAIGN:
      return state

    default:
      return state
  }
}
