import { cities } from '../constants'

export const addressForm = [
  {
    label: 'Endereço *',
    placeholder: '',
    type: 'text',
    name: 'street',
    error: 'Campo obrigatório',
    id: 'street',
    className: 'col m4'
  },
  {
    label: 'Número *',
    placeholder: '',
    type: 'text',
    name: 'number',
    error: 'Campo obrigatório',
    id: 'number',
    className: 'col m4',
    maxLength: 11
  },
  {
    label: 'Complemento',
    placeholder: '',
    type: 'text',
    name: 'complement',
    error: 'Campo obrigatório',
    id: 'complement',
    className: 'col m4',
    maxLength: 255
  },
  {
    label: 'Bairro *',
    placeholder: '',
    type: 'text',
    name: 'district',
    error: 'Campo obrigatório',
    id: 'district',
    className: 'col m6'
  },
  {
    label: 'Cidade *',
    placeholder: '',
    type: 'autocomplete',
    name: 'birth_something2',
    id: 'birth_something2',
    error: 'Campo obrigatório',
    options: cities,
    className: 'col m6 s12'
  }
]

export const documentsForm = [
  {
    label: 'Documento de identificação * (Máximo: 5MB)',
    placeholder: '',
    type: 'radio-button',
    name: 'personal_document_type_id1',
    error: 'Campo obrigatório',
    id: 'C',
    text: 'CNH'
  },
  {
    placeholder: '',
    type: 'radio-button',
    name: 'personal_document_type_id1',
    error: 'Campo obrigatório',
    id: 'R',
    text: 'RG'
  },
  {
    placeholder: '',
    type: 'radio-button',
    name: 'personal_document_type_id1',
    error: 'Campo obrigatório',
    id: 'O',
    text: 'OAB'
  },
  {
    placeholder: '',
    type: 'radio-button',
    name: 'personal_document_type_id1',
    error: 'Campo obrigatório',
    id: 'RN',
    text: 'RNE'
  },
  {
    label: 'Upload documento de identificação *',
    text: 'Arraste ou clique para fazer upload do seu documento identificação',
    placeholder: '',
    type: 'personal-document',
    name: 'upload_personal_document_type_id1',
    error: 'Campo obrigatório',
    id: 'upload_personal_document_type_id1'
  },
  {
    label: 'Documento pessoal *',
    placeholder: '',
    type: 'select',
    name: 'personal_document_type_id2',
    error: 'Campo obrigatório',
    id: 'personal_document_type_id2',
    options: [
      { id: 'O', text: '' },
      { id: 'CR', text: 'Comprovante de residência' }
    ]
  },
  {
    label: 'Upload documento pessoal *',
    text: 'Arraste ou clique para fazer upload do seu documento pessoal',
    placeholder: '',
    type: 'personal-document',
    name: 'upload_personal_document_type_id2',
    error: 'Campo obrigatório',
    id: 'upload_personal_document_type_id2'
  }
]

export const termsAndConditionsForm = [
  {
    label:
      'Li e ACEITO os <a rel="noopener noreferrer" href="https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-+Termos+e+Condic%CC%A7o%CC%83es+para+Investidores+e+Tomadores_Maio+2019+%5BFINAL%5D.pdf" target="_blank">Termos e Condições</a> para INVESTIR na Kavod Lending. *',
    placeholder: '',
    type: 'checkbox',
    name: 'compliance_agreement_c',
    error: 'Campo obrigatório',
    id: 'compliance_agreement_c'
  },
  {
    label:
      'Li e ACEITO os <a rel="noopener noreferrer" href="https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-Termos-e-Condic%CC%A7o%CC%83es-de-Uso-de-Plataforma-Digital.pdf" target="_blank">Termos e Condições</a> de USO DA PLATAFORMA DIGITAL da Kavod Lending. *',
    placeholder: '',
    type: 'checkbox',
    name: 'compliance_agreement_c2',
    error: 'Campo obrigatório',
    id: 'compliance_agreement_c2'
  }
]
