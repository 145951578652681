import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import moment from 'moment-timezone'
import { ToastContainer } from 'react-toastify'
import { createBrowserHistory } from 'history'
import { fetchCurrentUser, logout, notLogged } from './actions'
import Home from './containers/Home'
import Login from './containers/Login'
import SignUp from './containers/SignUp'
import SignUpCompany from './containers/SignUpCompany'
import UserAccount from './containers/UserAccount'
import Contact from './containers/Contact'
import WorkWithUs from './containers/WorkWithUs'
import UserAccountOptionsPage from './containers/UserAccountOptionsPage'
import CampaignUpdates from './containers/CampaignUpdates'
import CampaignUpdate from './containers/CampaignUpdate'
import AdminCampaigns from './containers/AdminCampaigns'
import CampaignsPage from './containers/CampaignsPage'
import CampaignsOptions from './containers/CampaignsOptions'
import CampaignPage from './containers/CampaignPage'
import CampaignsPageWithGuarantee from './containers/CampaignsPageWithGuarantee'
import CampaignUpdatesNew from './components/CampaignUpdatesNew'
import CampaignInformationPage from './components/CampaignInformationPage'
import CampaignDraftPage from './components/CampaignDrafPage'
import CampaignCompanyDetailsPage from './components/CampaignCompanyDetailsPage'
import CampaignOverviewPage from './components/CampaignOverviewPage'
import CampaignPhoto from './components/CampaignPhoto'
import CampaignGallery from './components/CampaignGallery'
import CampaignDelete from './components/CampaignDelete'
import CampaignNew from './components/CampaignNew'
import CampaignInvestmentsPhoto from './components/CampaignInvestmentsPhoto'
import CheckoutPage from './containers/CheckoutPage'
import PledgeReceivedPage from './containers/PledgeReceivedPage'
import BankCreditCertificateSignature from './containers/BankCreditCertificateSignature'
import Header from './components/commom/Header'
import { UserMessage } from './components/commom'
import FlashMessageComponent from './components/commom/FlashMessageComponent'
import RequestResetToken from './containers/RequestResetToken'
import CompletedSignUp from './containers/CompletedSignUp'
import ThankYouCompanies from './containers/ThankYouCompanies'
import PasswordReset from './containers/PasswordReset'
import MyInvestments from './containers/MyInvestments'
import MyInvestmentsNew from './containers/MyInvestments/MyInvestmentsNew'
import PrivateRoute from './components/PrivateRoute'
import { getToken, clearToken } from './services'
// import ChangePasswordModal from './components/ChangePasswordModal/index'
import NotFound from './containers/NotFound'
import RatingSerasa from './containers/RatingSerasa'
import AboutIndustry from './containers/AboutIndustry'
import SignatoryCampaigns from './containers/SignatoryCampaigns'
import SignUpPartner from './containers/SignUpPartner'
import Partner from './containers/Partner'
import PartnerLeads from './containers/PartnerLeads'
import MyIndications from './containers/MyIndications/MyIndications'
import MyIndicationsShow from './containers/MyIndications/Show/MyIndicationsShow'

import { ThemeProvider } from 'styled-components'
import { colors } from './Themes'

const history = createBrowserHistory()

moment.locale('pt-br')

Object.values(languages).forEach(l => numbro.registerLanguage(l))
numbro.setLanguage('pt-BR')

const App = props => {
  const { fetchCurrentUser, notLogged } = props
  // const [isModalVisible, setIsModalVisible] = React.useState(true)

  React.useEffect(() => {
    const token = getToken()
    if (token) {
      fetchCurrentUser()
    } else {
      notLogged()
    }
    const script = document.createElement('script')
    script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/6291eeda-2491-45a0-b85f-817d3d5ced7b-loader.js'
    script.async = true
    document.head.appendChild(script)
  }, [fetchCurrentUser, notLogged])

  React.useEffect(() => {
    window.addEventListener('beforeunload', event => {
      event.preventDefault()
      window.localStorage.removeItem('modal-new-plataform-closed')
    })
  }, [])

  const logout = () => {
    clearToken()
    props.logout()
    window.location.reload()
  }

  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={{ colors }}>
        <Router history={history}>
          <div>
            <Header authenticated={props.currentUser.authenticated} logout={logout} />
            <FlashMessageComponent />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/password-reset' component={RequestResetToken} />
              <Route exact path='/reset-de-senha/:tokenPassword' component={PasswordReset} />
              <Route exact path='/investidor' component={SignUp} />
              <Route exact path='/cadastro-parceria' component={SignUpPartner} />
              <Route exact path='/contato' component={Contact} />
              <Route exact path='/envie-seu-curriculo' component={WorkWithUs} />
              <Route exact path='/agradecimento-empresas' component={ThankYouCompanies} />
              <Route exact path='/empresa' component={SignUpCompany} />
              <Route exact path='/parcerias' component={Partner} />
              <PrivateRoute exact path='/indicacao-parceria' component={PartnerLeads} />
              <PrivateRoute exact path='/cadastro-incompleto' component={UserMessage} />
              <PrivateRoute exact path='/campanhas' component={CampaignsOptions} />
              <PrivateRoute exact path='/campanhas/com-aval-e-garantia' component={CampaignsPageWithGuarantee} />
              <PrivateRoute exact path='/campanhas/com-aval' component={CampaignsPage} />
              <PrivateRoute exact path='/minha-conta' component={UserAccountOptionsPage} />
              <PrivateRoute exact path='/vamos-investir' component={CompletedSignUp} />
              <PrivateRoute exact path='/meus-investimentos' component={MyInvestmentsNew} />
              <PrivateRoute exact path='/meus-investimentos-old' component={MyInvestments} />
              <PrivateRoute exact path='/cadastro-completo' component={UserAccount} />
              <PrivateRoute exact path='/assinatura-de-ccb/:campaignID' component={BankCreditCertificateSignature} />
              <PrivateRoute exact path='/campanhas/com-aval/:campaignURL' component={CampaignPage} />
              <PrivateRoute exact path='/campanhas/com-aval/:campaignURL/resumo-da-operacao' component={CheckoutPage} />
              <PrivateRoute exact path='/minhas-indicacoes' component={MyIndications} />
              <PrivateRoute exact path='/minhas-indicacoes/:id' component={props => <MyIndicationsShow {...props} />} />
              <PrivateRoute
                exact
                path='/campanhas/com-aval/:campaignURL/resumo-da-operacao/operacao-concluida'
                component={PledgeReceivedPage}
              />
              <PrivateRoute exact path='/campanhas/com-aval-e-garantia/:campaignURL' component={CampaignPage} />
              <PrivateRoute
                exact
                path='/campanhas/com-aval-e-garantia/:campaignURL/resumo-da-operacao'
                component={CheckoutPage}
              />
              <PrivateRoute
                exact
                path='/campanhas/com-aval-e-garantia/:campaignURL/resumo-da-operacao/operacao-concluida'
                component={PledgeReceivedPage}
              />
              <PrivateRoute exact path='/admin' component={AdminCampaigns} />
              <PrivateRoute exact path='/admin/campanhas' component={AdminCampaigns} />
              <PrivateRoute exact path='/admin/campanhas/criar' component={CampaignNew} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/resumo' component={CampaignOverviewPage} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/informacoes' component={CampaignInformationPage} />
              <PrivateRoute
                path='/admin/campanhas/:campaignURL/sobre-a-empresa'
                component={CampaignCompanyDetailsPage}
              />
              <PrivateRoute path='/admin/campanhas/:campaignURL/foto-capa' component={CampaignPhoto} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/galeria' component={CampaignGallery} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/excluir' component={CampaignDelete} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/atualizacoes/criar' component={CampaignUpdatesNew} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/atualizacoes/:id' component={CampaignUpdate} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/atualizacoes' component={CampaignUpdates} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/draft' component={CampaignDraftPage} />
              <PrivateRoute
                path='/admin/campanhas/:campaignURL/imagem-dashboard'
                component={CampaignInvestmentsPhoto}
              />
              <PrivateRoute path='/admin/campanhas/:campaignURL/rating-serasa' component={RatingSerasa} />
              <PrivateRoute path='/admin/campanhas/:campaignURL/sobre-a-industria' component={AboutIndustry} />
              <PrivateRoute path='/contratos-para-assinar/' component={SignatoryCampaigns} />

              <Route component={NotFound} />
            </Switch>
            {/* <Route component={(props) => <ChangePasswordModal {...props} visible={isModalVisible} closeModal={() => {
        window.localStorage.setItem('modal-new-plataform-closed', 'true')
        setIsModalVisible(false)
      }} />} /> */}
          </div>
        </Router>
      </ThemeProvider>
    </>
  )
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser
})

export default connect(mapStateToProps, { fetchCurrentUser, logout, notLogged })(App)
