import React from 'react'
import { withRouter } from 'react-router'
import { BorderedButton } from './commom'

const GraphicsContainer = props => {
  return (
    <section>
      <div className='graphics-section'>
        <div className='graphics-section-content'>
          <div className='content-wrapper'>
            <h2 className='content-title'>Quanto vai render meu investimento?</h2>
            <p>
              Além de ajudar pequenos e médios empreendedores, seu dinheiro vai render muito mais do que em
              investimentos tradicionais, podendo ser até 2,5 vezes melhor!
            </p>
            <p>Invista já e aumente a rentabilidade do seu portifólio.</p>
            <div className='button-container'>
              <BorderedButton
                label='Quero Investir'
                onClick={() =>
                  props.authenticated ? props.history.push('/campanhas') : props.history.push('/investidor')
                }
              />
            </div>
          </div>
        </div>
        <div className='graphic'>
          <img className='image' src='assets/img/home/analysis.png' alt='gráfico' />
        </div>
      </div>
      <div className='graphics-section blue-graphic'>
        <div className='graphics-section-content'>
          <div className='content-wrapper'>
            <h2 className='content-title'>Quanto vou pagar de juros?</h2>
            <p>
              Para quem solicita um empréstimo com a Kavod Lending, as taxas de juros são de a partir de 1,20% a.m.
              (Sujeitos a análise de crédito).
            </p>
            <p>Podendo ser até 40% mais barato do que nos bancos tradicionais.</p>
            <p>
              Preencha nosso formulário de solicitação de crédito e deixe-nos participar do crescimento da sua empresa.{' '}
            </p>
            <div className='button-container'>
              <BorderedButton label='Quero Empréstimos' onClick={() => props.history.push('/empresa')} />
            </div>
          </div>
        </div>
        <div className='graphic'>
          <img className='image' src='assets/img/home/bars-chart.png' alt='gráfico' />
        </div>
      </div>
    </section>
  )
}

const Graphics = withRouter(GraphicsContainer)

export { Graphics }
