import React from 'react'
import styled from 'styled-components'

import { Input, NumberInput } from '../index'
import Text from '../Text'

const CompanyDataIncompleted = ({ isEditing, errors, isMobile, isTablet, control }) => {
  return (
    <div>
      <Row>
        <Text mt={5} variant='h4'>
          {' '}
          Dados da empresa{' '}
        </Text>
      </Row>
      <Row>
        <Input
          mt={5}
          label='Nome da empresa *'
          error={errors.company_name}
          control={control}
          name='company_name'
          width={isMobile || isTablet ? '90%' : '65%'}
          ml={isMobile || isTablet ? 0 : 0}
        />
      </Row>
      <Row>
        <DivNumber>
          <NumberInput
            mt={5}
            error={errors.average_monthly_revenue}
            inputType='currency'
            label='FATURAMENTO MÉDIO MENSAL PREVISTO *'
            name='average_monthly_revenue'
            control={control}
            width={isMobile || isTablet ? '90%' : '45%'}
          />
        </DivNumber>
        {!isEditing && (
          <NumberInput
            mt={5}
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width={isMobile || isTablet ? '90%' : '45%'}
            ml={isMobile || isTablet ? 0 : 3}
          />
        )}
      </Row>
      <Row>
        <Input
          mt={5}
          label='Histórico da empresa *'
          control={control}
          name='company_history'
          width={isMobile || isTablet ? '90%' : '92%'}
          multiline
          rows={5}
          error={errors.company_history}
        />
      </Row>
      <Row>
        <Input
          mt={5}
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width={isMobile || isTablet ? '90%' : '92%'}
          multiline
          rows={5}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const DivNumber = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: contents;
  @media (max-width: 768px) {
    label {
      width: 108%;
    }
  }
`

export default CompanyDataIncompleted
