import styled from 'styled-components'
import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineSeparator
} from '@material-ui/lab'
import { Avatar } from '@material-ui/core'

import { Text } from '../../../../../components/v2Components'

import { IconCheck, IconClear } from '../../../../../components/v2Components/iconsV2'

const color = status => {
  switch (status) {
    case 4:
      return '#FD4646'
    case 2:
      return '#076F9F'
    case 1:
      return '#663CCC'
    case 3:
      return '#4AB34E'
    default:
      return '#076F9F'
  }
}

export const StyledTimeline = styled(Timeline)`
  align-items: center;
  @media (max-width: 768px) {
    align-items: inherit;
    padding: 6px 0px;
  }
`
export const StyledTimelineItem = styled(TimelineItem)`
  padding-bottom: 24px;

  &&.MuiTimelineItem-missingOppositeContent:before {
    content: none;
  }
`
export const StyledTimelineItemDate = styled(TimelineItem)`
  padding-bottom: 17px;
  min-height: 24px !important;

  &&.MuiTimelineItem-missingOppositeContent:before {
    content: none;
  }
`

export const StyledTimelineSeparator = styled(TimelineSeparator)`
  justify-content: center;
`
export const StyledDataTimelineSeparator = styled(TimelineSeparator)`
  justify-content: center;

  @media (max-width: 768px) {
    padding-left: 17px;
  }
`

export const StyledTimelineDot = styled(TimelineDot)`
  &&.MuiTimelineDot-root {
    margin-bottom: 0;
    position: relative;
    margin-top: 0;
    top: 7px;
  }
  &&.MuiTimelineDot-defaultGrey {
    background-color: ${({ status, bgcolor }) => (bgcolor ? bgcolor : color(status))};
  }
`
export const StyledTimelineConnector = styled(TimelineConnector)`
  background-color: #e0e0e0 !important;
  position: absolute;
  height: 104%;
  z-index: -1;
`
export const StyledTimelineContent = styled(TimelineContent)`
  width: 35vw;
  padding: 6px 16px;
  background: ${({ bgcolor }) => (bgcolor ? bgcolor : '#f5f5f5')};
  border-radius: 10px;
  margin-left: 23px;
  max-width: 460px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`
export const StyledTimelineContentDate = styled(TimelineContent)`
  width: 35vw;
  padding: 6px 16px 16px 16px;
  background: transparent;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 460px;

  div {
    display: flex;
    align-self: center;
    justify-content: center;
    position: relative;
    top: 6px;
  }

  hr {
    border: 0;
    border-top: 1px solid #eeeeee;
    width: 119%;
    position: relative;
    top: -14px;
    left: -39px;
    z-index: -1;
    height: 0px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    position: relative;
    left: 8px;
    top: 5px;
    width: 32px;
    height: 32px;
  }
`
export const Hr = styled.hr`
  border: 0;
  border-top: 1px solid #eeeeee;
  width: 23px;
  position: relative;
  top: -18px;
  left: 30px;
  height: 0px;
`
export const TextDate = styled(Text)`
  background: #3dc6ef;
  border-radius: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 0 8px 0px 8px;
`
export const TextStatus = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  margin-top: 16px !important;
`
export const Image = styled.img`
  width: 100%;
  height: 100%;
`
export const StyledIconCheck = styled(IconCheck)`
  @media (max-width: 550px) {
    display: none !important;
  }
`

export const StyledIconClear = styled(IconClear)`
  @media (max-width: 550px) {
    display: none !important;
  }
`
export const DivText = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  padding-left: 65px;
`
