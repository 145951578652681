import { CURRENT_INVESTMENT, CLEAR_CURRENT_INVESTMENT } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INVESTMENT:
    case CLEAR_CURRENT_INVESTMENT:
      return { ...action.payload }

    default:
      return state
  }
}
