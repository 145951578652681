import React, { Component } from 'react'
import { LinkedInIcon, FacebookIcon } from './icons'
import { teamMembers } from '../constants'

class Team extends Component {
  state = { teamMembers }

  renderContent = () => {
    return this.state.teamMembers.map(member => {
      if (member.showDescription) {
        return (
          <div
            className='col s12 m6 l3 card-member flex-center-column'
            key={member.order}
            onMouseLeave={() => this.hideDescription(member.order)}
          >
            <div className='member-description dfp'>
              <span>{member.fullDescription}</span>
            </div>
          </div>
        )
      }
      return (
        <div className='col s12 m6 l3 card-member flex-center-column' key={member.order}>
          <img
            className='profile-image'
            src={member.photoURL}
            alt={member.name}
            onMouseEnter={() => this.showDescription(member.order)}
          />
          <div className='center-align'>
            <span className='dfp-bottom text-dark strong-text member-name'>{member.name}</span>
            <hr style={{ backgroundColor: member.borderColor }} />
            <span className='member-role'>{member.role}</span>
            <div className='dfp'>
              {member.fb ? (
                <a href={member.fb} className='member-icon dfp'>
                  <FacebookIcon />
                </a>
              ) : null}
              {member.linkedIn ? (
                <a href={member.linkedIn} className='member-icon dfp'>
                  <LinkedInIcon />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      )
    })
  }

  showDescription = memberOrder => {
    let teamMembers = this.state.teamMembers
    return teamMembers.map(member => {
      if (member.order === memberOrder && member.fullDescription) {
        member.showDescription = true
        return this.setState({ teamMembers })
      }
      return null
    })
  }

  hideDescription = memberOrder => {
    let teamMembers = this.state.teamMembers
    return teamMembers.map(member => {
      if (member.order === memberOrder) {
        member.showDescription = false
        return this.setState({ teamMembers })
      }
      return null
    })
  }

  render() {
    const { teamMembers } = this.state
    return (
      <div className='team-section scrollspy' id='team'>
        <div className='section-description'>
          <div className='text-wrapper'>
            <h3>Nosso Time</h3>
            <p>Uma equipe multidisciplinar para atender com excelência</p>
          </div>
        </div>
        <div>
          <div className='row members'>
            {teamMembers.map(member => {
              const classVisibility = member.showDescription ? '' : 'invisible-description'
              return (
                <div
                  className='col s12 m6 l3 card-member flex-center-column'
                  key={member.order}
                  onMouseLeave={() => this.hideDescription(member.order)}
                  onMouseEnter={() => this.showDescription(member.order)}
                >
                  {
                    <div className={`member-description dfp ${classVisibility}`}>
                      <span>{member.fullDescription}</span>
                    </div>
                  }
                  <img className='profile-image' src={member.photoURL} alt={member.name} />
                  <div className='center-align'>
                    <span className='dfp-bottom member-name'>{member.name}</span>
                    <hr style={{ backgroundColor: member.borderColor }} />
                    <span className='member-role'>{member.role}</span>
                    <div className='dfp'>
                      {member.fb && (
                        <a href={member.fb} className='member-icon dfp'>
                          <FacebookIcon />
                        </a>
                      )}
                      {member.linkedIn && (
                        <a href={member.linkedIn} className='member-icon dfp'>
                          <LinkedInIcon />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export { Team }
