import React from 'react'
import { Controller } from 'react-hook-form'
import AutocompleteUI from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { VariableSizeList } from 'react-window'

import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ListSubheader from '@material-ui/core/ListSubheader'
import { useTheme } from '@material-ui/core/styles'

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node
}

const Autocomplete = ({ name, label, disabled, control, width, margin, options, error }) => {
  return (
    <Container width={width} margin={margin}>
      <Controller
        render={props => (
          <AutocompleteUI
            {...props}
            renderOption={({ label }) => <span>{label}</span>}
            options={options}
            getOptionLabel={option => option.label}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, className: params.inputProps.className + ' browser-default' }}
                label={label}
                variant='outlined'
                error={Boolean(error)}
                helperText={error && error.message}
              />
            )}
            onChange={(_, data) => props.onChange(data)}
            ListboxComponent={ListboxComponent}
            disabled={disabled}
            getOptionSelected={(option, value) => option.value === value.value}
          />
        )}
        defaultValue={options[0]}
        name={name}
        control={control}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '200px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`

export { Autocomplete }
