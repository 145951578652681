import React from 'react'

const IconMoney = () => {
    return (
<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 230 230">
<path style={{ fill: 'none', stroke: '#EDBF64', strokeWidth: 4, strokeMiterLimit: 10 }} d="M116,110.6"/>
<path style={{ fill: 'none', stroke: '#EDBF64', strokeWidth: 4, strokeMiterLimit: 10 }} d="M116,174.7"/>
<polygon style={{ fill: '#046F9F' }} points="177.3,176.7 116,176.7 116,172.7 173.3,172.7 173.3,57.6 118,57.6 118,110.6 114,110.6 114,53.6 
	177.3,53.6 "/>
<rect x="103.5" y="55.6" style={{ fill: '#42C7EF' }} width="4" height="39.1"/>
<rect x="103.5" y="99.3" style={{ fill: '#42C7EF' }} width="4" height="6.9"/>
<rect x="64.7" y="77.4" style={{ fill: '#42C7EF' }} width="4" height="6.9"/>
<rect x="64.7" y="89.5" style={{ fill: '#42C7EF' }} width="4" height="16.7"/>
<path style={{ fill: '#046F9F' }} d="M158.2,163.4h-25.7l0.1-2.1c0,0,0-2.4-1.3-3.8c-0.7-0.7-1.7-1-2.9-1h-2V72.9l2.2,0.3l0,0c0,0,1.5,0.1,2.6-0.9
	c1-0.9,1.5-2.4,1.5-4.6v-2h26l-0.1,2.1c0,0-0.1,2.5,1.4,4.1c0.8,0.9,2.1,1.3,3.8,1.3h2v82.7l-1.8,0.2c-5.4,0.7-5.6,4.9-5.6,5.4
	V163.4z M136.3,159.4h18c0.9-4.1,4.4-6.2,7.2-7V77c-1.9-0.3-3.5-1.1-4.7-2.4c-1.5-1.6-2.1-3.5-2.3-4.9h-18.1
	c-0.3,2.4-1.2,4.3-2.7,5.6c-1.1,1-2.4,1.5-3.5,1.7v75.7c1.5,0.3,2.8,1,3.8,2.1C135.5,156.2,136.1,158,136.3,159.4z"/>
<path style={{ fill: '#046F9F' }} d="M145.9,124.9c-5.7,0-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3
	C156.2,120.3,151.6,124.9,145.9,124.9z M145.9,108.2c-3.5,0-6.3,2.8-6.3,6.3c0,3.5,2.8,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3
	C152.2,111.1,149.4,108.2,145.9,108.2z"/>
<path style={{ fill: '#42C7EF' }} d="M86.6,175.8C68,175.8,53,160.7,53,142.2s15.1-33.6,33.6-33.6c18.5,0,33.6,15.1,33.6,33.6
	S105.1,175.8,86.6,175.8z M86.6,112.6c-16.3,0-29.6,13.3-29.6,29.6s13.3,29.6,29.6,29.6s29.6-13.3,29.6-29.6S102.9,112.6,86.6,112.6
	z"/>
<polygon style={{ fill: '#42C7EF' }} points="98.5,67.4 86.6,54.8 74.4,67.4 77.3,70.2 84.6,62.6 84.6,102.8 88.6,102.8 88.6,62.7 95.5,70.1 "/>
<path style={{ fill: '#42C7EF' }} d="M86.6,140.2c-11.4,0-12.6-4.5-12.8-5.6c0-0.1,0-0.2,0-0.3c0.3-1.2,1.9-6.4,12.6-6.4c9.7,0,11.8,4.3,12.2,5.9
	c0.1,0.4,0.4,0.6,0.8,0.6l2.4-0.1c0.5,0,0.9-0.5,0.8-1c-0.2-1-0.8-2.8-2.3-4.5c-2.6-3-6.7-4.6-12.2-4.9v-4.9h-4v4.9
	c-13.5,0.9-14.6,9.8-14.6,10.2c0,0,0,0.1,0,0.2c0,0.3,0,2.9,2.4,5.4c2.9,2.9,7.8,4.4,14.6,4.4c5,0,8.7,1.1,10.6,3.2
	c1.5,1.7,1.2,3.5,1.2,4c0,0,0,0.1,0,0.1c-0.2,0.9-1.6,6.2-11.8,6.2c-9.2,0-12.1-4.9-12.9-6.7c-0.2-0.4-0.5-0.6-0.9-0.5l-2.3,0.6
	c-0.5,0.1-0.7,0.6-0.6,1.1c0.9,2.3,4.2,8.5,14.3,9.4v4.9h4v-4.8c13-0.6,14.4-9.1,14.4-9.6c0-0.2,0.6-4-2.3-7.3
	C97.5,141.7,92.9,140.2,86.6,140.2z"/>
</svg>

    )
}

export { IconMoney }