import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formSubmited } from '../../actions'

class FlashMessageComponent extends Component {
  state = { messageSent: false }

  componentWillReceiveProps(props) {
    if (props.formStatus && props.formStatus.submited) {
      this.setState({ messageSent: true })
      setTimeout(() => this.setState({ messageSent: false }), 3000)
    }
  }

  hideFlashMessage = () => this.setState({ messageSent: false })

  render() {
    if (this.state.messageSent) {
      return (
        <div className='flash-message'>
          <p>{this.props.formStatus.message}</p>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = ({ formStatus }) => ({ formStatus })

export default connect(
  mapStateToProps,
  { formSubmited }
)(FlashMessageComponent)
