import React from 'react'
import styled from 'styled-components'

const RadioButton = ({ field }) => {
  return (
    <>
      {field.label ? (
        <>
          <p>
            <Title>{field.label}</Title>
          </p>
          <p>
            <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
              {field.meta.touched ? field.meta.error : ''}
            </span>
          </p>
        </>
      ) : null}
      <Label>
        <InputRadio
          type='radio'
          name={field.input.name}
          value={field.id}
          onChange={field.input.onChange}
          checked={field.input.value === String(field.id)}
        />
        {field.text}
      </Label>
    </>
  )
}

const Title = styled.label`
  font-size: 15px;
`

const Label = styled.label`
  color: #000;
  font-size: 16px;
`

const InputRadio = styled.input`
  margin: 0px 5px 20px 20px;
`

export { RadioButton }
