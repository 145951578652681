import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { resetPassword } from '../forms'
import handleField from '../forms/handleField'
import ResetTokenSuccess from '../components/ResetTokenSuccess'
import { Button, LoadingScreen, DisplayFormErrors } from '../components/commom'

import { resetPasswordValidator } from '../validators'
import { passwordReset, clearUserForm } from '../actions'
import { resetPassword as resetPasswordService } from '../services/v3'

class PasswordReset extends Component {
  state = {
    loading: false,
    token: null,
    requestSent: false
  }

  componentDidMount() {
    this.setState({ token: this.props.match.params.tokenPassword })
  }

  componentWillMount() {
    window.localStorage.setItem('modal-new-plataform-closed', 'true')
  }

  componentWillUnmount() {
    if (!this.props.currentUser.authenticated) {
      this.props.clearUserForm()
    }
  }

  onSubmit = async values => {
    try {
      this.setState({ loading: true })
      const { token } = this.state
      const body = {
        password_reset_token: token,
        password: values.password
      }
      await resetPasswordService(body)
      this.setState({ requestSent: true })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao solicitar a troca de senha')
    } finally {
      this.setState({ loading: false })
    }
  }

  goToLogin = () => {
    this.props.history.push('/login')
  }

  renderFields = fields =>
    fields.map(field => (
      <Field
        key={field.id}
        label={field.label}
        placeholder={field.placeholder}
        name={field.name}
        type={field.type}
        error={field.error}
        id={field.id}
        maxLength={field.maxLength}
        component={this.renderField}
      />
    ))

  renderField = field => handleField(field)

  render() {
    const { handleSubmit, currentUser } = this.props
    const { loading, requestSent } = this.state

    if (loading) return <LoadingScreen />

    if (requestSent && !currentUser.message) {
      return (
        <ResetTokenSuccess>
          <p>Senha alterada com sucesso</p>
          <Button text='Realizar Login' type='submit' onClick={this.goToLogin} />
        </ResetTokenSuccess>
      )
    }

    return (
      <div className='reset-row'>
        <div className='z-depth-2 login-section'>
          <form className='form-spaccing' onSubmit={handleSubmit(this.onSubmit)}>
            {this.renderFields(resetPassword)}
            <DisplayFormErrors login errors={[currentUser.message]} />
            <Button disabled={loading} text='Trocar senha' type='submit' />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default reduxForm({
  form: 'passwordResetForm',
  validate: resetPasswordValidator
})(connect(mapStateToProps, { passwordReset, clearUserForm })(PasswordReset))
