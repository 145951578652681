export const contactValidator = values => {
  let errors = {}

  if (!values.name) errors.name = 'Nome é obrigatório'
  if (!values.from) errors.from = 'Email é obrigatório'
  if (!values.subject) errors.subject = 'Assunto é obrigatório'

  if (!values.subject_type_id || values.subject_type_id === 'O') {
    errors.subject_type_id = 'Campo obrigatório'
  }
  return errors
}
