import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CampaignNavigationOptions from '../components/CampaignNavigationOptions'
import ListCampaignUpdates from './ListCampaignUpdates'

const CampaignUpdates = ({ match, history }) => {
  return (
    <div className='container-fluid stick-component'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <ListCampaignUpdates match={match} history={history} />
        </div>
      </div>
      <Link
        to={`/admin/campanhas/${match.params.campaignURL}/atualizacoes/criar`}
        id='create-button'
        className='btn-floating btn-large waves-effect waves-light blue-button'
      >
        <i className='material-icons'>add</i>
      </Link>
    </div>
  )
}

export default connect()(CampaignUpdates)
