import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { CloudUpload, Check } from '@material-ui/icons'
import { toast } from 'react-toastify'

import { BorderedButton, LoadingScreen } from '../components/commom'

import { getAreaTypes, createWorkWithUs } from '../services/v3'

const workWithUsSchema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  email: yup
    .string()
    .email()
    .required('O E-mail é obrigatório.'),
  body: yup.string().required('Este campo é obrigatório.'),
  subject: yup.string(),
  area_type_id: yup.array().required('Escolha pelo menos um campo.'),
  curriculum: yup.string().required('o currículo é obrigatório.')
})

class WorkWithUs extends Component {
  state = {
    messageSent: false,
    areaTypes: [],
    loading: true,
    name: '',
    email: '',
    fileUploaded: false,
    loadingWorkTypes: true
  }

  async componentDidMount() {
    const fetchInfos = async () => {
      try {
        const { data: areaTypes } = await getAreaTypes()
        this.setState({ areaTypes: areaTypes, loadingWorkTypes: false, loading: false })
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar as áreas')
      } finally {
        setTimeout(() => window.M.updateTextFields(), 200)
      }
    }
    fetchInfos()
  }

  handleSubmit = async values => {
    this.setState({ loading: true, name: values.name })
    try {
      const formData = new FormData()
      formData.append('file', values.curriculum)
      formData.append('body', values.body)
      formData.append('name', values.name)
      formData.append('email', values.email)
      formData.append('subject', values.subject)
      values.area_type_id.forEach(areaId => formData.append('area_type_ids', areaId))
      await createWorkWithUs(formData)
    } catch (error) {
      console.error(error)
      this.setState({ errors: 'Erro ao enviar mensagem.' })
    } finally {
      setTimeout(() => window.M.updateTextFields(), 200)
    }
    this.setState({ messageSent: true, loading: false })
  }

  render() {
    const { messageSent, areaTypes, loading, name, fileUploaded } = this.state
    if (loading) {
      return <LoadingScreen />
    }
    return (
      <div className='work-with-us-form'>
        {messageSent ? (
          <div className='success-work-with-us-form'>
            <p className='success-work-with-us-message'>Olá {name}, o time da Kavod agradece a sua mensagem! </p>
            <p>
              Recebemos o seu CV e agradecemos o interesse em fazer parte da nossa equipe. Vamos analisar o seu
              curriculo e, se tiver uma oportunidade que esteja alinhada com o seu perfil, alguém da nossa equipe
              entrará em contato.
            </p>
            <p>
              <span>Obrigado!</span> <span>Time Kavod Lending.</span>
            </p>
          </div>
        ) : (
          <div className='z-depth-2 contact-section work-with-us-section'>
            <Formik
              initialValues={{
                name: this.state.name || '',
                email: this.state.email || '',
                subject: '',
                body: '',
                area_type_id: [],
                curriculum: []
              }}
              onSubmit={this.handleSubmit}
              validationSchema={workWithUsSchema}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form encType='multipart/form-data'>
                  <div className='input-field'>
                    <label htmlFor='name'>
                      Nome Completo <span>*</span>
                    </label>
                    <Field type='text' name='name' />
                    <ErrorMessage name='name' component='span' className='helper-text' />
                  </div>

                  <div className='input-field'>
                    <label htmlFor='email'>
                      E-mail <span>*</span>
                    </label>
                    <Field type='email' name='email' />
                    <ErrorMessage name='email' component='span' className='helper-text' />
                  </div>

                  <div className='input-field'>
                    <label htmlFor='subject'>Assunto</label>
                    <Field type='text' name='subject' />
                    <ErrorMessage name='subject' component='span' className='helper-text' />
                  </div>
                  <div className='input-field checkbox-container'>
                    <h2 className='checkbox-row__title'>
                      Aréas de interesse <span>*</span>
                    </h2>
                    <FieldArray
                      name='area_type_id'
                      render={arrayHelpers => (
                        <div className='checkbox-row'>
                          {areaTypes.map((areaType, index) => (
                            <div key={areaType.id} className='checkbox'>
                              <Field
                                type='checkbox'
                                id={areaType.id}
                                name={`area_type_id.${index}`}
                                checked={values.area_type_id.includes(areaType.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(areaType.id)
                                  } else {
                                    const idx = values.area_type_id.indexOf(e.target.id)
                                    arrayHelpers.remove(idx)
                                  }
                                }}
                              />
                              <label className='input-field__label input-field__checkbox' htmlFor={areaType.id}>
                                {areaType.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <ErrorMessage name='area_type_id' component='span' className='helper-text' />
                  </div>

                  <div className='user-uploads-wrapper'>
                    <label className='input-field '>
                      Currículo <span>*</span>
                    </label>
                    <label htmlFor='curriculum'>
                      <div className='dropzone flex-center-column file-input-wrapper' aria-disabled='false'>
                        <div className='flex-center-column'>
                          {fileUploaded ? <Check /> : <CloudUpload />}
                          <span>
                            {fileUploaded
                              ? 'Seu arquivo está pronto pra ser enviado.'
                              : 'Arraste ou clique para fazer upload do seu documento.'}
                          </span>
                        </div>
                        <input
                          id='curriculum'
                          type='file'
                          autoComplete='off'
                          accept='application/pdf,.doc,.docx'
                          className='file-input'
                          name='curriculum'
                          onChange={event => {
                            setFieldValue('curriculum', event.currentTarget.files[0])
                            this.setState({ fileUploaded: true })
                          }}
                        />
                      </div>
                      <ErrorMessage name='curriculum' component='span' className='helper-text' />
                    </label>
                    <span className='helper-text' dataerror='Campo obrigatório' datasuccess='' />
                  </div>

                  <div className='input-field'>
                    <label htmlFor='body'>
                      Por que você quer trabalhar na Kavod? <span>*</span>
                    </label>
                    <Field type='text' name='body' id='body' />
                    <ErrorMessage name='body' component='span' className='helper-text' />
                  </div>
                  <BorderedButton center disabled={loading} label='Enviar' type='submit' />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    )
  }
}

export default connect()(WorkWithUs)
