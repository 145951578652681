import React from 'react'

const Testimonials = () => (
  <section>
    <div className='section-description'>
      <div className='text-wrapper'>
        <h3>Quem já usou a Kavod?</h3>
        <p>
          Investidores ou Empresários. Veja a opinião de quem já teve o seu patrimônio ampliado ou o seu negócio
          financiado ao investir ou solicitar empréstimos através da nossa plataforma.
        </p>
      </div>
    </div>
    <div className='profiles-container'>
      <div className='profile-wrapper'>
        <img
          className='profile-image'
          src='/assets/testimonials/Camila_Giulliano.jpg'
          alt='imagem investidora camila'
        />
        <div className='profile-content'>
          <p className='profile-body'>
            Invisto com a Kavod porque é uma ótima forma de diversificar o portfólio com baixo risco, além de saber que
            estou fazendo a diferença na vida dos empreendedores que estão acessando um crédito mais barato e viável
            para o seu negócio. É uma relação ganha-ganha!
          </p>
          <strong>Camila Betterelli Giuliano</strong>
        </div>
      </div>
      <div className='profile-wrapper'>
        <img
          className='profile-image'
          src='/assets/testimonials/Leonardo_Direzenchi.jpg'
          alt='imagem investidora camila'
        />
        <div className='profile-content'>
          <p className='profile-body'>
            Como investidor, fiquei impressionado com as taxas quase irresistíveis. Vejo na Kavod a oportunidade de
            ganhar mais dinheiro investindo e ao mesmo tempo transformar o futuro em algo melhor, pois sei que estou
            ajudando empreendedores com uma taxa muito melhor que os bancos tradicionais.
          </p>
          <strong>Leonardo Direzenchi</strong>
        </div>
      </div>
      <div className='profile-wrapper'>
        <img className='profile-image' src='/assets/testimonials/Julia_Chagas.jpg' alt='imagem investidora camila' />
        <div className='profile-content'>
          <p className='profile-body'>
            A rapidez, facilidade e alta rentabilidade de investir com a Kavod foram o que mais me chamaram a atenção. E
            somado a isso, saber que é possível contribuir para que empreendedores possam desenvolver seus negócios, é
            ainda melhor! Tenho certeza que meu investimento está em boas mãos!
          </p>
          <strong>Júlia R. F. Chagas</strong>
        </div>
      </div>
    </div>
  </section>
)

export { Testimonials }
