import React from 'react'

const IconHand = () => {
  return (
    <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 230 230' >
      <path style={{ fill: '#42C7EF' }} d='M182,133l-10.6-11.2l-9.5,9l-8.5-9.5l-9.4,9.4l-9.5-9L124,133V48.2l11,12.2l8.4-9.9l9.6,9.3l8.9-9.3l9.6,10.1
	L182,47.9V133z M134.4,116.1l9.5,9l9.6-9.6l8.5,9.5l9.5-9l6.4,6.8V59l-6.4,7.7l-9.8-10.3l-8.8,9.1l-9.4-9.1L135,66.5l-7-7.8v64.3
	L134.4,116.1z' />
      <rect x='132.7' y='70.1' style={{ fill: '#42C7EF' }} width='13' height='4' />
      <rect x='150.4' y='70.1' style={{ fill: '#42C7EF' }} width='4.3' height='4' />
      <rect x='132.7' y='78.8' style={{ fill: '#42C7EF' }} width='8.9' height='4' />
      <rect x='132.7' y='87.6' style={{ fill: '#42C7EF' }} width='17.7' height='4' />
      <rect x='74.1' y='110.7' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -66.9561 90.7731)' style={{ fill: '#046F9F' }} width='4' height='31.1' />
      <rect x='127.6' y='152.6' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -70.8441 138.1859)' style={{ fill: '#046F9F' }} width='7.6' height='4' />
      <rect x='92.2' y='140.8' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -74.4554 108.8814)' style={{ fill: '#046F9F' }} width='4' height='7' />
      <path style={{ fill: '#046F9F' }} d='M156.1,133.4l-3.3-3.3l-2.6,3l3.1,3.1c2.1,2.1,2.1,5.5,0,7.6h0l-31.8,31.8c-2.1,2.1-5.5,2.1-7.6,0l-7.8-7.8
	l33.1-33.1l-2.8-2.8L103.3,165l-47.5-47.5c-1-1-1.6-2.4-1.6-3.8s0.6-2.8,1.6-3.8l31.8-31.8c1-1,2.4-1.6,3.8-1.6s2.8,0.6,3.8,1.6
	l22.2,22.2l2.7-3L98,75.3c-1.8-1.8-4.1-2.7-6.6-2.7s-4.9,1-6.6,2.7l-31.8,31.8c-3.6,3.6-3.6,9.6,0,13.2l58.1,58.1
	c1.8,1.8,4.2,2.7,6.6,2.7c2.4,0,4.8-0.9,6.6-2.7l31.8-31.8h0C159.7,143,159.7,137,156.1,133.4z' />
      <path style={{ fill: '#42C7EF' }} d='M73.5,56.3h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C74.2,56,73.9,56.3,73.5,56.3z' />
      <path style={{ fill: '#42C7EF' }} d='M55.3,56.3h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C56.1,56,55.7,56.3,55.3,56.3z' />
      <path style={{ fill: '#42C7EF' }} d='M73.5,74.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C74.2,73.8,73.9,74.1,73.5,74.1z' />
      <path style={{ fill: '#42C7EF' }} d='M78.1,65.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C78.8,64.9,78.5,65.2,78.1,65.2z' />
      <path style={{ fill: '#42C7EF' }} d='M60,139.4h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8H60c0.4,0,0.8,0.3,0.8,0.8v2.8
	C60.7,139,60.4,139.4,60,139.4z' />
      <path style={{ fill: '#42C7EF' }} d='M55.3,147.8h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C56.1,147.5,55.7,147.8,55.3,147.8z' />
      <path style={{ fill: '#42C7EF' }} d='M53.2,157.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C53.9,156.7,53.6,157.1,53.2,157.1z' />
      <path style={{ fill: '#42C7EF' }} d='M55.3,166.4h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C56.1,166,55.7,166.4,55.3,166.4z' />
      <path style={{ fill: '#42C7EF' }} d='M60,175.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8H60c0.4,0,0.8,0.3,0.8,0.8v2.8
	C60.7,174.8,60.4,175.2,60,175.2z' />
      <path style={{ fill: '#42C7EF' }} d='M68.6,179.8h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C69.4,179.4,69.1,179.8,68.6,179.8z' />
      <path style={{ fill: '#42C7EF' }} d='M77.7,181.9H75c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C78.5,181.6,78.2,181.9,77.7,181.9z' />
      <path style={{ fill: '#42C7EF' }} d='M86.9,179.8h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C87.7,179.4,87.3,179.8,86.9,179.8z' />
      <path style={{ fill: '#42C7EF' }} d='M95.6,175.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C96.3,174.8,96,175.2,95.6,175.2z' />
      <path style={{ fill: '#42C7EF' }} d='M156.5,161.6h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C157.3,161.3,157,161.6,156.5,161.6z' />
      <path style={{ fill: '#42C7EF' }} d='M170.2,154.9h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C170.9,154.6,170.6,154.9,170.2,154.9z' />
      <path style={{ fill: '#42C7EF' }} d='M177.2,141.5h-2.8c-0.4,0-0.8-0.3-0.8-0.8V138c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C178,141.2,177.6,141.5,177.2,141.5z' />
      <path style={{ fill: '#42C7EF' }} d='M55.3,74.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C56.1,73.8,55.7,74.1,55.3,74.1z' />
      <path style={{ fill: '#42C7EF' }} d='M51,65.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8H51c0.4,0,0.8,0.3,0.8,0.8v2.8
	C51.8,64.9,51.4,65.2,51,65.2z' />
      <path style={{ fill: '#42C7EF' }} d='M64.3,52h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C65.1,51.7,64.7,52,64.3,52z' />
      <path style={{ fill: '#42C7EF' }} d='M64.3,79.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C65.1,78.8,64.7,79.1,64.3,79.1z' />
      <g>
        <polygon style={{ fill: '#42C7EF' }} points='168.9,86.8 170.6,86.6 170.6,86.6 170.6,86.6 	' />
        <path style={{ fill: '#42C7EF' }} d='M170.9,86.6l-0.3,0c0,0,0,0,0,0L170.9,86.6z' />
        <path style={{ fill: '#42C7EF' }} d='M170.7,94.7c-1.6-1.7-3.8-2.6-6.6-2.6c-1.6,0-2.7-0.4-3.5-1.2c-1-1.1-1.2-2.7-1.3-3.6c0-0.3,0-0.5,0.1-0.8
		c0.4-1.2,1.8-3.7,5.1-3.7c2.4,0,3.5,1.4,4,2.6c0.3,0.8,1.2,1.3,2.1,1.2c1.3-0.2,2.1-1.5,1.6-2.7c-0.9-2.1-2.7-4.3-6-4.9v-2.8
		c0-0.7-0.6-1.4-1.4-1.4H164c-0.7,0-1.4,0.6-1.4,1.4V79c-4.6,0.9-6.8,4.8-7.3,7.1c0,0.1,0,0.2-0.1,0.3c0,0.4-0.3,4.3,2.3,7.1
		c1.6,1.7,3.7,2.5,6.5,2.5c1.6,0,2.8,0.4,3.6,1.3c1.1,1.2,1.2,2.9,1.2,3.6c0,0.2,0,0.3,0,0.4c-0.2,1.1-1.3,4.5-4.8,4.5
		c-2.6,0-3.8-1.5-4.4-2.7c-0.4-0.8-1.2-1.2-2.1-1.1c-1.3,0.2-2.1,1.6-1.5,2.8c1,2.1,3.1,4.4,6.5,4.9v2.5c0,0.7,0.6,1.4,1.4,1.4h0.8
		c0.7,0,1.4-0.6,1.4-1.4v-2.6c4.5-1.1,6.4-5.5,6.7-8c0,0,0-0.1,0-0.1C172.9,101.1,173.2,97.4,170.7,94.7z' />
      </g>
    </svg>

  )
}

export { IconHand }
