import React, { Component } from 'react'

class AutoCompleteRedux extends Component {
  state = { fieldWithOptions: false }

  componentWillReceiveProps({ field }) {
    const { fieldWithOptions } = this.state
    field.input.value = field.input.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    if (!fieldWithOptions && field.options) {
      const elems = document.getElementById(field.id)
      const options = {
        data: field.options,
        minLength: 1,
        limit: 10,
        onAutocomplete: this.onAutocomplete
      }
      window.M.Autocomplete.init(elems, options)
      this.setState({ fieldWithOptions: true })
    }
  }

  onAutocomplete = val => this.props.field.input.onChange(val)

  render() {
    const { field } = this.props
    return (
      <div className={`select-group ${field.className ? field.className : ''}`}>
        <div className='input-field'>
          <input
            type='text'
            id={field.id}
            autoComplete='new-value'
            className={field.readOnly ? 'read-only-text' : 'autocomplete'}
            readOnly={field.readOnly ? field.readOnly : false}
            {...field.input}
          />
          <label className='active' htmlFor={field.id}>
            {field.label}
          </label>
          <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
            {field.meta.touched ? field.meta.error : ''}
          </span>
        </div>
      </div>
    )
  }
}

export { AutoCompleteRedux }
