import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { FormLabel, FormHelperText } from '@material-ui/core'

import { StyledRadio, StyledFormControl, StyledFormControlLabel, Label, StyledRadioGroup } from './styles'

const Radio = ({
  defaultValue,
  control,
  label,
  color,
  name,
  options,
  error,
  column,
  variant,
  helperText,
  viewonly,
  ...props
}) => {
  return (
    <StyledFormControl variant={variant || 'outlined'} error={Boolean(error)} {...props}>
      <FormLabel component='legend'>
        <Label>{label}</Label>
      </FormLabel>
      {(helperText || error) && <FormHelperText>{helperText || error.message}</FormHelperText>}
      <Controller
        as={
          <StyledRadioGroup aria-label={name} name={name} column={column}>
            {options.map(({ value, label, disabled, checked, viewonly }) => (
              <StyledFormControlLabel
                key={value}
                value={value}
                control={
                  <StyledRadio color={color} disabled={viewonly || disabled} viewonly={viewonly} checked={checked} />
                }
                label={label}
              />
            ))}
          </StyledRadioGroup>
        }
        defaultValue={defaultValue}
        error={error}
        name={name}
        control={control}
        {...props}
      />

    </StyledFormControl>
  )
}

Radio.defaultProps = {
  color: 'primary',
  defaultValue: ''
}

Radio.propTypes = {
  error: PropTypes.object,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  viewOnly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
}

export default Radio
