import React from 'react'
import SimpleCurrencyInput from 'react-simple-currency'

const CurrencyInput = ({ className, onInputChange, value, field, label }) => (
  <div className={`input-field ${className}`}>
    <SimpleCurrencyInput
      onInputChange={onInputChange}
      value={value}
      id={field}
      name={field}
      className='validate'
      precision={2}
      separator=','
      delimiter='.'
      unit='R$'
      placeholder='Placeholder'
    />
    <label className='active' htmlFor={field}>
      {label}
    </label>
  </div>
)

export { CurrencyInput }
