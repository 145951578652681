import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { AttachFile, Check } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'

import { LoadingScreen } from '../components/commom'
import CampaignNavigationOptions from '../components/CampaignNavigationOptions'

import { getCampaignById, updateCampaign } from '../services/v3'

const CampaignPage = ({ match }) => {
  const [campaign, setCampaign] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [file, setFile] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileName, setFileName] = useState(null)

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const { data: campaign } = await getCampaignById(match.params.campaignURL)
        setCampaign(campaign)
        if (campaign.rating_url) setFileName(campaign.rating_url.split('/').pop())
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar a campanha')
      } finally {
        setIsLoading(false)
      }
    }
    fetchCampaign()
  }, [match.params.campaignURL])

  const handleFile = ({
    target: {
      files: [file]
    }
  }) => {
    setFile(file)
    setFileName(file.name)
    setIsDisabled(false)
  }

  const submitFile = async () => {
    try {
      setSubmitting(true)
      const formData = new FormData()
      formData.append('rating', file)
      await updateCampaign(match.params.campaignURL, formData)
      toast.success('Rating Serasa atualizado com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar fazer o upload do arquivo.')
    } finally {
      setSubmitting(false)
    }
  }

  if (isLoading) return <LoadingScreen />

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <Container>
            <RatingUploadContainer>
              <UploadContainer>
                <FileLabel htmlFor='file'>
                  {file ? (
                    <Fragment>
                      <Check /> <p>Pronto pra enviar</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <AttachFile /> <p>Selecione o arquivo.</p>
                    </Fragment>
                  )}
                </FileLabel>
                <FileInput accept='application/pdf' onChange={handleFile} id='file' type='file' />
                {fileName && (
                  <FileName>
                    nome do arquivo atual:{' '}
                    <ShowFile disabled={!file} href={campaign.rating_url} target='_blank'>
                      {fileName}
                    </ShowFile>
                  </FileName>
                )}
                {file && (
                  <div>
                    {!isDisabled && (
                      <SendFile disabled={submitting} onClick={submitFile}>
                        {submitting ? <CircularProgress style={{ color: '#fff' }} size={15} /> : 'confirmar envio'}
                      </SendFile>
                    )}
                  </div>
                )}
              </UploadContainer>
            </RatingUploadContainer>
          </Container>
        </div>
      </div>
    </div>
  )
}

const ShowFile = styled.a`
  pointer-events: ${({ disabled }) => (disabled ? 'auto' : 'none')};
  color: ${({ disabled }) => (disabled ? '#039be5' : 'gray')};
`

const FileName = styled.p`
  color: darkgray;
  font-size: 0.9em;
`

const SendFile = styled.button`
  padding: 0.3em 1.1em;
  margin: 0.3em 0;
  width: 200px;
  background: ${({ disabled }) => (disabled ? 'darkgray' : 'darkgreen')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
`

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FileLabel = styled.label`
  display: flex;
  width: 255px;
  cursor: pointer;
  align-items: center;
  padding: 0.7em 1.3em;
  border: 1px solid #076f9f;
  border-radius: 4px;
  color: #076f9f;

  p {
    margin: 0 1em;
  }
`

const FileInput = styled.input`
  display: none;
  color: white;
`

const RatingUploadContainer = styled.div`
  padding: 1em 0;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 0 3em;
  @media (max-width: 769px) {
    align-items: center;
  }
`

export default CampaignPage
