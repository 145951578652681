import styled from 'styled-components'

export const IndicationContainer = styled.div`
  padding-bottom: 30px;
  margin-left: 32px;
  margin-right: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'Header Header'
    'Details Timeline'
    'Accordion Timeline'
    '. Timeline';

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
  }
`
export const RowHeaderIndicator = styled.div`
  display: flex;
  max-width: 545px;
  margin-top: 24px;
  flex-wrap: wrap;
  align-items: center;

  h3 {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const StyledMain = styled.main`
  padding: 20px;
  width: 100%;
  max-width: 1200px;

  @media only screen and (max-width: 730px) {
    padding: 16px;
  }
`
