import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import { Button } from '../components/commom'
import { LoadingScreen } from '../components/commom'

import { formatCurrency } from '../helpers'
import { getCurrentUser, getPledges, createPledge } from '../services/v3'
import { pledgeStatuses } from '../constants'

const CheckoutPage = ({ currentInvestment, history, match }) => {
  const [loading, setLoading] = useState(true)
  const [checkingOut, setCheckingOut] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [pledges, setPledges] = useState([])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setLoading(true)
        const { data: user } = await getCurrentUser()
        if (!user.investor) {
          throw new Error('Usuário não tem perfil de investidor')
        }

        const { data: pledges } = await getPledges({
          campaign_id: currentInvestment.campaign.id,
          investor_id: user.investor.id
        })

        const activePledgeStatusOptions = [pledgeStatuses.ACCEPTED, pledgeStatuses.ACTIVE, pledgeStatuses.ON_HOLD]
        const userValidPledges = pledges.filter(({ pledge_status: { abbreviation } }) =>
          activePledgeStatusOptions.includes(abbreviation)
        )

        setCurrentUser(user)
        setPledges(userValidPledges)
      } catch (error) {
        toast.error(error.message)
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (!currentInvestment || !currentInvestment.amount) {
      toast.error('Ocorreu um erro ao encontrar sua intenção de investimento, por favor tente novamente')
      history.push('/campanhas')
      return
    }
    fetchInfos()
  }, [currentInvestment, history])

  const confirmPledge = async e => {
    e.preventDefault()
    try {
      setCheckingOut(true)
      const body = {
        campaign_id: currentInvestment.campaign.id,
        investor_id: currentUser.investor.id,
        amount: currentInvestment.amount,
        send_email: true
      }

      await createPledge(body)

      const slugs = match.url.split('/')
      history.push(`/campanhas/${slugs[2]}/${slugs[3]}/${slugs[4]}/operacao-concluida`)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao processar o pedido, por favor tente novamente')
    }
  }

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <form onSubmit={e => confirmPledge(e)}>
      <div className='container' style={{ maxWidth: '1024px', marginTop: '80px' }}>
        <h4>Seu investimento</h4>
        <table>
          <thead>
            <tr>
              <th>Campanha - {currentInvestment ? currentInvestment.campaign.name : ''}</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {pledges.map(pledge => (
              <tr key={pledge.id}>
                <td>
                  <span style={{ color: '#bfbfbf' }}>
                    Pedido #{pledge.id} -
                    {moment.utc(pledge.created_at).format(' [Realizado em] DD MMM [de] YYYY [às] HH:mm')}
                  </span>
                </td>
                <td>
                  <span style={{ color: '#bfbfbf' }}>{formatCurrency(pledge.amount)}</span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Novo Pedido</td>
              <td>
                <span style={{ fontWeight: 700 }}>
                  <span>{formatCurrency(currentInvestment.amount)}</span>
                </span>
              </td>
            </tr>
            <tr>
              <th>Valor total do investimento</th>
              <th>
                <span style={{ fontWeight: 700 }}>
                  <span>
                    {formatCurrency(pledges.reduce((acc, { amount }) => acc + amount, currentInvestment.amount))}
                  </span>
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
        <div>
          <ul>
            <li>
              <div>
                <p>
                  Ao término da captação, você receberá instruções para enviar a TED/DOC para nosso(a) banco/financeira
                  parceiro(a).
                </p>
              </div>
            </li>
          </ul>
        </div>
        <Button disabled={checkingOut} text='Confirmar Investimento' type='submit' align='right-align' />
      </div>
    </form>
  )
}

const mapStateToProps = ({ currentInvestment }) => ({
  currentInvestment
})

export default connect(mapStateToProps, { createPledge })(CheckoutPage)
