import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

import AnticipationRow from './AnticipationRow'

const AnticipationTableModal = ({ installments, isOpen, onClose, handleInstallmentClick }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      className='Modal modal-container-anticipation'
      overlayClassName='Overlay'
    >
      <IconContainer>
        <Title>Detalhes da Liquidação Antecipada</Title>
        <CloseIcon onClick={onClose} />
      </IconContainer>
      <TableContainer>
        <table className='accordion-investments'>
          <thead>
            <tr className='accordion-table-row'>
              <th className='accordion-table-cell --header'>#</th>
              <th className='accordion-table-cell --header'>Valor líquido original</th>
              <th className='accordion-table-cell --header'>Valor liquidado da TED</th>
              <th className='accordion-table-cell --header'>Previsão de pagamento original</th>
              <th className='accordion-table-cell --header'>Pago em</th>
            </tr>
          </thead>
          <tbody>
            {installments.map(portion => (
              <AnticipationRow key={portion.id} portion={portion} handleInstallmentClick={handleInstallmentClick} />
            ))}
          </tbody>
        </table>
      </TableContainer>
      <FooterContainer>
        <FooterText onClick={onClose}>Fechar</FooterText>
      </FooterContainer>
    </Modal>
  )
}

const TableContainer = styled.div`
  flex: 1;
  margin-bottom: 30px;
`

const IconContainer = styled.div`
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FooterContainer = styled.div`
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterText = styled.p`
  font-weight: bold;
  font-size: 1.2em;
`

const Title = styled.p`
  margin-top: 0px;
  font-size: 16px;
`

export default AnticipationTableModal
