import React from 'react'
import { connect } from 'react-redux'

import CampaignDraftShow from '../containers/CampaignDraftShow'
import CampaignNavigationOptions from './CampaignNavigationOptions'
import { LoadingScreen } from '../components/commom'

const CampaignDraftPage = ({ currentUser, match }) => {
  if (!currentUser) return <LoadingScreen />

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <CampaignDraftShow match={match} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default connect(mapStateToProps)(CampaignDraftPage)
