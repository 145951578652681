import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

const NotFound = ({ history }) => {
  return (
    <Container>
      <TextContainer>
        <Texts>
          <Title>Oops!</Title>
          <Body>Não conseguimos encontrar a página que você está procurando. Alguns links que podem ser úteis:</Body>
          <LinksContainer>
            <Link className='footer-title' onClick={() => history.push('/investidor')}>
              Quero Investir
            </Link>
            <Link className='footer-title' onClick={() => history.push('/empresa')}>
              Quero Empréstimo
            </Link>
            <Link className='footer-title' onClick={() => history.push('/envie-seu-curriculo')}>
              Trabalhe conosco
            </Link>
            <Link className='footer-title' onClick={() => history.push('/contato')}>
              Contato
            </Link>
            <Link className='footer-title' onClick={() => history.push('/campanhas')}>
              Campanhas
            </Link>
          </LinksContainer>
        </Texts>
      </TextContainer>
      <ImageContainer>
        <Image src='/assets/img/notfound/404.svg' />
      </ImageContainer>
    </Container>
  )
}

const Texts = styled.div`
  width: 510px;
  @media (max-width: 769px) {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

const Image = styled.img`
  height: 350px;

  @media (max-width: 769px) {
    width: 100%;
    height: 250px;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 769px) {
    margin-top: 30px;
    flex-direction: column;
  }
`

const Link = styled.p`
  padding: 0 0.4em;
  font-size: 0.9em;
  &:hover {
    color: black;
    transition: all 0.3s;
  }
`

const LinksContainer = styled.div`
  p:first-of-type {
    padding-left: 0;
  }
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 769px) {
    justify-content: space-evenly;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 3em;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 769px) {
    padding: 0 1em;
    align-items: center;
    text-align: center;
  }
`
const Title = styled.p`
  margin: 0;
  font-size: 1.3em;
  font-weight: bolder;
  color: rgb(7, 111, 159);
  @media (max-width: 769px) {
    font-size: 2em;
  }
`
const Body = styled.p`
  font-size: 1em;
  color: rgb(7, 111, 159);
  font-weight: bold;

  @media (max-width: 769px) {
    font-size: 1em;
  }
`
export default withRouter(NotFound)
