import client from '../providers/fetchClient'
import {
  CAMPAIGN_GALLERY_FETCH,
  CAMPAIGN_GALLERY_CREATE,
  CAMPAIGN_GALLERY_DELETE,
  CAMPAING_GALLERY_UPDATE
} from './types'

export const fetchCampaignGallery = id => dispatch => {
  client
    .get(`/campaign-photos/campaign_id/${id}?stt_id=A&rol_id=GL&exactmatch=true`)
    .then(res => {
      dispatch({ type: CAMPAIGN_GALLERY_FETCH, payload: res })
    })
    .catch(res => {
      dispatch({ type: CAMPAIGN_GALLERY_FETCH, payload: res })
    })
}

export const createCampaignGalleryPhoto = (data, callback) => {
  const request = client.put(`/campaign-photos/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return { type: CAMPAIGN_GALLERY_CREATE, payload: request }
}

export const deleteCampaignGallery = (data, callback) => {
  const request = client.put(`/campaign-photos/update`, data).then(() => callback())

  return { type: CAMPAIGN_GALLERY_DELETE, payload: request }
}

export const updateCampaignGallery = (data, callback) => {
  const request = client.put(`/campaign-photos/update`, data).then(() => callback())

  return { type: CAMPAING_GALLERY_UPDATE, payload: request }
}
