import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Title, LoadingScreen } from '../components/commom'

import { getCampaigns } from '../services/v3'

const AdminCampaigns = ({ history }) => {
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    const findCampaigns = async () => {
      try {
        const { data: campaigns } = await getCampaigns()
        if (campaigns) setCampaigns(campaigns)
      } catch (error) {
        toast.error(error.message)
      }
    }
    findCampaigns()
  }, [])

  if (!campaigns) return <LoadingScreen />

  return (
    <div className='stick-component'>
      <div className='container'>
        <Title text='Campanhas' />
        <table className='highlight'>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map(campaign => (
              <tr key={campaign.id} onClick={e => history.push(`/admin/campanhas/${campaign.id}/informacoes`)}>
                <td>{campaign.id}</td>
                <td>{campaign.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Link
        to='/admin/campanhas/criar'
        id='create-button'
        className='btn-floating btn-large waves-effect waves-light blue-button'
      >
        <i className='material-icons'>add</i>
      </Link>
    </div>
  )
}

const mapStateToProps = ({ campaigns }) => ({ campaigns })

export default connect(mapStateToProps)(AdminCampaigns)
