import { CAMPAIGN_PROFILE_IMG_FETCH, CAMPAIGN_PROFILE_IMG_UPDATE, LOADING_CAMPAIGN_PROFILE_IMG } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_PROFILE_IMG_FETCH:
      return { ...action.payload }

    case LOADING_CAMPAIGN_PROFILE_IMG:
      return action.payload

    case CAMPAIGN_PROFILE_IMG_UPDATE:
      return action.payload

    default:
      return state
  }
}
