import React, { Component } from 'react'
import { reduxForm, Field, reset, change } from 'redux-form'
import { connect } from 'react-redux'
import { DisplayFormErrors, LoadingScreen, BorderedButton } from '../components/commom'
import handleField from '../forms/handleField'
import { contactValidator } from '../validators'
import { contactForm } from '../forms'
import { fetchPerson } from '../actions'

import { createContact } from '../services/v3'

const contactTypes = [
  { id: 'C', name: 'Comercial' },
  { id: 'DE', name: 'Dúvidas Empresas' },
  { id: 'DI', name: 'Dúvidas Investidor' },
  { id: 'MK', name: 'Marketing' },
  { id: 'OD', name: 'Outras Dúvidas' },
  { id: 'PC', name: 'Problemas de Cadastro/Login' },
  { id: 'S', name: 'Sugestões' }
]

class Contact extends Component {
  state = {
    loading: true,
    formFields: null,
    person: null,
    errors: null,
    messageSend: false,
    kvdId: null,
    fileUploaded: false
  }

  async componentDidMount() {
    try {
      const options = contactTypes.map(option => {
        option.text = option.name
        delete option.description
        delete option.name
        return option
      })
      const formWithOptions = contactForm.map(field => {
        if (field.name === 'subject_type_id') {
          return {
            ...field,
            options: [...field.options, ...options]
          }
        }
        return field
      })
      this.setState({ formFields: formWithOptions, loading: false, options })
    } catch (e) {
      this.setState({
        erro: 'Não foi possivel carregar as categorias para contato.',
        loading: false
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { dispatch, currentUser, form } = this.props
    if (currentUser.authenticated && !this.state.kvdId) {
      this.props.fetchPerson(currentUser.kvd_id)
      this.setState({ kvdId: true })
    }
    if (this.props.person.data && this.props.person.data.first_name && this.props.person.data !== this.state.person) {
      dispatch(change(form, 'name', `${this.props.person.data.first_name} ${this.props.person.data.last_name}`))
      dispatch(change(form, 'from', this.props.currentUser.email))
      this.setState({ person: this.props.person.data })
    }
  }

  onSubmit = async values => {
    const { currentUser, dispatch, form } = this.props
    const { options } = this.state

    this.setState({ loading: true })

    const filteredOptions = options.filter(option => option.id === values.subject_type_id)

    const body = {
      name: values.name,
      email: values.from,
      type: filteredOptions[0].text,
      subject: values.subject,
      message: values.body
    }

    if (currentUser.kvd_id) {
      body.kvd_id = currentUser.kvd_id
    }

    try {
      await createContact(body)
      dispatch(reset(form))
      dispatch(change(form, 'subject_type_id', 'O'))
      this.setState({ messageSent: true, loading: false })
      setTimeout(() => window.M.updateTextFields(), 200)
    } catch (e) {
      this.setState({ error: 'Erro ao enviar mensagem.' })
      setTimeout(() => window.M.updateTextFields(), 200)
    }
  }

  renderFields = fields =>
    fields.map(field => {
      return (
        <Field
          key={field.id}
          label={field.label}
          placeholder={field.placeholder}
          name={field.name}
          id={field.id}
          type={field.type}
          error={field.error}
          readOnly={field.readOnly}
          className={field.className}
          options={field.options}
          component={this.renderField}
          maxLength={field.maxLength}
        />
      )
    })

  renderField = field => handleField(field)

  render() {
    const { handleSubmit, currentUser, person } = this.props
    const { loading, formFields, errors, messageSent, fileUploaded } = this.state

    if (loading || currentUser.loading || person.loading) return <LoadingScreen />

    return (
      <div className='contact-form' style={{ flexDirection: 'column' }}>
        <div className='z-depth-2 contact-section'>
          <form className='form-spaccing' onSubmit={handleSubmit(this.onSubmit)}>
            {this.renderFields(formFields)}
            <DisplayFormErrors signUp errors={[errors]} />
            <BorderedButton center disabled={this.state.loading} label='Enviar' type='submit' />
          </form>
        </div>
        {messageSent && (
          <div className='success-contact-form'>
            <p className='success-contact-message'>
              {fileUploaded
                ? 'O seu arquivo foi anexado.'
                : 'Agradecemos a sua mensagem. Em breve você receberá uma resposta.'}
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser, person }) => ({ currentUser, person })

export default reduxForm({
  form: 'ContactForm',
  validate: contactValidator
})(
  connect(mapStateToProps, {
    fetchPerson
  })(Contact)
)
