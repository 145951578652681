import client from '../providers/fetchClient'
import {
  LOADING_CAMPAIGNS,
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  LOADING_CAMPAIGN
} from './types'
import { getCampaignById } from '../services/v3'

export const fetchCampaigns = param => dispatch => {
  dispatch({ type: LOADING_CAMPAIGNS, payload: null })
  let url = ''
  if (typeof param === 'undefined') {
    url = '/esh-campaign/'
  } else {
    url = `/esh-campaign/${param.name}/${param.value}`
  }

  client
    .get(url)
    .then(res => {
      res.data.forEach(campaign => {
        client.get(`/campaign-photos/campaign_id/${campaign.id}?stt_id=A&rol_id=B&exactmatch=true`).then(photo => {
          campaign.brandImg = photo.data[0] ? photo.data[0].photoURL : null
          dispatch({ type: FETCH_CAMPAIGNS, payload: res.data })
        })
      })
    })
    .catch(() => dispatch({ type: FETCH_CAMPAIGNS, payload: null }))
}

export const fetchCampaign = campaignID => dispatch => {
  dispatch({ type: LOADING_CAMPAIGN, payload: null })
  getCampaignById(campaignID)
    .then(campaign => {
      client
        .get(`/campaign-photos/campaign_id/${campaign.data.id}?stt_id=A&rol_id=B&exactmatch=true`)
        .then(photo => {
          dispatch({
            type: FETCH_CAMPAIGN,
            payload: {
              campaign: campaign.data[0],
              photo: photo.data[0] ? photo.data[0] : null
            }
          })
        })
        .catch(err => console.error(err))
    })
    .catch(err => {
      console.error(err)
      return dispatch({ type: FETCH_CAMPAIGN, payload: null })
    })
}

export const fetchCampaignForURL = campaignURL => dispatch => {
  dispatch({ type: LOADING_CAMPAIGN, payload: null })
  let token = window.localStorage.getItem('kavodJWT')

  client
    .get(`/esh-campaign/url/${campaignURL}`, {
      headers: { Authorization: token }
    })
    .then(campaign => {
      client
        .get(`/campaign-photos/campaign_id/${campaign.data[0].id}?stt_id=A&rol_id=B&exactmatch=true`)
        .then(photo => {
          dispatch({
            type: FETCH_CAMPAIGN,
            payload: {
              campaign: campaign.data[0],
              photo: photo.data[0] ? photo.data[0] : null
            }
          })
        })
        .catch(err => console.log(err))
    })
    .catch(() => dispatch({ type: FETCH_CAMPAIGN, payload: null }))
}

export const createCampaign = (data, callback) => dispatch => {
  dispatch({ type: LOADING_CAMPAIGN, payload: null })
  let token = window.localStorage.getItem('kavodJWT')
  client
    .post(`/v2/campaigns/`, data, {
      headers: { Authorization: token }
    })
    .then(res => {
      callback(res)
      dispatch({ type: CREATE_CAMPAIGN, payload: res.data })
    })
    .catch(err => {
      callback(err)
      dispatch({ type: LOADING_CAMPAIGN, payload: null })
    })
}

export const updateCampaign = (data, callback) => dispatch => {
  dispatch({ type: LOADING_CAMPAIGN, payload: null })
  const token = window.localStorage.getItem('kavodJWT')
  delete data.image_url
  client
    .put(`/v2/campaigns/${data.id}`, data, { headers: { Authorization: token } })
    .then(res => {
      callback(res)
      dispatch({ type: UPDATE_CAMPAIGN, payload: res.data })
    })
    .catch(err => {
      callback(err)
      dispatch({ type: LOADING_CAMPAIGN, payload: null })
    })
}
