import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

import { ModalBaseSmall } from './ModalBaseSmall'

const ModalDeleteCampaignUpdate = ({ open, onClose, onConfirm }) => {
  return (
    <ModalBaseSmall open={open} onClose={onClose}>
      <Title>Deseja realmente excluir a atualização?</Title>
      <ButtonsContainer>
        <Button
          variant='contained'
          onClick={onConfirm}
          style={{ margin: '0 16px', backgroundColor: '#076f9f', color: '#fff' }}
        >
          Sim
        </Button>
        <Button
          variant='contained'
          onClick={onClose}
          style={{ margin: '0 16px', backgroundColor: '#d33131', color: '#fff' }}
        >
          Não
        </Button>
      </ButtonsContainer>
    </ModalBaseSmall>
  )
}

const Title = styled.p`
  font-size: 18px;
`

const ButtonsContainer = styled.div`
  padding: 16px 0;
`

export { ModalDeleteCampaignUpdate }
