import React from 'react'

const DarkBlueButton = ({ label, type, className, center, disabled, ...props }) => (
  <div className={center ? 'bordered-center' : ''}>
    <button disabled={disabled} {...props} type='submit' className={`button-default button-dark-blue ${className}`}>
      {label}
    </button>
  </div>
)

export { DarkBlueButton }
