import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import styled from 'styled-components'

import { LoadingScreen, BorderedButton } from '../commom'
import { Select, NumberInput, TextInput, Autocomplete, Radio, DatePicker } from '../newFormComponents'

import { formatPhone, checkIsCompletedPersonalData } from '../../helpers'
import { getAllGenders, getAllMartialTypes, updateInvestor } from '../../services/v3'
import { userPersonalDataSchema } from '../../schemas'

import { statesOptions, cityOptions, nationalityOptions, nationalities } from '../../constants'

moment.locale('pt-br')

const UserPersonalDataForm = ({ investor, fetchInvestor, changeTab }) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  const [genders, setGenders] = useState([])
  const [martialTypes, setMartialTypes] = useState([])
  const [identityLabel, setIdentityLabel] = useState('RG *')

  const { handleSubmit, errors, control, reset, watch } = useForm({
    resolver: userPersonalDataSchema,
    mode: 'onBlur'
  })

  const nationality = watch('nationality')

  useEffect(() => {
    setIdentityLabel(nationality && nationality === nationalities.FOREIGN ? 'RNE *' : 'RG *')
  }, [nationality])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setLoading(true)
        const [{ data: genders }, { data: martialTypes }] = await Promise.all([getAllGenders(), getAllMartialTypes()])
        setGenders(genders.map(({ id, name }) => ({ value: String(id), label: name })))
        setMartialTypes(martialTypes.map(({ id, name }) => ({ value: id, label: name })))
        setIsCompleted(checkIsCompletedPersonalData(investor))

        if (investor) {
          reset({
            first_name: investor.first_name || '',
            last_name: investor.last_name || '',
            document_number: (investor.user && investor.user.document_number) || '',
            email: (investor.user && investor.user.email) || '',
            nationality: investor.nationality || '',
            father_name: investor.father_name || '',
            mother_name: investor.mother_name || '',
            rg: investor.rg || '',
            agent: investor.rg_agent || '',
            uf: investor.rg_uf || '',
            phone: investor.phone && investor.prefix ? formatPhone(investor.prefix, investor.phone) : '',
            birth_date: investor.birth_date
              ? moment(investor.birth_date)
                  .utc()
                  .format('YYYY-MM-DD')
              : null,
            ...(investor.marital_type_id && { marital_type_id: String(investor.marital_type_id) }),
            ...(investor.gender_id && { gender_id: String(investor.gender_id) }),
            ...(investor.naturality && {
              birth_something: { value: investor.naturality, label: investor.naturality }
            })
          })
        }
      } catch (error) {
        console.error(error)
        toast.error('Erro ao carregar os dados, por favor tente novamente.')
      } finally {
        setLoading(false)
        const elems = document.querySelectorAll('.collapsible')
        window.M.Collapsible.init(elems)
      }
    }
    fetchInfos()
  }, [investor, reset])

  const onSubmit = async values => {
    try {
      const numberPhone = values.phone.replace(/\D/g, '')
      const body = {
        first_name: values.first_name.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) {
          return capitalize.toUpperCase()
        }),
        last_name: values.last_name.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) {
          return capitalize.toUpperCase()
        }),
        birth_date: moment.utc(new Date(values.birth_date)).format('YYYY-MM-DD'),
        prefix: numberPhone.slice(0, 2),
        phone: numberPhone.slice(2),
        gender_id: Number(values.gender_id),
        marital_type_id: Number(values.marital_type_id),
        mother_name: values.mother_name,
        father_name: values.father_name,
        nationality: values.nationality,
        naturality: values.birth_something.value,
        rg: values.rg,
        rg_agent: values.agent,
        rg_uf: values.uf,
        email: values.email
      }
      await updateInvestor(investor.id, body)
      setIsCompleted(true)
      toast.success('Dados atualizados com sucesso')
      fetchInvestor()
      changeTab(0, 1)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar os dados pessoais')
    }
  }

  return (
    <li>
      <div className='collapsible-header'>
        <div className='collapsible-description'>
          <i className='material-icons'>account_circle</i>
          <p>Dados pessoais</p>
        </div>
        <i className='material-icons' style={{ color: isCompleted ? 'green' : 'grey' }}>
          check_circle
        </i>
      </div>
      <div className='collapsible-body'>
        {loading ? (
          <LoadingScreen small />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <TextInput width='50%' name='first_name' control={control} label='Nome *' error={errors['first_name']} />
              <TextInput
                width='50%'
                name='last_name'
                control={control}
                label='Sobrenome *'
                error={errors['last_name']}
              />
            </InputContainer>
            <InputContainer>
              <NumberInput
                disabled
                name='document_number'
                format='###.###.###-##'
                width='50%'
                control={control}
                label='CPF *'
                error={errors['document_number']}
                placeholder='Ex: 076.414.749-85'
              />
              <TextInput width='50%' name='email' control={control} label='Email *' error={errors['email']} />
            </InputContainer>
            <InputContainer>
              <NumberInput
                format={value =>
                  value.length <= 10
                    ? `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
                    : `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
                }
                name='phone'
                width='50%'
                control={control}
                label='Celular/Whatsapp *'
                error={errors['phone']}
                placeholder='(11) 98765-4564'
              />
              <DatePicker
                name='birth_date'
                width='50%'
                control={control}
                label='Data de nascimento *'
                error={errors['birth_date']}
              />
            </InputContainer>
            <InputContainer>
              <Select
                name='nationality'
                control={control}
                label='Nacionalidade *'
                error={errors['nationality']}
                options={nationalityOptions}
                width='50%'
              />
              <Autocomplete
                name='birth_something'
                width='50%'
                control={control}
                label='Naturalidade (Cidade - UF) *'
                error={errors['birth_something']}
                options={cityOptions}
              />
            </InputContainer>
            <InputContainer>
              <Radio
                name='gender_id'
                width='50%'
                control={control}
                label='Gênero *'
                error={errors['gender_id']}
                options={genders}
              />
              <Select
                name='marital_type_id'
                width='50%'
                control={control}
                label='Estado civil *'
                options={martialTypes}
                error={errors['marital_type_id']}
              />
            </InputContainer>
            <InputContainer>
              <TextInput name='rg' control={control} label={identityLabel} error={errors['rg']} width='50%' />
              <AgentContainer>
                <TextInput
                  name='agent'
                  control={control}
                  label='Orgão Expedidor *'
                  error={errors['agent']}
                  width='70%'
                  margin='0px 10px 0px 0px'
                />
                <Select
                  name='uf'
                  control={control}
                  label='UF *'
                  options={statesOptions}
                  error={errors['uf']}
                  width='30%'
                  margin='0px 0px 0px 10px'
                />
              </AgentContainer>
            </InputContainer>
            <InputContainer>
              <TextInput
                name='mother_name'
                width='50%'
                control={control}
                label='Nome da mãe *'
                error={errors['mother_name']}
              />
              <TextInput
                name='father_name'
                width='50%'
                control={control}
                label='Nome do pai *'
                error={errors['father_name']}
              />
            </InputContainer>
            <div className='dfp'>
              <BorderedButton center type='submit' label='SALVAR E CONTINUAR' />
            </div>
          </form>
        )}
      </div>
    </li>
  )
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 620px) {
    flex-direction: column;
  }
`

const AgentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 10px;
  @media (max-width: 620px) {
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
`

export default UserPersonalDataForm
