import styled from 'styled-components'

import {
  Radio as RadioMaterial,
  FormControlLabel as MaterialFormControlLabel,
  RadioGroup,
  FormControl
} from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const StyledFormControl = styled(FormControl)`
  ${borders};
  ${flexbox};
  ${sizing};
  ${spacing};
  ${shadows};
`

export const StyledRadio = styled(RadioMaterial)`
  &&.MuiRadio-root {
    color: ${props => (props.viewonly ? props.theme.palette.gray['800'] : '')};
  }

  &&.MuiButtonBase-root {
    span {
      input {
        opacity: 0;
      }
    }
  }
`
export const StyledFormControlLabel = styled(MaterialFormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${props => (props.viewonly ? props.theme.palette.gray['800'] : '')};
  }
  ${palette};
  ${typography};
`

export const Label = styled.label`
  color: ${props => (props.labelColor ? props.labelColor : props.theme.palette.gray['800'])};
`

export const StyledRadioGroup = styled(RadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    flex-direction: ${props => (props.column ? props.column : 'row')};
  }
`
