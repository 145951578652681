import styled from 'styled-components'
import { display, positions, sizing, spacing, flexbox } from '@material-ui/system'

import { TextField } from '@material-ui/core'

export const InputText = styled(TextField)`
  &&.MuiFormControl-root {
    ${display};
    ${positions};
    ${sizing};
    ${spacing};
    ${flexbox};
  }

  /* styles to override materialize */
  input {
    border: none !important;
    margin: 0px !important;
    height: 1.1876em !important;
    padding: 10.5px 14px !important;
  }

  svg {
    color: ${({ error, theme }) => (error ? theme.palette.error.main : theme.palette.gray[600])};
  }

  label {
    color: ${({ theme }) => theme.palette.gray.main};
    &.MuiInputLabel-shrink {
      text-transform: uppercase;
      font-weight: 600;
      padding-right: 4px;
      background: ${({ theme }) => theme.palette.white};
    }
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.primary[400]};
    }
  }

  div {
    &.MuiOutlinedInput-root {
      fieldset {
        border-color: ${({ theme }) => theme.palette.gray[700]};
      }
      &.Mui-focused {
        fieldset {
          border-color: ${({ theme }) => theme.palette.primary[400]};
        }
      }
    }
  }

  .MuiFormHelperText-root {
    margin-left: 2px;
    color: ${({ theme }) => theme.palette.gray[700]};
  }
`
