import React from 'react'
import Modal from 'react-modal'
import './change-password-modal.scss'

const ChangePasswordModal = ({ visible, closeModal, ...props }) => {
  const alreadySeenModal = window.localStorage.getItem('modal-new-plataform-closed')
  const showModal = alreadySeenModal !== 'true'
  const resetPassword = () => {
    props.history.push('/password-reset')
    closeModal()
  }
  const signIn = () => {
    props.history.push('/investidor')
    closeModal()
  }
  return (
    <Modal
      ariaHideApp={false}
      isOpen={visible && showModal}
      className='Modal-ChangePassword modal-container'
      overlayClassName='Overlay'
      style={{ padding: 0 }}
    >
      <div className='modal-password-container'>
        <i className='fas fa-times close-modal-button' onClick={closeModal} />
        <p className='modal-password-title'>NOVO SITE KAVOD LENDING</p>
        <p className='modal-password-sub-title'>A equipe da Kavod te dá as boas vindas!</p>
        <p className='reset-instructions'>
          A partir de agora, você passará a fazer login em nosso site usando o seu <span>CPF e Senha</span>. Por isso é{' '}
          <span>muito importante</span> que siga as instruções abaixo:
        </p>
        <div className='rocket-steps-container'>
          <img className='rocket-image' src='/assets/img/shared/foguete.png' alt='imagem de um foguete' />
          <div className='steps-container'>
            <p>
              <span>Usuários que já haviam preenchido o CPF no cadastro</span>
            </p>
            <p className='spaced-text'>
              Basta <button onClick={resetPassword}>clicar aqui</button> e redefinir a sua senha de acesso.
            </p>
            <p>
              <span>Usuários que NÃO inseriram o CPF após cadastro inicial</span>
            </p>
            <p className='spaced-text'>
              Será necessário fazer um <span className='bold-span'>novo cadastro informando o seu CPF</span> para ter
              acesso ao site. <button onClick={signIn}>Clique aqui</button> e faça seu cadastro.
            </p>
            <p>
              Esse é apenas o começo. Siga <span className='bold-span'>@kavodlending</span> nas redes e acompanhe as{' '}
              <span className='bold-span'>NOVIDADES da Kavod!</span>
            </p>
          </div>
        </div>
        <img className='crowd-image' src='/assets/img/shared/torcida.png' alt='imagem de uma torcida' />
      </div>
    </Modal>
  )
}

export default ChangePasswordModal
