import React from 'react'
import CepInputRedux from '../components/commom/CepInputRedux'
import {
  InputRedux,
  DatepickerRedux,
  SelectRedux,
  AutoCompleteRedux,
  CheckBoxRedux,
  UploadPersonalDocument,
  CPFRedux,
  UploadFile,
  RadioButton
} from '../components/commom'

export default (field, extraProp = null) => {
  switch (field.type) {
    case 'password':
    case 'text':
    case 'tel':
    case 'number':
    case 'email':
    case 'agency':
    case 'bank_account':
    case 'currency':
    case 'text-area':
      return <InputRedux field={field} />

    case 'cpf':
      return <CPFRedux field={field} />

    case 'date':
      return <DatepickerRedux field={field} />

    case 'select':
      return <SelectRedux field={field} />

    case 'radio-button':
      return <RadioButton field={field} />

    case 'autocomplete':
      return <AutoCompleteRedux field={field} />

    case 'cep':
      return <CepInputRedux field={field} dispatch={extraProp} />

    case 'checkbox':
      return <CheckBoxRedux field={field} />

    case 'personal-document':
      return <UploadPersonalDocument field={field} />

    case 'file':
      return <UploadFile field={field} />

    default:
      return null
  }
}
