import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify'

import { Button, LoadingScreen } from '../components/commom'

import { modules } from '../constants'
import { getCampaignById, updateCampaign } from '../services/v3'

import 'react-quill/dist/quill.snow.css'
import '../css/react-quill.scss'

const CampaignOverviewForm = props => {
  const [campaign, setCampaign] = useState({})
  const [shortDescription, setShortDescription] = useState('')

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const { data: campaign } = await getCampaignById(props.match.params.campaignURL)
        setCampaign(campaign)
        setShortDescription(campaign.short_description)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar o resumo da campanha')
      }
    }
    fetchCampaign()
    setTimeout(() => window.M.updateTextFields(), 500)
  }, [props.match.params.campaignURL])

  const handleSubmit = async e => {
    try {
      if (e) e.preventDefault()
      const { campaignURL } = props.match.params
      await updateCampaign(campaignURL, { short_description: shortDescription })
      toast.success('Resumo da campanha atualizado com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar atualizar o resumo da campanha')
    }
  }

  if (!campaign.id) return <LoadingScreen />

  return (
    <div className='row'>
      <div className='col s12'>
        <div className='dfp'>
          <form onSubmit={handleSubmit}>
            <ReactQuill
              className='react-quill'
              theme='snow'
              value={shortDescription}
              onChange={short_description => setShortDescription(short_description)}
              placeholder='Resumo da campanha'
              modules={modules}
            />
            <div className='dfp'>
              <Button type='submit' text='SALVAR' />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default connect()(CampaignOverviewForm)
