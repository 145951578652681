import React, { Component } from 'react'

class Timepicker extends Component {
  componentDidMount() {
    let element = document.getElementById(this.props.name)
    window.M.Timepicker.init(element, {
      onSelect: this.onTimepickerChange,
      autoClose: true,
      twelveHour: false
    })
  }

  onTimepickerChange = (hour, minute) => this.props.onTimepickerChange(hour, minute, this.props.name)

  render() {
    const { className, name, placeholder, value, label } = this.props
    return (
      <div className={`input-field ${className}`}>
        <input
          type='text'
          className='timepicker'
          id={name}
          name={name}
          placeholder={placeholder}
          readOnly
          value={value}
        />
        <label htmlFor={name}>{label}</label>
      </div>
    )
  }
}

export { Timepicker }
