import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  HomeBanner,
  Graphics,
  HowItWork,
  Testimonials,
  WeAreKavod,
  HomeQuestions,
  HomeLaptop,
  Investor,
  HomeNews
} from '../components'
import { Footer } from '../components/commom'
import ModalEqSeedInvestment from '../components/Modals'

class Home extends Component {
  state = {
    isOpenModal: false
  }

  componentDidMount() {
    const stateCheck = setInterval(() => {
      if (document.readyState === 'interactive') {
        clearInterval(stateCheck)
        this.handleUrlScroll()
      }
    }, 100)
  }

  handleUrlScroll = () => {
    if (this.props.location.hash === '#we-are-kavod') {
      this.scrollToWeAreKavod()
    }
    if (this.props.location.hash === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  scrollToWeAreKavod = () => {
    window.scrollTo({
      top: this.weAreKavodRef.offsetTop,
      behavior: 'smooth'
    })
  }

  setWeAreKavodRef = node => {
    this.weAreKavodRef = node
  }

  render() {
    const { isOpenModal } = this.state

    return (
      <div id='home'>
        <div ref={this.myRefSignUp}>
          <HomeBanner authenticated={this.props.currentUser.authenticated} scrollToSignUp={this.scrollToSignUp} />
        </div>
        <Investor authenticated={this.props.currentUser.authenticated} />
        <HowItWork />
        <Graphics authenticated={this.props.currentUser.authenticated} />
        <Testimonials />
        <HomeLaptop />
        <HomeQuestions />
        <HomeNews />
        <div id='we-are-kavod' ref={this.setWeAreKavodRef}>
          <WeAreKavod authenticated={this.props.currentUser.authenticated} />
        </div>
        <Footer />
        <ModalEqSeedInvestment 
          open={isOpenModal}
          onClose={() => this.setState({ isOpenModal: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default withRouter(connect(mapStateToProps)(Home))
