import React from 'react'
import Modal from 'react-modal'

import { formatMoney } from '../../helpers'

const InvestmentsResumeModal = ({ isOpen, onAfterOpen, onRequestClose, info, closeModal }) => {
  const handleKey = ({ key }) => key === 'Escape' && closeModal()
  return (
    <div onKeyUp={handleKey}>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        info={info}
        closeModal={closeModal}
        className='Modal modal-container'
        overlayClassName='Overlay'
      >
        <div>
          <div className='modal-header'>
            <h4>Resumo do investimento: </h4>
            <i className='material-icons icon-close' onClick={closeModal}>
              close
            </i>
          </div>
          <div className='table-modal'>
            <div className='modal-row'>
              <p>Valor do investimento:</p>
              <p>R$ {formatMoney(info.amount)}</p>
            </div>
            <div className='modal-row'>
              <p>Retorno bruto:</p>
              <p>R$ {formatMoney(info.overview.gross_amount)}</p>
            </div>
            <div className='modal-row'>
              <p>Retorno líquido :</p>
              <p>R$ {formatMoney(info.overview.net_amount)}</p>
            </div>
            <div className='modal-row'>
              <p>Ganho real líquido:</p>
              <p>R$ {formatMoney(info.overview.value_return)}</p>
            </div>
            <div className='modal-row'>
              <p>Taxa mensal:</p>
              <p>{formatMoney(info.campaign.rate_month)} %</p>
            </div>
            <div className='modal-row'>
              <p>Aumento de patrimônio:</p>
              <p>{formatMoney(info.overview.percentual_return)} %</p>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <p onClick={closeModal}>Fechar</p>
        </div>
      </Modal>
    </div>
  )
}

export { InvestmentsResumeModal }
