import React from 'react'
import CampaignNavigationOptions from './CampaignNavigationOptions'
import CampaignUploadGalleryForm from '../containers/CampaignUploadGalleryForm'

const CampaignGallery = props => (
  <div className='container-fluid'>
    <div className='row'>
      <div className='col s12 m3'>
        <CampaignNavigationOptions match={props.match} />
      </div>
      <div className='col s12 m9'>
        <CampaignUploadGalleryForm match={props.match} />
      </div>
    </div>
  </div>
)

export default CampaignGallery
