import React, { Component } from 'react'

class DisplayFormErrors extends Component {
  renderErrors = () => {
    const { errors } = this.props

    return errors.map((error, index) => (
      <span className='error-msg' key={index}>
        {error}
      </span>
    ))
  }

  render() {
    const { errors, className, login, signUp } = this.props
    if (!errors || !errors.length || errors[0] === '') return null

    return (
      <div className={className}>
        <div className={`${login || signUp ? 'dfp-bottom-big' : 'dfp'}  center-align`}>{this.renderErrors()}</div>
      </div>
    )
  }
}

export { DisplayFormErrors }
