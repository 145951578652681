export const campaignTypes = {
  AVAL: 1,
  AVAL_E_GARANTIA: 2
}

export const campaignStatuses = {
  ACTIVE: 'A',
  CLOSED: 'CL',
  CLOSED_WITHOUT_CAPTATION: 'CS',
  WAITING_LIST: 'WL',
  PAYMENT_RECEIVED: 'PR',
  DISBURSED: 'DD',
  OLD_CCBS: 'CCBs',
  PROCESS_PLEDGES: 'STEP1',
  BMP_SIMULATOR: 'STEP2',
  SEND_PROPOSAL: 'STEP3',
  PROPOSAL_STATUS: 'STEP4',
  CCBS: 'STEP5',
  DELETED: 'D'
}

export const minInvestmentAmount= {
  number: 200000,
  text: 'R$ 2.000,00',
  textSmall: 'R$ 2 mil'
}
