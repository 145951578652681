import React from 'react'
import { connect } from 'react-redux'

import CampaignNavigationOptions from './CampaignNavigationOptions'
import CampaignUploadProfileImgForm from '../containers/CampaignUploadProfileImgForm'

const CampaignPhoto = ({ match }) => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <CampaignUploadProfileImgForm match={match} />
        </div>
      </div>
    </div>
  )
}

export default connect()(CampaignPhoto)
