import { isEmpty } from 'lodash'
import { FETCH_COMPANIES_SUCCESS, FETCH_COMPANIES_FAIL, FETCH_COMPANIES_LOADING } from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  fail: false,
  data: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_LOADING:
      return { fail: false, loading: true, data: null }

    case FETCH_COMPANIES_SUCCESS:
      const data = isEmpty(action.payload) ? null : action.payload
      return { fail: false, loading: false, data }

    case FETCH_COMPANIES_FAIL:
      return { fail: true, loading: false, data: null }

    default:
      return state
  }
}
