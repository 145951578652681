import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { Select as SelectComponent, MenuItem, InputLabel, FormHelperText, FormControl } from '@material-ui/core'

const Select = ({
  control,
  defaultValue = '',
  name,
  error,
  label,
  options,
  placeholder,
  disabled,
  width,
  margin,
  dense = false,
  ...props
}) => {
  return (
    <Container width={width} margin={margin}>
      <FormControl {...(dense && { margin: 'dense' })} variant='outlined' fullWidth error={Boolean(error)}>
        <InputLabel>{label}</InputLabel>
        <Controller
          as={
            <SelectComponent label={label}>
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SelectComponent>
          }
          defaultValue={defaultValue}
          error={Boolean(error)}
          helpertext={error && error.message}
          name={name}
          control={control}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '200px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export { Select }
