export const documentsValidator = values => {
  let errors = {}

  if (!values.compliance_agreement_c2 || !values.compliance_agreement_c) {
    errors.compliance_agreement_c2 =
      'Para investir em uma de nossas campanhas é necessário concordar com os termos de uso'
  }

  if (!values.personal_document_type_id1) {
    errors.personal_document_type_id1 = 'Selecione uma opção'
  }

  if (!values.personal_document_type_id2) {
    errors.personal_document_type_id2 = 'Selecione uma opção'
  }

  if (!values.upload_personal_document_type_id1 & !values.with_document_type_id1) {
    errors.upload_personal_document_type_id1 = 'Upload não detectado'
  }

  if (!values.upload_personal_document_type_id2 & !values.with_document_type_id2) {
    errors.upload_personal_document_type_id2 = 'Upload não detectado'
  }

  return errors
}
