import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import { ModalBaseSmall } from './ModalBaseSmall'
import { NumberInput, Button } from '../../components/newFormComponents'

import { updateCampaignPhoto } from '../../services/v3'
import { photoOrderSchema } from '../../schemas'

const ModalEditPhotoOrder = ({ open, onClose, photo, refresh }) => {
  const [sending, setSending] = useState(false)

  const { control, errors, reset, handleSubmit } = useForm({
    defaultValues: { order: '' },
    resolver: photoOrderSchema
  })

  useEffect(() => {
    reset({ order: photo.photo_order })
  }, [photo, reset])

  const onSubmit = async values => {
    try {
      setSending(true)
      await updateCampaignPhoto(photo.id, { photo_order: String(values.order) })
      toast.success('Ordem da foto alterada')
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar a ordem da foto')
      setSending(false)
    }
  }

  return (
    <ModalBaseSmall open={open} onClose={onClose}>
      <Title>Editar ordem da foto na galeria</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <NumberInput name='order' label='Ordem' control={control} error={errors['order']} dense />
        <ButtonsContainer>
          <Button label='Atualizar' type='submit' width='100%' loading={sending} />
        </ButtonsContainer>
      </Form>
    </ModalBaseSmall>
  )
}

const Form = styled.form`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 18px;
  margin: 0px;
  margin-top: 8px;
`

const ButtonsContainer = styled.div`
  padding: 8px 0px;
`

export { ModalEditPhotoOrder }
