import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import { NumberInput, Button } from '../components/newFormComponents'

import { forgetPassword } from '../services/v3'
import { passwordResetSchema } from '../schemas'

const PasswordResetToken = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [maskedEmail, setMaskedEmail] = useState('')

  const { handleSubmit, errors, control } = useForm({
    resolver: passwordResetSchema,
    defaultValues: { document_number: '' }
  })

  const onSubmit = async values => {
    try {
      setLoading(true)
      const { data: resetPassword } = await forgetPassword({
        document_number: values.document_number.replace(/\D/g, '')
      })
      if (!resetPassword || !resetPassword.masked_email) {
        return toast.error('Seu cadastro está com erro, por favor entre em contato com a Kavod')
      }
      setMaskedEmail(resetPassword.masked_email)
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao solicitar a troca de senha')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Container>
        {!maskedEmail ? (
          <>
            <Title>Recuperação de senha</Title>
            <Message>Digite o CPF associado à sua conta e enviaremos um link para redefinir sua senha</Message>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <NumberInput
                name='document_number'
                control={control}
                error={errors['document_number']}
                label='CPF *'
                format='###.###.###-##'
                margin='0px'
                width='100%'
                dense
              />
              <Button label='Enviar' type='submit' width='100%' margin='40px 0px' loading={loading} />
              <LoginLink to='/login'>Ir para login</LoginLink>
            </Form>
          </>
        ) : (
          <>
            <Title>Link enviado com sucesso</Title>
            <Message>Um link de redefinição de senha foi enviado para o email {maskedEmail}</Message>
            <Button
              onClick={() => history.push('/login')}
              label='Voltar para o login'
              width='100%'
              margin='40px 0px 10px 0px'
            />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
`

const Form = styled.form`
  padding: 0px;
`

const Container = styled.div`
  width: 432px;
  background: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 24px;
  @media (max-width: 768px) {
    width: 388px;
  }
  @media (max-width: 375px) {
    width: 360px;
    box-shadow: none;
    border-radius: 0px;
  }
`

const Title = styled.h1`
  color: #212121;
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  margin-top: 0px;
`

const Message = styled.p`
  font-size: 16px;
  color: #424242;
  margin-bottom: 40px;
`

const LoginLink = styled(Link)`
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #076f9f;
`

export default withRouter(PasswordResetToken)
