import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { CPF_LENGTH } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const passwordResetSchema = yupResolver(
  yup.object().shape({
    document_number: yup
      .string()
      .required()
      .test('cpf', 'CPF incompleto', value => value.replace(/\D/g, '').length === CPF_LENGTH)
  })
)
