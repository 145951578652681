import React from 'react'
import Modal from 'react-modal'
import { ArrowLeftTwoTone, ArrowRightTwoTone } from '@material-ui/icons'

import { getInstalmentStatus, formatMoney, sanitizeDate } from '../../helpers'
import { installmentStatuses } from '../../constants'

const PortionsModal = ({ isOpen, onAfterOpen, onRequestClose, closeModal, handleChangePortion, index, portions }) => {
  const prevPortion = () => {
    if (index > 0) handleChangePortion(index - 1)
  }

  const nextPortion = () => {
    if (index < portions.length - 1) handleChangePortion(index + 1)
  }

  const delayedValue =
    portions[index].interest + portions[index].fine + portions[index].default_interest - portions[index].IR_on_delay

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose || closeModal}
        closeModal={closeModal}
        className='Modal modal-container'
        overlayClassName='Overlay'
      >
        <div>
          <div className='modal-header'>
            <h4 style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
              {'Detalhes da parcela: '}
              <ArrowLeftTwoTone onClick={prevPortion} style={{ cursor: 'pointer', fontSize: '20px' }} />
              {` ${portions[index].installment_number}/${portions[index].total_installments} `}
              <ArrowRightTwoTone onClick={nextPortion} style={{ cursor: 'pointer', fontSize: '20px' }} />
            </h4>
            <i className='material-icons icon-close' onClick={closeModal}>
              close
            </i>
          </div>
          <div className='table-modal'>
            <div className='modal-row'>
              <p>Status:</p>
              <p>{getInstalmentStatus(portions[index].abbreviation)}</p>
            </div>
            <div className='modal-row'>
              <p>Valor bruto da parcela:</p>
              <p>
                R${' '}
                {formatMoney(
                  portions[index].installment_default_value_delayed || portions[index].installment_default_value
                )}
              </p>
            </div>
            <div className='modal-row'>
              <p>Amortização:</p>
              <p>R$ {formatMoney(portions[index].amortization)}</p>
            </div>
            <div className='modal-row'>
              <p>Juros da parcela:</p>
              <p>R$ {formatMoney(portions[index].tax_per_installment)}</p>
            </div>
            <div className='modal-row'>
              <p>Faixa do imposto de renda:</p>
              <p>{formatMoney(portions[index].IR_tax_based_on_past_days)} %</p>
            </div>
            <div className='modal-row'>
              <p>Imposto de renda da parcela:</p>
              <p>R$ {formatMoney(portions[index].IR_based_on_past_days + portions[index].IR_on_delay)} </p>
            </div>
            <div className='modal-row'>
              <p>Imposto de renda acumulado:</p>
              <p>R$ {formatMoney(portions[index].accumulated_ir)} </p>
            </div>
            <div className='modal-row'>
              <p>Valor líquido original:</p>
              <p>R$ {formatMoney(portions[index].liquidation_transfer_amount || portions[index].transfer_amount)}</p>
            </div>
            {Boolean(delayedValue) && (
              <div className='modal-row'>
                <p>Juros do atraso:</p>
                <p>R$ {formatMoney(delayedValue)}</p>
              </div>
            )}
            <div className='modal-row'>
              <p>Valor previsto da TED:</p>
              <p>R$ {formatMoney(portions[index].transfer_amount_delayed || portions[index].transfer_amount)}</p>
            </div>
            <div className='modal-row'>
              <p>Dias corridos desde o ínicio da campanha:</p>
              <p>{portions[index].past_days}</p>
            </div>
            <div className='modal-row'>
              <p>Previsão da TED:</p>
              <p>{sanitizeDate(portions[index].transfer_date_delayed || portions[index].transfer_date)}</p>
            </div>
            {installmentStatuses.PAID === portions[index].abbreviation && (
              <>
                <div className='modal-row'>
                  <p>Valor efetivo da TED:</p>
                  <p>R$ {formatMoney(portions[index].transfer_amount_delayed || portions[index].transfer_amount)}</p>
                </div>
                <div className='modal-row'>
                  <p>Pago em:</p>
                  <p>{sanitizeDate(portions[index].pay_date)}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='modal-footer'>
          <p onClick={closeModal}>Fechar</p>
        </div>
      </Modal>
    </div>
  )
}

export { PortionsModal }
