import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop'
import { toast } from 'react-toastify'

import { Button } from '../components/commom'

import { createCampaignPhoto, updateCampaignPhoto } from '../services/v3'

import 'react-image-crop/dist/ReactCrop.css'

class UploadCampaignProfilePhoto extends Component {
  state = {
    loading: false,
    notCropped: true,
    fileName: null,
    src: null,
    crop: {
      aspect: 1,
      width: 50,
      x: 0,
      y: 0
    }
  }

  onSelectFile = e => {
    this.setState({ currentImg: e.target.files })
    this.setState({ fileName: e.target.files[0].name })
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => this.setState({ src: reader.result }))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
  }

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop)
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      await this.getCroppedImg(this.imageRef, pixelCrop, this.state.fileName)
    }
  }
  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )
    this.setState({ notCropped: false })
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        this.setState({
          currentImg: [this.blobToFile(blob, this.state.fileName)]
        })
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  handleSubmit = async e => {
    try {
      e.preventDefault()

      if (!this.state.currentImg) return false
      this.setState({ loading: true })

      const { currentImg } = this.state
      const { campaign, photoTypeId, campaignProfileImg } = this.props

      const formData = new FormData()
      formData.append('file', currentImg[0])
      formData.append('campaign_id', campaign.id)
      formData.append('campaign_photo_type_id', photoTypeId)

      if (campaignProfileImg) {
        await updateCampaignPhoto(campaignProfileImg.id, formData)
      } else {
        await createCampaignPhoto(formData)
      }

      toast.success('Imagem atualizada com sucesso')
      this.props.refetch()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao salvar a imagem')
    }
  }

  render() {
    const { crop, src } = this.state
    return (
      <form onSubmit={this.handleSubmit} className='dfp'>
        <div className='file-field input-field'>
          <div className='btn'>
            <span>File</span>
            <input type='file' id='ProfileFileInput' onChange={this.onSelectFile} />
          </div>
          <div className='file-path-wrapper'>
            <input className='file-path validate' type='text' />
          </div>
        </div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            keepSelection={true}
          />
        )}
        <Button type='submit' text='UPLOAD' disabled={this.state.loading} />
      </form>
    )
  }
}

export default connect()(UploadCampaignProfilePhoto)
