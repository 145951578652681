import { monthsShort } from '../constants'
import numbro from 'numbro'
import moment from 'moment-timezone'

export const sanitizeDate = value => (moment(value).isValid() ? moment.utc(value).format('DD/MM/YYYY') : '-')

export const dateToString = date => {
  if (date === null || date === undefined) return ''

  let newDate = new Date(date)

  return `${monthsShort[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`
}

export const formatDate = _date => {
  return String(_date).length === 1 ? `0${_date}` : _date
}

export const formatDateToBackEnd = _date => {
  let date = new Date(_date)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const formatDateFromBackEnd = _date => {
  let date = new Date(_date)
  return `${monthsShort[date.getMonth()]} ${moment.utc(date).format('DD')}, ${date.getFullYear()}`
}

export const formatDateWithTime = date => {
  const dataWithMoment = moment.utc(date)
  const day = dataWithMoment.format('DD')
  const month = dataWithMoment.format('MMM')
  const year = dataWithMoment.format('YYYY')
  const hours = dataWithMoment.format('HH')
  const minutes = dataWithMoment.format('mm')
  return `${day} ${month} de ${year} às ${hours}:${minutes}`
}

export const formatDateFromBackEndComplete = _date => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  let date = new Date(_date).toLocaleDateString('pt-BR', options)
  return date
}

export const validateURL = url => {
  if (url) {
    let normalized = url.replace(/[àáâãäåæ]/gi, 'a')
    normalized = normalized.replace(/[ç]/gi, 'c')
    normalized = normalized.replace(/[èéêëæ]/gi, 'e')
    normalized = normalized.replace(/[ìíîï]/gi, 'i')
    normalized = normalized.replace(/[ñ]/gi, 'n')
    normalized = normalized.replace(/[òóôõöø]/gi, 'o')
    normalized = normalized.replace(/[ß]/gi, 's')
    normalized = normalized.replace(/[ùúûü]/gi, 'u')
    normalized = normalized.replace(/[ÿ]/gi, 'y')
    normalized = normalized.replace(/[^a-zA-Z0-9.]/g, '-')
    normalized = normalized.toLowerCase()
    return normalized
  } else return ''
}
export const handleFetchedDescription = _description => {
  if (!_description) return ['', '']

  return _description.split('SPLIT-DESCRIPTION-TAG')
}

export const handlePostDescription = (_descriptionOne, _descriptionTwo) => {
  let descriptionOne = _descriptionOne || ''
  let descriptionTwo = _descriptionTwo || ''

  return `${descriptionOne} SPLIT-DESCRIPTION-TAG ${descriptionTwo}`
}

export const formatCurrency = value => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const remainingDays = value => {
  if (value) {
    const valueTime = moment.utc(value)
    const diff = moment
      .tz(`${valueTime.format('YYYY-MM-DD')} ${valueTime.format('HH:mm')}`, 'America/Araguaina')
      .diff(moment.tz('America/Araguaina'), 'days')
    return diff < 0 ? 0 : diff
  }
}

export const timeDifferenceFromNow = (date, time) =>
  moment.tz('America/Araguaina').diff(moment.tz(`${date} ${time}`, 'America/Araguaina'))

export const calculatePercent = (maxValue, currentAmount) => {
  let percent = (currentAmount * 100) / maxValue
  if (percent > 100) return 100
  return percent.toFixed(2)
}

export const formatPhone = (prefix, phone) => {
  return `(${prefix}) ${phone.substr(0, 1)} ${phone.substr(1, 4)}-${phone.substr(5, 8)}`
}

export const scrollElementToTop = event => {
  let element = event
  if (event.target) element = event.target
  const y = element.getBoundingClientRect().top + window.scrollY - 100
  window.scroll(null, y)
}
export const normalizeCity = string => {
  if (!string) return
  let normalized = string.replace(/[àáâãäåæ]/gi, 'a')
  normalized = normalized.replace(/[ç]/gi, 'c')
  normalized = normalized.replace(/[èéêëæ]/gi, 'e')
  normalized = normalized.replace(/[ìíîï]/gi, 'i')
  normalized = normalized.replace(/[ñ]/gi, 'n')
  normalized = normalized.replace(/[òóôõöø]/gi, 'o')
  normalized = normalized.replace(/[ß]/gi, 's')
  normalized = normalized.replace(/[ùúûü]/gi, 'u')
  normalized = normalized.replace(/[ÿ]/gi, 'y')

  return normalized
}

export const getInstalmentStatus = status => {
  switch (status) {
    case 'A':
      return 'Ativo'
    case 'AC':
      return 'Aceito'
    case 'C':
      return 'Cancelado'
    case 'CL':
      return 'Fechado'
    case 'I':
      return 'Inative'
    case 'L':
      return 'Trancado'
    case 'O':
      return 'Aberto'
    case 'OH':
      return 'Em espera'
    case 'WL':
      return 'Aberto para reservas'
    case 'P':
      return 'Pago'
    case 'PA':
      return 'Esperando ativação'
    case 'RJ':
      return 'Rejeitado'
    case 'T':
      return 'Terminado'
    default:
      return 'indefinido'
  }
}

export const getPledgesAwaitingProcessingStatus = status => {
  switch (status) {
    case 'A':
      return 'Intenção de investimento criada.'
    case 'AC':
      return 'Solicitação de investimento aprovada.'
    case 'OH':
      return 'Em processamento.'
    default:
      return 'Indefinido'
  }
}

export const formatMoney = value => {
  if (value === null) return '-'
  const newValue = isNaN(value) ? 0 : value
  return numbro(newValue).format({
    thousandSeparated: true,
    mantissa: 2
  })
}

export const sanitizeMoney = value =>
  Number(
    value
      .replace(/\./g, '')
      .replace(',', '.')
      .replace(/^\D*/, '')
  )

export const bmpFormatNumber = value => (isNaN(value) ? Number(value.replace(',', '.')) : value)

export const getAnnualRate = value => {
  if (isNaN(parseFloat(value))) {
    return 0
  } else {
    return numbro(((parseFloat(value) / 100 + 1) ** 12 - 1) * 100).format({ mantissa: 2 })
  }
}

export const getEndDate = date =>
  moment(date ? date : new Date())
    .add(7, 'd')
    .format('ll')

export const formatDateAndTimeToBackEnd = (date, time) => {
  const formatDate = moment(date).format('YYYY-MM-DD')
  const formatTime = `${time}:00`
  return `${formatDate} ${formatTime}`
}

export const getHour = date => (date ? moment.utc(date).format('HH:mm:ss') : '00:00')

export const getMaxValue = value => Math.floor(value / 300000) * 100000

const limit = (value, max) => {
  if (value.length === 1 && value[0] > max[0]) value = '0' + value
  if (value.length === 2 && value > max) value = max

  return value
}

export const timeValidation = value => {
  const hour = limit(value.substring(0, 2), '23')
  const minutes = limit(value.substring(2, 4), '59')

  return hour + (minutes.length ? ':' + minutes : '')
}

export * from './files'
export * from './services'
export * from './campaigns'
export * from './investors'
export * from './parsers'
export * from './pledges'
export * from './users'
export * from './formaters'
export * from './modal'
