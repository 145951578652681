import React, { Component } from 'react'

class CompletedSignUp extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push('/campanhas')
    }, 3000)
  }

  render() {
    return (
      <div className='signup-success'>
        <div className='signup-successful'>
          <p> Parabéns </p>
        </div>
        <div className='signup-success-info'>
          <p style={{ fontSize: '18px', color: '#006904' }}>O seu cadastro está completo.</p>
          <p>Agora você já pode investir nas campanhas da Kavod.</p>
          <p>
            Você será redirecionado para a página de <a href='/campanhas'>campanhas</a>, caso prefira acessar agora,{' '}
            <a href='/campanhas'> clique aqui</a>!
          </p>
        </div>
      </div>
    )
  }
}

export default CompletedSignUp
