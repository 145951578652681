import {
  CHECK_COMPLETE_PROFILE_LOADING,
  CHECK_COMPLETE_PROFILE_FAIL,
  CHECK_COMPLETE_PROFILE_SUCCESS
} from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case CHECK_COMPLETE_PROFILE_SUCCESS:
      return { fail: false, loading: false, ...action.payload }

    case CHECK_COMPLETE_PROFILE_LOADING:
      return { fail: false, loading: true, data: null }

    case CHECK_COMPLETE_PROFILE_FAIL:
      return { fail: true, loading: false, data: null }
    default:
      return state
  }
}
