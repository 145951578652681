import React from 'react'
import { connect } from 'react-redux'

import { getAnnualRate } from '../helpers'

const CampaignProfileImg = ({ campaign, image }) => {
  return (
    <div className='center-align campaign-page-profile-img '>
      <h2 className='campaign-page-title hide-on-large-only'>{campaign.name}</h2>
      <img
        className='responsive-img'
        src={image ? image.photo_url : null}
        alt={'Capa para a campanha da empresa: ' + campaign.name}
      />
      <div className='center-align'>
        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            color: '#222  ',
            width: '95%',
            margin: 'auto',
            marginTop: '5px'
          }}
        >
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between'
            }}
          >
            <div className='campaign-page-values-info'>Taxa mensal</div>
            <div className='campaign-page-values-info'>Taxa anual</div>
            <div className='campaign-page-values-info'>Prazo</div>
          </div>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between'
            }}
          >
            <div className='campaign-page-values-info'>{campaign.rate_month}% a.m.</div>
            <div className='campaign-page-values-info'>{getAnnualRate(campaign.rate_month)}%</div>
            <div className='campaign-page-values-info'>{campaign.installments} Meses</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(CampaignProfileImg)
