import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { LoadingScreen, Button } from '../components/commom'

import { getCurrentUserSigner, getPhotos, getCampaignPhotoTypes } from '../services/v3'

function SignatoryCampaigns(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [campaignsToSign, setCampaignsToSign] = useState(null)
  const [isDisabled, setIsDisabled] = useState([])

  useEffect(() => {
    const fetchSignatoryCampaigns = async () => {
      try {
        setIsLoading(true)
        const [
          {
            data: { eletronic_signatures, campaigns }
          },
          { data: campaignPhotoTypes }
        ] = await Promise.all([getCurrentUserSigner(), getCampaignPhotoTypes()])

        const dashPhotoType = campaignPhotoTypes.find(({ abbreviation }) => abbreviation === 'D')

        const populatedCampaigns = await Promise.all(
          campaigns.map(async campaign => {
            const { data: campaignPhotos } = await getPhotos({ campaign_id: campaign.id })

            return {
              ...campaign,
              has_signed: eletronic_signatures.some(({ campaign_id }) => campaign_id === campaign.id),
              dash_image: campaignPhotos.find(
                ({ photo_url, campaign_photo_type_id }) => photo_url && campaign_photo_type_id === dashPhotoType.id
              )
            }
          })
        )
        setCampaignsToSign(populatedCampaigns)
      } catch {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchSignatoryCampaigns()
  }, [])

  const handleClick = campaign => {
    setIsDisabled([...isDisabled, campaign.id])
    props.history.push(`/assinatura-de-ccb/${campaign.id}`)
  }

  if (isLoading) return <LoadingScreen />

  if (hasError)
    return (
      <ErrorContainer>
        <img width={400} src='/assets/illustrations/no_data.svg' alt='No data illustration' />
        <p>Você não possui nenhuma campanha pendente para assinar.</p>
      </ErrorContainer>
    )

  return (
    <Container>
      <Title>Campanhas para assinar: </Title>
      {campaignsToSign.map(campaign => (
        <Card key={campaign.id}>
          <ImageContainer>
            <Image src={campaign.dash_image && campaign.dash_image.photo_url} alt={campaign.name} />
          </ImageContainer>
          <CampaignInfo>
            <CampaignName>{campaign.name}</CampaignName>
            <CampaignDate>Data da operação: {moment(campaign.operation_date).format('DD/MM/YYYY')}</CampaignDate>
          </CampaignInfo>
          <ButtonContainer>
            <Button
              disabled={isDisabled.includes(campaign.id) || campaign.has_signed}
              text='Abrir tela de assinatura'
              onClick={() => handleClick(campaign)}
            />
            {campaign.has_signed && <Signed>Você já assinou essa campanha.</Signed>}
          </ButtonContainer>
        </Card>
      ))}
    </Container>
  )
}

const Signed = styled.p`
  font-weight: 600;
`

const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  @media (max-width: 769px) {
    width: 100%;
    align-items: center;
  }
`

const CampaignName = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`

const CampaignDate = styled.p`
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0px;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.p`
  font-weight: bold;
  font-size: 1.3em;
`

const ImageContainer = styled.div`
  width: 200px;
`

const Image = styled.img`
  height: 100px;
  display: block;
  margin: auto;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const Card = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 900px;
  padding: 2em;
  border-radius: 4px;
  margin: 1em 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    width: 90%;
    flex-direction: column;
    padding: 1em;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export default connect()(SignatoryCampaigns)
