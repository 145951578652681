import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import ReactQuill from 'react-quill'
import styled from 'styled-components'

import { Input, Button, Datepicker, Timepicker, DisplayFormErrors, LoadingScreen } from '../components/commom'
import { ModalDeleteCampaignUpdate } from '../components/Modals'
import { campaignUpdateFormValidator } from '../validators'

import { modules } from '../constants'
import { IDLE, FETCHING, SENDING } from '../constants/status'
import { dateToString, formatDate } from '../helpers'
import { getCampaignUpdates, createCampaignUpdate, updateCampaignUpdate, deleteCampaignUpdate } from '../services/v3'

import 'react-quill/dist/quill.snow.css'

class CampaignUpdateForm extends Component {
  state = {
    form: { title: '', date: '', time: '', description: '' },
    status: IDLE,
    errros: [],
    campaignUpdate: null,
    isOpenModalDelete: false
  }

  fetchCampaignUpdate = async () => {
    try {
      this.setState({ status: FETCHING })
      const { campaignURL, id } = this.props.match.params
      const { data: campaignUpdates } = await getCampaignUpdates(campaignURL)
      const campaignUpdate = campaignUpdates.find(update => update.id === Number(id))
      const momentDatetime = moment.utc(campaignUpdate.datetime)
      this.setState({
        form: {
          title: campaignUpdate.title,
          date: momentDatetime.format('MMM DD, YYYY'),
          time: momentDatetime.format('HH:mm'),
          description: campaignUpdate.description
        }
      })
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar a atualização: ' + error)
    } finally {
      this.setState({ status: IDLE })
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.fetchCampaignUpdate()
    }
    setTimeout(() => window.M.updateTextFields(), 200)
  }

  onInputChange = e => {
    let form = this.state.form
    form[e.target.name] = e.target.value
    this.setState({ form })
  }

  handleDescription = description => {
    let form = this.state.form
    form.description = description
    this.setState({ form })
  }

  onDatepickerChange = (event, field) => {
    let form = this.state.form
    form[field] = dateToString(event)
    this.setState({ form })
  }

  onTimepickerChange = (_hour, _minute, field) => {
    let hour = formatDate(_hour)
    let minute = formatDate(_minute)
    let form = this.state.form
    form[field] = `${hour}:${minute}`
    this.setState({ form })
  }

  handleSubmit = async e => {
    try {
      e.preventDefault()
      this.setState({ status: SENDING })
      const { form } = this.state
      const { campaignURL, id } = this.props.match.params

      let validate = campaignUpdateFormValidator(form)
      if (!validate.isValid) return this.setState({ errors: validate.errors })

      const momentDatetime = moment.utc(form.date + ' ' + form.time)

      const body = {
        campaign_id: campaignURL,
        title: form.title,
        description: form.description,
        datetime: momentDatetime.format()
      }

      !id ? await createCampaignUpdate(body) : await updateCampaignUpdate(id, body)
      toast.success('Atualização da campanha publicada com sucesso')
      this.props.history.push(`/admin/campanhas/${campaignURL}/atualizacoes`)
    } catch (error) {
      toast.error('Ocorreu um erro ao publicar a atualização da campanha: ' + error)
      this.setState({ status: IDLE })
    }
  }

  handleDelete = async e => {
    try {
      e.preventDefault()
      this.setState({ status: SENDING })
      const { campaignURL, id } = this.props.match.params
      await deleteCampaignUpdate(id)
      toast.success('Atualização removida com sucesso')
      this.props.history.push(`/admin/campanhas/${campaignURL}/atualizacoes`)
    } catch (error) {
      toast.error('Ocorreu um erro ao remover a atualização: ' + error)
      this.setState({ status: IDLE })
    }
  }

  render() {
    const { id } = this.props.match.params

    if (this.state.status === FETCHING) return <LoadingScreen />

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <Input
            value={this.state.form.title}
            onInputChange={this.onInputChange}
            field='title'
            type='text'
            placeholder='Título'
            label='Título'
          />
          <Datepicker
            onDatepickerChange={this.onDatepickerChange}
            value={this.state.form.date}
            label='Data atualização'
            name='date'
            placeholder='Data atualização'
          />
          <Timepicker
            onTimepickerChange={this.onTimepickerChange}
            value={this.state.form.time}
            label='Horário atualização'
            placeholder='Horário atualização'
            name='time'
          />
          <ReactQuill
            theme='snow'
            value={this.state.form.description}
            onChange={this.handleDescription}
            placeholder='Descrição'
            modules={modules}
          />
          <DisplayFormErrors className='col s12' errors={this.state.errors} />
          <ButtonContainer>
            <Button margin='10px' type='submit' disabled={this.state.status === SENDING} text='SALVAR' />
            {id && (
              <DeleteButton
                disabled={this.state.status === SENDING}
                type='button'
                onClick={() => this.setState({ isOpenModalDelete: true })}
              >
                EXCLUIR
              </DeleteButton>
            )}
          </ButtonContainer>
        </form>
        {this.state.isOpenModalDelete && (
          <ModalDeleteCampaignUpdate
            open={this.state.isOpenModalDelete}
            onClose={() => this.setState({ isOpenModalDelete: false })}
            onConfirm={e => {
              this.setState({ isOpenModalDelete: false })
              this.handleDelete(e)
            }}
          />
        )}
      </div>
    )
  }
}

const ButtonContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`

const DeleteButton = styled.button(
  ({ disabled }) => `
  background: ${disabled ? '#DFDFDF' : '#d33131'};
  color: ${disabled ? '#9F9F9F' : '#fff'};
  border: none;
  border-radius: 2px;
  height: 36px;
  padding: 0px 16px;
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  box-shadow: ${
    disabled
      ? 'none'
      : '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)'
  };
  &:focus {
    background: #d33131 !important;
  }
`
)

export default connect()(CampaignUpdateForm)
