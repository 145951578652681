import styled from 'styled-components'
import { ExpansionPanel as Accordion, ExpansionPanelDetails as AccordionDetails } from '@material-ui/core'

import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'
import { Text } from '../../../../../components/v2Components'

export const StyledAccordion = styled(Accordion)`
  &&.MuiExpansionPanel-root {
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};
    border-bottom: 1px solid #e0e0e0;
    &&.Mui-expanded {
      margin: 0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  &&.MuiExpansionPanelDetails-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px 0px;
  }
`

export const Label = styled(Text)`
  &&.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
  }
`
export const CompanyData = styled(AccordionDetails)`
  &&.MuiExpansionPanelDetails-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 0px;
    grid-template-areas:
      'CNPJ CNPJ'
      'fantasyName fantasyName'
      'site site'
      'CEP address'
      'number district'
      'complement city'
      'state state'
      'FMM Value'
      'historic historic'
      'historic historic'
      'historic historic'
      'reason reason';
    &&.Mui-expanded {
      margin: 0px;
    }
  }
`
export const NotCnpjCompanyData = styled(AccordionDetails)`
  &&.MuiExpansionPanelDetails-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 0px;
    grid-template-areas:
      'FMM Value'
      'reason reason'
      'reason reason';
    &&.Mui-expanded {
      margin: 0px;
    }
  }
`

export const FranchiseInfo = styled(AccordionDetails)`
  &&.MuiExpansionPanelDetails-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 0px;
    grid-template-areas:
      'franchiseType franchisor'
      'franchiseDescrip franchiseDescrip'
      'franchiseDescrip franchiseDescrip'
      'franchiseDescrip franchiseDescrip';
    &&.Mui-expanded {
      margin: 0px;
    }
  }
`

export const StyledText = styled(Text)`
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  margin-top: 34px !important;
  margin-bottom: 24px !important;
`
