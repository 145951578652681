import { campaignStatuses } from '../constants'

const closedOptions = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION]

export const verifyPledgeSteps = (abbreviation, campaignStatus) => {
  if (abbreviation === campaignStatuses.PAYMENT_RECEIVED) {
    return {
      payments_received: true,
      disbursement_done: false
    }
  }

  if (abbreviation === campaignStatuses.DISBURSED || closedOptions.includes(campaignStatus)) {
    return {
      payments_received: true,
      disbursement_done: true
    }
  }

  return {
    payments_received: false,
    disbursement_done: false
  }
}
