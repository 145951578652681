import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify'

import { Button, LoadingScreen } from '../components/commom'

import { modules } from '../constants'
import { handleFetchedDescription, handlePostDescription } from '../helpers'
import { getCampaignById, updateCampaign } from '../services/v3'

import '../css/react-quill.scss'
import 'react-quill/dist/quill.snow.css'

const CampaignCompanyDetailsForm = ({ match }) => {
  const [descriptionOne, setDescriptionOne] = useState('')
  const [descriptionTwo, setDescriptionTwo] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [campaign, setCampaign] = useState({})

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setIsLoading(true)
        const { data: campaign } = await getCampaignById(match.params.campaignURL)
        let description = handleFetchedDescription(campaign.description)
        setCampaign(campaign)
        setDescriptionOne(description[0] || '')
        setDescriptionTwo(description[1] || '')
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar sobre a empresa')
      } finally {
        setIsLoading(false)
      }
    }
    fetchCampaign()
    setTimeout(() => window.M.updateTextFields(), 500)
  }, [match.params.campaignURL])

  const handleSubmit = async e => {
    try {
      if (e) e.preventDefault()
      let description = handlePostDescription(descriptionOne, descriptionTwo)
      await updateCampaign(match.params.campaignURL, { description })
      toast.success('Sobre a empresa atualizado com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar os dados de sobre a empresa da campanha')
    }
  }

  if (isLoading || !campaign.id) return <LoadingScreen />

  return (
    <div className='row'>
      <div className='col s12'>
        <form onSubmit={handleSubmit}>
          <div className='dfp'>
            <ReactQuill
              className='react-quill'
              theme='snow'
              value={descriptionOne}
              onChange={descriptionOne => setDescriptionOne(descriptionOne)}
              placeholder='Sobre a Empresa - Parte 1'
              modules={modules}
            />
          </div>
          <div className='dfp'>
            <ReactQuill
              className='react-quill'
              theme='snow'
              value={descriptionTwo}
              onChange={descriptionTwo => setDescriptionTwo(descriptionTwo)}
              placeholder='Sobre a Empresa - Parte 2'
              modules={modules}
            />
          </div>
          <div className='dfp'>
            <Button type='submit' text='SALVAR' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default connect()(CampaignCompanyDetailsForm)
