import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import {
  UserPersonalDataForm,
  UserAddressForm,
  UserFinancialInfoForm,
  UserPersonalDocumentsForm
} from '../components/UserPersonalForms'
import { LoadingScreen } from '../components/commom'

import { fetchCurrentUser, checkCompleteProfile } from '../actions'
import { getCurrentUser, getInvestor } from '../services/v3'

class UserAccountNew extends Component {
  state = {
    submitted: false,
    completedPersonal: false,
    completedAddress: false,
    completedFinancial: false,
    missingData: null,
    loading: true,
    alreadyCompleted: false,
    isLoading: true,
    user: null,
    investor: null
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true })

      const { data: userInfo } = await getCurrentUser()

      if (!userInfo.investor) throw new Error('Você não possui papel de investidor na plataforma')

      const { data: investorInfo } = await getInvestor(userInfo.investor.id)

      this.setState({ user: userInfo, investor: investorInfo })

      this.collapsiableRef = createRef()
    } catch (error) {
      console.error(error)
      toast.error(error.message)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onUpdate = (close, open) => {
    if (this.collapsiableRef.current) {
      this.collapsiableRef.current.M_Collapsible.close(close)
      this.collapsiableRef.current.M_Collapsible.open(open)
    }
  }

  fetchInvestor = async () => {
    try {
      const { user } = this.state
      const { data: investorInfo } = await getInvestor(user.investor.id)
      this.setState({ investor: investorInfo })
    } catch (error) {
      this.setState({ investor: null })
    }
  }

  submitNewInfo = () => {
    this.setState({ submitted: true })
  }

  render() {
    const { isLoading, investor } = this.state

    if (isLoading) {
      return <LoadingScreen />
    }

    return (
      <div className='container user-account-page'>
        <ContentWrapper>
          <ul className='collapsible' ref={this.collapsiableRef}>
            <UserPersonalDataForm
              investor={investor}
              changeTab={this.onUpdate}
              fetchInvestor={this.fetchInvestor}
              submitNewInfo={this.submitNewInfo}
            />
            <UserAddressForm
              investor={investor}
              changeTab={this.onUpdate}
              fetchInvestor={this.fetchInvestor}
              submitNewInfo={this.submitNewInfo}
            />
            <UserFinancialInfoForm
              investor={investor}
              changeTab={this.onUpdate}
              fetchInvestor={this.fetchInvestor}
              submitNewInfo={this.submitNewInfo}
            />
            <UserPersonalDocumentsForm
              investor={investor}
              changeTab={this.onUpdate}
              fetchInvestor={this.fetchInvestor}
              submitNewInfo={this.submitNewInfo}
            />
          </ul>
        </ContentWrapper>
      </div>
    )
  }
}

const ContentWrapper = styled.div`
  padding: 60px 0 120px 0;
`

const mapStateToProps = ({ currentUser, completeProfile }) => ({
  currentUser,
  completeProfile
})

export default connect(mapStateToProps, {
  fetchCurrentUser,
  checkCompleteProfile
})(UserAccountNew)
