import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'

import { BorderedButton } from './BorderedButton'
import { UploadIcon, ValidateIcon } from '../icons'

import { getInvestorDocumentUrl } from '../../services/v3'

const dropzoneStyleActive = {
  border: '1px solid green',
  opacity: '0.9'
}

class UploadPersonalDocument extends Component {
  state = {
    allowedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
    withDocument: false
  }

  componentDidMount() {
    const { field } = this.props
    this.setState({
      withDocument: !!field.document
    })
  }

  onDrop = async (acceptedFiles, _rejectedFiles) => {
    if (!acceptedFiles.length) return
    this.props.field.input.onChange(acceptedFiles[0])
  }

  showDocument = async e => {
    const {
      field: { document }
    } = this.props
    e.preventDefault()
    try {
      const {
        data: { url }
      } = await getInvestorDocumentUrl(document.investor_id, document.document_type_id)
      window.open(url, '_blank')
    } catch (error) {
      console.error(error)
    }
  }

  sendNewDocument = e => {
    e.preventDefault()
    this.setState({ withDocument: false })
  }

  render() {
    const { field } = this.props
    const { acceptedFiles, withDocument } = this.state

    return (
      <div className='user-uploads-wrapper'>
        {withDocument ? (
          <div className='dropzone dropzone-big flex-center-column'>
            <p className='doc-succes-message'>Você já enviou esse documento.</p>
            <div className='buttons-drop-container'>
              <BorderedButton
                className='button-show-document'
                onClick={this.showDocument}
                label='VISUALIZAR DOCUMENTO'
              />
              <BorderedButton onClick={this.sendNewDocument} label='ENVIAR NOVO DOCUMENTO' />
            </div>
          </div>
        ) : (
          <Fragment>
            <Dropzone
              onDrop={this.onDrop}
              className='dropzone dropzone-big flex-center-column'
              activeStyle={dropzoneStyleActive}
              multiple={false}
              accept={acceptedFiles}
            >
              <div className='flex-center-column'>
                {!field.input.value ? <UploadIcon /> : <ValidateIcon />}
                <span>{field.text || 'Arraste ou clique para fazer upload do seu documento'}</span>
              </div>
            </Dropzone>
            <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
              {field.meta.touched ? field.meta.error : ''}
            </span>
          </Fragment>
        )}
      </div>
    )
  }
}

export { UploadPersonalDocument }
