import React from 'react'
import { connect } from 'react-redux'

import CampaignNavigationOptions from '../components/CampaignNavigationOptions'
import CampaignUpdateForm from '../containers/CampaignUpdateForm'

const CampaignUpdatesNew = ({ history, match }) => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <CampaignUpdateForm history={history} match={match} />
        </div>
      </div>
    </div>
  )
}

export default connect()(CampaignUpdatesNew)
