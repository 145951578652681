import React from 'react'
import { format, parseISO } from 'date-fns'
import { InfoTemplate, StyledName } from './styles'

const Info = ({ IndicationInfo }) => {
  return (
    <InfoTemplate>
      <div style={{ gridArea: 'requested_amount' }}>
        <p>Valor Solicitado</p>
        <label>
          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(IndicationInfo.desired_amount)}
        </label>
      </div>

      <div style={{ gridArea: 'cnpj' }}>
        <p>CNPJ</p>
        <label>
          {!!IndicationInfo.cnpj
            ? IndicationInfo.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
            : ''}
        </label>
      </div>

      <div style={{ gridArea: 'request_date' }}>
        <p>Solicitado em</p>
        <label>{format(parseISO(IndicationInfo.created_at), 'dd/MM/yy')}</label>
      </div>
      <div style={{ gridArea: 'responsible' }}>
        <p>Responsável</p>
        <StyledName>{IndicationInfo.responsible}</StyledName>
      </div>
    </InfoTemplate>
  )
}

export default Info
