import React from 'react'
import { withRouter } from 'react-router-dom'

const FooterContainer = ({ history }) => {
  return (
    <footer>
      <div className='footer-container'>
        <div className='footer-content'>
          <div className='footer-brand'>
            <img src='/assets/img/shared/logo.png' alt='Kavod Logo' />
          </div>
          <div className='company-details'>
            <p>Al Santos, 455</p>
            <p>16 Andar, Cj 1606</p>
            <p>São Paulo, SP - 01419-000</p>
          </div>
          <div className='company-details'>
            <p>Kavod Lending Tecnologia e Serviços Ltda</p>
            <p>CNPJ 26.610.266/0001-40</p>
          </div>
          <div className='social-networks'>
            <a href='https://www.facebook.com/kavodlending/' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-facebook-f fa-2x' />
            </a>
            <a href='https://twitter.com/kavodlending' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-twitter fa-2x' />
            </a>
            <a
              href='https://www.youtube.com/channel/UCGwiK2YOwBPmx1vVz4PuqMQ'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-youtube fa-2x ' />
            </a>
            <a href='https://www.instagram.com/kavodlending/' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-instagram fa-2x' />
            </a>
            <a href='https://www.linkedin.com/company/kavod-lending/' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-linkedin-in fa-2x' />
            </a>
          </div>
        </div>
        <div className='footer-content partners-container'>
          <div className='footer-partners'>
            <p className='footer-title'>Acelerados por:</p>
            <img src='/assets/img/shared/startup.png' alt='startup farm' />
            <img src='/assets/img/shared/ahead.png' alt='ahead visa' />
          </div>
          <div className='footer-partners'>
            <p className='footer-title'>Correspondente bancário:</p>
            <img src='/assets/img/shared/bmp.png' alt='bmp money plus' />
            <img src='/assets/img/shared/parati.png' alt='Parati crédito bancário' />
          </div>
        </div>
        <div className='footer-content footer-links'>
          <p className='footer-title' onClick={() => history.push('/campanhas')}>
            Campanhas
          </p>
          <p className='footer-title' onClick={() => history.push('/investidor')}>
            Quero Investir
          </p>
          <p className='footer-title' onClick={() => history.push('/empresa')}>
            Quero Empréstimo
          </p>
          <p className='footer-title' onClick={() => history.push('/envie-seu-curriculo')}>
            Trabalhe conosco
          </p>
          <p className='footer-title' onClick={() => history.push('/contato')}>
            Contato
          </p>
          <p className='footer-title'>
            <a href='/parcerias' target='_blank' rel='noopener noreferrer'>
              Parcerias
            </a>
          </p>
        </div>
      </div>
      <div className='footer-about'>
        <p>
          <span>kavodlending.com</span> pertence à Kavod Lending Tecnologia e Serviços Ltda (“Kavod Lending”). A Kavod
          Lending não é uma instituição financeira e não realiza operações de crédito diretamente, mas um correspondente
          bancário da MONEY PLUS SOCIEDADE DE CREDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA (“BMP Money
          Plus”) nos termos do artigo 2°, da resolução CMN n°3.954, de 24 de fevereiro de 2011. Ouvidoria da instituição
          financeira: 0800 723 4787. Informações adicionais - Custo Efetivo Total: CET mínimo de 1,8% ao mês e máximo de
          7%. Exemplo - Empréstmo de R$ 100.000,00 para pagar em 2 anos (24 meses) - Total de 24 parcelas de R$
          5.349,40. Valor total a pagar igual a R$ 128.385,60 com uma taxa de juros de 1,81% ao mês (24.01% ao ano). CET
          de 2,1% ao mês(28,27% ano).
        </p>
        <p>
          A Kavod Lending Tecnologia e Serviços Ltda não realiza operações de financiamento e/ou investimento
          diretamente, tampouco realiza quaisquer outras operações privativas de instituições financeiras. Todos os
          financiamentos e/ou investimentos são realizados por meio da BMP Money Plus, devidamente autorizado pelo Banco
          Central do Brasil a realizar esse tipo de atividade.
        </p>
      </div>
    </footer>
  )
}

const Footer = withRouter(FooterContainer)
export { Footer }
