import React from 'react'
import { Modal } from '@material-ui/core'
import styled from 'styled-components'
import { Close as CloseIcon } from '@material-ui/icons'

const ModalEqSeedInvestment = ({ open, onClose }) => {
 
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <ModalContainer>
        <ModalCloseIcon data-tip='Fechar' onClick={onClose} />
          <Link href='https://www.eqseed.com/investir/kavod' target='_blank' rel='noopener noreferrer'>
            <Img src='/assets/img/home/eqseedInvestment.png' alt='Investir na Kavod'/>
          </Link>
      </ModalContainer>
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const ModalContainer = styled.div`
  max-width: 600px;
  max-height: 500px;
  overflow: auto;
  background-color: transparent;
  position: relative;
  outline: none;
  display: block;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 620px) {
    width: 450px;
    margin: 0 20px;
  }
`

const ModalCloseIcon = styled(CloseIcon)`
  color: #ffffff;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const Link = styled.a`
  max-width: 600px;
  max-height: 500px; 
`
const Img = styled.img`
  @media (max-width: 620px) {
    width: 100%;
  }
`

export default ModalEqSeedInvestment
