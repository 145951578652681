import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import publicIp from 'public-ip'

import { LoadingScreen } from '../components/commom'

import { formatMoney, bmpFormatNumber } from '../helpers'
import { getCurrentUserSigner, getCampaignById, getCampaignResponse, createEletronicSignature } from '../services/v3'

const BankCreditCertificateSignature = ({ match, history }) => {
  const [selectedAmount, setSelectedAmount] = useState(0)
  const [allSelected, setAllSelected] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [optionsBeforeSelectAll, setOptionsBeforeSelectAll] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [operations, setOperations] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setIsLoading(true)

        const [
          { data: signer },
          { data: campaign },
          {
            data: { usersProposal }
          }
        ] = await Promise.all([
          getCurrentUserSigner(),
          getCampaignById(match.params.campaignID),
          getCampaignResponse(match.params.campaignID)
        ])

        if (!signer) return history.push('/campanhas')

        const alreadySigned = signer.eletronic_signatures.some(
          ({ campaign_id }) => campaign_id === Number(match.params.campaignID)
        )

        if (alreadySigned) return history.push('/campanhas')

        const campaignValues = usersProposal.reduce(
          (acc, cur) => {
            return {
              operationValue: Number(acc.operationValue) + Number(bmpFormatNumber(cur.user.proposal.OriginalOrder)),
              netValue: Number(acc.netValue) + Number(bmpFormatNumber(cur.user.proposal.VlrSolicitado)),
              parcel: Number(acc.parcel) + Number(bmpFormatNumber(cur.user.proposal.VlrParcela)),
              debtAmount: Number(acc.debtAmount) + Number(bmpFormatNumber(cur.user.proposal.VlrParcela)),
              tacValue: Number(acc.tacValue) + Number(cur.user.proposal.VlrTAC),
              iofValue: Number(acc.iofValue) + Number(bmpFormatNumber(cur.user.proposal.VlrIOF))
            }
          },
          {
            operationValue: 0,
            netValue: 0,
            parcel: 0,
            debtAmount: 0,
            tacValue: 0,
            iofValue: 0
          }
        )

        const populatedCampaign = { ...campaign, campaignValues }
        setCampaign(populatedCampaign)
        setOperations(usersProposal)
        setCurrentUser(signer)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return history.push('/campanhas')
        }
        console.error(error.message)
        toast.error('Ocorreu um erro ao buscar as informações da campanha')
      } finally {
        setIsLoading(false)
      }
    }

    fetchInfos()
  }, [history, match.params.campaignID])

  const handleSelectedAmountChange = e => {
    if (e.target.checked) {
      setSelectedAmount(operations.length)
      setAllSelected(true)
      setOptionsBeforeSelectAll(selectedOptions)
      setSelectedOptions(operations)
    } else {
      setSelectedAmount(optionsBeforeSelectAll.length)
      setAllSelected(false)
      setSelectedOptions(optionsBeforeSelectAll)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)
      const ip = await publicIp.v4()
      const body = {
        ip,
        campaign_id: match.params.campaignID,
        signer_id: currentUser.id
      }
      await createEletronicSignature(body)
      toast.success("CCB'S Assinadas. Você está sendo redirecionado.")
      history.push('/contratos-para-assinar/')
    } catch (error) {
      console.error(error.message)
      toast.error('Ocorreu um erro ao assinar as CCBs, por favor tente novamente. ')
      setIsDisabled(false)
    }
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container>
      <SummaryCard>
        <Summary>Resumo dos dados da operação: </Summary>
        <CampaignInfo>
          <RowItem>
            <Text>Nome da empresa</Text>
            <Small>{campaign.name}</Small>
          </RowItem>
          <RowItem>
            <Text>Tipo de operação</Text>
            <Small>Financiamento</Small>
          </RowItem>
          <RowItem>
            <Text>Valor da operação</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.operationValue)}</Small>
          </RowItem>
          <RowItem>
            <Text>Valor líquido</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.netValue)}</Small>
          </RowItem>
          <RowItem>
            <Text>Parcela</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.parcel)}</Small>
          </RowItem>
          <RowItem>
            <Text>Valor da dívida</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.parcel * campaign.installments)}</Small>
          </RowItem>
          <RowItem>
            <Text>Número de parcelas</Text>
            <Small>{campaign.installments}</Small>
          </RowItem>
          <RowItem>
            <Text>TAC</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.tacValue)}</Small>
          </RowItem>
          <RowItem>
            <Text>IOF</Text>
            <Small>R$ {formatMoney(campaign.campaignValues.iofValue)}</Small>
          </RowItem>
        </CampaignInfo>
        <Row style={{ margin: '15px 5px' }}>
          <Text>
            <Strong>Total de CCB's Selecionadas:</Strong> {selectedAmount + '/' + operations.length}
          </Text>
        </Row>
        <Row>
          <FormControlLabel
            control={<Checkbox onChange={e => handleSelectedAmountChange(e)} color='primary' />}
            label='Selecionar todas para assinatura'
          />

          <Button
            disabled={!selectedAmount || isDisabled}
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            style={{ width: 200 }}
          >
            Assinar
          </Button>
        </Row>
      </SummaryCard>
      <Summary>
        CCB's com assinatura pendente: {operations.length - selectedAmount}/{operations.length}
      </Summary>
      <BankCreditCertificateCardsContainer>
        {operations.map(el => (
          <BankCreditCertificateCard disabled={allSelected} key={el.uuid}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardItem>
                <Text disabled={allSelected}>Valor do operação</Text>
                <Small disabled={allSelected}>R$ {formatMoney(el.user.proposal.OriginalOrder)}</Small>
              </CardItem>
              <CardItem>
                <Text disabled={allSelected}>Valor da dívida</Text>
                <Small disabled={allSelected}>
                  R${' '}
                  {formatMoney(Number(el.user.proposal.Prazo) * Number(el.user.proposal.VlrParcela.replace(',', '.')))}
                </Small>
              </CardItem>
            </div>
          </BankCreditCertificateCard>
        ))}
      </BankCreditCertificateCardsContainer>
    </Container>
  )
}

const CampaignInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 769px) {
    justify-content: center;
  }
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  width: 175px;
  @media (max-width: 769px) {
    width: 150px;
  }
`

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const BankCreditCertificateCard = styled.div`
  padding: 1em;
  margin: 0 5px;
  width: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  margin-bottom: 1em;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
`

const BankCreditCertificateCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 97%;
  @media (max-width: 769px) {
    width: 97%;
  }
`

const Small = styled.small`
  font-size: 0.8em;
  color: ${({ disabled }) => (disabled ? 'gray' : 'black')};
`

const Text = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? 'gray' : 'black')};
  @media (max-width: 769px) {
    font-size: 0.9em;
  }
`

const Strong = styled.strong`
  font-weight: bold;
`
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
`

const Summary = styled.p`
  font-size: 1.5em;
  font-weight: bold;
`

const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (max-width: 769px) {
    width: 90%;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export default withRouter(BankCreditCertificateSignature)
