import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: min-content;
  background: #ffffff;
  border: 1px solid #757575;
  border-radius: 4px;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    width: 100%;
    min-width: 260px;
  }

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #9e9e9e;
  }
  :focus {
    border-color: #076f9f;
    border-bottom: 0px !important;
    box-shadow: none !important;
  }

  input {
    min-width: 384px;
    width: 20%;
    margin-bottom: 15px;
    background: #ffffff;
    border: 1px solid #757575;
    border-bottom: none !important;
    margin: 0px 0 0 12px !important;
    border-radius: 4px;
    font-size: 16px;
    border: none !important;

    ::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #9e9e9e;
    }
    :focus {
      border-color: transparent;
      outline: none !important;
    }

    @media (max-width: 600px) {
      width: 100%;
      min-width: 260px;
    }
  }

  img {
    z-index: 2;
    margin-left: -23px;
    margin-bottom: 0px;
  }
`
