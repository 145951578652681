import React from 'react'

const Switch = ({ className, right, left, name, value, checked, onClick }) => (
  <div className={`switch ${className || ''}`}>
    <label>
      {right}
      <input type='checkbox' onChange={onClick} name={name} value={value} checked={checked} />
      <span className='lever' />
      {left}
    </label>
  </div>
)

export { Switch }
