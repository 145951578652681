import {
  FETCH_PLEDGES,
  CREATE_PLEDGE,
  UPDATE_PLEDGE,
  LOADING_PLEDGES,
  FETCH_ALL_PLEDGES_SUCCESS,
  LOADING_ALL_PLEDGES
} from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_PLEDGES:
    case FETCH_ALL_PLEDGES_SUCCESS:
      return { ...action.payload }
    case LOADING_PLEDGES:
    case LOADING_ALL_PLEDGES:
      return null
    case UPDATE_PLEDGE:
    case CREATE_PLEDGE:
      return state
    default:
      return state
  }
}
