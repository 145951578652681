import { isEmpty } from 'lodash'
import {
  FETCH_ADDRESS_FAIL,
  FETCH_ADDRESS_SUCCESS,
  FETCH_ADDRESS_LOADING,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_LOADING,
  UPDATE_ADDRESS_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  fail: false,
  data: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ADDRESS_SUCCESS:
    case FETCH_ADDRESS_SUCCESS:
      const data = isEmpty(action.payload) ? null : action.payload[0]
      return { fail: false, loading: false, data }

    case UPDATE_ADDRESS_LOADING:
    case FETCH_ADDRESS_LOADING:
      return { fail: false, loading: true, data: null }

    case UPDATE_ADDRESS_FAIL:
    case FETCH_ADDRESS_FAIL:
      return { fail: true, loading: false, data: null }

    default:
      return state
  }
}
