import React from 'react'

const IconContract = () => {
  return (
    <svg version='1.1' id='Layer_1' x='0px' y='0px'viewBox='0 0 230 230' >
      <rect x='90' y='102.6' transform='matrix(0.9708 -0.24 0.24 0.9708 -21.6547 31.4463)' style={{ fill: '#046F9F' }} width='56.5' height='4' />
      <rect x='90' y='114.6' transform='matrix(0.9708 -0.24 0.24 0.9708 -24.5198 31.7952)' style={{ fill: '#046F9F' }} width='56.5' height='4' />
      <rect x='130.6' y='84.8' transform='matrix(0.9708 -0.24 0.24 0.9708 -16.7935 35.7268)' style={{ fill: '#046F9F' }} width='15.3' height='4' />
      <rect x='92.6' y='132.5' transform='matrix(0.9708 -0.24 0.24 0.9708 -29.348 27.993)' style={{ fill: '#046F9F' }} width='15.3' height='4' />
      <rect x='92.6' y='142.5' transform='matrix(0.9708 -0.24 0.24 0.9708 -31.7562 28.2862)' style={{ fill: '#046F9F' }} width='15.3' height='4' />
      <rect x='130.6' y='75' transform='matrix(0.9708 -0.24 0.24 0.9708 -14.4386 35.44)' style={{ fill: '#046F9F' }} width='15.3' height='4' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,170.5h-2.8c-0.4,0-0.8-0.3-0.8-0.8V167c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,170.2,66,170.5,65.5,170.5z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,160.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,159.9,66,160.2,65.5,160.2z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,149.9h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,149.6,66,149.9,65.5,149.9z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,140.6h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,140.3,66,140.6,65.5,140.6z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,130.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,129.9,66,130.2,65.5,130.2z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,120.4h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,120,66,120.4,65.5,120.4z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,110.5h-2.8c-0.4,0-0.8-0.3-0.8-0.8V107c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,110.2,66,110.5,65.5,110.5z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,100.7h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,100.3,66,100.7,65.5,100.7z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,90.8h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,90.5,66,90.8,65.5,90.8z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,81h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,80.7,66,81,65.5,81z' />
      <path style={{ fill: '#42C7EF' }} d='M65.5,71.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C66.3,70.8,66,71.2,65.5,71.2z' />
      <path style={{ fill: '#42C7EF' }} d='M76,68.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8H76c0.4,0,0.8,0.3,0.8,0.8v2.8
	C76.7,67.7,76.4,68.1,76,68.1z' />
      <path style={{ fill: '#42C7EF' }} d='M85.8,65.9h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C86.6,65.6,86.3,65.9,85.8,65.9z' />
      <path style={{ fill: '#42C7EF' }} d='M95.6,63.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C96.4,62.7,96.1,63.1,95.6,63.1z' />
      <path style={{ fill: '#42C7EF' }} d='M105.7,60.3h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C106.5,60,106.1,60.3,105.7,60.3z' />
      <path style={{ fill: '#42C7EF' }} d='M115.4,58.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C116.2,57.8,115.8,58.1,115.4,58.1z' />
      <path style={{ fill: '#42C7EF' }} d='M125.4,55.4h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C126.2,55,125.8,55.4,125.4,55.4z' />
      <path style={{ fill: '#42C7EF' }} d='M136,53.2h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C136.8,52.9,136.4,53.2,136,53.2z' />
      <path style={{ fill: '#42C7EF' }} d='M145.5,51.1h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C146.3,50.7,145.9,51.1,145.5,51.1z' />
      <path style={{ fill: '#42C7EF' }} d='M176.6,135.2l-2-2c-0.3-0.3-0.3-0.8,0-1.1l2-2c0.3-0.3,0.8-0.3,1.1,0l2,2c0.3,0.3,0.3,0.8,0,1.1l-2,2
	C177.4,135.5,176.9,135.5,176.6,135.2z' />
      <path style={{ fill: '#42C7EF' }} d='M148.3,185h-2.8c-0.4,0-0.8-0.3-0.8-0.8v-2.8c0-0.4,0.3-0.8,0.8-0.8h2.8c0.4,0,0.8,0.3,0.8,0.8v2.8
	C149,184.6,148.7,185,148.3,185z' />
      <polygon style={{ fill: '#046F9F' }} points='53,159.3 49,159.3 49,57.2 98.6,45.9 99.4,49.8 53,60.4 ' />
      <polygon style={{ fill: '#046F9F' }} points='74.7,184.1 74.7,77.4 158.2,58.4 158.2,115.6 154.2,115.6 154.2,63.4 78.7,80.6 78.7,179.1
	113.5,170.9 114.5,174.8 ' />
      <g>
        <path style={{ fill: '#42C7EF' }} d='M146.4,125.3c7.2,0,13.8,2.7,18.8,7.2l2.9-2.8c-5.7-5.2-13.3-8.4-21.6-8.4c-17.7,0-32.2,14.4-32.2,32.2
		c0,15.4,10.9,28.4,25.5,31.5l0.4-4c-12.5-2.9-21.8-14.1-21.8-27.4C118.2,138,130.9,125.3,146.4,125.3z' />
        <path style={{ fill: '#42C7EF' }} d='M173.5,146c0.7,2.5,1,4.7,1,7.5c0,12.8-8.6,23.6-20.2,27l0.6,4c13.6-3.8,23.6-16.2,23.6-31
		c0-2.8-0.4-5.6-1.1-8.2L173.5,146z' />
      </g>
      <path style={{ fill: '#42C7EF' }} d='M141.7,170.4l-17.4-17.4l10.2-10.2l7.5,7.5l8.6-8.5c0.4-0.4,0.9-0.3,1.3,0l1.5,1.6c0.3,0.4,0.3,0.9,0,1.3
	L141.9,156l-7.4-7.4l-4.5,4.5l11.7,11.7l26.9-26.9c0.3-0.3,0.8-0.3,1.2,0l1.7,1.7c0.3,0.3,0.3,0.8,0,1.2L141.7,170.4z' />
    </svg>

  )
}

export { IconContract }
