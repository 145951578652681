import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from '../components/commom'
import { LoadingScreen } from '../components/commom'

import { formatDateFromBackEnd, formatDateFromBackEndComplete, formatCurrency } from '../helpers'
import { getPledges, getCurrentUser } from '../services/v3'

class PledgeReceivedPage extends Component {
  state = { loading: false, fetching: true, pledges: [], currentUser: null }

  componentWillMount() {
    const { currentInvestment, history } = this.props

    const fetchInfos = async () => {
      try {
        this.setState({ fetching: true })
        const { data: user } = await getCurrentUser()

        const { data: pledges } = await getPledges({
          campaign_id: currentInvestment.campaign.id,
          investor_id: user.investor.id
        })

        this.setState({
          pledges: pledges.sort((a, b) => b.id - a.id),
          currentUser: user
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.setState({ fetching: false })
      }
    }

    if (!currentInvestment.campaign) {
      return history.push('/campanhas')
    }

    fetchInfos()
  }

  renderContent = lastInvest =>
    this.state.pledges.map(pledge =>
      pledge.id !== lastInvest ? (
        <tr key={pledge.id}>
          <td>
            <span style={{ color: '#bfbfbf' }}>
              Pedido #{pledge.id} - Realizado em {formatDateFromBackEnd(pledge.created_at)}
            </span>
          </td>
          <td>
            <span style={{ color: '#bfbfbf' }}>{formatCurrency(pledge.amount)}</span>
          </td>
        </tr>
      ) : (
        <tr key={pledge.id} />
      )
    )

  render() {
    const { currentInvestment } = this.props
    const { loading, pledges, fetching, currentUser } = this.state

    if (fetching) {
      return <LoadingScreen />
    }

    const lastPledge = pledges.length !== 0 ? pledges[0] : null

    return (
      <section>
        <div className='container' style={{ maxWidth: '1024px', marginTop: '80px' }}>
          <h4>Parabéns</h4>
          <p>Sua solicitação de investimento foi recebida com sucesso.</p>
          <h6>Fique atento ao seu e-mail, em breve você receberá informações dos próximos passos.</h6>
          <br />
          <br />
          <ul className={'row'}>
            <li className={'col col-3'} style={{ borderRight: 'black 1px solid' }}>
              Número da solicitação:
              <span style={{ fontWeight: 700 }}>{lastPledge.id || ''}</span>
            </li>
            <li className={'col col-3'} style={{ borderRight: 'black 1px solid' }}>
              Data:{' '}
              <span style={{ fontWeight: 700 }}>
                {pledges.length !== 0 ? formatDateFromBackEndComplete(pledges[0].created_at) : ''}
              </span>
            </li>
            <li className={'col col-3'} style={{ borderRight: 'black 1px solid' }}>
              Email: <span style={{ fontWeight: 700 }}>{currentUser.email}</span>
            </li>
            <li className={'col col-3'}>
              Valor investido:{' '}
              <span style={{ fontWeight: 700 }}>
                <span>
                  <span>R$</span>&nbsp;
                  {lastPledge ? formatCurrency(lastPledge.amount) : ''}
                </span>
              </span>
            </li>
          </ul>
          <h4>Detalhes</h4>
          <table>
            <thead>
              <tr>
                <th>Campanha - {currentInvestment ? currentInvestment.campaign.name : ''}</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>{lastPledge ? this.renderContent(lastPledge.id) : this.renderContent()}</tbody>
            <tfoot>
              <tr>
                <td>Novo Pedido #{lastPledge.id || ''}</td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    <span>{formatCurrency(currentInvestment.amount)}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <th>Valor total do investimento</th>
                <th>
                  <span style={{ fontWeight: 700 }}>
                    <span>
                      <span>{formatCurrency(pledges.reduce((acc, { amount }) => acc + amount, 0))}</span>
                    </span>
                  </span>
                </th>
              </tr>
            </tfoot>
          </table>
          <div>
            <ul>
              <li>
                <div>
                  <p>
                    Ao término da captação, você receberá instruções para enviar a TED/DOC para nosso(a)
                    banco/financeira parceiro(a).
                  </p>
                </div>
              </li>
            </ul>
            <Link className='sidenav-close' to='/campanhas'>
              <Button disabled={loading} text='Retornar a campanhas' type='submit' align='right-align' />
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ currentInvestment }) => ({
  currentInvestment
})

export default connect(mapStateToProps)(PledgeReceivedPage)
