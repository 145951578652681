import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'

import { checkIsAdmin, checkIsPartner } from '../helpers/users'

const PrivateRoute = props => {
  const { currentUser, path } = props
  if (!currentUser.authenticated && !currentUser.loading) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }
  if (currentUser.authenticated && /(admin)/.test(path) && !checkIsAdmin(currentUser)) {
    return <Redirect to={{ pathname: '/' }} />
  }

  if (
    currentUser.authenticated &&
    /(indicacao-parceria)/.test(path) &&
    !(checkIsPartner(currentUser) || checkIsAdmin(currentUser))
  ) {
    toast.info(
      'Olá, você já é usuário Kavod, mas ainda não se cadastrou como parceiro.  Complete o seu cadastro para começar a indicar empresas.',
      { autoClose: 7000 }
    )
    return <Redirect to={{ pathname: '/parcerias' }} />
  }

  return window.localStorage.getItem('kavodJWT') ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser
})

export default connect(mapStateToProps)(withRouter(PrivateRoute))
