import React from 'react'

const Arrow = () => {
  return (
    <svg version='1.1' id='Layer_1' x='0px' y='0px'viewBox='0 0 87 48'>
      <g>
        <path style={{ fill: '#1BD94F' }} d='M79.6,21.7L61,6.2c-1-0.7-2.5-0.6-3.3,0.3l0,0c-0.8,0.9-0.6,2.3,0.4,3l12.1,11.3H39.6c-1.2,0-2.2,1.2-2.2,2.7
            l0,0c0,1.5,1,2.7,2.2,2.7h30.7L59.6,37.3c-1,0.8-1.1,2.1-0.3,3s2.3,1,3.3,0.2L79.7,25C80.8,24.2,80.8,22.6,79.6,21.7z' />
        <path style={{ fill: '#1BD94F' }} d='M29.4,20.9h-6.3c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7h6.3c1.5,0,2.7-1.2,2.7-2.7S30.9,20.9,29.4,20.9z' />
        <path style={{ fill: '#1BD94F' }} d='M11.3,20.9c-1.6,0-2.8,1-2.8,2.2V24c0,1.2,1.3,2.2,2.8,2.2c1.6,0,2.8-1,2.8-2.2v-0.9
            C14.2,21.9,12.9,20.9,11.3,20.9z' />
      </g>
    </svg>
  )
}

export { Arrow }
