import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'

import {
  Input,
  Datepicker,
  Button,
  CurrencyInput,
  Timepicker,
  DisplayFormErrors,
  LoadingScreen,
  Select,
  InputMask
} from '../components/commom'

import { campaignInformationFormValidator } from '../validators'
import {
  dateToString,
  formatDate,
  validateURL,
  getAnnualRate,
  getEndDate,
  getMaxValue,
  getQueryParameter
} from '../helpers'
import { fetchCampaign, formSubmited, fetchCompanies } from '../actions'
import {
  getCompanyInfo,
  createCampaign,
  getCampaignTypes,
  getCampaignStatuses,
  getCampaignById,
  updateCampaign
} from '../services/v3'
import { campaignTypes, minInvestmentAmount } from '../constants'

class CampaignInformationForm extends Component {
  state = {
    form: {
      name: '',
      url: '',
      publication_date: '',
      publication_time: '',
      start_date: moment().format('MMM DD, YYYY'),
      start_time: '',
      end_date: getEndDate(moment()),
      end_time: '23:59',
      amount: 0,
      rate_month: parseFloat(0).toFixed(2),
      installments: '',
      grace_period: 0,
      TAC: 6,
      CDI: '',
      max_amount: 0,
      min_amount: minInvestmentAmount.number,
      company_name: '',
      cnpj: '',
      type: ''
    },
    annualRate: 0,
    errors: [],
    urlPrefix: 'kavodlending.com/campanhas/',
    isLoading: true,
    typeCampaign: '',
    campaignId: null,
    campaignTypes: []
  }

  async componentDidMount() {
    this.setState({ isLoading: true })

    if (this.props.history && this.props.history.location) {
      const companyName = getQueryParameter(this.props.history.location.search, 'company_name')
      const companyCnpj = getQueryParameter(this.props.history.location.search, 'cnpj')
      const desiredAmount = getQueryParameter(this.props.history.location.search, 'desired_amount')
      this.setState({
        form: {
          ...this.state.form,
          cnpj: companyCnpj || '',
          company_name: companyName || '',
          amount: desiredAmount * 100 || '',
          ...(desiredAmount ? { max_amount: getMaxValue(desiredAmount * 100) } : null)
        }
      })
    }

    const { data: campaignTypes } = await getCampaignTypes()
    this.setState({ campaignTypes })

    if (!this.props.match) {
      this.setState({ isLoading: false })
      setTimeout(() => window.M.updateTextFields(), 500)
      return
    }

    const campaignID = this.props.match.params.campaignURL

    if (campaignID) {
      try {
        const { data: campaign } = await getCampaignById(campaignID)
        this.setState({
          campaignId: campaignID,
          annualRate: getAnnualRate(campaign.rate_month),
          form: {
            name: campaign.name,
            url: campaign.url,
            publication_date: campaign.publication_date
              ? moment.utc(campaign.publication_date).format('MMM DD, YYYY')
              : '',
            publication_time: campaign.publication_date ? moment.utc(campaign.publication_date).format('HH:mm') : '',
            start_date: campaign.start_date ? moment.utc(campaign.start_date).format('MMM DD, YYYY') : '',
            start_time: campaign.start_date ? moment.utc(campaign.start_date).format('HH:mm') : '',
            end_date: campaign.end_date ? moment.utc(campaign.end_date).format('MMM DD, YYYY') : '',
            end_time: campaign.end_date ? moment.utc(campaign.end_date).format('HH:mm') : '',
            amount: campaign.amount * 100,
            rate_month: campaign.rate_month.toFixed(2),
            installments: campaign.installments,
            grace_period: campaign.grace_period,
            TAC: campaign.TAC ? campaign.TAC.toFixed(2) : '6.00',
            CDI: '',
            max_amount: campaign.max_amount * 100,
            min_amount: campaign.min_amount * 100,
            company_name: campaign.company_id ? campaign.company.name : '',
            cnpj: campaign.company_id ? campaign.company.cnpj : '',
            type: campaign.campaign_type_id
          },
          typeCampaign: this.handleTypeCampaign(campaign.campaign_type_id)
        })
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar a campanha')
      } finally {
        this.setState({ isLoading: false })
        setTimeout(() => window.M.updateTextFields(), 500)
      }
    }
  }

  isInt = num => {
    return parseInt(num) === num
  }

  formatFloat = number => {
    const integer = number.toString().slice(0, -2)
    const decimal = (number % 100).toString().padStart(2, '0')
    const stringNumber = `${integer}.${decimal}`
    return parseFloat(stringNumber)
  }

  handleSubmit = async e => {
    if (e) e.preventDefault()

    const { data: campaignStatuses } = await getCampaignStatuses()
    const activeCampaignType = campaignStatuses.find(({ abbreviation }) => abbreviation === 'A')
    if (!activeCampaignType) throw new Error('Não foram encontrados os status da campanha')

    let formValidation = campaignInformationFormValidator(this.state.form)
    if (!formValidation.isValid) {
      return this.setState({ errors: formValidation.errors })
    }

    const { history, formSubmited } = this.props
    delete this.state.form.image_url

    const body = {
      ...this.state.form,
      rate_anual: Number(this.state.annualRate.replace(',', '.')),
      campaign_type_id: this.state.form.type,
      rate_month: +this.state.form.rate_month,
      publication_date: moment.utc(this.state.form.publication_date + ' ' + this.state.form.publication_time).format(),
      start_date: moment.utc(this.state.form.start_date + ' ' + this.state.form.start_time).format(),
      end_date: moment.utc(this.state.form.end_date + ' ' + this.state.form.end_time).format(),
      amount: this.formatFloat(this.state.form.amount),
      max_amount: this.formatFloat(this.state.form.max_amount),
      min_amount: this.formatFloat(this.state.form.min_amount),
      grace_period: parseInt(this.state.form.grace_period),
      TAC: Number(this.state.form.TAC),
      cnpj: this.state.form.cnpj.replace(/\D/g, ''),
      published: true
    }
    
    delete body.CDI
    delete body.publication_time
    delete body.start_time
    delete body.end_time
    delete body.type

    try {
      const { campaignId } = this.state
      if (campaignId) {
        await updateCampaign(campaignId, body)
        formSubmited({ message: 'Campanha atualizada' })
      } else {
        const { data: createdCampaign } = await createCampaign({ ...body, campaign_status_id: activeCampaignType.id })
        formSubmited({ message: 'Campanha criada' })
        history.push(`/admin/campanhas/${createdCampaign.id}/informacoes`)
      }
    } catch {
      formSubmited({ message: 'Tivemos problemas ao submeter o formulário' })
    }
  }

  onRateInputClick = event => {
    if (event.target.value < 1) {
      event.target.value = ''
    }
  }

  onRateInputChange = event => {
    if (event.target.value.length > 4) return false
    if (event.target.value > 10) event.target.value = event.target.value / 10
    if (event.target.value === 10) {
      event.target.value = parseFloat(event.target.value / 10).toFixed(1)
    }

    let form = this.state.form
    form[event.target.name] = event.target.value
    this.setState({ form, annualRate: getAnnualRate(event.target.value) })
  }

  onTACRateInputChange = event => {
    if (event.target.value < 0) return false
    if (event.target.value.length > 5) return false
    if (event.target.value > 100) event.target.value = event.target.value / 10
    if (event.target.value === 10) {
      event.target.value = parseFloat(event.target.value).toFixed(2)
    }

    let form = this.state.form
    form[event.target.name] = event.target.value
    this.setState({ form })
  }

  fetchCNPJ = async (cnpj, form) => {
    try {
      const companyInfo = await getCompanyInfo(cnpj)
      form.company_name = companyInfo.nome
      this.setState({ form })
      window.M.updateTextFields()
    } catch (error) {
      toast.error(error.message)
    }
  }

  onInputChange = event => {
    let form = this.state.form
    form[event.target.name] = event.target.value
    this.setState({ form })
    if (event.target.name === 'cnpj') {
      const cnpj = event.target.value.replace(/\D/g, '')
      if (cnpj.length === 14) this.fetchCNPJ(cnpj, form)
    }
  }

  onURLInputChange = event => {
    const value = ['1', '2'].includes(String(this.state.form.type))
      ? event.target.value.split(this.state.typeCampaign)[1]
      : event.target.value.split(this.state.urlPrefix)[1]

    let form = this.state.form
    form[event.target.name] = validateURL(value)
    this.setState({ form })
  }

  handleSelectChange = event => {
    this.setState({
      form: { ...this.state.form, type: event.target.value },
      typeCampaign: this.handleTypeCampaign(Number(event.target.value))
    })
  }

  onAmountChange = value => {
    this.setState({ form: { ...this.state.form, amount: value, max_amount: getMaxValue(value) } })
  }
  onMaxAmountChange = value => {
    this.setState({ form: { ...this.state.form, max_amount: value } })
  }
  onMinAmountChange = value => {
    this.setState({ form: { ...this.state.form, min_amount: value } })
  }

  onDatepickerChange = (event, field) => {
    let form = this.state.form
    form[field] = dateToString(event)
    this.setState({ form })
  }

  onStartDatepickerChange = (event, field) => {
    let form = this.state.form
    form[field] = dateToString(event)
    this.setState({ form: { ...this.state.form, end_date: getEndDate(form[field]) } })
  }

  onTimepickerChange = (_hour, _minute, field) => {
    let hour = formatDate(_hour)
    let minute = formatDate(_minute)
    let form = this.state.form
    form[field] = `${hour}:${minute}`
    this.setState({ form })
  }

  onCompanyChange = event => {
    this.setState({
      form: { ...this.state.form, company_id: event.target.value }
    })
  }

  handleTypeCampaign = type => {
    if (type === campaignTypes.AVAL) return 'com-aval/'
    else if (type === campaignTypes.AVAL_E_GARANTIA) return 'com-aval-e-garantia/'
    else return ''
  }

  handleLabelEndDate = _ => {
    const difference = moment(this.state.form.end_date).diff(moment(this.state.form.start_date), 'days')
    return difference === 1 ? `${difference} dia` : `${difference} dias`
  }

  render() {
    const {
      form: { amount, max_amount, min_amount }
    } = this.state
    if (!this.props.campaign || this.state.isLoading) {
      return <LoadingScreen />
    }
    return (
      <form className='row' onSubmit={this.handleSubmit}>
        <InputMask
          onInputChange={this.onInputChange}
          value={this.state.form.cnpj || ''}
          field='cnpj'
          type='text'
          label='CNPJ'
          error='Valor inválido'
          className='col s12 m3'
          mask={[
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/
          ]}
        />
        <Input
          onInputChange={this.onInputChange}
          value={this.state.form.company_name || ''}
          field='company_name'
          type='text'
          label='Empresa'
          error='Valor inválido'
          className='col s12 m9'
        />
        <Select
          title='Tipo de campanha'
          value={this.state.form.type}
          className='col s12 m3'
          onInputChange={this.handleSelectChange}
        >
          {this.state.campaignTypes.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>
        <Input
          onInputChange={this.onInputChange}
          value={this.state.form.name || ''}
          field='name'
          type='text'
          label='Título'
          error='Nome inválido'
          className='col s12 m9'
        />
        <Input
          onInputChange={this.onURLInputChange}
          value={`${this.state.urlPrefix}${this.state.typeCampaign}${this.state.form.url || ''}`}
          field='url'
          type='text'
          label='URL'
          placeholder='kavodlending.com/campanhas/nome-da-campanha'
          error='Nome inválido'
          className='col s12'
        />
        <CurrencyInput
          onInputChange={this.onAmountChange}
          value={amount || 0}
          field='amount'
          label='Meta da captação'
          error='Valor inválido'
          className='col s12 m4'
        />
        <CurrencyInput
          onInputChange={this.onMinAmountChange}
          value={min_amount || 0}
          field='min_amount'
          label='Valor mínimo'
          error='Valor inválido'
          className='col s12 m4'
        />
        <CurrencyInput
          onInputChange={this.onMaxAmountChange}
          value={max_amount || 0}
          field='max_amount'
          label='Valor máximo'
          error='Valor inválido'
          className='col s12 m4'
        />
        <Input
          onInputChange={this.onRateInputChange}
          onInputClick={this.onRateInputClick}
          value={this.state.form.rate_month || ''}
          field='rate_month'
          type='number'
          label='Taxa mensal'
          step={0.01}
          error='Valor inválido'
          className='col s12 m3'
          placeholder='Ex: 1,25'
        />
        <Input
          onInputChange={this.onRateInputChange}
          value={this.state.annualRate}
          field='CDI'
          disabled
          type='text'
          label='Taxa anual'
          step='0.10'
          error='Valor inválido'
          className='col s12 m3'
        />
        <Input
          onInputChange={this.onInputChange}
          value={this.state.form.installments || ''}
          field='installments'
          type='number'
          min={0}
          max={100}
          label='Número de parcelas'
          error='Valor inválido'
          className='col s12 m2'
          placeholder='Ex: 24'
        />
        <Input
          onInputChange={this.onInputChange}
          value={this.state.form.grace_period}
          field='grace_period'
          type='number'
          min={0}
          max={100}
          label='Período de carência'
          error='Valor inválido'
          className='col s12 m2'
          placeholder='Ex: 90'
        />
        <Input
          onInputChange={this.onTACRateInputChange}
          value={this.state.form.TAC || ''}
          field='TAC'
          type='number'
          label='TAC'
          step={0.01}
          error='Valor inválido'
          className='col s12 m2'
          placeholder='Ex: 6.00'
        />
        <Datepicker
          name='publication_date'
          onDatepickerChange={this.onDatepickerChange}
          value={this.state.form.publication_date || ''}
          label='Data de publicação'
          className='col s12 m2'
        />
        <Timepicker
          name='publication_time'
          onTimepickerChange={this.onTimepickerChange}
          value={this.state.form.publication_time || ''}
          label='Horário de publicação'
          className='col s12 m2'
        />
        <Datepicker
          name='start_date'
          onDatepickerChange={this.onStartDatepickerChange}
          value={this.state.form.start_date || ''}
          label='Data de abertura'
          className='col s12 m2'
        />
        <Timepicker
          name='start_time'
          onTimepickerChange={this.onTimepickerChange}
          value={this.state.form.start_time || ''}
          label='Horário de abertura'
          className='col s12 m2'
        />
        <Datepicker
          name='end_date'
          onDatepickerChange={this.onDatepickerChange}
          value={this.state.form.end_date || ''}
          label={`Data de término (${this.handleLabelEndDate()}) `}
          className='col s12 m2'
        />
        <Timepicker
          name='end_time'
          onTimepickerChange={this.onTimepickerChange}
          value={this.state.form.end_time || ''}
          label='Horário de término'
          className='col s12 m2'
        />
        <DisplayFormErrors className='col s12' errors={this.state.errors} />
        <div className='col s12'>
          <div className='dfp'>
            <Button text='SALVAR' type='submit' />
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = ({ campaign, companies }) => ({ campaign, companies })

export default connect(mapStateToProps, {
  updateCampaign,
  fetchCampaign,
  formSubmited,
  fetchCompanies
})(CampaignInformationForm)
