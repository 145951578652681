import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { minInvestmentAmount } from '../constants'

const CampaignsOptions = ({ history }) => {
  return (
    <CardsContainer>
      <Card onClick={_ => history.push('/campanhas/com-aval')}>
        <Image backgroundImage='/assets/img/campaigns/aval.png'>
          <p>Aval</p>
        </Image>
        <CardBody>
          <Caption>Características</Caption>
          <List listStyle='disc'>
            <ListItem>Investimento mínimo de {minInvestmentAmount.textSmall}</ListItem>
            <ListItem>Campanhas com Aval dos Sócios</ListItem>
          </List>
        </CardBody>
      </Card>
      <Card onClick={_ => history.push('/campanhas/com-aval-e-garantia')}>
        <Image backgroundImage='/assets/img/campaigns/aval-garantias.png'>
          <p>Aval</p>
          <p>+</p>
          <p>Garantias Reais</p>
        </Image>
        <CardBody>
          <Caption>Características</Caption>
          <List listStyle='disc'>
            <ListItem>Investimento mínimo de {minInvestmentAmount.textSmall}</ListItem>
            <ListItem>Campanha com Aval dos Sócios</ListItem>
            <ListItem>
              Campanhas com garantias reais
              <List>
                <li> - Aplicação financeira</li>
                <li> - Recebíveis de cartão / Boleto</li>
                <li> - Duplicatas</li>
                <li> - Etc..</li>
              </List>
            </ListItem>
          </List>
        </CardBody>
      </Card>
    </CardsContainer>
  )
}

const ListItem = styled.li`
  margin-bottom: 1.1em;
`

const List = styled.ul`
  padding-left: 1em !important;
  padding-bottom: 1em;
  margin: 0;
  li {
    list-style-type: ${({ listStyle }) => listStyle || 'none'} !important;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.2em;
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
  }
`

const Caption = styled.p`
  font-weight: bold;
  color: #076f9f;
`

const Card = styled.div`
  cursor: pointer;
  height: auto;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.5);
  @media (max-width: 769px) {
    margin-bottom: 2em;
  }
`

const Image = styled.div`
  ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 200px 200px 121px 200px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  p {
    margin: 0;
    transition: all 0.3s;
  }

  &:hover {
    box-shadow: inset 200px 200px 121px 200px rgba(0, 0, 0, 0.8);
    p {
      transform: scale(1.3);
    }
  }
  transition: all 0.3s;
`

export default withRouter(CampaignsOptions)
