import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'

import { LoadingScreen } from '../components/commom'
import { CampaignPageGallery } from '../components/CampaignPageGallery'
import CampaignPageAmount from './CampaignPageAmount'
import CampaignProfileImg from '../components/CampaignProfileImg'

import {
  formatDateFromBackEndComplete,
  scrollElementToTop,
  checkIsCompletedInvestor,
  getOldStatus,
  getQueryParameter,
  checkIsAdmin
} from '../helpers'

import { getCampaigns, getCampaignById, getPhotos, getPledges, getCurrentUser } from '../services/v3'
import { campaignStatuses, pledgeStatuses, photoTypes } from '../constants'

const closedOptions = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION]
const pledgeAccOptions = [pledgeStatuses.ACCEPTED, pledgeStatuses.ACTIVE]

class CampaignPage extends Component {
  state = {
    activeNavLink: 'aboutCompany',
    sumOrders: 0,
    campaign: null,
    loading: true,
    completeProfile: false,
    profilePhoto: null,
    galleryPhotos: [],
    sumInvestorOrders: 0,
    currentUser: null
  }

  async componentDidMount() {
    const fetchInfos = async () => {
      const { campaignURL } = this.props.match.params

      this.setState({ loading: true })
      let campaign = null
      try {
        if (this.props.match.path === '/admin/campanhas/:campaignURL/draft') {
          const { data: campaignById } = await getCampaignById(campaignURL)
          campaign = campaignById
        } else {
          const {
            data: [campaignByUrl]
          } = await getCampaigns({ url: campaignURL })
          campaign = campaignByUrl
        }

        const { data: currentUser } = await getCurrentUser()
        const [completeProfile, { data: campaignPhotos }, { data: investorCampaignPledges }] = await Promise.all([
          checkIsCompletedInvestor(),
          getPhotos({ campaign_id: campaign.id }),
          getPledges({
            campaign_id: campaign.id,
            investor_id: currentUser.investor.id
          })
        ])

        let sumInvestorOrders = 0
        if (currentUser.investor) {
          sumInvestorOrders = investorCampaignPledges
            .filter(({ pledge_status: { abbreviation } }) => pledgeAccOptions.includes(abbreviation))
            .reduce((acc, { amount }) => acc + amount, 0)

          this.setState({ sumInvestorOrders, currentUser })
        }

        const profilePhoto = campaignPhotos.find(
          ({ campaign_photo_type }) => campaign_photo_type && campaign_photo_type.abbreviation === photoTypes.COVER
        )

        const galleryPhotos = campaignPhotos
          .filter(
            ({ campaign_photo_type }) => campaign_photo_type && campaign_photo_type.abbreviation === photoTypes.GALLERY
          )
          .sort((a, b) => Number(a.photo_order) - Number(b.photo_order))
          .map((photo, index) => ({ ...photo, galleryId: index }))

        this.setState({
          campaign,
          completeProfile,
          profilePhoto,
          galleryPhotos: galleryPhotos || [],
          sumOrders: campaign.total_captured
        })

        if (
          this.props.location &&
          getQueryParameter(this.props.location.search, 'section') === 'updates' &&
          campaign.campaign_updates.length > 0 &&
          sumInvestorOrders !== 0
        ) {
          this.setState({ activeNavLink: 'campaignUpdates' })
          this.changeNavLink(null, 'campaignUpdates')
        }
      } catch (error) {
        this.props.history.push('/campanhas')
        console.error(error)
        toast.error('Ocorreu um erro ao buscar a campanha')
      } finally {
        this.setState({ loading: false })
      }
    }
    fetchInfos()
  }

  changeNavLink = (e, link) => {
    if (e) e.preventDefault()
    this.setState({ activeNavLink: link })
    setTimeout(() => {
      let element = document.getElementById(link === 'aboutCompany' ? 'campaign-info-desc' : 'campaign-updates')
      if (element) {
        scrollElementToTop(element)
      }
    }, 300)
  }

  splitDescription(text, tag = 'SPLIT-DESCRIPTION-TAG', part = 0) {
    if (text) {
      let splittedText = text.split(tag)[part]
      return splittedText
    }
  }

  renderCampaignUpdatesLink = () => {
    const { activeNavLink, campaign, sumInvestorOrders } = this.state

    if (campaign.campaign_updates.length <= 0 || (!checkIsAdmin(this.state.currentUser) && sumInvestorOrders === 0)) {
      return null
    }

    return (
      <li className={activeNavLink === 'campaignUpdates' ? 'campaign-page-info-current' : null}>
        <a
          className={
            activeNavLink === 'campaignUpdates'
              ? 'campaign-page-active-link nav-campaign-page-info-menu-link'
              : 'nav-campaign-page-info-menu-link'
          }
          href='#campaign-updates'
          onClick={e => this.changeNavLink(e, 'campaignUpdates')}
        >
          Atualizações
        </a>
      </li>
    )
  }

  renderCampaignUpdates() {
    const { campaign_updates } = this.state.campaign
    return (
      <div id='campaign-updates'>
        <ul className='campaign-page-update-list'>
          {campaign_updates
            .sort((a, b) => moment(a.datetime).diff(moment(b.datetime)))
            .map(update => (
              <li key={update.id} className='campaign-page-update-list-item'>
                <span className='campaign-page-update-round-marker' onClick={e => scrollElementToTop(e)} />
                <h5 className='campaign-page-update-title center-align' onClick={e => scrollElementToTop(e)}>
                  {formatDateFromBackEndComplete(update.datetime)}
                </h5>

                <h5>{update.title}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: update.description
                  }}
                />
              </li>
            ))}
        </ul>
      </div>
    )
  }

  renderCampaignInfoDesc() {
    const { galleryPhotos, campaign } = this.state
    const statusCampaign = getOldStatus(campaign.campaign_status.abbreviation)

    return (
      <div id='campaign-info-desc' className='react-quill'>
        <div
          dangerouslySetInnerHTML={{
            __html: this.splitDescription(campaign.description, 'SPLIT-DESCRIPTION-TAG', 0)
          }}
        />
        <div id='campaign-gallery'>
          <CampaignPageGallery campaign={campaign} campaignGallery={galleryPhotos} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.splitDescription(campaign.description, 'SPLIT-DESCRIPTION-TAG', 1)
          }}
        />
        {campaign.rating_url && !closedOptions.includes(statusCampaign) && (
          <div>
            <p>
              <span className='ql-size-large'>Credit Rating Serasa</span> -{' '}
              <a rel='noopener noreferrer' target='_blank' href={campaign.rating_url}>
                Clique aqui para baixar
              </a>
            </p>
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: this.splitDescription(campaign.about_company, 'SPLIT-DESCRIPTION-TAG', 0)
          }}
        />
      </div>
    )
  }

  render() {
    const { match, history } = this.props
    const { campaign, profilePhoto, loading, sumOrders, activeNavLink, completeProfile, sumInvestorOrders } = this.state

    if (loading) {
      return <LoadingScreen />
    }

    return (
      <div className='row'>
        <div className='campaign-page-font col s10 push-s1' style={{ marginTop: '50px' }}>
          <div>
            <div className='row'>
              <div className='col s12 l5'>
                <CampaignProfileImg campaign={campaign} image={profilePhoto} />
              </div>
              <CampaignPageAmount
                campaign={campaign}
                history={history}
                sumOrders={sumOrders}
                sumInvestorOrders={sumInvestorOrders}
                completeProfile={completeProfile}
                match={match}
              />
            </div>
            <div>
              <ul className='nav-campaign-page-info-menu'>
                <li className={activeNavLink === 'aboutCompany' ? 'campaign-page-info-current' : null}>
                  <a
                    className={
                      activeNavLink === 'aboutCompany'
                        ? 'campaign-page-active-link nav-campaign-page-info-menu-link'
                        : 'nav-campaign-page-info-menu-link'
                    }
                    href='#campaign-info-desc'
                    onClick={e => this.changeNavLink(e, 'aboutCompany')}
                  >
                    Sobre a empresa
                  </a>
                </li>
                {this.renderCampaignUpdatesLink()}
              </ul>
            </div>
            <div className='additional-information'>
              {activeNavLink === 'aboutCompany' ? this.renderCampaignInfoDesc() : this.renderCampaignUpdates()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(CampaignPage)
