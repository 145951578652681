import React from 'react'
import PropTypes from 'prop-types'

import { Row } from '../../../components/v2Components'
import { MaterialDrawer, Close, TitleContainer } from './styles'

const Drawer = ({ anchor, open, onClose, children, title, iconClose, titleContainer, ...props }) => {
  return (
    <MaterialDrawer anchor={anchor} open={open} onClose={onClose} BackdropProps={{ invisible: true }} {...props}>
      <Row alignItems='center' justifyContent='space-between'>
        <TitleContainer>
          {title && <h4>{title}</h4>}
          {titleContainer}
        </TitleContainer>
        {iconClose && <Close onClick={onClose} />}
      </Row>
      {children}
    </MaterialDrawer>
  )
}

Drawer.defaultProps = {
  anchor: 'right',
  iconClose: false
}

Drawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  iconClose: PropTypes.bool,
  title: PropTypes.string,
  titleContainer: PropTypes.node
}

export default Drawer
