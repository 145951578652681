import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import numbro from 'numbro'
import { toast } from 'react-toastify'

import { LoadingScreen } from '../components/commom'

import { simulateInvestment } from '../services/v3'
import { formatMoney } from '../helpers'

const CampaignReturnSimulator = ({ campaign, investmentValue }) => {
  const [loading, setLoading] = useState(true)
  const [simulation, setSimulation] = useState({})

  useEffect(() => {
    const fetchSimulationInfos = async () => {
      try {
        setLoading(true)
        const body = {
          amount: investmentValue,
          campaign_id: campaign.id
        }
        const { data: investmentSimulation } = await simulateInvestment(body)
        setSimulation(investmentSimulation)
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao simular o investimento')
      } finally {
        setLoading(false)
      }
    }
    fetchSimulationInfos()
  }, [campaign.id, investmentValue])

  if (loading) return <LoadingScreen />

  return (
    <Fragment>
      <p className='iframe__title'>
        Quanto a campanha deve render? <br /> Veja a simulação abaixo com um investimento de R${' '}
        {formatMoney(investmentValue)}!
      </p>
      <Container>
        <Title>Retorno Estimado Total*</Title>
        <ExpectedAmount>{`R$ ${numbro(simulation.investment_return_amount).format({
          thousandSeparated: true,
          mantissa: 2
        })}`}</ExpectedAmount>
        <Installments>
          {campaign.installments} Parcelas de R$ {simulation.installment_amount}
        </Installments>
        <Warning>
          * os valores podem variar de acordo com a data de fechamento da campanha e a formalização da CCB
        </Warning>
      </Container>
    </Fragment>
  )
}

const Warning = styled.p`
  margin-bottom: 1em;
  color: #686c8b;
`

const Installments = styled.p`
  font-size: 1em !important;
  color: #686c8b;
  margin-top: 1em;
`

const ExpectedAmount = styled.p`
  font-size: 2.5rem !important;
  color: #076f9f;
  font-weight: 750;
  margin: 0;
`

const Title = styled.p`
  font-size: 1.75em !important;
  font-weight: 500;
  color: #686c8b;
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default CampaignReturnSimulator
