import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import Dropdown from './DropdownHeader'
import { generateIncomeReportData, downloadIncomeReportPDF } from '../../utils'

import { checkIsAdmin, checkIsSigner, checkIsPartner } from '../../helpers/users'

const MyAccount = ({ handleMenu, currentUser }) => {
  const [subItems, setSubItems] = useState([
    {
      title: 'Meus dados',
      href: `/cadastro-completo`
    },
    {
      title: 'Meus Investimentos',
      href: '/meus-investimentos'
    },
    {
      title: 'Avalie a Kavod',
      href: 'https://yubb.com.br/melhores-empresas-de-investimento/kavod-lending',
      blank: true
    }
  ])

  useEffect(() => {
    const fetchIncomeReportData = async () => {
      try {
        const userIncomeReportData = await generateIncomeReportData()

        if (userIncomeReportData) {
          setSubItems(oldState => [
            ...oldState,
            {
              title: 'Informe de Rendimento',
              callback: async () => {
                try {
                  await downloadIncomeReportPDF(userIncomeReportData)
                } catch (error) {
                  toast.error(error.message)
                }
              }
            }
          ])
        }

        if (checkIsPartner(currentUser)) {
          setSubItems(oldState => {
            const state = oldState
            state.splice(2, 0, {
              title: 'Minhas Indicações',
              href: '/minhas-indicacoes'
            })
            return state
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchIncomeReportData()
  }, [currentUser])

  return <Dropdown title='Minha conta' href='/minha-conta' onClick={handleMenu} children={subItems} />
}

const Header = ({ logout, currentUser, authenticated }) => {
  const [isOpen, setIsOpen] = useState(false)

  const wrapper = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [])

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  return (
    <div className='header' id='home' ref={wrapper}>
      <div className='navbar-wrapper'>
        <Link to='/' className='logo-wrapper' onClick={handleMenu}>
          <img width='135' src='/assets/img/shared/logo.png' alt='Kavod Logo' />
        </Link>
        <div className={isOpen ? 'navbar isOpen' : 'navbar'}>
          <div className='nav-list'>
            <div className='nav-item'>
              <Link to='/' className='nav-item-bordered' href='#' onClick={handleMenu}>
                Home
              </Link>
            </div>
            {authenticated && checkIsAdmin(currentUser) && (
              <div className='nav-item'>
                <Link to='/admin' className='nav-item-bordered' onClick={handleMenu}>
                  Admin
                </Link>
              </div>
            )}
            <div className='nav-item'>
              <Link to='/parcerias' className='nav-item-bordered' onClick={handleMenu}>
                Parcerias
              </Link>
            </div>
            {authenticated && (
              <Fragment>
                <div className='nav-item'>
                  <Dropdown
                    title='Campanhas'
                    href='/campanhas'
                    onClick={handleMenu}
                    children={[
                      {
                        title: 'Com aval',
                        href: '/campanhas/com-aval/'
                      },
                      {
                        title: 'Com aval + garantias',
                        href: '/campanhas/com-aval-e-garantia/'
                      }
                    ]}
                  />
                </div>
                <div className='nav-item'>
                  <MyAccount handleMenu={handleMenu} currentUser={currentUser} />
                </div>
              </Fragment>
            )}
            {authenticated && checkIsSigner(currentUser) && (
              <div className='nav-item'>
                <Link to='/contratos-para-assinar' className='nav-item-bordered' onClick={handleMenu}>
                  Assinar contratos
                </Link>
              </div>
            )}
            <div className='nav-item '>
              <Dropdown
                title='Sobre nós'
                href='/#we-are-kavod'
                children={[{ title: 'Termos e Condições de uso' }]}
                onClick={handleMenu}
                subDropdown={[
                  {
                    title: 'Investidor Kavod',
                    href:
                      'https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-+Termos+e+Condic%CC%A7o%CC%83es+para+Investidores+e+Tomadores_Maio+2019+%5BFINAL%5D.pdf',
                    blank: true
                  },
                  {
                    title: 'Uso da plataforma digital',
                    href:
                      'https://s3-sa-east-1.amazonaws.com/esh-documents-hmg/terms/Kavod-Lending-Termos-e-Condic%CC%A7o%CC%83es-de-Uso-de-Plataforma-Digital.pdf',
                    blank: true
                  }
                ]}
              />
            </div>
            <div className='nav-item'>
              <Dropdown
                href='/contato'
                title='Contato'
                onClick={handleMenu}
                children={[
                  { title: 'Fale conosco', href: '/contato' },
                  { title: 'Trabalhe conosco', href: '/envie-seu-curriculo' }
                ]}
              />
            </div>
            {!authenticated && (
              <div className='nav-item'>
                <Dropdown
                  title='Cadastre-se'
                  onClick={handleMenu}
                  href='/investidor'
                  children={[
                    { title: 'Empresa', href: '/empresa' },
                    { title: 'Investidor', href: '/investidor' },
                    { title: 'Parceiro', href: '/cadastro-parceria' }
                  ]}
                />
              </div>
            )}
            {authenticated ? (
              <div className='nav-item' onClick={logout}>
                <span className='nav-item-bordered'>Sair</span>
              </div>
            ) : (
              <div className='nav-item'>
                <Link className='nav-item-bordered' to='/login' onClick={handleMenu}>
                  Entrar
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='nav-hamburger' onClick={handleMenu}>
        <i className='fas fa-bars' />
      </div>
    </div>
  )
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default connect(mapStateToProps)(withRouter(Header))
