import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { LoadingScreen } from '../components/commom'

import { formatDateFromBackEnd } from '../helpers'
import { getCampaignById, getCampaignUpdates } from '../services/v3'

class ListCampaignUpdates extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, campaign: null, campaignUpdates: [], campaignURL: null }
  }

  fetchInfos = async () => {
    try {
      this.setState({ loading: true })
      const { campaignURL } = this.props.match.params
      const [{ data: campaign }, { data: campaignUpdates }] = await Promise.all([
        getCampaignById(campaignURL),
        getCampaignUpdates(campaignURL)
      ])
      this.setState({ campaign, campaignUpdates, campaignURL })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar a campanha')
    } finally {
      this.setState({ loading: false })
    }
  }

  componentWillMount() {
    this.fetchInfos()
  }

  navigateToUpdatePage = update => {
    const { history } = this.props
    const { campaignURL } = this.state

    history.push(`/admin/campanhas/${campaignURL}/atualizacoes/${update.id}`)
  }

  render() {
    const { loading, campaignUpdates } = this.state

    if (loading) return <LoadingScreen />

    if (campaignUpdates.length === 0) {
      return <p>Campanha sem atualizações</p>
    }

    return (
      <div className='container'>
        <table className='highlight'>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {campaignUpdates.map(update => (
              <tr key={update.id} onClick={_ => this.navigateToUpdatePage(update)}>
                <td>{formatDateFromBackEnd(update.datetime)}</td>
                <td>{update.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect()(ListCampaignUpdates)
