import client from '../providers/fetchClient'
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  TOKEN_RESET_LOADING,
  TOKEN_RESET_SUCCESS,
  TOKEN_RESET_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_FORM_SUCCESS,
  LOGOUT
} from './types'

import { login as loginService, getCurrentUser, createInvestor } from '../services/v3'
import { setToken } from '../services'

export const login = (data, callback) => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: {
        authenticated: null,
        message: ''
      }
    })

    try {
      const res = await loginService(data)
      setToken(res.data.token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          authenticated: true,
          message: ''
        }
      })
      resolve()
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          authenticated: false,
          message: 'Dados de login incorretos.'
        }
      })
      reject(err)
    }
  })

export const signup = data => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({
      type: SIGN_UP_LOADING,
      payload: { authenticated: null, message: '' }
    })

    try {
      delete data.confirmPassword
      const res = await createInvestor({ ...data, investor_status_id: 1 })
      setToken(res.data.token)

      dispatch({
        type: SIGN_UP_SUCCESS,
        payload: {
          authenticated: true,
          message: ''
        }
      })

      resolve()
    } catch (err) {
      console.error('erro: ', err.response.data.message)
      let message = ''
      if (/Duplicate entry/.test(err.response.data.message)) {
        if (/users_cpf_unique/.test(err.response.data.message)) {
          message = 'CPF já está cadastrado'
        }
        if (/investors_email_unique/.test(err.response.data.message)) {
          message = 'Email já está cadastrado'
        }
      }

      dispatch({
        type: SIGN_UP_FAIL,
        payload: {
          authenticated: false,
          message: message || err.response.data.message
        }
      })
      reject(err)
    }
  })

export const tokenPasswordReset = (data, callback) => dispatch => {
  dispatch({
    type: TOKEN_RESET_LOADING,
    payload: { authenticated: null, message: '' }
  })

  client
    .post('/request-password-reset', { data })
    .then(res => {
      callback()
      dispatch({
        type: TOKEN_RESET_SUCCESS,
        payload: {
          authenticated: false,
          message: ''
        }
      })
    })
    .catch(() => {
      callback()
      dispatch({
        type: TOKEN_RESET_FAIL,
        payload: {
          authenticated: false,
          message: 'CPF não encontrado'
        }
      })
    })
}

export const passwordReset = (data, callback) => dispatch => {
  dispatch({
    type: RESET_PASSWORD_LOADING,
    payload: { authenticated: null, message: '' }
  })

  client
    .post('/change-password', { data })
    .then(res => {
      callback()
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          authenticated: false,
          message: ''
        }
      })
    })
    .catch(err => {
      callback()
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: {
          authenticated: false,
          message: err.response.data.errors[0]
        }
      })
    })
}

export const fetchCurrentUser = () => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: { message: '', loading: true }
    })

    try {
      const res = await getCurrentUser()
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { authenticated: true, message: '', ...res.data, roles: res.data.roles, loading: false }
      })
      resolve(res)
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: { authenticated: false, message: '', loading: false }
      })
      reject(err)
    }
  })

export const clearUserForm = (data, callback) => dispatch => {
  dispatch({
    type: RESET_FORM_SUCCESS,
    payload: { authenticated: false, message: '' }
  })
}

export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT,
    payload: { authenticated: null, message: '', loading: false }
  })
}

export const notLogged = () => dispatch => {
  dispatch({
    type: LOGIN_FAIL,
    payload: { authenticated: false, message: '', loading: false }
  })
}
