import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import CampaignNavigationOptions from './CampaignNavigationOptions'
import { LoadingScreen } from './commom'

import { getCampaignById, deleteCampaign } from '../services/v3'

const CampaignDelete = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [campaign, setCampaign] = useState(true)

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setIsLoading(true)
        const { data: campaign } = await getCampaignById(match.params.campaignURL)
        setCampaign(campaign)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao carregar a campanha')
      } finally {
        setIsLoading(false)
      }
    }
    fetchCampaign()
  }, [match.params.campaignURL])

  const handleDelete = async campaignId => {
    try {
      const response = await deleteCampaign(campaignId)
      if (response.status === 200) {
        toast.success('Campanha deletada com sucesso')
      } else {
        throw new Error('Não conseguimos deletar sua campanha. Tente Novamente.')
      }
      history.push('/admin')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao deletar a campanha, por favor tente novamente')
    }
  }

  if (isLoading || !campaign.id) {
    return <LoadingScreen />
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <Container>
            <Paragraph>Deseja mesmo deletar a campanha {campaign.name} ?</Paragraph>
            <DeleteButton onClick={() => handleDelete(campaign.id)}>Confirmar exclusão</DeleteButton>
          </Container>
        </div>
      </div>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 4em 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Paragraph = styled.p`
  font-size: 1.5em;
  text-align: center;
`

const DeleteButton = styled.button`
  background: #ff3f3f;
  border: 0;
  color: #fff;
  padding: 0.8em 0.6em;
  font-size: 1.3em;
  margin: 1em;
  border-radius: 0.5em;
  cursor: pointer;
  &:hover {
    background: #b73333;
    transition: all 0.3s;
  }

  &:focus {
    background: #b73333 !important;
  }
`

export default connect()(CampaignDelete)
