import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getIndicationsShow } from '../../../services/v3/indications'

import { IndicationContainer, RowHeaderIndicator, Container, StyledMain } from './styles'

import { Info, Acordion, Timeline, BadgeStatus } from './components'
import { LoadingScreen } from '../../../components/commom/LoadingScreen'

import { Text, InternalNavigation } from '../../../components/v2Components'

const MyIndicationsShow = ({ match: { params }, history }) => {
  const [loading, setLoading] = useState(true)

  const [indication, setIndicantion] = useState({
    company_name: '',
    lead_status: { id: null, name: '' },
    cnpj: '',
    created_at: '2018-10-10',
    lead_updates: []
  })

  useEffect(() => {
    const fetchInfos = async id => {
      try {
        const { data: indi } = await getIndicationsShow(id)
        setIndicantion(indi)

        if (indi.desired_amount) {
          setLoading(false)
        }
      } catch {
        history.push('/minhas-indicacoes')
        toast.error('Não é possivel acessar essa indicação.')
      }
    }

    fetchInfos(params.id)
  }, [params.id, history])

  if (loading) return <LoadingScreen />

  return (
    <Container>
      <StyledMain>
        <IndicationContainer>
          <div style={{ gridArea: 'Header' }}>
            <InternalNavigation
              link='/minhas-indicacoes'
              routeName='Minhas Indicações'
              name={indication.company_name}
            />
            <RowHeaderIndicator>
              <Text variant='h3' fontSize='24px'>
                {indication.company_name}
              </Text>
              <BadgeStatus status={indication.lead_status.id} />
            </RowHeaderIndicator>
          </div>
          <div style={{ gridArea: 'Details' }}>
            <Info IndicationInfo={indication} />
          </div>
          <div style={{ gridArea: 'Accordion' }}>
            <Acordion IndicationData={indication} />
          </div>
          <div style={{ gridArea: 'Timeline' }}>
            <Timeline timelineData={indication} />
          </div>
        </IndicationContainer>
      </StyledMain>
    </Container>
  )
}

export default withRouter(MyIndicationsShow)
