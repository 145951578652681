import { isEmpty } from 'lodash'
import {
  CHECK_INVESTOR_COMPLIANCE_FAIL,
  CHECK_INVESTOR_COMPLIANCE_SUCCESS,
  CHECK_INVESTOR_COMPLIANCE_LOADING
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  fail: false,
  data: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_INVESTOR_COMPLIANCE_LOADING:
      return { fail: false, loading: true, data: null }

    case CHECK_INVESTOR_COMPLIANCE_SUCCESS:
      const data = isEmpty(action.payload) ? null : action.payload
      return { fail: false, loading: false, ...data }

    case CHECK_INVESTOR_COMPLIANCE_FAIL:
      return { fail: true, loading: false, data: null }

    default:
      return state
  }
}
