import React from 'react'
import { IconHand, IconContract, IconMoney, IconPay, Arrow } from './icons'

const HowItWork = () => {
  return (
    <section className='how-it-work'>
      <div className='section-description'>
        <div className='text-wrapper'>
          <h3>Como Funciona?</h3>
          <p>Investidor, dê mais rentabilidade ao seu patrimônio.</p>
          <p>Empreendedor, consiga empréstimos a juros justos.</p>
        </div>
      </div>
      <div className='icons-container'>
        <div className='icons-content'>
          <div className='icon-container'>
            <IconHand />
            <p>Uma solicitação de empréstimo é criada na plataforma</p>
          </div>
          <div className='arrow-wrapper'>
            <Arrow />
          </div>
          <div className='icon-container'>
            <IconContract />
            <p>Se pré aprovada pela Kavod, é oferecida aos investidores</p>
          </div>
          <div className='arrow-wrapper'>
            <Arrow />
          </div>
          <div className='icon-container'>
            <IconMoney />
            <p>Investidores escolhem se querem e quanto querem financiar</p>
          </div>
          <div className='arrow-wrapper'>
            <Arrow />
          </div>
          <div className='icon-container'>
            <IconPay />
            <p>Caso financiada, a empresa recebe o seu empréstimo e os investidores seus pagamentos mensais</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export { HowItWork }
