import client from '../providers/fetchClient'
import { FETCH_COMPANIES_LOADING, FETCH_COMPANIES_SUCCESS, FETCH_COMPANIES_FAIL } from './types'

export const fetchCompanies = () => dispatch => {
  dispatch({ type: FETCH_COMPANIES_LOADING, payload: null })

  client
    .get(`/companies/stt_id/A?limit=10000`)
    .then(res => {
      dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: res.data })
    })
    .catch(err => {
      console.log(err)
      dispatch({ type: FETCH_COMPANIES_FAIL, payload: null })
    })
}
