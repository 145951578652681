import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Theme'

const Error = ({ error }) => {
  return error ? <StyledError>{error.message}</StyledError> : null
}

const StyledError = styled.span`
  color: ${colors.red};
  font-size: 12px;
`

export default Error
