import React, { Component } from 'react'

class Select extends Component {
  componentDidMount() {
    const elems = document.querySelectorAll('select')
    window.M.FormSelect.init(elems)
  }
  helperText = () => {
    if (!this.props.helperText) return null

    return <span className='helper-text'>{this.props.helperText}</span>
  }

  render() {
    const { className } = this.props
    return (
      <div className={`input-field  ${className || ''}`}>
        <select
          onChange={this.props.onInputChange}
          onBlur={this.handleErrorMsg}
          value={this.props.value}
          name={this.props.field}
          className='validate'
          id={this.props.field}
        >
          <option value='' disabled>
            {this.props.title || 'Selecione uma opção'}
          </option>
          {this.props.children}
        </select>
        <label htmlFor={this.props.field}>{this.props.label}</label>
        {this.helperText()}
      </div>
    )
  }
}

export { Select }
