import React from 'react'
import { withRouter } from 'react-router'
import { BorderedButton, OutlinedButton } from '../components/commom'

const backgroundsArray = [
  '/assets/img/home/bg01.png',
  '/assets/img/home/bg02.png',
  '/assets/img/home/bg03.png',
  '/assets/img/home/bg04.png',
  '/assets/img/home/bg05.png'
]
const RANDOM_ARRAY_POSITION = Math.floor(Math.random() * backgroundsArray.length)

const Home = props => {
  return (
    <section className='container-home-banner'>
      <div
        style={{ backgroundImage: `url('${backgroundsArray[RANDOM_ARRAY_POSITION]}')` }}
        className='container-background'
      >
        <div className='image-container-text'>
          <h1>
            Investidor, dê mais rentabilidade ao seu patrimônio.
            <br />
            Empreendedor, consiga empréstimos a juros justos.
          </h1>
        </div>
        <div className='buttons-wrapper'>
          <BorderedButton
            onClick={() => {
              props.authenticated ? props.history.push('/campanhas') : props.history.push('/investidor')
            }}
            label='Quero Investir'
          />
          <OutlinedButton
            onClick={() => {
              props.history.push('/empresa')
            }}
            label='Quero Empréstimo'
          />
        </div>
      </div>
    </section>
  )
}

const HomeBanner = withRouter(Home)

export { HomeBanner }
