import { CAMPAIGN_UPDATE_FETCH } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_UPDATE_FETCH:
      return { ...action.payload }

    default:
      return state
  }
}
