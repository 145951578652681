import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import MomentUtils from '@date-io/moment'


import Theme from './Themes/Theme'
import App from './App'

import rootReducer from './reducers'

import 'materialize-css/dist/css/materialize.min.css'
import './css/style.css'

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={createStoreWithMiddleware(rootReducer)}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Theme>
        <App />
      </Theme>
    </MuiPickersUtilsProvider>
  </Provider>,
  rootElement
)
