// Form Actions ~> show flashMessage
export const FORM_SUBMIT = 'FORM_SUBMIT'

// Campaign Gallery Actions
export const CAMPAIGN_GALLERY_FETCH = 'CAMPAIGN_GALLERY_FETCH'
export const CAMPAIGN_GALLERY_CREATE = 'CAMPAIGN_GALLERY_CREATE'
export const CAMPAIGN_GALLERY_DELETE = 'CAMPAIGN_GALLERY_DELETE'
export const CAMPAING_GALLERY_UPDATE = 'CAMPAING_GALLERY_UPDATE'

// Campaign Profile img actions
export const LOADING_CAMPAIGN_PROFILE_IMG = 'LOADING_CAMPAIGN_PROFILE_IMG'
export const CAMPAIGN_PROFILE_IMG_UPDATE = 'CAMPAIGN_PROFILE_IMG_UPDATE'
export const CAMPAIGN_PROFILE_IMG_FETCH = 'CAMPAIGN_PROFILE_IMG_FETCH'

// Campaign updates actions
export const CAMPAIGN_UPDATES_FETCH = 'CAMPAIGN_UPDATES_FETCH'
export const CAMPAIGN_UPDATE_FETCH = 'CAMPAIGN_UPDATE_FETCH'
export const CAMPAIGN_UPDATE_PUT = 'CAMPAIGN_UPDATE_PUT'
export const CAMPAIGN_UPDATE_POST = 'CAMPAIGN_UPDATE_POST'

// Campaign actions
export const LOADING_CAMPAIGNS = 'LOADING_CAMPAIGNS'
export const LOADING_CAMPAIGN = 'LOADING_CAMPAIGN'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS'
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN'

// Auth actions
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'

export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const TOKEN_RESET_LOADING = 'TOKEN_RESET_LOADING'
export const TOKEN_RESET_SUCCESS = 'TOKEN_RESET_SUCCESS'
export const TOKEN_RESET_FAIL = 'TOKEN_RESET_FAIL'

export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const RESET_FORM_SUCCESS = 'RESET_FORM_SUCCESS'

// Pledge actions
export const FETCH_PLEDGES = 'FETCH_PLEDGE'
export const CREATE_PLEDGE = 'CREATE_PLEDGE'
export const UPDATE_PLEDGE = 'UPDATE_PLEDGE'
export const LOADING_PLEDGES = 'LOADING_PLEDGES'
export const CURRENT_INVESTMENT = 'CURRENT_INVESTMENT'
export const CLEAR_CURRENT_INVESTMENT = 'CLEAR_CURRENT_INVESTMENT'

// Person actions
export const FETCH_PERSON_LOADING = 'FETCH_PERSON_LOADING'
export const FETCH_PERSON_FAIL = 'FETCH_PERSON_FAIL'
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS'
export const CHECK_COMPLETE_PROFILE_LOADING = 'CHECK_COMPLETE_PROFILE_LOADING'
export const CHECK_COMPLETE_PROFILE_FAIL = 'CHECK_COMPLETE_PROFILE_FAIL'
export const CHECK_COMPLETE_PROFILE_SUCCESS = 'CHECK_COMPLETE_PROFILE_SUCCESS'

// Phone actions
export const FETCH_PHONE_LOADING = 'FETCH_PHONE_LOADING'
export const FETCH_PHONE_FAIL = 'FETCH_PHONE_FAIL'
export const FETCH_PHONE_SUCCESS = 'FETCH_PHONE_SUCCESS'
export const UPDATE_PERSON_LOADING = 'UPDATE_PERSON_LOADING'
export const UPDATE_PERSON_FAIL = 'UPDATE_PERSON_FAIL'
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS'

// Bank actions
export const FETCH_BANK_FAIL = 'FETCH_BANK_FAIL'
export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS'
export const FETCH_BANK_LOADING = 'FETCH_BANK_LOADING'
export const UPDATE_BANK_LOADING = 'UPDATE_BANK_LOADING'
export const UPDATE_BANK_FAIL = 'UPDATE_BANK_FAIL'
export const UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS'

// Address actions
export const FETCH_ADDRESS_FAIL = 'FETCH_ADDRESS_FAIL'
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS'
export const FETCH_ADDRESS_LOADING = 'FETCH_ADDRESS_LOADING'
export const UPDATE_ADDRESS_LOADING = 'UPDATE_ADDRESS_LOADING'
export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL'
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS'

// Investor actions
export const FETCH_INVESTOR_LOADING = 'FETCH_INVESTOR_LOADING'
export const FETCH_INVESTOR_FAIL = 'FETCH_INVESTOR_FAIL'
export const FETCH_INVESTOR_SUCCESS = 'FETCH_INVESTOR_SUCCESS'
export const UPDATE_INVESTOR_LOADING = 'UPDATE_INVESTOR_LOADING'
export const UPDATE_INVESTOR_FAIL = 'UPDATE_INVESTOR_FAIL'
export const UPDATE_INVESTOR_SUCCESS = 'UPDATE_INVESTOR_SUCCESS'
export const CHECK_INVESTOR_COMPLIANCE_LOADING = 'CHECK_INVESTOR_COMPLIANCE_LOADING '
export const CHECK_INVESTOR_COMPLIANCE_FAIL = 'CHECK_INVESTOR_COMPLIANCE_FAIL '
export const CHECK_INVESTOR_COMPLIANCE_SUCCESS = 'CHECK_INVESTOR_COMPLIANCE_SUCCESS '

// Upload userDocument actions
export const UPDATE_USER_DOCUMENT_LOADING = 'UPDATE_USER_DOCUMENT_LOADING'
export const UPLOAD_USER_DOCUMENT_LOADING = 'UPLOAD_USER_DOCUMENT_LOADING'
export const FETCH_USER_CR_LOADING = 'FETCH_USER_CR_LOADING'
export const FETCH_USER_CR_FAIL = 'FETCH_USER_CR_FAIL'

export const FETCH_PERSONAL_DOCUMENTS_FAIL = 'FETCH_PERSONAL_DOCUMENTS_FAIL'
export const FETCH_PERSONAL_DOCUMENTS_SUCCESS = 'FETCH_PERSONAL_DOCUMENTS_SUCCESS'
export const FETCH_PERSONAL_DOCUMENTS_LOADING = 'FETCH_PERSONAL_DOCUMENTS_LOADING'
export const FETCH_ALL_DOCUMENTS_SUCCESS = 'FETCH_ALL_DOCUMENTS_SUCCESS'

// Compliance Document actions
export const FETCH_COMPLIANCE_FAIL = 'FETCH_COMPLIANCE_FAIL'
export const FETCH_COMPLIANCE_SUCCESS = 'FETCH_COMPLIANCE_SUCCESS'
export const FETCH_COMPLIANCE_LOADING = 'FETCH_COMPLIANCE_LOADING'
export const UPDATE_COMPLIANCE_LOADING = 'UPDATE_COMPLIANCE_LOADING'
export const UPDATE_COMPLIANCE_FAIL = 'UPDATE_COMPLIANCE_FAIL'
export const UPDATE_COMPLIANCE_SUCCESS = 'UPDATE_COMPLIANCE_SUCCESS'
export const CREATE_COMPLIANCE_LOADING = 'CREATE_COMPLIANCE_LOADING'
export const CREATE_COMPLIANCE_FAIL = 'CREATE_COMPLIANCE_FAIL'
export const CREATE_COMPLIANCE_SUCCESS = 'CREATE_COMPLIANCE_SUCCESS'

// Company actions
export const FETCH_COMPANIES_LOADING = 'FETCH_COMPANIES_LOADING'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL'

export const FETCH_ALL_PLEDGES_SUCCESS = 'FETCH_ALL_PLEDGES_SUCCESS'
export const FETCH_ALL_PLEDGES_FAIL = 'FETCH_ALL_PLEDGES_FAIL'
export const LOADING_ALL_PLEDGES = 'LOADING_FETCH_ALL_PLEDGES'
