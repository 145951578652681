import client from '../providers/fetchClient'
import {
  FETCH_PERSON_LOADING,
  FETCH_PERSON_FAIL,
  FETCH_PERSON_SUCCESS,
  UPDATE_PERSON_LOADING,
  UPDATE_PERSON_FAIL,
  UPDATE_PERSON_SUCCESS,
  CHECK_COMPLETE_PROFILE_LOADING,
  CHECK_COMPLETE_PROFILE_FAIL,
  CHECK_COMPLETE_PROFILE_SUCCESS
} from './types'

export const fetchPerson = kvdId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: FETCH_PERSON_LOADING, payload: null })

    client
      .get(`/person/kvd_id/${kvdId}?exactmatch=true`)
      .then(res => dispatch({ type: FETCH_PERSON_SUCCESS, payload: res.data }))
      .catch(() => dispatch({ type: FETCH_PERSON_FAIL, payload: null }))
  })

export const checkCompleteProfile = kvdId => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: CHECK_COMPLETE_PROFILE_LOADING, payload: null })

    try {
      const res = await client.get(`/person-complete/kvd_id/${kvdId}?exactmatch=true`)
      dispatch({ type: CHECK_COMPLETE_PROFILE_SUCCESS, payload: res.data })
      resolve(res)
    } catch (err) {
      dispatch({ type: CHECK_COMPLETE_PROFILE_FAIL, payload: null })
      reject(err)
    }
  })

export const updatePerson = data => dispatch => {
  dispatch({ type: UPDATE_PERSON_LOADING, payload: null })

  return new Promise((resolve, reject) => {
    client
      .put('/person/', data)
      .then(res => {
        dispatch({ type: UPDATE_PERSON_SUCCESS, payload: res.data })
        resolve({ success: true })
      })
      .catch(() => {
        const err = { success: false }
        dispatch({ type: UPDATE_PERSON_FAIL, payload: null })
        reject(err)
      })
  })
}
