import React from 'react'
import { ExpansionPanelSummary as AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  StyledAccordion,
  StyledAccordionDetails,
  Label,
  CompanyData,
  StyledText,
  FranchiseInfo,
  NotCnpjCompanyData
} from './styles'

import { Text, Row } from '../../../../../components/v2Components'

import { formatPhoneWithoutPrefix } from '../../../../../helpers/formatters'

const Acordion = ({ IndicationData }) => {
  const cnpjLength = IndicationData.cnpj ? IndicationData.cnpj : ''

  return (
    <div>
      <StyledText>Informações da solicitação</StyledText>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text variant='subtitle2'>Dados do contato</Text>
        </AccordionSummary>
        <StyledAccordionDetails>
          <div>
            <Label>Nome</Label>
            <Text variant='body1'>{IndicationData.first_name}</Text>
          </div>
          <div>
            <Label>Sobrenome</Label>
            <Text variant='body1'>{IndicationData.last_name || '-'}</Text>
          </div>
          <div>
            <Label>Celular</Label>
            <Text variant='body1'>
              ({IndicationData.celphone_prefix}) {formatPhoneWithoutPrefix(IndicationData.celphone)}
            </Text>
          </div>
          <div>
            <Label>Telefone fixo</Label>
            <Text variant='body1'>
              ({IndicationData.telephone_prefix || '##'}) {formatPhoneWithoutPrefix(IndicationData.telephone) || ' - '}
            </Text>
          </div>
          <div>
            <Label>E-mail</Label>
            <Row justifyContent='start' alignItems='center'>
              <Text mr={1} variant='body1'>
                {IndicationData.email}
              </Text>
            </Row>
          </div>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text variant='subtitle2'>Tipo de empresa</Text>
        </AccordionSummary>
        <StyledAccordionDetails>
          {IndicationData.company_type === 'Empresa em geral' && (
            <div>
              <Label>Tipo de empresa</Label>
              <Text variant='body1'>{IndicationData.company_type}</Text>
            </div>
          )}
          {IndicationData.company_type !== 'Empresa em geral' && (
            <>
              <div>
                <Label>Tipo de empresa</Label>
                <Text variant='body1'>{IndicationData.company_type}</Text>
              </div>
              <div>
                <Label>Franqueador</Label>
                <Text variant='body1'>{IndicationData.franchisor ? 'Sim' : 'Não'}</Text>
              </div>
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
      {IndicationData.company_type === 'Franquia' && (
        <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Text variant='subtitle2'>Tipo de franquia</Text>
          </AccordionSummary>
          <FranchiseInfo>
            <div style={{ gridArea: 'franchiseType' }}>
              <Label>Tipo de franquia</Label>
              <Text variant='body1'>{IndicationData.franchise_type}</Text>
            </div>

            {IndicationData.franchise_type === 'Existente' && (
              <>
                <div style={{ gridArea: 'franchisor' }}>
                  <Label>Franqueador</Label>
                  <Text variant='body1'>{IndicationData.franchisor_name}</Text>
                </div>
                <div style={{ gridArea: 'franchiseDescrip' }}>
                  <Label>Descrição da franquia</Label>
                  <Text variant='body1'>{IndicationData.franchise_description}</Text>
                </div>
              </>
            )}

            {IndicationData.franchise_type === 'Nova' && (
              <>
                <div style={{ gridArea: 'franchisor' }}>
                  <Label>Possui CNPJ</Label>
                  <Text variant='body1'>{IndicationData.have_cnpj ? 'Sim' : 'Não'}</Text>
                </div>
                <div style={{ gridArea: 'franchiseDescrip' }}>
                  <Label>Histórico da empresa</Label>
                  <Text variant='body1'>{IndicationData.franchise_description}</Text>
                </div>
              </>
            )}
          </FranchiseInfo>
        </StyledAccordion>
      )}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text variant='subtitle2'>Dados da empresa</Text>
        </AccordionSummary>

        {(cnpjLength.length > 0 ? true : false) && (
          <CompanyData>
            <div style={{ gridArea: 'CNPJ' }}>
              <Label>CNPJ</Label>
              <Text variant='body1'>
                {IndicationData.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
              </Text>
            </div>
            <div style={{ gridArea: 'fantasyName' }}>
              <Label>Razão Social</Label>
              <Text variant='body1'>{IndicationData.company_name}</Text>
            </div>
            <div style={{ gridArea: 'site' }}>
              <Label>Site da empresa</Label>
              <Text variant='body1'>{IndicationData.company_site}</Text>
            </div>
            <div style={{ gridArea: 'CEP' }}>
              <Label>CEP</Label>
              <Text variant='body1'>{IndicationData.zip_code}</Text>
            </div>
            <div style={{ gridArea: 'address' }}>
              <Label>Rua</Label>
              <Text variant='body1'>{IndicationData.street}</Text>
            </div>
            <div style={{ gridArea: 'number' }}>
              <Label>Nº</Label>
              <Text variant='body1'>{IndicationData.number}</Text>
            </div>
            <div style={{ gridArea: 'district' }}>
              <Label>Bairro</Label>
              <Text variant='body1'>{IndicationData.district}</Text>
            </div>
            <div style={{ gridArea: 'complement' }}>
              <Label>Complemento</Label>
              <Text variant='body1'>{IndicationData.complement}</Text>
            </div>
            <div style={{ gridArea: 'city' }}>
              <Label>Cidade</Label>
              <Text variant='body1'>{IndicationData.city}</Text>
            </div>
            <div style={{ gridArea: 'state' }}>
              <Label>Estado</Label>
              <Text variant='body1'>{IndicationData.uf}</Text>
            </div>
            <div style={{ gridArea: 'FMM' }}>
              <Label>Faturamento Médio Mensal (FMM)</Label>
              <Text variant='body1'>
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
                  IndicationData.average_monthly_revenue
                )}
              </Text>
            </div>
            <div style={{ gridArea: 'Value' }}>
              <Label>Valor do empréstimo desejado</Label>
              <Text variant='body1'>
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
                  IndicationData.desired_amount
                )}
              </Text>
            </div>
            <div style={{ gridArea: 'historic' }}>
              <Label>Histórico da empresa</Label>
              <Text variant='body1'>{IndicationData.company_history}</Text>
            </div>
            <div style={{ gridArea: 'reason' }}>
              <Label>Motivo do empréstimo</Label>
              <Text variant='body1'>{IndicationData.lending_reason}</Text>
            </div>
          </CompanyData>
        )}

        {(cnpjLength.length > 0 ? false : true) && (
          <NotCnpjCompanyData>
            <div style={{ gridArea: 'FMM' }}>
              <Label>Faturamento Médio Mensal (FMM)</Label>
              <Text variant='body1'>
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
                  IndicationData.average_monthly_revenue
                )}
              </Text>
            </div>
            <div style={{ gridArea: 'Value' }}>
              <Label>Valor do empréstimo desejado</Label>
              <Text variant='body1'>
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRl' }).format(
                  IndicationData.desired_amount
                )}
              </Text>
            </div>
            <div style={{ gridArea: 'reason' }}>
              <Label>Motivo do empréstimo</Label>
              <Text variant='body1'>{IndicationData.lending_reason}</Text>
            </div>
          </NotCnpjCompanyData>
        )}
      </StyledAccordion>
    </div>
  )
}

export default Acordion
