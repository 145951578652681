import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { sidenavbarLinks } from '../../constants'

class SidebarContentWithRouter extends Component {
  logout = async () => {
    window.localStorage.removeItem('kavodJWT')
    await this.props.fetchCurrentUser()
    this.props.history.push('/')
  }

  renderContent = () => {
    const { currentUser } = this.props
    return sidenavbarLinks.map(
      ({ anchor, text, className, href, dataTarget, blank, rel, to, onlyLogged, onlyNonLogged, logout }) => {
        if (onlyLogged && !currentUser.authenticated) return null

        if (onlyNonLogged && currentUser.authenticated) return null

        if (anchor) {
          return (
            <li key={text}>
              <a
                className={className}
                href={href}
                data-target={dataTarget || null}
                target={blank ? '_blank' : null}
                rel={rel || null}
              >
                {text}
              </a>
            </li>
          )
        }

        return (
          <li key={text}>
            {logout ? (
              <p className={className} onClick={this.logout}>
                {text}
              </p>
            ) : (
              <Link className={className} to={to}>
                {text}
              </Link>
            )}
          </li>
        )
      }
    )
  }

  render() {
    return <div>{this.renderContent()}</div>
  }
}

const SidebarContent = withRouter(SidebarContentWithRouter)

export { SidebarContent }
