import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const photoOrderSchema = yupResolver(
  yup.object().shape({
    order: yup
      .string()
      .required()
      .test('valid', 'Valor inválido', value => Number(value))
  })
)
