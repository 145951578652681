import React, { useState, useMemo } from 'react'
import { Container, Content, Main, FilterContainer } from './styles'

import { Pagination, GlobalFilter } from '../../../../components/v2Components'

import PropTypes from 'prop-types'

const CardBase = ({ indicators, hasPaginationTop, hasPaginationBottom, offset, rowCount, navigationTo, children }) => {
  const [filter, setFilter] = useState('')

  const data = useMemo(
    () =>
      indicators.filter(
        indicator => indicator['Razão Social'].toUpperCase().indexOf(filter.toUpperCase()) !== -1 && indicator
      ),
    [indicators, filter]
  )

  const paginationindicators = useMemo(() => {
    let list = [[]]
    let group = 0

    for (let i = 0; i < data.length; i++) {
      list[group].push(data[i])

      if (list[group].length === rowCount) {
        group++

        if (i + 1 < data.length) {
          list[group] = []
        }
      }
    }

    return list
  }, [data, rowCount])

  return (
    <Main>
      <FilterContainer>
        <GlobalFilter
          filter={filter}
          setFilter={value => {
            navigationTo(0)
            setFilter(value)
          }}
        />
      </FilterContainer>

      {children}

      {hasPaginationTop && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationindicators.length
          }}
          search={({ page }) => navigationTo(page - 1)}
        />
      )}

      <Content>
        {paginationindicators[offset] &&
          paginationindicators[offset].map(indicator => {
            return (
              <Container key={indicator['Razão Social']}>
                <header>
                  <p>{indicator['Razão Social']}</p>
                  <span>{indicator.Status}</span>
                </header>
                <main>
                  <div>
                    <p>CNPJ</p>
                    <label>{indicator.CNPJ}</label>
                  </div>
                  <div>
                    <p>Fatur. Médio Mensal</p>
                    <label>{indicator['Médio Mensal']}</label>
                  </div>
                  <div>
                    <p>Valor Solicitado</p>
                    <label>{indicator['Valor Solicitado']}</label>
                  </div>
                  <div>
                    <p>Solicitado em</p>
                    <label>{indicator['Solicitado em']}</label>
                  </div>
                  <div>
                    <p>Responsável</p>
                    <label>{indicator['Responsável']}</label>
                  </div>
                </main>
                <footer>{indicator.detalhes}</footer>
              </Container>
            )
          })}
      </Content>

      {hasPaginationBottom && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationindicators.length
          }}
          search={({ page }) => navigationTo(page - 1)}
        />
      )}
    </Main>
  )
}

CardBase.propTypes = {
  indicator: PropTypes.shape({
    'Razão Social': PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    CNPJ: PropTypes.string.isRequired,
    'Médio Mensal': PropTypes.string.isRequired,
    'Valor Solicitado': PropTypes.string.isRequired,
    'Solicitado em': PropTypes.string.isRequired,
    Responsável: PropTypes.string.isRequired
  }),
  hasPaginationTop: PropTypes.bool,
  hasPaginationBottom: PropTypes.bool,
  offset: PropTypes.number,
  rowCount: PropTypes.number,
  pageCount: PropTypes.number,
  navigationTo: PropTypes.func
}

export default CardBase
