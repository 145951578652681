import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Select, NumberInput, Autocomplete } from '../newFormComponents'
import { LoadingScreen, BorderedButton } from '../../components/commom'

import { bankOptions, userFinancialInfoFormDefaultValues, accountTypeOptions } from '../../constants'
import { getBankById, updateInvestor } from '../../services/v3'
import { checkIsCompletedBank, sanitizeMoney, formatCurrency } from '../../helpers'
import { userFinancialInfoSchema } from '../../schemas'

const UserFinancialInfoForm = ({ investor, fetchInvestor, changeTab, style }) => {
  const [loading, setLoading] = useState(true)
  const [isCompleted, setIsCompleted] = useState(false)

  const { handleSubmit, errors, control, reset } = useForm({
    resolver: userFinancialInfoSchema,
    defaultValues: userFinancialInfoFormDefaultValues
  })

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setLoading(true)
        if (investor && investor.bank_id) {
          const { data: bank } = await getBankById(investor.bank_id)
          setIsCompleted(checkIsCompletedBank({ ...investor, bank }))
          const findBank = bankOptions.find(({ value }) => String(bank.code) === String(value.slice(0, 3)))
          reset({
            type: bank.type || '',
            code: findBank || { value: '', label: '' },
            account: bank.account,
            account_digit: bank.account_digit,
            agency: bank.agency,
            monthly_earn: investor.monthly_earn ? formatCurrency(investor.monthly_earn) : '',
            property_value: investor.property_value ? formatCurrency(investor.property_value) : ''
          })
        }
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao carregar os dados financeiros, por favor tente novamente')
      } finally {
        setLoading(false)
      }
    }
    fetchInfos()
  }, [investor, reset])

  const onSubmit = async values => {
    try {
      const body = {
        code: values.code.value.slice(0, 3),
        agency: values.agency.trim(),
        account: values.account.trim(),
        account_digit: values.account_digit.trim(),
        type: values.type
      }

      const investorBody = {
        monthly_earn: sanitizeMoney(values.monthly_earn),
        property_value: sanitizeMoney(values.property_value)
      }

      await updateInvestor(investor.id, { bank: body, ...investorBody })
      setIsCompleted(true)
      toast.success('Informações bancárias salvas com sucesso')
      fetchInvestor()
      changeTab(2, 3)
    } catch (e) {
      console.error(e)
      toast.error('Ocorreu um erro ao salvar as informações bancárias')
    }
  }

  return (
    <li style={style}>
      <div className='collapsible-header'>
        <div className='collapsible-description'>
          <i className='material-icons'>account_balance</i>
          <p>Dados financeiros</p>
        </div>
        <i className='material-icons' style={{ color: isCompleted ? 'green' : 'grey' }}>
          check_circle
        </i>
      </div>
      <div className='collapsible-body'>
        {loading ? (
          <LoadingScreen small />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <InputContainer>
                <Select
                  name='type'
                  control={control}
                  label='Tipo *'
                  error={errors['type']}
                  options={accountTypeOptions}
                  width='100%'
                />
              </InputContainer>
              <InputContainer>
                <Autocomplete
                  name='code'
                  width='100%'
                  control={control}
                  label='Banco *'
                  error={errors['code']}
                  options={bankOptions}
                />
              </InputContainer>
              <InputContainer>
                <NumberInput
                  format='#####'
                  name='agency'
                  width='40%'
                  control={control}
                  label='Agência (sem o dígito) *'
                  error={errors['agency']}
                />
                <NumberInput
                  name='account'
                  format='############'
                  width='40%'
                  control={control}
                  label='Conta *'
                  error={errors['account']}
                />
                <NumberInput
                  name='account_digit'
                  format='#'
                  width='20%'
                  control={control}
                  label='Dígito conta *'
                  error={errors['account_digit']}
                />
              </InputContainer>
              <InputContainer>
                <NumberInput
                  name='monthly_earn'
                  width='50%'
                  control={control}
                  label='Renda mensal *'
                  error={errors['monthly_earn']}
                  type='currency'
                />
                <NumberInput
                  name='property_value'
                  width='50%'
                  control={control}
                  label='Patrimônio *'
                  error={errors['property_value']}
                  type='currency'
                />
              </InputContainer>
              <div className='col s12'>
                <BorderedButton center type='submit' label='SALVAR E CONTINUAR' />
              </div>
            </div>
          </form>
        )}
      </div>
    </li>
  )
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 620px) {
    flex-direction: column;
  }
`

export default UserFinancialInfoForm
