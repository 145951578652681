import React, { Component } from 'react'
import { connect } from 'react-redux'
import CampaignCompanyDetailsForm from '../containers/CampaignCompanyDetailsForm'
import CampaignNavigationOptions from './CampaignNavigationOptions'
import { LoadingScreen } from '../components/commom'
import EventEmitter from 'events'

const emitter = new EventEmitter()

class CampaignCompanyDetailsPage extends Component {
  state = { clicked: false }

  tabChangeEventeEmitter() {
    emitter.emit('CampaignCompanyDetailsForm', {})
  }

  render() {
    const { currentUser, match } = this.props
    if (!currentUser) return <LoadingScreen />

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col s12 m3'>
            <CampaignNavigationOptions match={match} onTabChange={this.tabChangeEventeEmitter} />
          </div>
          <div className='col s12 m9'>
            <CampaignCompanyDetailsForm match={match} tabChanged={emitter} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default connect(mapStateToProps)(CampaignCompanyDetailsPage)
