import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'

import CampaignNavigationOptions from '../components/CampaignNavigationOptions'
import { Button } from '../components/commom'
import { LoadingScreen } from '../components/commom'

import { modules } from '../constants'
import { getCampaignById, updateCampaign } from '../services/v3'

import 'react-quill/dist/quill.snow.css'
import '../css/react-quill.scss'

const AboutIndustry = ({ match }) => {
  const [quillValue, setQuillValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getCampaign = async () => {
      try {
        setIsLoading(true)
        const { data: campaign } = await getCampaignById(match.params.campaignURL)
        setQuillValue(campaign.about_company || '')
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar as informações setoriais da campanha')
      } finally {
        setIsLoading(false)
      }
    }
    getCampaign()
  }, [match.params.campaignURL])

  const handleSubmit = async () => {
    try {
      await updateCampaign(match.params.campaignURL, { about_company: quillValue })
      toast.success('Setoriais atualizado com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar atualizar as informações setoriais')
    }
  }

  if (isLoading) return <LoadingScreen />

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <div className='dfp'>
            <ReactQuill
              className='react-quill'
              theme='snow'
              value={quillValue}
              onChange={value => setQuillValue(value)}
              placeholder='Setoriais/Sobre a indústria'
              modules={modules}
            />
          </div>
          <div className='dfp'>
            <Button onClick={handleSubmit} text='SALVAR' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutIndustry
