import React from 'react'

import { formatMoney, sanitizeDate } from '../helpers'
import { installmentStatuses } from '../constants'

const InstallmentRow = ({ portion, handleRowClick }) => {
  return (
    <tr className='accordion-table-row' onClick={handleRowClick}>
      <th className='accordion-table-cell col-mw50'>
        <i
          className='material-icons'
          style={{
            color: 'green',
            visibility: portion.abbreviation !== installmentStatuses.PAID ? 'hidden' : 'visible'
          }}
        >
          check_circle
        </i>
        <p className='acordion-table-cell__content'>
          {portion.installment_number}/{portion.total_installments}
        </p>
      </th>
      <th className='accordion-table-cell col-mw100'>
        {`R$ ${formatMoney(portion.transfer_amount_delayed || portion.transfer_amount)}`}
      </th>
      <th className='accordion-table-cell col-mw80'>
        {sanitizeDate(portion.transfer_date_delayed || portion.transfer_date)}
      </th>
      <th className='accordion-table-cell col-mw80'>{sanitizeDate(portion.pay_date)}</th>
    </tr>
  )
}

export default InstallmentRow
