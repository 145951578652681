import { validateCPF } from './validateCPF'

export const loginFormValidator = data => {
  let errors = {}

  if (!data.cpf) errors.cpf = 'CPF é obrigatório'
  if (!data.password) errors.password = 'Senha é um campo obrigatório'

  if (data.cpf && !validateCPF(data.cpf)) {
    errors.cpf = 'CPF inválido'
  }

  return errors
}
