import React from 'react'
import styled from 'styled-components'

import { NumberInput, Input } from '../index'

const ContactData = ({ errors, isMobile, isTablet, control, disable = false }) => {
  return (
    <div>
      <Row mt={5}>
        <Input
          mt={5}
          error={errors.first_name}
          label='Nome *'
          control={control}
          name='first_name'
          width={isMobile || isTablet ? '90%' : '45%'}
          disabled={disable}
        />
        <Input
          mt={5}
          error={errors.last_name}
          label='Sobrenome *'
          control={control}
          name='last_name'
          width={isMobile || isTablet ? '90%' : '45%'}
          ml={isMobile || isTablet ? 0 : 3}
          disabled={disable}
        />
      </Row>
      <Row>
        <NumberInput
          mt={5}
          error={errors.celphone}
          inputType='celphone'
          control={control}
          name='celphone'
          width={isMobile || isTablet ? '90%' : '45%'}
          disabled={disable}
        />
        <NumberInput
          mt={5}
          inputType='phone'
          control={control}
          name='telephone'
          width={isMobile || isTablet ? '90%' : '45%'}
          ml={isMobile || isTablet ? 0 : 3}
          disabled={disable}
        />
      </Row>
      <Row>
        <Input
          mt={5}
          error={errors.email}
          label='E-mail *'
          control={control}
          name='email'
          width={isMobile || isTablet ? '90%' : '45%'}
          disabled={disable}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

export default ContactData
