import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import PeopleIcon from '@material-ui/icons/People'
import WorkIcon from '@material-ui/icons/Work'
import MonetizationOn from '@material-ui/icons/MonetizationOn'

import { toast } from 'react-toastify'
import { getCurrentUser } from '../services/v3'

import { Button } from '../components/v2Components'

const Partner = props => {
  const [isPartner, setIsPartner] = useState(false)
  const [isLogged, setIsLogged] = useState(false)

  const handlePartner = async () => {
    try {
      props.history.push('/indicacao-parceria')
    } catch (error) {
      toast.info('Você ainda não é um Parceiro')
    }
  }

  const handleRegisterPartner = async () => {
    try {
      props.history.push('/cadastro-parceria')
    } catch (error) {}
  }

  useEffect(() => {
    const isLoged = async () => {
      try {
        const response = await getCurrentUser()
        setIsLogged(true)
        if (response.data.partner) {
          setIsPartner(true)
        }
      } catch (error) {
        setIsLogged(false)
      }
    }
    isLoged()
  }, [])

  return (
    <Container>
      <Banner>
        <Mask>
          <BannerContainerText>
            <Text>Aumente a sua renda sendo um parceiro da Kavod Lending e indicando empresas</Text>
            <ParagraphContainer>
              <Paragraph>Se você ainda não é um parceiro Kavod, cadastre-se para começar a indicar empresas</Paragraph>
            </ParagraphContainer>
            <ButtonDiv>
              {isLogged && !isPartner && (
                <Button maxWidth={180} width='100%' marginLeft={1} marginRight={1} onClick={handleRegisterPartner}>
                  Realizar cadastro
                </Button>
              )}
              {isLogged && isPartner && (
                <Button maxWidth={180} width='100%' marginLeft={1} marginRight={1} onClick={handlePartner}>
                  Indicar empresa
                </Button>
              )}
              {!isLogged && (
                <Button maxWidth={180} width='100%' marginLeft={1} marginRight={1} onClick={handleRegisterPartner}>
                  Realizar cadastro
                </Button>
              )}
              {!isLogged && (
                <ButtonOut
                  variant='outlined'
                  maxWidth={180}
                  width='100%'
                  marginLeft={1}
                  marginRight={1}
                  onClick={handlePartner}
                >
                  Já sou parceiro
                </ButtonOut>
              )}
            </ButtonDiv>
          </BannerContainerText>
        </Mask>
      </Banner>
      <Content>
        <ContentContainer>
          <Link to='/cadastro-parceria'>
            <PeopleIcon style={{ color: '#076f9f', fontSize: 80 }} />
          </Link>
          <LinkTitle to='/cadastro-parceria'>1. Cadastre-se como parceiro</LinkTitle>
          <Paragraph>
            Basta fazer um simples cadastro e, após aprovado, você já poderá começar a fazer as indicações
          </Paragraph>
        </ContentContainer>
        <ContentContainer>
          <Link to='/indicacao-parceria'>
            <WorkIcon style={{ color: '#076f9f', fontSize: 80 }} />
          </Link>
          <LinkTitle to='/indicacao-parceria'>2. Indique empresas</LinkTitle>
          <Paragraph color='black'>
            Quanto mais empresas indicar, maior a chance de aumentar a sua renda como Parceiro Kavod
          </Paragraph>
        </ContentContainer>
        <ContentContainer>
          <Link to='/indicacao-parceria'>
            <MonetizationOn style={{ color: '#076f9f', fontSize: 80 }} />
          </Link>
          <LinkTitle to='/indicacao-parceria'>3. Aumente a sua renda</LinkTitle>
          <Paragraph color='black'>
            Para cada empresa indicada e aprovada, você receberá uma comissão da Kavod
          </Paragraph>
        </ContentContainer>
      </Content>
      <Footer>
        <FooterCopyright>Kavod Lending Tecnologia e Serviços 2018 © Todos os direitos reservados</FooterCopyright>
        <img src='/assets/img/shared/logo-branco.png' alt='Kavod lending' />
      </Footer>
    </Container>
  )
}

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  max-width: 416px;
  justify-content: space-evenly;
  margin-top: 48px;

  button {
    height: 32px;
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0px;
    height: 140px;
    align-items: center;
  }
`

const ButtonOut = styled(Button)`
  color: white !important;
  background-color: transparent !important;
  border: 2px solid white !important;

  &:hover {
    color: black !important;
    background-color: white !important;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  width: 100%;
  min-height: calc(100vh - 80px);
`

const Banner = styled.div`
  width: 100%;
  min-height: 40vh;
  background-image: url('/assets/img/Partner/partner_banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 768px) {
    min-height: 60vh;
  }
`

const Mask = styled.div`
  background-color: rgba(0, 3, -8, 0.6);
  width: 100%;
  min-height: 45vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 768px) {
    min-height: 60vh;
  }
`

const BannerContainerText = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`

const Paragraph = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  color: ${({ color }) => color};
`

const ParagraphContainer = styled.div`
  margin-top: 10px;
`

const Text = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  max-width: 685px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  @media (max-width: 768px) {
    min-height: 75vh;
  }
`

const ContentContainer = styled.div`
  width: 25vw;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    color: #424242;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    width: 70vw;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
  }
`

const LinkTitle = styled(Link)`
  color: #076f9f;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
`

const Footer = styled.div`
  background-color: #076f9f;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const FooterCopyright = styled.p`
  font-size: 12px;
`

export default Partner
