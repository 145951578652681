import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import InputBase from './InputBase'

import { formatValueToBrl, timeValidation } from '../../../helpers'

const NumberInput = ({
  name,
  label,
  inputType,
  defaultValue,
  helperText,
  control,
  icon,
  suffix,
  prefix,
  children,
  mask,
  type,
  register,
  format,
  decimalScale,
  fixedDecimalScale,
  thousandSeparator,
  placeholder,
  disabled,
  inputProps,
  ...props
}) => {
  const numberType = {
    cpf: {
      label: 'CPF',
      placeholder: '000.000.000-00',
      format: '###.###.###-##',
      mask: '_'
    },
    cnpj: {
      label: 'CNPJ',
      placeholder: '00.000.000/0000-00',
      format: '##.###.###/####-##',
      mask: '_'
    },
    cep: {
      label: 'CEP',
      placeholder: '00000-000',
      format: '#####-###',
      mask: '_'
    },
    phone: {
      label: 'Telefone fixo (opcional)',
      placeholder: '(00) 0000 - 0000',
      format: '(##) #### - ####',
      mask: '_'
    },
    celphone: {
      label: 'Celular *',
      placeholder: '(00) 0 0000 - 0000',
      format: '(##) # #### - ####',
      mask: '_'
    },
    hour: {
      label: 'Horas',
      placeholder: 'HH:MM',
      format: timeValidation
    },
    currency: {
      label: 'Valor',
      type: 'currency',
      placeholder: 'R$ 0,00',
      format: value => formatValueToBrl(value / 100)
    },
    date: {
      label: 'Data',
      placeholder: '00/00/0000',
      format: '##/##/####',
      mask: '_'
    }
  }

  const numberFormatProps = {
    ...((placeholder && { shrink: true }) || (inputType && { shrink: true })),
    ...(inputType && numberType[inputType]),
    ...(label && { label }),
    ...(mask && { mask }),
    ...(decimalScale && { decimalScale })
  }

  return (
    <Controller
      as={
        <NumberFormat
          {...numberFormatProps}
          prefix={prefix}
          suffix={suffix}
          customInput={InputBase}
          decimalScale={decimalScale}
          decimalSeparator={','}
          fixedDecimalScale={fixedDecimalScale}
          thousandSeparator={thousandSeparator || null}
          {...props}
        />
      }
      defaultValue={defaultValue}
      name={name}
      control={control}
      ref={register}
      disabled={disabled}
      fullWidth
    />
  )
}

NumberInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string
    }),
    PropTypes.bool
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  inputType: PropTypes.oneOf(['cpf', 'cnpj', 'cep', 'phone', 'celphone', 'hour', 'currency', 'date']),
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputProps: PropTypes.object,
  mask: PropTypes.string,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

NumberInput.defaultProps = {
  variant: 'outlined',
  size: 'small',
  multiline: false
}

export default NumberInput
