import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import UploadCampaignProfilePhoto from './UploadCampaignProfilePhoto'
import { LoadingScreen } from '../components/commom'

import { getCampaignById, getCampaignPhotoTypes, getPhotos } from '../services/v3'

const CampaignUploadProfileImgForm = props => {
  const [loading, setLoading] = useState(true)
  const [campaign, setCampaign] = useState({})
  const [campaignProfileImg, setCampaignProfileImg] = useState(null)
  const [profilePhotoTypeId, setProfilePhotoTypeId] = useState(null)

  const fetchInfos = useCallback(async () => {
    try {
      setLoading(true)
      const { campaignURL } = props.match.params

      const [{ data: campaign }, { data: campaignPhotos }, { data: campaignPhotoTypes }] = await Promise.all([
        getCampaignById(campaignURL),
        getPhotos({ campaign_id: campaignURL }),
        getCampaignPhotoTypes()
      ])

      const profilePhotoType = campaignPhotoTypes.find(({ abbreviation }) => abbreviation === 'B')

      const brandImage = campaignPhotos.find(
        ({ photo_url, campaign_photo_type_id }) => photo_url && campaign_photo_type_id === profilePhotoType.id
      )

      setCampaign(campaign)
      setCampaignProfileImg(brandImage)
      setProfilePhotoTypeId(profilePhotoType.id)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar a campanha')
    } finally {
      setLoading(false)
    }
  }, [props.match.params])

  useEffect(() => {
    fetchInfos()
  }, [props.match.params.campaignURL, fetchInfos])

  if (loading) return <LoadingScreen />

  return (
    <div>
      <div className='container'>
        <UploadCampaignProfilePhoto
          photoTypeId={profilePhotoTypeId}
          campaign={campaign}
          refetch={fetchInfos}
          campaignProfileImg={campaignProfileImg}
        />
      </div>
      {!campaign.id || !campaignProfileImg || !campaignProfileImg.photo_url ? (
        <div>
          <p>Sem foto de perfil</p>
        </div>
      ) : (
        <div className='row'>
          <div className='col s12 m6 offset-m3 l6 offset-l3 center-align'>
            <img
              className='responsive-img dfp'
              alt='campaign profile'
              src={`${campaignProfileImg.photo_url}?timestamp=${new Date()}`}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default connect()(CampaignUploadProfileImgForm)
