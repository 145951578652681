import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Image from 'material-ui-image'

import { CampaignStatus } from './commom'

import { calculatePercent, remainingDays, formatCurrency, getAnnualRate, getOldStatus } from '../helpers'
import { campaignTypes, campaignStatuses } from '../constants'

const styleLabels = {
  display: 'inline-flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between'
}

const validStatus = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION, campaignStatuses.WAITING_LIST]

class CampaignCard extends Component {
  state = { cube: null }

  showRight(e) {
    let cube = document.getElementById(e)
    cube.style.transform = 'translateZ(-150px) rotateY(-90deg)'
  }
  showFront(e) {
    let cube = document.getElementById(e)
    cube.style.transform = 'rotateY(0deg) translateZ(-150px)'
  }
  touchHandler(element) {
    this.setState({ cube: element })
    var startx = 0
    var dist = 0
    element.addEventListener('touchstart', e => {
      var touchobj = e.changedTouches[0]
      startx = parseInt(touchobj.clientX, 10)
    })
    element.addEventListener('touchmove', e => {
      var touchobj = e.changedTouches[0]
      dist = parseInt(touchobj.clientX, 10) - startx
      if (dist < -50) {
        this.showRight(this.props.campaign.id)
      }
      if (dist > 50) {
        this.showFront(this.props.campaign.id)
      }
    })
  }

  resizeCube(scene) {
    var cs = getComputedStyle(scene)
    var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight)
    let elementWidth = scene.offsetWidth - paddingX

    ;[...document.getElementsByClassName('cube')].forEach(cube => {
      cube.style.width = `${elementWidth}px `
    })
    ;[...document.getElementsByClassName('cube__face')].forEach(cubeFace => {
      cubeFace.style.width = `${elementWidth}px `
    })
    ;[...document.getElementsByClassName('cube__face--front')].forEach(faceFront => {
      faceFront.style.transform = `rotateY(0deg) translateZ(${elementWidth / 2}px) `
    })
    ;[...document.getElementsByClassName('cube__face--right')].forEach(faceRight => {
      faceRight.style.transform = `rotateY(90deg) translateZ(${elementWidth / 2}px) `
    })
  }

  componentDidUpdate() {
    if (this.state.cube === null) {
      let cube = document.getElementById(this.props.campaign.id)
      let scene = document.getElementById('wrap' + this.props.campaign.id)
      if (cube && scene) {
        window.addEventListener('resize', () => this.resizeCube(scene))
        this.touchHandler(cube)
        this.resizeCube(scene)
      }
    }
  }

  hasValidStatus = campaignStatus => validStatus.includes(campaignStatus)

  render() {
    const { campaign } = this.props
    const total = campaign.total_captured
    const campaignStatus = getOldStatus(campaign.campaign_status.abbreviation)
    const campaignIsClosed = [campaignStatuses.CLOSED, campaignStatuses.CLOSED_WITHOUT_CAPTATION].includes(
      campaignStatus
    )
    const isGoalAchieved = [campaignStatuses.CLOSED, campaignStatuses.WAITING_LIST].includes(campaignStatus)

    return (
      <div
        className='scene col s12 m6 l4 cursor-pointer'
        onMouseEnter={() => this.showRight(campaign.id)}
        onMouseLeave={() => this.showFront(campaign.id)}
        id={'wrap' + campaign.id}
        onClick={() => {
          if (campaign.campaign_type_id === campaignTypes.AVAL)
            this.props.history.push('/campanhas/com-aval/' + campaign.url)
          if (campaign.campaign_type_id === campaignTypes.AVAL_E_GARANTIA)
            this.props.history.push('/campanhas/com-aval-e-garantia/' + campaign.url)
        }}
      >
        <div className='cube' id={campaign.id}>
          <div className='cube__face cube__face--front hoverable'>
            <h5 className='cube-title'>{campaign.name}</h5>
            <div className='align-to-start'>
              <div className='responsive-img'>
                <Image
                  disableSpinner={true}
                  src={campaign.brand_image ? campaign.brand_image.photo_url : ''}
                  id={campaign.id}
                />
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    color: '#222  ',
                    width: '95%',
                    margin: 'auto',
                    marginTop: '5px',
                    textAlign: 'left'
                  }}
                >
                  <div style={styleLabels}>
                    <div>Taxa mensal</div>
                    <div>Taxa anual</div>
                    <div>Prazo</div>
                  </div>
                  <div style={styleLabels}>
                    <div>{campaign.rate_month}% a.m.</div>
                    <div>{getAnnualRate(campaign.rate_month)}% </div>
                    <div>{campaign.installments} Meses</div>
                  </div>
                </div>
                {this.hasValidStatus(campaignStatus) && <CampaignStatus status={campaignStatus} />}
              </div>
              <div className='info-area'>
                <span className='values-label'>
                  Captado: {isGoalAchieved ? 100 : calculatePercent(campaign.amount, total)}%
                </span>
                <div className='progress'>
                  <div
                    className='determinate'
                    style={{
                      width: isGoalAchieved ? '100%' : calculatePercent(campaign.amount, total) + '%'
                    }}
                  />
                </div>
                <div className='row'>
                  <div className='col s12 left-align'>
                    <span className='values'>Meta: </span>
                    <span className='values-label'>{formatCurrency(campaign.amount)}</span>
                  </div>
                  <div className='col s12 left-align'>
                    <span className='values'>Captado: </span>
                    <span className='values-label'>
                      {isGoalAchieved
                        ? formatCurrency(campaign.amount)
                        : formatCurrency(total > campaign.amount ? campaign.amount : total)}
                    </span>
                  </div>
                  <div className='col s12 left-align'>
                    <span className='values'>Dias&nbsp;restantes: </span>
                    <span className='values-label'>{campaignIsClosed ? 0 : remainingDays(campaign.end_date)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='cube__face cube__face--right hoverable'>
            <div className='align-to-start'>
              <h5 className='cube-title' style={{ marginTop: '15px' }}>
                {campaign.name}
              </h5>
              <div style={{ margin: '15px 15px 0 15px' }}>
                <span className='values-label'>
                  Captado:&nbsp;
                  {isGoalAchieved
                    ? `${formatCurrency(campaign.amount)} 100%`
                    : `${formatCurrency(total > campaign.amount ? campaign.amount : total)} ${calculatePercent(
                        campaign.amount,
                        total
                      )}%`}
                </span>
                <div className='progress'>
                  <div
                    className='determinate'
                    style={{
                      width: isGoalAchieved ? '100%' : calculatePercent(campaign.amount, total) + '%'
                    }}
                  />
                </div>
              </div>
              <div
                className='short-desc'
                dangerouslySetInnerHTML={{
                  __html: campaign.short_description
                }}
              />
            </div>
            <div className='show-details'>Clique para saber mais...</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CampaignCard)
