import { isEmpty } from 'lodash'
import {
  FETCH_PERSONAL_DOCUMENTS_FAIL,
  FETCH_PERSONAL_DOCUMENTS_SUCCESS,
  FETCH_PERSONAL_DOCUMENTS_LOADING,
  FETCH_ALL_DOCUMENTS_SUCCESS
} from '../actions/types'

const INITIAL_STATE = {
  loading: true,
  fail: false,
  data: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PERSONAL_DOCUMENTS_LOADING:
      return { fail: false, loading: true, data: null }

    case FETCH_PERSONAL_DOCUMENTS_SUCCESS:
      const data = isEmpty(action.payload) ? null : action.payload
      return { fail: false, loading: true, data: { ...state.data, ...data } }

    case FETCH_PERSONAL_DOCUMENTS_FAIL:
      return { fail: true, loading: false, data: null }

    case FETCH_ALL_DOCUMENTS_SUCCESS:
      return { fail: false, loading: false, data: { ...state.data } }

    default:
      return state
  }
}
