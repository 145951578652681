import React from 'react'
import { connect } from 'react-redux'
import EventEmitter from 'events'

import { LoadingScreen } from '../components/commom'
import CampaignNavigationOptions from './CampaignNavigationOptions'
import CampaignInformationForm from '../containers/CampaignInformationForm'

const emitter = new EventEmitter()

const CampaignInformationPage = ({ currentUser, match }) => {
  const tabChangeEventeEmitter = () => {
    emitter.emit('CampaignInformationForm', {})
  }

  if (!currentUser) return <LoadingScreen />

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} onTabChange={tabChangeEventeEmitter} />
        </div>
        <div className='col s12 m9'>
          <CampaignInformationForm match={match} tabChanged={emitter} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })

export default connect(mapStateToProps)(CampaignInformationPage)
