export const campaignUpdateFormValidator = data => {
  let response = {}
  response.errors = []

  if (!data.title) {
    response.isValid = false
    response.errors.push('Título é um campo obrigatório')
    return response
  }
  if (!data.date) {
    response.isValid = false
    response.errors.push('Data atualização é um campo obrigatório')
    return response
  }
  if (!data.description) {
    response.isValid = false
    response.errors.push('Descrição é um campo obrigatório')
    return response
  }

  response.isValid = true
  return response
}
