import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import { toast } from 'react-toastify'
import publicIp from 'public-ip'

import { LoadingScreen, BorderedButton } from '../../components/commom'
import handleField from '../../forms/handleField'

import { documentsForm, termsAndConditionsForm } from '../../forms'
import { documentsValidator } from '../../validators'
import {
  getAllDocumentTypes,
  getAllComplianceAgreementTypes,
  createComplianceAgreement,
  createDocument,
  updateDocument
} from '../../services/v3'
import { checkIsCompletedDocuments } from '../../helpers'

class UserPersonalDocumentsForm extends Component {
  state = {
    submitting: false,
    withFirstDoc: null,
    withSecondDoc: null,
    complianceDocStatus: false,
    loading: true,
    identificationDocuments: [],
    residenceDocuments: null,
    investorComplianceAgreements: null,
    identificationDoc: null,
    residenceDoc: null,
    isCompleted: false
  }

  fetchInfos = async () => {
    try {
      this.setState({ loading: true })
      const { investor, dispatch, form } = this.props
      const investorComplianceAgreements = investor.compliance_agreements

      const [{ data: allDocumentTypes }, { data: allComplianceAgreementTypes }] = await Promise.all([
        getAllDocumentTypes(),
        getAllComplianceAgreementTypes()
      ])

      const complianceTypes = allComplianceAgreementTypes.filter(({ abbreviation }) =>
        ['I', 'P'].includes(abbreviation)
      )

      const complianceDocStatus = complianceTypes.every(complianceType =>
        investorComplianceAgreements.some(
          investorCompliance => investorCompliance.compliance_agreement_type_id === complianceType.id
        )
      )

      if (investor) {
        const identificationDoc = investor.documents.find(
          ({ document_type, s3_name }) => ['C', 'R', 'O', 'RN'].includes(document_type.abbreviation) && s3_name
        )

        const residenceDoc = investor.documents.find(
          ({ document_type, s3_name }) => document_type.abbreviation === 'CR' && s3_name
        )

        if (identificationDoc) {
          dispatch(change(form, 'personal_document_type_id1', identificationDoc.document_type.abbreviation))
          dispatch(change(form, 'with_document_type_id1', true))
          this.setState({ withFirstDoc: identificationDoc })
        } else {
          dispatch(change(form, 'personal_document_type_id1', 'C'))
        }

        if (residenceDoc) {
          dispatch(change(form, 'personal_document_type_id2', 'CR'))
          dispatch(change(form, 'with_document_type_id2', true))
          this.setState({ withSecondDoc: residenceDoc })
        }
      }

      this.setState({
        identificationDocuments: allDocumentTypes.filter(({ abbreviation }) =>
          ['C', 'R', 'O', 'RN'].includes(abbreviation)
        ),
        residenceDocuments: allDocumentTypes.find(({ abbreviation }) => abbreviation === 'CR'),
        investorComplianceAgreements,
        complianceDocStatus,
        isCompleted: await checkIsCompletedDocuments(investor)
      })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao carregar os dados')
    } finally {
      this.setState({ loading: false })
    }
  }

  async componentDidMount() {
    this.fetchInfos()
  }

  submit = async data => {
    try {
      const {
        complianceDocStatus,
        residenceDocuments,
        identificationDocuments,
        withFirstDoc,
        withSecondDoc
      } = this.state
      const { investor } = this.props

      this.setState({ submitting: true })

      if (!complianceDocStatus) {
        const ip = await publicIp.v4()
        await createComplianceAgreement({ ip, investor_id: investor.id })
        this.setState({ complianceDocStatus: true })
      }

      if (data.upload_personal_document_type_id1) {
        const newDocType = identificationDocuments.find(
          ({ abbreviation }) => abbreviation === data.personal_document_type_id1
        )
        const formData = new FormData()
        formData.append('investor_id', investor.id)
        formData.append('document_type_id', newDocType.id)
        formData.append('file', data.upload_personal_document_type_id1)

        withFirstDoc ? await updateDocument(withFirstDoc.id, formData) : await createDocument(formData)
      }

      if (data.upload_personal_document_type_id2) {
        const formData = new FormData()
        formData.append('investor_id', investor.id)
        formData.append('document_type_id', residenceDocuments.id)
        formData.append('file', data.upload_personal_document_type_id2)

        withSecondDoc ? await updateDocument(withSecondDoc.id, formData) : await createDocument(formData)
      }
      toast.success('Documentos atualizado com sucesso')
      await this.props.fetchInvestor()
      await this.fetchInfos()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao salvar os documentos.')
    } finally {
      this.setState({ submitting: false })
    }
  }

  renderField = field => handleField(field)

  renderContent = fields =>
    fields.map(field => {
      const { dispatch, form } = this.props
      if (field.name === 'personal_document_type_id2') {
        dispatch(change(form, 'personal_document_type_id2', 'CR'))
        return (
          <p key={field.id} style={{ color: '#9e9e9e' }}>
            Comprovante de residência * (Máximo: 5MB)
          </p>
        )
      }
      if (field.name === 'upload_personal_document_type_id1' && this.state.withFirstDoc) {
        field.document = this.state.withFirstDoc
      }
      if (field.name === 'upload_personal_document_type_id2' && this.state.withSecondDoc) {
        field.document = this.state.withSecondDoc
      }
      return (
        <Field
          key={field.id}
          label={field.label}
          placeholder={field.placeholder}
          name={field.name}
          text={field.text}
          id={field.id}
          type={field.type}
          error={field.error}
          document={field.document}
          readOnly={field.readOnly}
          options={field.options}
          component={this.renderField}
          value={field.value}
        />
      )
    })

  render() {
    const { handleSubmit, invalid, submitFailed } = this.props
    const { loading, complianceDocStatus, isCompleted } = this.state
    return (
      <li style={this.props.style}>
        <div className='collapsible-header'>
          <div className='collapsible-description'>
            <i className='material-icons'>folder</i>
            <p>Documentos</p>
          </div>
          <i className='material-icons' style={{ color: isCompleted ? 'green' : 'grey' }}>
            check_circle
          </i>
        </div>
        <div className='collapsible-body'>
          {loading ? (
            <LoadingScreen small />
          ) : (
            <form onSubmit={handleSubmit(this.submit)}>
              <div className='row'>
                <div className='col s12'>{this.renderContent(documentsForm)}</div>
              </div>
              <div className='row'>
                {!complianceDocStatus && <div className='col s12'>{this.renderContent(termsAndConditionsForm)}</div>}
                {invalid && submitFailed && (
                  <span className='helper-text'>Verifique os campos em branco/inválidos</span>
                )}
              </div>
              <div className='dfp'>
                <BorderedButton center type='submit' label='CONCLUIR' />
              </div>
            </form>
          )}
        </div>
      </li>
    )
  }
}

export default reduxForm({
  form: 'userPersonalDocumentsForm',
  validate: documentsValidator,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
})(connect()(UserPersonalDocumentsForm))
