import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Theme'

const Label = ({ focused, label }) => {
  return <StyledLabel focused={focused}>{label}</StyledLabel>
}

const StyledLabel = styled.label`
  color: ${({ focused }) => (focused ? colors.blueKavod : colors.mediumGray)};
  font-size: 14px;
`

export default Label
