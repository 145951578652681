import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import FormStatusReducer from './reducer_form_status'
import CampaignGalleryReducer from './reducer_campaign_gallery'
import CampaignProfileImgReducer from './reducer_campaign_profile_img'
import CampaignUpdatesReducer from './reducer_campaign_updates'
import CampaignUpdateReducer from './reducer_campaign_update'
import CampaignsReducer from './reducer_campaigns'
import CampaignReducer from './reducer_campaign'
import AuthReducer from './reducer_auth'
import PledgeReducer from './reducer_pledge'
import PhoneReducer from './reducer_phone'
import PersonReducer from './reducer_person'
import BankReducer from './reducer_bank'
import AddressReducer from './reducer_address'
import CurrentInvestmentReducer from './reducer_current_investment'
import InvestorReducer from './reducer_investor'
import InvestorComplianceReducer from './reducer_investor_compliance'
import PersonalDocumentsReducer from './reducer_personal_documents'
import ProfileCompletedReducer from './reducer_profile_complete'
import complianceDocsReducer from './reducer_compliance_doc'
import companiesReducer from './reducer_companies'

const rootReducer = combineReducers({
  formStatus: FormStatusReducer,
  campaignGallery: CampaignGalleryReducer,
  campaignProfileImg: CampaignProfileImgReducer,
  campaignUpdates: CampaignUpdatesReducer,
  campaignUpdate: CampaignUpdateReducer,
  campaigns: CampaignsReducer,
  campaign: CampaignReducer,
  currentUser: AuthReducer,
  form: formReducer,
  pledges: PledgeReducer,
  phone: PhoneReducer,
  person: PersonReducer,
  bank: BankReducer,
  address: AddressReducer,
  currentInvestment: CurrentInvestmentReducer,
  investor: InvestorReducer,
  investorCompliance: InvestorComplianceReducer,
  personalDocuments: PersonalDocumentsReducer,
  completeProfile: ProfileCompletedReducer,
  complianceDocs: complianceDocsReducer,
  companies: companiesReducer
})

export default rootReducer
