import React from 'react'

import {
  Apps,
  Tune,
  Search,
  Visibility,
  VisibilityOff,
  ExpandMore,
  ExpandLess,
  Clear,
  ImportExport,
  ChevronLeft,
  ChevronRight,
  Update,
  CheckCircle,
  CancelRounded,
  AddCircleRounded,
  InfoOutlined,
  InfoRounded,
  CalendarTodayRounded,
  LaunchRounded,
  NotesRounded,
  EditRounded,
  MonetizationOnRounded,
  ArrowRightAltRounded,
  ArrowDropDownRounded,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  RadioButtonUncheckedRounded,
  RadioButtonCheckedRounded,
  Check,
  FiberManualRecord,
  MenuRounded,
  AttachFile,
  SpeakerNotes,
  Phone,
  AttachMoneyRounded
} from '@material-ui/icons'
import { Export, Calculate, Download, Task } from './custom'

const IconMonetization2 = props => <AttachMoneyRounded {...props} />

const IconTask = props => <Task {...props} />

const IconPhone = props => <Phone {...props} />

const IconSpeakerNotes = props => <SpeakerNotes {...props} />

const IconApps = props => <Apps {...props} />

const IconTune = props => <Tune {...props} />

const IconSearch = props => <Search {...props} />

const IconVisibility = props => <Visibility {...props} />

const IconVisibilityOff = props => <VisibilityOff {...props} />

const IconExpandMore = props => <ExpandMore {...props} />

const IconExpandLess = props => <ExpandLess {...props} />

const IconClear = props => <Clear {...props} />

const IconImportExport = props => <ImportExport {...props} />

const IconChevronLeft = props => <ChevronLeft {...props} />

const IconChevronRight = props => <ChevronRight {...props} />

const IconUpdate = props => <Update {...props} />

const IconExport = props => <Export {...props} />

const IconCheckCircle = props => <CheckCircle {...props} />

const IconCalculate = props => <Calculate {...props} />

const IconCancel = props => <CancelRounded {...props} />

const IconAddCircle = props => <AddCircleRounded {...props} />

const IconInfo = props => <InfoOutlined {...props} />

const IconInfoRounded = props => <InfoRounded {...props} />

const IconCalendar = props => <CalendarTodayRounded {...props} />

const IconLaunch = props => <LaunchRounded {...props} />

const IconNotes = props => <NotesRounded {...props} />

const IconEdit = props => <EditRounded {...props} />

const IconDownload = props => <Download {...props} />

const IconMonetization = props => <MonetizationOnRounded {...props} />

const IconArrowRightAlt = props => <ArrowRightAltRounded {...props} />

const IconArrowDropDown = props => <ArrowDropDownRounded {...props} />

const IconCheckBoxOutlineBlank = props => <CheckBoxOutlineBlankRounded {...props} />

const IconCheckBox = props => <CheckBoxRounded {...props} />

const IconRadioButtonUnchecked = props => <RadioButtonUncheckedRounded {...props} />

const IconRadioButtonChecked = props => <RadioButtonCheckedRounded {...props} />

const IconCheck = props => <Check {...props} />

const IconCircleFilled = props => <FiberManualRecord {...props} />

const IconMenu = props => <MenuRounded {...props} />
const IconAttachFileIcon = props => <AttachFile {...props} />

export {
  IconApps,
  IconTune,
  IconSearch,
  IconVisibility,
  IconVisibilityOff,
  IconExpandMore,
  IconExpandLess,
  IconClear,
  IconImportExport,
  IconChevronLeft,
  IconChevronRight,
  IconUpdate,
  IconCheckCircle,
  IconCalculate,
  IconExport,
  IconCancel,
  IconAddCircle,
  IconInfo,
  IconInfoRounded,
  IconCalendar,
  IconLaunch,
  IconNotes,
  IconEdit,
  IconDownload,
  IconMonetization,
  IconArrowRightAlt,
  IconArrowDropDown,
  IconCheckBoxOutlineBlank,
  IconCheckBox,
  IconRadioButtonUnchecked,
  IconRadioButtonChecked,
  IconCheck,
  IconCircleFilled,
  IconMenu,
  IconAttachFileIcon,
  IconSpeakerNotes,
  IconPhone,
  IconTask,
  IconMonetization2
}
