import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import CampaignNavigationOptions from './CampaignNavigationOptions'
import { Button, LoadingScreen } from '../components/commom'

import {
  getCampaignById,
  getPhotos,
  getCampaignPhotoTypes,
  createCampaignPhoto,
  updateCampaignPhoto
} from '../services/v3'

const CampaignInvestmentsPhoto = ({ match }) => {
  const [campaign, setCampaign] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [campaignDashImg, setCampaignDashImg] = useState(null)
  const [dashPhotoTypeId, setDashPhotoTypeId] = useState(null)
  const [currentImg, setCurrentImg] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  const fetchInfos = useCallback(async () => {
    try {
      setIsLoading(true)
      const { campaignURL } = match.params

      const [{ data: campaign }, { data: campaignPhotos }, { data: campaignPhotoTypes }] = await Promise.all([
        getCampaignById(campaignURL),
        getPhotos({ campaign_id: campaignURL }),
        getCampaignPhotoTypes()
      ])

      const dashPhotoType = campaignPhotoTypes.find(({ abbreviation }) => abbreviation === 'D')

      const dashImage = campaignPhotos.find(
        ({ photo_url, campaign_photo_type_id }) => photo_url && campaign_photo_type_id === dashPhotoType.id
      )
      setCampaign(campaign)
      setCampaignDashImg(dashImage)
      setDashPhotoTypeId(dashPhotoType.id)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar a campanha')
    } finally {
      setIsLoading(false)
    }
  }, [match.params])

  useEffect(() => {
    fetchInfos()
  }, [match.params.campaignURL, fetchInfos])

  const onSelectFile = e => {
    setCurrentImg(e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      setIsDisabled(false)
    }
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      formData.append('file', currentImg[0])
      formData.append('campaign_id', campaign.id)
      formData.append('campaign_photo_type_id', dashPhotoTypeId)

      if (campaignDashImg) {
        await updateCampaignPhoto(campaignDashImg.id, formData)
      } else {
        await createCampaignPhoto(formData)
      }

      toast.success('Imagem atualizada com sucesso')
      fetchInfos()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao tentar atualizar a imagem')
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col s12 m3'>
          <CampaignNavigationOptions match={match} />
        </div>
        <div className='col s12 m9'>
          <PhotoContainer>
            <form className='dfp'>
              <div className='file-field input-field'>
                <div className='btn'>
                  <span>File</span>
                  <input type='file' id='ProfileFileInput' onChange={onSelectFile} />
                </div>
                <div className='file-path-wrapper'>
                  <input className='file-path validate' type='text' />
                </div>
              </div>
            </form>
            <Button type='submit' text='UPLOAD' onClick={handleSubmit} disabled={isDisabled} />
            {!campaignDashImg || !campaignDashImg.photo_url ? (
              <p>Sem foto de perfil</p>
            ) : (
              <Image src={`${campaignDashImg.photo_url}?timestamp=${new Date()}`} />
            )}
          </PhotoContainer>
        </div>
      </div>
    </div>
  )
}

const Image = styled.img`
  width: 200px;
  margin: 1em 0;
`

const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export default CampaignInvestmentsPhoto
