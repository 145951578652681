import styled from 'styled-components'
import { Row } from '../../components/v2Components'

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 16px;
    color: #424242;

    margin-bottom: 8px;
  }

  label {
    width: 100%;
    color: #424242;
  }

  section {
    display: flex;
    flex-direction: column;
    margin-top: -16px;
    margin-bottom: 12px;
    padding-top: 0px;
  }

  section + section {
    margin-top: 0px;
    border-top: #e0e0e0 solid 1px;
  }

  footer {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 100%;
  }
`

export const DrawerTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #424242;
`

export const Detail = styled.a`
  color: #076f9f;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`
export const OptionDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
  }
  position: relative;
  top: 26px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`

export const OptionCardDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`

export const DivExport = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const RowMyIndication = styled(Row)`
  justify-content: space-between;
  align-items: center;

  button {
    width: 100%;
    max-width: 180px;
    height: 40px;
  }

  @media (max-width: 730px) {
    flex-direction: column;
    margin-bottom: 40px;

    button {
      max-width: 100%;
    }
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const StyledMain = styled.main`
  padding: 20px;
  width: 100%;
  max-width: 1260px;

  @media only screen and (max-width: 730px) {
    padding: 16px;
  }
`
