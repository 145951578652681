import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { UploadIcon, ValidateIcon } from '../icons'

const dropzoneStyleActive = {
  border: '1px solid green',
  opacity: '0.9'
}

class UploadFile extends Component {
  state = {
    allowedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
    withDocument: false
  }

  componentDidMount() {
    const { field } = this.props
    this.setState({
      withDocument: !!field.document
    })
  }

  onDrop = async (acceptedFiles, _rejectedFiles) => {
    if (!acceptedFiles.length) return
    this.props.field.input.onChange(acceptedFiles[0])
  }

  sendNewDocument = e => {
    e.preventDefault()
    this.setState({ withDocument: false })
  }

  render() {
    const { field } = this.props
    const { acceptedFiles } = this.state

    return (
      <div className='user-uploads-wrapper'>
        <label>{field.label}</label>
        <Dropzone
          onDrop={this.onDrop}
          className='dropzone flex-center-column'
          style={{
            padding: '20px',
            margin: '5px 0px 30px 0px'
          }}
          activeStyle={dropzoneStyleActive}
          multiple={false}
          accept={acceptedFiles}
        >
          <div className='flex-center-column'>
            {!field.input.value ? <UploadIcon /> : <ValidateIcon />}
            <span style={{ fontSize: '14px' }}>
              {field.text || 'Arraste ou clique para fazer upload do seu documento'}
            </span>
          </div>
        </Dropzone>

        <span className='helper-text' dataerror={field.meta.error} datasuccess=''>
          {field.meta.touched ? field.meta.error : ''}
        </span>
      </div>
    )
  }
}

export { UploadFile }
