import React from 'react'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import PledgeCard from './PledgeCard'

const ModalAggregatePledges = ({ pledges, handleDeleteCard, open, onClose }) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <ModalContainer>
        <ModalCloseIcon data-tip='Fechar' onClick={onClose} />
        {pledges.map(pledge => (
          <PledgeCard key={pledge.id} pledge={pledge} handleDeleteCard={handleDeleteCard} />
        ))}
      </ModalContainer>
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const ModalContainer = styled.div`
  width: 80vw;
  max-height: 80%;
  padding: 1em 2em;
  background-color: #fff;
  position: relative;
  outline: none;
  display: block;
  overflow: auto;
  @media (max-width: 500px) {
    height: 100%;
    width: 100%;
  }
`

const ModalCloseIcon = styled(CloseIcon)`
  color: #7f7f7f;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

export default ModalAggregatePledges
