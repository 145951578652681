import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { PortionsModal } from './commom'
import InstallmentRow from './InstallmentRow'
import AnticipationTableModal from './AnticipationTableModal'

const InstallmentsTable = ({ installments }) => {
  const [portionIndex, setPortionIndex] = useState(0)
  const [normalInstallments, setNormalInstallments] = useState([])
  const [anticipateInstallments, setAnticipateInstallments] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [anticipationModalIsOpen, setAnticipationModalIsOpen] = useState(false)

  useEffect(() => {
    setNormalInstallments(installments.filter(({ was_total_liquidation }) => !was_total_liquidation))
    setAnticipateInstallments(installments.filter(({ was_total_liquidation }) => was_total_liquidation))
  }, [installments])

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleInstallmentClick = index => () => {
    setPortionIndex(index)
    setModalIsOpen(true)
  }

  const handleAnticipationClick = () => {
    setAnticipationModalIsOpen(true)
  }

  return (
    <Container>
      <table className='accordion-investments'>
        <thead>
          <tr className='accordion-table-row'>
            <th className='accordion-table-cell --header'>#</th>
            <th className='accordion-table-cell --header'>Valor previsto da TED</th>
            <th className='accordion-table-cell --header'>Previsão de pagamento</th>
            <th className='accordion-table-cell --header'>Pago em</th>
          </tr>
        </thead>
        <tbody>
          {normalInstallments.map((portion, index) => (
            <InstallmentRow key={portion.id} portion={portion} handleRowClick={handleInstallmentClick(index)} />
          ))}
          {anticipateInstallments.length > 0 && (
            <tr className='accordion-table-row' onClick={handleAnticipationClick}>
              <AnticipationMessageRow className='accordion-table-cell'>
                Esta campanha possuía um prazo de {installments.length} parcelas porém foi quitada antecipadamente,
                clique para ver o detalhamento.
              </AnticipationMessageRow>
            </tr>
          )}
        </tbody>
      </table>
      {modalIsOpen && (
        <PortionsModal
          className='Modal'
          overlayClassName='Overlay'
          isOpen={modalIsOpen}
          closeModal={closeModal}
          portions={installments}
          index={portionIndex}
          handleChangePortion={setPortionIndex}
        />
      )}
      {anticipationModalIsOpen && (
        <AnticipationTableModal
          installments={anticipateInstallments}
          isOpen={anticipationModalIsOpen}
          onClose={() => setAnticipationModalIsOpen(false)}
          handleInstallmentClick={handleInstallmentClick}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  overflow: auto;
`

const AnticipationMessageRow = styled.th`
  height: 106px;
`

export { InstallmentsTable }
