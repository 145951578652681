import client from '../providers/fetchClient'
import {
  FETCH_PLEDGES,
  CREATE_PLEDGE,
  UPDATE_PLEDGE,
  LOADING_PLEDGES,
  CURRENT_INVESTMENT,
  CLEAR_CURRENT_INVESTMENT,
  LOADING_ALL_PLEDGES,
  FETCH_ALL_PLEDGES_SUCCESS
} from './types'

export const fetchPledge = (campaignId, kvd_id) => dispatch => {
  dispatch({ type: LOADING_PLEDGES, payload: null })
  if (kvd_id) kvd_id = '&kvd_id=' + kvd_id
  else kvd_id = ''
  if (campaignId) {
    campaignId = `campaign_id/${campaignId}`
  } else {
    campaignId = ''
    kvd_id = ''
  }
  client
    .get(`/pledge/${campaignId}` + kvd_id)
    .then(res => {
      dispatch({ type: FETCH_PLEDGES, payload: res.data })
    })
    .catch(() => {
      dispatch({ type: FETCH_PLEDGES, payload: null })
    })
}

export const fetchPledgev2 = (campaignId, kvdId) => dispatch => {
  dispatch({ type: LOADING_PLEDGES, payload: null })

  client
    .get(`/v2/users/${kvdId}/campaigns/${campaignId}/pledges`)
    .then(res => {
      dispatch({ type: FETCH_PLEDGES, payload: res.data })
    })
    .catch(() => {
      dispatch({ type: FETCH_PLEDGES, payload: null })
    })
}

export const fetchAllPledge = () => dispatch => {
  dispatch({ type: LOADING_ALL_PLEDGES, payload: null })
  client
    .get(`/v2/pledges`)
    .then(res => {
      dispatch({ type: FETCH_ALL_PLEDGES_SUCCESS, payload: res.data })
    })
    .catch(() => {
      dispatch({ type: FETCH_ALL_PLEDGES_SUCCESS, payload: null })
    })
}

export const createPledge = (data, callback) => dispatch => {
  dispatch({ type: LOADING_PLEDGES, payload: null })

  client
    .post(`/pledge/`, data)
    .then(res => {
      callback(res)
      dispatch({
        type: CREATE_PLEDGE,
        payload: { ...res.data, canAccessCheckout: true }
      })
    })
    .catch(err => {
      callback(err)
      dispatch({ type: LOADING_PLEDGES, payload: null })
    })
}

export const updatePledge = (data, callback) => dispatch => {
  dispatch({ type: LOADING_PLEDGES, payload: null })

  client
    .put(`/pledge/`, { data })
    .then(res => {
      callback(res)
      dispatch({ type: UPDATE_PLEDGE, payload: res.data })
    })
    .catch(err => {
      callback(err)
      dispatch({ type: LOADING_PLEDGES, payload: null })
    })
}

export const setCurrentInvestment = (data, callback) => dispatch => {
  dispatch({ type: CURRENT_INVESTMENT, payload: data })
  callback()
}

export const clearCurrentInvestment = () => dispatch => {
  dispatch({ type: CLEAR_CURRENT_INVESTMENT, payload: null })
}
