import React from 'react'
import Error from './Error'

import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const Checkbox = ({ register, name, label, error, children, ...props }) => {
  return (
    <Container item xs={12} {...props}>
      <Label>
        <input type='checkbox' name={name} ref={register} />
        {children}
      </Label>
      <Error error={error} />
    </Container>
  )
}

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Label = styled.label`
  margin-bottom: 8px;
`

export { Checkbox }
