import React from 'react'
import MaskedInput from 'react-text-mask'

const InputMask = ({
  helperText,
  onInputChange,
  disabled,
  onInputClick,
  className,
  handleErrorMsg,
  value,
  step,
  field,
  type,
  maxLength,
  minLength,
  placeholder,
  label,
  mask
}) => {
  return (
    <div className={`input-field ${className || ''}`}>
      <MaskedInput
        onChange={onInputChange}
        disabled={disabled}
        onClick={onInputClick}
        onBlur={handleErrorMsg}
        value={value}
        step={step}
        name={field}
        type={type}
        className='validate'
        id={field}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        mask={mask}
      />
      <label htmlFor={field}>{label}</label>
      {helperText ? <span className='helper-text'>{helperText}</span> : null}
    </div>
  )
}

export { InputMask }
