import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 96px;
  height: 32px;

  background: #ffffff;

  border-radius: 4px;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    color: #ffffff;
  }

  ${({ color }) => (color === 'blue' ? ' background: #076F9F ' : ' ')}
  ${({ color }) => (color === 'green' ? ' background: #4AB34E ' : '')}
  ${({ color }) => (color === 'red' ? ' background: #FD4646 ' : ' ')}
  ${({ color }) => (color === 'yellow' ? ' background: #F6B344' : ' ')}
  ${({ color }) => (color === 'purple' ? ' background: #663CCC' : ' ')}
`
