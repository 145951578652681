import React from 'react'

const BorderedButton = ({ label, type, className, center, disabled, ...props }) => (
  <div className={center ? 'bordered-center' : ''}>
    <button disabled={disabled} {...props} type='submit' className={`button-default button-blue ${className}`}>
      {label}
    </button>
  </div>
)

export { BorderedButton }
