import styled from 'styled-components'
import { Avatar } from '@material-ui/core'

export const InfoTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 120px;
  margin-top: 32px;
  grid-template-areas:
    'requested_amount cnpj'
    'request_date responsible';

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
  }

  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #424242;
    display: flex;
  }

  @media (max-width: 730px) {
    gap: 16px 0px;
    grid-template-areas:
      'requested_amount '
      'cnpj  '
      'request_date '
      'responsible ';
    grid-template-columns: 1fr;
  }
`

export const StyledName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #424242 !important;

  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
`
