import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { CEP_LENGTH } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const userAddressSchema = yupResolver(
  yup.object().shape({
    zip_code: yup
      .string()
      .required()
      .test('is_valid', 'CEP incompleto', value => value && value.replace(/\D/g, '').length === CEP_LENGTH),
    type: yup.string().required(),
    street: yup.string().required(),
    number: yup.string().required(),
    district: yup.string().required(),
    address_city: yup
      .object()
      .typeError('Campo Obrigatório')
      .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value))
  })
)
