import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers'

import { LoadingScreen } from '../components/commom'
import { Checkbox } from '../components/formComponents'
import { TextInput, NumberInput, Button, BlankField } from '../components/newFormComponents'

import { LOADING, SENDING, IDLE } from '../constants/status'
import { signupPartner, getCompanyInfo, getAddressByCep, checkCpf } from '../services/v3'

import { colors } from '../Theme'
import { setToken } from '../services'

const schema = yupResolver(
  yup.object().shape({
    cnpj: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CNPJ incompleto', value => value.replace(/\D/g, '').length === 14),
    company_name: yup.string().required('Campo Obrigatório'),
    zip_code: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CEP incompleto', value => value.replace(/\D/g, '').length === 8),
    street: yup.string().required('Campo Obrigatório'),
    number: yup.string().required('Campo Obrigatório'),
    district: yup.string().required('Campo Obrigatório'),
    complement: yup.string(),
    city: yup.string().required('Campo Obrigatório'),
    uf: yup.string().required('Campo Obrigatório'),
    first_name: yup.string().required('Campo Obrigatório'),
    last_name: yup.string().required('Campo Obrigatório'),
    document_number: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CPF incompleto', value => value.replace(/\D/g, '').length === 11),
    telephone: yup
      .string()
      .required('Campo Obrigatório')
      .min(14, 'Telefone incompleto'),
    celphone: yup
      .string()
      .required('Campo Obrigatório')
      .test('celphone_incomplete', 'Celular incompleto', value => value && value.replace(/\D/g, '').length === 11),
    email: yup
      .string()
      .email('Email inválido')
      .required('Campo Obrigatório'),
    password: yup.string().required('Campo obrigatório'),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem'),
    terms_company_profile: yup.bool().test('accepted_terms', 'Os termos devem ser aceitos', value => value),
    terms_partnership: yup.bool().test('accepted_terms', 'Os termos devem ser aceitos', value => value)
  })
)

const schemaCPF = yupResolver(
  yup.object().shape({
    document_number: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CPF incompleto', value => value.replace(/\D/g, '').length === 11),
    cnpj: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CNPJ incompleto', value => value.replace(/\D/g, '').length === 14),
    company_name: yup.string().required('Campo Obrigatório'),
    zip_code: yup
      .string()
      .required('Campo Obrigatório')
      .test('min', 'CEP incompleto', value => value.replace(/\D/g, '').length === 8),
    street: yup.string().required('Campo Obrigatório'),
    number: yup.string().required('Campo Obrigatório'),
    district: yup.string().required('Campo Obrigatório'),
    complement: yup.string(),
    city: yup.string().required('Campo Obrigatório'),
    uf: yup.string().required('Campo Obrigatório'),
    terms_company_profile: yup.bool().test('accepted_terms', 'Os termos devem ser aceitos', value => value),
    terms_partnership: yup.bool().test('accepted_terms', 'Os termos devem ser aceitos', value => value)
  })
)

const defaultValues = {
  cnpj: '',
  company_name: '',
  zip_code: '',
  street: '',
  number: '',
  district: '',
  complement: '',
  city: '',
  uf: '',
  first_name: '',
  last_name: '',
  document_number: '',
  telephone: '',
  celphone: '',
  email: '',
  password: '',
  confirm_password: '',
  terms_company_profile: false,
  terms_partnership: false
}

const CompanyInfos = ({ control, errors }) => {
  return (
    <InputsContainer container>
      <Row>
        <NumberInput
          name='cnpj'
          format='##.###.###/####-##'
          control={control}
          defaultValue=''
          error={errors.cnpj}
          label='CNPJ *'
          width='20%'
          dense
        />
        <TextInput
          control={control}
          name='company_name'
          defaultValue=''
          error={errors.company_name}
          label='Empresa *'
          width='60%'
          dense
        />
        <NumberInput
          name='telephone'
          type='number'
          format={value =>
            value.length <= 10
              ? `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
              : `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
          }
          defaultValue=''
          control={control}
          error={errors.telephone}
          label='Telefone *'
          width='20%'
          dense
        />
      </Row>
      <Row>
        <NumberInput
          name='zip_code'
          type='number'
          format='#####-###'
          defaultValue=''
          control={control}
          error={errors.zip_code}
          label='CEP *'
          width='20%'
          dense
        />
        <TextInput
          control={control}
          name='street'
          defaultValue=''
          error={errors.street}
          label='Endereço Comercial *'
          width='40%'
          dense
        />
        <NumberInput
          name='number'
          type='number'
          defaultValue=''
          control={control}
          error={errors.number}
          label='Número *'
          width='20%'
          dense
        />
        <TextInput
          control={control}
          name='complement'
          defaultValue=''
          error={errors.complement}
          label='Complemento'
          width='20%'
          dense
        />
      </Row>
      <Row>
        <TextInput
          control={control}
          name='district'
          defaultValue=''
          error={errors.district}
          label='Bairro *'
          width='20%'
          dense
        />
        <TextInput
          control={control}
          name='city'
          defaultValue=''
          error={errors.city}
          label='Cidade *'
          width='25%'
          dense
        />
        <TextInput control={control} name='uf' defaultValue='' error={errors.uf} label='UF *' width='13.5%' dense />
        <BlankField width='41.5%' />
      </Row>
    </InputsContainer>
  )
}

const PersonalInfosCpfChecked = ({ control, errors }) => {
  return (
    <InputsContainer container>
      <NumberInput
        name='document_number'
        type='number'
        format='###.###.###-##'
        defaultValue=''
        control={control}
        error={errors.document_number}
        label='CPF *'
        width='25%'
        dense
      />
    </InputsContainer>
  )
}

const PersonalInfos = ({ control, errors }) => {
  return (
    <InputsContainer container>
      <Row>
        <NumberInput
          name='document_number'
          type='number'
          format='###.###.###-##'
          defaultValue=''
          control={control}
          error={errors.document_number}
          label='CPF *'
          width='25%'
          dense
        />
        <TextInput
          name='first_name'
          defaultValue=''
          error={errors.first_name}
          label='Nome *'
          control={control}
          width='25%'
          dense
        />
        <TextInput
          name='last_name'
          defaultValue=''
          error={errors.last_name}
          label='Sobrenome *'
          width='25%'
          dense
          control={control}
        />
        <NumberInput
          name='celphone'
          type='number'
          format='(##) #####-####'
          defaultValue=''
          control={control}
          error={errors.celphone}
          label='Celular *'
          width='25%'
          dense
        />
      </Row>
      <Row>
        <TextInput
          control={control}
          name='email'
          defaultValue=''
          error={errors.email}
          label='Email *'
          width='25%'
          dense
        />
        <TextInput
          name='password'
          type='password'
          defaultValue=''
          error={errors.password}
          label='Senha *'
          width='25%'
          dense
          control={control}
        />
        <TextInput
          type='password'
          name='confirm_password'
          defaultValue=''
          error={errors.confirm_password}
          label='Confirmar Senha *'
          width='25%'
          dense
          control={control}
        />
        <BlankField width='25%' />
      </Row>
    </InputsContainer>
  )
}

const InputsContainer = styled(Grid)`
  padding: 15px 15px;
  display: flex;
`

const SignUpPartner = () => {
  const [status, setStatus] = useState(IDLE)
  const [cpfChecked, setCpfChecked] = useState(true)
  const [isTrue, setIsTrue] = useState(false)
  const [cpf, setCpf] = useState('')

  const { register, handleSubmit, errors, control, reset, watch, setValue } = useForm({
    submitFocusError: false,
    resolver: isTrue ? schemaCPF : schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues
  })

  const cnpjValue = watch('cnpj')
  const zipCodeValue = watch('zip_code')
  const cpfValue = watch('document_number')

  useEffect(() => {
    const cpfCheck = async () => {
      if (cpfValue.replace(/\D/g, '').length === 11) {
        const { data } = await checkCpf(cpfValue.replace(/\D/g, ''))
        setIsTrue(data.have_user)
        setCpfChecked(data.have_user)
        setCpf(cpfValue.replace(/\D/g, ''))
      }
    }
    cpfCheck()
  }, [cpfValue])

  useEffect(() => {
    setValue('document_number', cpf)
  }, [cpf, setValue])

  const onSubmit = async data => {
    try {
      setStatus(SENDING)
      if (!isTrue) {
        const body = {
          document_number: data.document_number.replace(/\D/g, ''),
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
          email: data.email,
          telephone_prefix: data.telephone.replace(/\D/g, '').slice(0, 2),
          telephone: data.telephone.replace(/\D/g, '').slice(2),
          celphone_prefix: data.celphone.replace(/\D/g, '').slice(0, 2),
          celphone: data.celphone.replace(/\D/g, '').slice(2),
          street: data.street,
          number: data.number,
          zip_code: data.zip_code.replace(/\D/g, ''),
          ...(data.complement && { complement: data.complement }),
          district: data.district,
          city: data.city,
          uf: data.uf,
          cnpj: data.cnpj.replace(/\D/g, ''),
          company_name: data.company_name
        }
        const { data: response } = await signupPartner(body)
        setToken(response.token)
        window.location.replace('/minhas-indicacoes')
      } else {
        const body = {
          telephone_prefix: data.telephone.replace(/\D/g, '').slice(0, 2),
          telephone: data.telephone.replace(/\D/g, '').slice(2),
          document_number: data.document_number.replace(/\D/g, ''),
          street: data.street,
          number: data.number,
          zip_code: data.zip_code.replace(/\D/g, ''),
          ...(data.complement && { complement: data.complement }),
          district: data.district,
          city: data.city,
          uf: data.uf,
          cnpj: data.cnpj.replace(/\D/g, ''),
          company_name: data.company_name,
          email: data.email
        }
        const { data: response } = await signupPartner(body)
        setToken(response.token)
        toast.success('Cadastro realizado com sucesso')
        window.location.replace('/minhas-indicacoes')
      }
      reset()
    } catch (error) {
      if (error.response.status === 400) {
        return toast.error(error.response.data.message)
      }
      if (error.response) {
        if (error.response.data.internalError && /Duplicate entry/.test(error.response.data.internalError)) {
          if (/account_c_document_number_uindex/.test(error.response.data.internalError)) {
            return toast.error('Este CPF já foi cadastrado')
          }
          if (/account_c_email_uindex/.test(error.response.data.internalError)) {
            return toast.error('Este email já foi cadastrado')
          }
        }
        return toast.error(error.response.data.message)
      }
      toast.error(
        'Ocorreu um erro desconhecido ao realizar o cadastro, verifique os dados e se você já não tem cadastro.'
      )
    } finally {
      setStatus(IDLE)
    }
  }

  const setCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfo(cnpj)
        if (companyInfo.status === 'ERROR') throw new Error(companyInfo.message)
        setValue('company_name', companyInfo.nome)
      } catch (error) {
        toast.error(error.message)
      }
    },
    [setValue]
  )

  useEffect(() => {
    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')
    if (sanitizedCnpj.toString().length === 14) {
      setCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, setCompanyName])

  const setAddressInfos = useCallback(
    async zipCode => {
      try {
        const addressInfo = await getAddressByCep(zipCode)
        setValue('street', addressInfo.logradouro)
        setValue('district', addressInfo.bairro)
        setValue('city', addressInfo.localidade)
        setValue('uf', addressInfo.uf)
      } catch (error) {
        toast.error(error.message)
      }
    },
    [setValue]
  )

  useEffect(() => {
    const sanitizedZipCode = zipCodeValue.replace(/\D/g, '')
    if (sanitizedZipCode.length === 8) {
      setAddressInfos(sanitizedZipCode)
    }
  }, [zipCodeValue, setAddressInfos])

  if (status === LOADING) {
    return <LoadingScreen />
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer>
          <Title>Dados da Empresa</Title>
        </TitleContainer>
        <CompanyInfos control={control} errors={errors} />
        <TitleContainer>
          <Title> Dados do Contato</Title>
        </TitleContainer>
        {isTrue ? (
          <Alert>
            {' '}
            CPF já cadastrado, basta aceitar os termos e clicar em Criar Conta para ativar sua conta de parceiro{' '}
          </Alert>
        ) : null}
        {!cpfChecked ? (
          <PersonalInfos control={control} errors={errors} defaultValue={cpf} />
        ) : (
          <PersonalInfosCpfChecked control={control} errors={errors} defaultValue={cpf} />
        )}

        <TermsContainer>
          <CheckboxContainer style={{ marginBottom: '15px' }}>
            <Checkbox name='terms_company_profile' error={errors.terms_company_profile} register={register()}>
              <Text>Li e Aceito os</Text>
              <Terms
                onClick={e => {
                  e.preventDefault()
                  window.open('https://kavodlending.com/parcerias/PerfilEmpresasParceriaKavod.pdf')
                }}
              >
                Termos de Perfil de Empresas e de Operações para Indicação
              </Terms>
            </Checkbox>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name='terms_partnership' error={errors.terms_partnership} register={register()}>
              <Text>Li e Aceito os</Text>
              <Terms
                onClick={e => {
                  e.preventDefault()
                  window.open('https://kavodlending.com/parcerias/TermosParceriaKavod.pdf')
                }}
              >
                Termos de Parceria Kavod Lending
              </Terms>
            </Checkbox>
          </CheckboxContainer>
        </TermsContainer>
        <ButtonContainer>
          <Button label='CRIAR CONTA' type='submit' width='270px' />
        </ButtonContainer>
      </Form>
    </Container>
  )
}

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 16px;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Terms = styled.span`
  color: ${colors.blueKavod};
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const Container = styled(Grid)`
  padding: 30px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 0px;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Text = styled.span`
  color: #000 !important;
  font-size: 16px !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
`

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #424242;
  margin: 5px 0px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  padding: 0px 15px;
`

const ButtonContainer = styled.div`
  margin: 10px;
  padding: 0px 15px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin: 10px 0px;
  }
`

const Alert = styled.p`
  color: ${colors.blueKavod};
  font-weight: bold;
`

export default SignUpPartner
